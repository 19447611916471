import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OtherIncome } from '../model/other-income.model';
import { RentalIncome } from '../model/rental-income.model';
import { WorkIncome } from '../model/work-income.model';

@Injectable({ providedIn: 'root' })
export class IncomeDetailService {
	public addEditWorkIncome$: Observable<WorkIncome | null>;
	public addEditRentalIncome$: Observable<RentalIncome | null>;
	public addEditOtherIncome$: Observable<OtherIncome | null>;
	private addEditWorkIncomeSource = new BehaviorSubject<WorkIncome | null>(null);
	private addEditRentalIncomeSource = new BehaviorSubject<RentalIncome | null>(null);
	private addEditOtherIncomeSource = new BehaviorSubject<OtherIncome | null>(null);

	constructor() {
		this.addEditWorkIncome$ = this.addEditWorkIncomeSource.asObservable();
		this.addEditRentalIncome$ = this.addEditRentalIncomeSource.asObservable();
		this.addEditOtherIncome$ = this.addEditOtherIncomeSource.asObservable();
	}

	public addEditWorkIncome(workIncome?: WorkIncome): void {
		this.addEditWorkIncomeSource.next(workIncome ?? null);
	}

	public addEditRentalIncome(rentalIncome?: RentalIncome): void {
		this.addEditRentalIncomeSource.next(rentalIncome ?? null);
	}

	public addEditOtherIncome(otherIncome?: OtherIncome): void {
		this.addEditOtherIncomeSource.next(otherIncome ?? null);
	}
}
