import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { EnumObject, NonRealEstateAssetType } from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { AssetService } from '../../api/asset.service';
import { AssetType } from '../../enums/asset-type.enum';
import { OtherAsset } from '../../model/other-asset.model';
import { AssetDetailService } from '../../service/asset-detail.service';

@Component({
	selector: 'other-asset-details',
	templateUrl: './other-asset-details.component.html',
	styleUrls: ['./other-asset-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherAssetDetailsComponent implements OnInit, OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<OtherAsset> = new EventEmitter<OtherAsset>();
	@Output() public delete: EventEmitter<OtherAsset> = new EventEmitter<OtherAsset>();

	public otherAssetForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public assetTypes: EnumObject[] = [];
	public applicants: Applicant[] = [];
	public isEditMode = false;
	public isSubmitting = false;

	public sectionTitle = '';
	public whoseAssetConfig: FieldMetadata = {} as FieldMetadata;
	public typeConfig: FieldMetadata = {} as FieldMetadata;
	public descriptionConfig: FieldMetadata = {} as FieldMetadata;
	public valueConfig: FieldMetadata = {} as FieldMetadata;
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private assetService: AssetService,
		private applicantService: ApplicantService,
		private assetDetailService: AssetDetailService,
		private changeDetectorRef: ChangeDetectorRef,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.otherAssetForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			value: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
			type: [null, Validators.required],
			description: ['', Validators.maxLength(200)]
		});

		this.validationErrors = {
			value: {
				required: 'We need the other amount',
				min: 'Value cannot be zero',
				max: 'Value cannot be more than $10,000,000'
			},
			type: {
				required: 'Select a type'
			},
			applicantId: {
				required: 'Select who is this for'
			},
			description: {
				maxlength: 'Description cannot be more than 200 characters'
			}
		};

		this.applicants = this.applicantService.getApplicants(true);
		this.assetDetailService.addEditOtherAsset$
			.pipe(takeUntil(this.destroy$))
			.subscribe((otherAsset: OtherAsset | null) => {
				this.addEditOtherAsset(otherAsset);
			});
	}

	public get value() {
		return this.otherAssetForm.controls.value as UntypedFormControl;
	}

	public get type() {
		return this.otherAssetForm.controls.type as UntypedFormControl;
	}

	public get applicantId() {
		return this.otherAssetForm.controls.applicantId as UntypedFormControl;
	}

	public get description() {
		return this.otherAssetForm.controls.description as UntypedFormControl;
	}

	public ngOnInit(): void {
		this.type.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((type: any) => {
			this.changeDetectorRef.markForCheck();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.otherAssetForm.invalid) {
			return;
		}

		this.isSubmitting = true;
		const assetToSave = {
			...this.otherAssetForm.value,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			applicantId: this.applicantId.value,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			value: CurrencyHelper.unformatAmount(this.value.value),
			type: (this.type.value as EnumObject).id,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			description: this.description.value?.trim(),
			percent: 100
		} as OtherAsset;
		this.assetService
			.saveOtherAsset(assetToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.otherAssetForm.controls.id.setValue(result);
				assetToSave.id = result;
				this.update.emit(assetToSave);
			})
			.add(() => {
				this.isSubmitting = false;
				this.changeDetectorRef.markForCheck();
			});
	}

	public deleteRecord(): void {
		this.assetService
			.deleteOtherAsset(this.otherAssetForm.controls.applicationId.value, this.otherAssetForm.controls.id.value)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.otherAssetForm.value);
			});
	}

	public populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}

	private addEditOtherAsset(otherAsset: OtherAsset | null): void {
		this.otherAssetForm.reset();
		if (otherAsset) {
			this.isEditMode = true;
			this.otherAssetForm.patchValue({
				id: otherAsset.id,
				applicationId: otherAsset.applicationId,
				applicantId: otherAsset.applicantId,
				description: otherAsset.description,
				type: EnumHelper.getEnumObject(
					NonRealEstateAssetType as unknown as { [index: string]: number },
					otherAsset.type
				),
				value: otherAsset.value
			});
			this.applicantId.disable();
		} else {
			this.isEditMode = false;
			this.otherAssetForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				description: '',
				type: null,
				value: null
			});
			this.applicantId.enable();
		}
		this.changeDetectorRef.markForCheck();
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Assets',
				AssetType.Other,
				AssetType.Other
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about this asset`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseAssetConfig = this.metadataService.getFieldByName('WhoseAsset', fields);
			this.typeConfig = this.metadataService.getFieldByName('Type', fields);
			this.assetTypes = this.populateOptions(this.typeConfig.options);
			this.descriptionConfig = this.metadataService.getFieldByName('Description', fields);
			this.valueConfig = this.metadataService.getFieldByName('Value', fields);

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}
}
