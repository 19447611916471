import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { EnumObject, FrequencyShort, OtherIncomeType } from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { IncomeService } from '../../api/income.service';
import { IncomeType } from '../../enums/income-type.enum';
import { OtherIncome } from '../../model/other-income.model';
import { IncomeDetailService } from '../../service/income-detail.service';

@Component({
	selector: 'other-income-details',
	templateUrl: './other-income-details.component.html',
	styleUrls: ['./other-income-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherIncomeDetailsComponent implements OnInit, OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<OtherIncome> = new EventEmitter<OtherIncome>();
	@Output() public delete: EventEmitter<void> = new EventEmitter<void>();

	public otherIncomeForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: { incomeTypes: EnumObject[]; incomeFrequencies: EnumObject[]; applicants: Applicant[] } = {
		incomeTypes: [],
		incomeFrequencies: [],
		applicants: []
	};
	public showOtherIncomeType = false;
	public isEditMode = false;
	public isSubmitting = false;

	public sectionTitle = '';
	public whoseIncomeConfig: FieldMetadata = {} as FieldMetadata;
	public otherIncomeTypeConfig: FieldMetadata = {} as FieldMetadata;
	public otherIncomeTypeOptions: EnumObject[] = [];
	public otherIncomeAmountConfig: FieldMetadata = {} as FieldMetadata;
	public otherIncomeFrequencyConfig: FieldMetadata = {} as FieldMetadata;
	public otherIncomeFrequencyOptions: EnumObject[] = [];
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private incomeService: IncomeService,
		private incomeDetailService: IncomeDetailService,
		private applicantService: ApplicantService,
		private changeDetectorRef: ChangeDetectorRef,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.options = {
			incomeTypes: EnumHelper.getEnumArray(OtherIncomeType as unknown as { [index: string]: number }),
			incomeFrequencies: EnumHelper.getEnumArray(FrequencyShort as unknown as { [index: string]: number }),
			applicants: this.applicantService.getApplicants(true)
		};

		this.otherIncomeForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			description: ['', Validators.maxLength(200)],
			type: [null, Validators.required],
			otherIncome: this.formBuilder.group({
				amount: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
				frequency: [this.getDefaultFrequency()?.id, Validators.required]
			})
		});

		this.validationErrors = {
			type: {
				required: 'Select an income type'
			},
			amount: {
				required: 'We need the income amount',
				min: 'Amount cannot be zero',
				max: 'Amount cannot be more than $10,000,000'
			},
			applicantId: {
				required: 'Select who is this for'
			},
			description: {
				maxlength: 'Description cannot be more than 200 characters'
			}
		};

		this.incomeDetailService.addEditOtherIncome$
			.pipe(takeUntil(this.destroy$))
			.subscribe((otherIncome: OtherIncome | null) => {
				this.addEditOtherIncome(otherIncome);
			});
	}

	public get description(): UntypedFormControl {
		return this.otherIncomeForm.controls.description as UntypedFormControl;
	}

	public get type(): UntypedFormControl {
		return this.otherIncomeForm.controls.type as UntypedFormControl;
	}

	public get otherIncome(): UntypedFormGroup {
		return this.otherIncomeForm.controls.otherIncome as UntypedFormGroup;
	}

	public get applicantId() {
		return this.otherIncomeForm.controls.applicantId as UntypedFormControl;
	}

	public ngOnInit(): void {
		this.type.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((incomeType: EnumObject) => {
			if (incomeType && incomeType.id === OtherIncomeType.OtherIncome) {
				this.showOtherIncomeType = true;
			} else {
				this.showOtherIncomeType = false;
			}
			this.changeDetectorRef.markForCheck();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.otherIncomeForm.invalid) {
			return;
		}

		this.isSubmitting = true;
		const incomeToSave = {
			...this.otherIncomeForm.value,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			type: this.type.value.id,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			description: this.type.value.id === OtherIncomeType.OtherIncome ? this.description.value : '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			amount: CurrencyHelper.unformatAmount(this.otherIncome.value.amount),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			frequency: this.otherIncome.value.frequency,
			percent: 100
		} as OtherIncome;

		this.incomeService
			.saveOtherIncome(incomeToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.otherIncomeForm.controls.id.setValue(result);
				incomeToSave.id = result;
				this.update.emit(incomeToSave);
			})
			.add(() => {
				this.isSubmitting = false;
				this.changeDetectorRef.markForCheck();
			});
	}

	public deleteRecord(): void {
		this.incomeService
			.deleteOtherIncome(this.otherIncomeForm.controls.applicationId.value, this.otherIncomeForm.controls.id.value)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.otherIncomeForm.value);
			});
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.options.incomeFrequencies.find((frequency: EnumObject) => frequency.id === FrequencyShort.Yearly);
	}

	private addEditOtherIncome(otherIncome: OtherIncome | null): void {
		this.otherIncomeForm.reset();
		if (otherIncome) {
			this.isEditMode = true;
			this.otherIncomeForm.patchValue({
				id: otherIncome.id,
				applicationId: otherIncome.applicationId,
				applicantId: otherIncome.applicantId,
				type: EnumHelper.getEnumObject(OtherIncomeType as unknown as { [index: string]: number }, otherIncome.type),
				description: otherIncome.description,
				otherIncome: {
					amount: otherIncome.amount,
					frequency: otherIncome.frequency
				}
			});
			if (otherIncome.type === OtherIncomeType.OtherIncome) {
				this.showOtherIncomeType = true;
			}
		} else {
			this.isEditMode = false;
			this.otherIncomeForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				description: '',
				otherIncome: {
					amount: null,
					frequency: this.getDefaultFrequency()?.id
				}
			});
		}
		this.changeDetectorRef.markForCheck();
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Income',
				IncomeType.Other,
				IncomeType.Other
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about this income`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseIncomeConfig = this.metadataService.getFieldByName('WhoseIncome', fields);
			this.otherIncomeTypeConfig = this.metadataService.getFieldByName('OtherIncomeType', fields);
			this.otherIncomeTypeOptions = this.populateOptions(this.otherIncomeTypeConfig?.options);
			this.otherIncomeAmountConfig = this.metadataService.getFieldByName('Amount', fields);
			this.otherIncomeFrequencyConfig = this.metadataService.getFieldByName('Frequency', fields);
			this.otherIncomeFrequencyOptions = this.populateOptions(this.otherIncomeFrequencyConfig?.options);

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}

	private populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}
}
