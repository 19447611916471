<div class="liability-details">
	<div class="liability-details__section-title col-12 col-md-6">
		<i class="fal fa-credit-card fa-2x simp-margin-right-small simp-text--tertiary"></i>
		<h4 class="simp-h4 simp-text--primary liability-details__title--mobile">{{ areaMetadata.title }}</h4>
		<h1 class="simp-h1 simp-text--primary liability-details__title--desktop">{{ areaMetadata.title }}</h1>
	</div>

	<div class="liability-details__form-container col-12 col-md-6">
		<form class="liability-details__form col-12" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="liability-details__scrollable-area">
				<div class="liability-details__category simp-margin-bottom-large">
					<div class="liability-details__category-title simp-padding-vertical-base">
						{{ creditCardSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button liability-details__category-add"
							(click)="addEditAdditionalLiability(liabilityType.CreditCard)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="liability-details__income-summary simp-padding-small"
						*ngFor="let creditCardRecord of liability.creditCard"
						(click)="addEditAdditionalLiability(liabilityType.CreditCard, creditCardRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light liability-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(creditCardRecord.applicantId) + ", " : "" }} {{
							creditCardRecord.creditLimit | currency: "USD":"symbol":"1.0-0" }} limit, {{ creditCardRecord.issuer |
							enumDesc: financialInstitution }}
						</h5>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>

				<div class="liability-details__category simp-margin-bottom-large">
					<div class="liability-details__category-title simp-padding-vertical-base">
						{{ personalLoanSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button liability-details__category-add"
							(click)="addEditAdditionalLiability(liabilityType.PersonalLoan)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="liability-details__income-summary simp-padding-small"
						*ngFor="let personalLoanRecord of liability.personalLoan"
						(click)="addEditAdditionalLiability(liabilityType.PersonalLoan, personalLoanRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light liability-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(personalLoanRecord.applicantId) + ", " : "" }} {{
							personalLoanRecord.financialInstitution | enumDesc: financialInstitution }}, {{
							personalLoanRecord.loanAmount | currency: "USD":"symbol":"1.0-0" }}
						</h5>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>

				<div class="liability-details__category simp-margin-bottom-large">
					<div class="liability-details__category-title simp-padding-vertical-base">
						{{ homeLoanSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button liability-details__category-add"
							(click)="addEditAdditionalLiability(liabilityType.HomeLoan)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="liability-details__income-summary simp-padding-small"
						*ngFor="let homeLoanRecord of liability.homeLoan"
						(click)="addEditAdditionalLiability(liabilityType.HomeLoan, homeLoanRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light liability-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(homeLoanRecord.applicantId) + ", " : "" }} {{
							homeLoanRecord.loanAmount | currency: "USD":"symbol":"1.0-0" }}, {{
							buildAddressLine(homeLoanRecord.address) }}, {{ homeLoanRecord.financialInstitution | enumDesc:
							financialInstitution }}
						</h5>
						<i
							class="fas fa-info-circle simp-text--grey70 simp-margin-right-base"
							[ngbPopover]="popContent"
							[triggers]="popTrigger"
							placement="left"
							popoverClass="purchasing-help-popover"
							(shown)="handlePopoverShown()"
							(hidden)="handlePopoverHidden()"
							(click)="$event.stopPropagation()"
							#popover2="ngbPopover"
							*ngIf="homeLoanRecord.address.isPrimaryProperty"
						></i>
						<ng-template #popContent>
							<div class="simp-text simp-text-small">
								This is the property being {{ isRefinance ? "refinanced" : "purchased" }}
							</div>
						</ng-template>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>

				<div class="liability-details__category">
					<div class="liability-details__category-title simp-padding-vertical-base">
						{{ otherLiabilitiesSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button liability-details__category-add"
							(click)="addEditAdditionalLiability(liabilityType.Other)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="liability-details__income-summary simp-padding-small"
						*ngFor="let otherLiabilityRecord of liability.otherLiability"
						(click)="addEditAdditionalLiability(liabilityType.Other, otherLiabilityRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light liability-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(otherLiabilityRecord.applicantId) + ", " : "" }} {{
							otherLiabilityRecord.outstandingBalance | currency: "USD" }}, {{ otherLiabilityRecord.type | enumDesc:
							liabilityTypeLimited }}{{ showOtherLiabilityDescription(otherLiabilityRecord) ? " - " +
							otherLiabilityRecord.description : "" }}
						</h5>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>
			</div>

			<div class="liability-details__summary simp-margin-vertical-medium simp-padding-horizontal-small">
				<div>{{ totalLiabilities | currency: "USD":"symbol":"1.0-0" }}</div>
				<div>total liabilities</div>
			</div>

			<div class="liability-details__buttons-container simp-row simp-margin-bottom-medium">
				<div class="col-6">
					<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="finishLater()">
						{{ finishButtonConfig.title }}
					</button>
				</div>
				<div class="col-6">
					<button
						type="submit"
						class="btn btn-primary btn-lg simp-button col-12"
						[disabled]="!hasLiability && !hasSelectedLiabilityPreference"
					>
						{{ doneButtonConfig.title }}
					</button>
				</div>
			</div>
			<div class="simp-margin-bottom-medium" *ngIf="!hasLiability && !hasSelectedLiabilityPreference">
				<button type="button" class="btn btn-tertiary btn-lg simp-button col-12" (click)="onSubmit()">
					{{ continueButtonConfig.title }}
				</button>
			</div>
		</form>
	</div>
</div>

<additional-liability-details
	[@slideInOut]="additionalInfoState"
	[liabilityType]="additionalInfoType"
	(goBack)="toggleAdditionalInfoPage()"
	(updateLiability)="updateLiability($event)"
	(deleteLiability)="handleLiabilityDelete($event)"
></additional-liability-details>

<ng-template #aipCompleteContent>
	<i class="fas fa-check-circle overlay__check-icon simp-margin-bottom-medium"></i>
	<h4 class="simp-h4">{{ loadingMessage1 }}</h4>
	<h4 class="simp-h4" *ngIf="loadingMessage2Config?.visible === 'Visible'">{{ loadingMessage2Config.title }}</h4>
</ng-template>

<ng-template #finshLaterContent>
	<h4 class="simp-h4 overlay__message">We've saved where you're up to. Return and continue any time.</h4>
	<div class="simp-row overlay__buttons-container">
		<div class="col-6">
			<button class="btn btn-secondary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay()">
				Keep going
			</button>
		</div>
		<div class="col-6">
			<button class="btn btn-primary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay(true)">
				Exit
			</button>
		</div>
	</div>
</ng-template>
