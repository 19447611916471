import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { Address } from '../model/address.model';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseApiService<Address> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/Address');
	}

	public getByApplication(applicationId: number): Observable<Address[]> {
		return <Observable<Address[]>>this.getCustom(`${applicationId}`);
	}
}
