<div class="personal-details">
	<form class="personal-details__form" [formGroup]="personalDetailsForm" (ngSubmit)="onSubmit()" autocomplete="off">
		<div class="personal-details__section simp-row">
			<div class="personal-details__section-title col-12 col-md-6">
				<i class="fas fa-user-alt fa-2x simp-margin-right-small simp-text--tertiary"></i>
				<h4 class="simp-h4 simp-text--primary personal-details__title--mobile">{{ primaryApplicantSectionTitle }}</h4>
				<h1 class="simp-h1 simp-text--primary personal-details__title--desktop">{{ primaryApplicantSectionTitle }}</h1>
			</div>

			<div formGroupName="primaryApplicantGroup" class="col-12 col-md-6">
				<div class="col-12">
					<div class="simp-row">
						<div class="form-group col-4" *ngIf="primaryApplicantTitleConfig?.visible === 'Visible'">
							<label class="simp-label" for="titleSelect">{{ primaryApplicantTitleConfig.title }}</label>
							<ng-select
								class="simp-select"
								labelForId="titleSelect"
								placeholder=""
								[items]="primaryApplicantTitleOptions"
								[searchable]="false"
								[clearable]="false"
								[formControl]="primaryApplicantTitle"
							>
							</ng-select>

							<simp-field-error
								*ngIf="(primaryApplicantTitle.dirty || primaryApplicantTitle.touched) && primaryApplicantTitle.errors"
								[errors]="primaryApplicantTitle.errors"
								[validationErrors]="validationErrors.title"
							></simp-field-error>
						</div>
						<simp-input
							class="col-8"
							id="firstName"
							[formControl]="primaryApplicantFirstName"
							[validationErrors]="validationErrors.firstName"
							*ngIf="primaryApplicantFirstNameConfig?.visible === 'Visible'"
						>
							{{ primaryApplicantFirstNameConfig.title }}
						</simp-input>
					</div>

					<div class="simp-row">
						<simp-input
							class="col-12"
							id="lastName"
							[formControl]="primaryApplicantLastName"
							[validationErrors]="validationErrors.lastName"
							*ngIf="primaryApplicantLastNameConfig?.visible === 'Visible'"
						>
							{{ primaryApplicantLastNameConfig.title }}
						</simp-input>
					</div>

					<div class="simp-row">
						<simp-input
							class="col-12"
							id="email"
							[formControl]="primaryApplicantEmail"
							[validationErrors]="validationErrors.email"
							*ngIf="primaryApplicantEmailConfig?.visible === 'Visible'"
						>
							{{ primaryApplicantEmailConfig.title }}
						</simp-input>
					</div>

					<div class="simp-row">
						<simp-input
							class="col-6"
							id="mobile"
							[formControl]="primaryApplicantMobilePhoneNumber"
							[validationErrors]="validationErrors.mobilePhoneNumber"
							*ngIf="primaryApplicantMobileConfig?.visible === 'Visible'"
						>
							{{ primaryApplicantMobileConfig.title }}
						</simp-input>

						<ng-container *ngIf="!isMultiApplicant; then maritalStatus"></ng-container>
					</div>

					<div class="simp-row" *ngIf="isMultiApplicant">
						<ng-container *ngTemplateOutlet="maritalStatus"></ng-container>

						<simp-button-group
							id="partnerCoApplicant"
							class="col-6"
							[formControl]="primaryApplicantPartnerCoApplicant"
							[validationErrors]="validationErrors.isPartnerCoApplicant"
							[options]="options.partnerCoApplicant"
							*ngIf="showPartnerCoApplicant"
						>
							{{ primaryApplicantPartnerCoApplicantConfig.title }}
						</simp-button-group>
					</div>

					<div class="simp-row">
						<div
							class="form-group col-6 simp-datepicker"
							*ngIf="primaryApplicantDateOfBirthConfig?.visible === 'Visible'"
						>
							<label class="simp-label">{{ primaryApplicantDateOfBirthConfig.title }}</label>
							<div class="input-group">
								<input
									type="text"
									id="dateOfBirth"
									class="form-control"
									[class.is-invalid]="
                    primaryApplicantDateOfBirth.invalid &&
                    (primaryApplicantDateOfBirth.dirty || primaryApplicantDateOfBirth.touched)
                  "
									[formControl]="primaryApplicantDateOfBirth"
									placeholder="DD/MM/YYYY"
									[minDate]="minDateOfBirth"
									[maxDate]="maxDateOfBirth"
									ngbDatepicker
									#dob="ngbDatepicker"
								/>
								<div class="input-group-text">
									<button class="btn" (click)="dob.toggle()" type="button">
										<i class="fal fa-calendar-alt"></i>
									</button>
								</div>
							</div>

							<simp-field-error
								*ngIf="
                  (primaryApplicantDateOfBirth.dirty || primaryApplicantDateOfBirth.touched) &&
                  primaryApplicantDateOfBirth.errors
                "
								[errors]="primaryApplicantDateOfBirth.errors"
								[validationErrors]="validationErrors.dateOfBirth"
							></simp-field-error>
						</div>
					</div>

					<div class="simp-row" *ngIf="primaryApplicantMemberNumberConfig?.visible === 'Visible'">
						<simp-input
							class="col"
							id="memberNumber"
							[formControl]="primaryApplicantMemberNumber"
							[validationErrors]="validationErrors.memberNumber"
						>
							{{ primaryApplicantMemberNumberConfig.title }}
						</simp-input>
					</div>

					<div
						class="personal-details__privacy-check simp-margin-top-base"
						[class.simp-margin-bottom-large]="isMultiApplicant"
					>
						<input
							class="personal-details__privacy-checkbox"
							type="checkbox"
							id="privacyTerms"
							[formControl]="primaryApplicantPrivacyChecked"
						/>
						<label for="privacyTerms"
							><h6 class="simp-h6 simp-text--grey70">
								{{ privacyContent1 }}
								<a class="simp-link simp-text-small" [routerLink]="[]" (click)="togglePrivacyTerms()">
									{{ privacyContentLinkText }}</a
								>. {{ privacyContent2 }}
							</h6></label
						>
					</div>
				</div>
			</div>
		</div>

		<div class="personal-details__section simp-row simp-margin-top-medium">
			<div class="personal-details__section-title col-12 col-md-6" *ngIf="isMultiApplicant">
				<i class="fas fa-user-alt fa-2x simp-margin-right-small simp-text--tertiary"></i>
				<h4 class="simp-h4 simp-text--primary personal-details__title--mobile">
					{{ additionalApplicantSectionTitle }}
				</h4>
				<h1 class="simp-h1 simp-text--primary personal-details__title--desktop">
					{{ additionalApplicantSectionTitle }}
				</h1>
			</div>

			<div formGroupName="additionalApplicantGroup" class="col-12 col-md-6" *ngIf="isMultiApplicant">
				<div class="col-12">
					<div class="simp-row">
						<div class="form-group col-4" *ngIf="additionalApplicantTitleConfig?.visible === 'Visible'">
							<label class="simp-label" for="titleSelect">{{ additionalApplicantTitleConfig.title }}</label>
							<ng-select
								class="simp-select"
								labelForId="titleSelect"
								placeholder=""
								[items]="primaryApplicantTitleOptions"
								[searchable]="false"
								[clearable]="false"
								[formControl]="additionalApplicantTitle"
							>
							</ng-select>

							<simp-field-error
								*ngIf="
                  (additionalApplicantTitle.dirty || additionalApplicantTitle.touched) &&
                  additionalApplicantTitle.errors
                "
								[errors]="additionalApplicantTitle.errors"
								[validationErrors]="validationErrors.title"
							></simp-field-error>
						</div>
						<simp-input
							class="col-8"
							id="partnerFirstName"
							[formControl]="additionalApplicantFirstName"
							[validationErrors]="validationErrors.firstName"
							*ngIf="additionalApplicantFirstNameConfig?.visible === 'Visible'"
						>
							{{ additionalApplicantFirstNameConfig.title }}
						</simp-input>
					</div>
					<div class="simp-row">
						<simp-input
							class="col-12"
							id="partnerLastName"
							[formControl]="additionalApplicantLastName"
							[validationErrors]="validationErrors.lastName"
							*ngIf="additionalApplicantLastNameConfig?.visible === 'Visible'"
						>
							{{ additionalApplicantLastNameConfig.title }}
						</simp-input>
					</div>

					<div class="simp-row">
						<simp-input
							class="col-12"
							id="partnerEmail"
							[formControl]="additionalApplicantEmail"
							[validationErrors]="validationErrors.email"
							*ngIf="additionalApplicantEmailConfig?.visible === 'Visible'"
						>
							{{ additionalApplicantEmailConfig.title }}
						</simp-input>
					</div>

					<div class="simp-row simp-margin-bottom-small">
						<simp-input
							class="col-6"
							id="mobileCoApplicant"
							[formControl]="additionalApplicantMobilePhoneNumber"
							[validationErrors]="validationErrors.mobilePhoneNumber"
							*ngIf="additionalApplicantMobileConfig?.visible === 'Visible'"
						>
							{{ additionalApplicantMobileConfig.title }}
						</simp-input>

						<div
							class="form-group col-6"
							*ngIf="enableAdditionalMaritalStatus && additionalApplicantMaritalStatusConfig?.visible === 'Visible'"
						>
							<label class="simp-label" for="maritalStatusSelect"
								>{{ additionalApplicantMaritalStatusConfig.title }}</label
							>
							<ng-select
								class="simp-select"
								labelForId="maritalStatusSelect"
								placeholder=""
								[items]="primaryApplicantMaritalStatusOptions"
								[searchable]="false"
								[clearable]="false"
								[formControl]="additionalApplicantMaritalStatus"
							></ng-select>

							<simp-field-error
								*ngIf="
                  (additionalApplicantMaritalStatus.dirty || additionalApplicantMaritalStatus.touched) &&
                  additionalApplicantMaritalStatus.errors
                "
								[errors]="additionalApplicantMaritalStatus.errors"
								[validationErrors]="validationErrors.maritalStatus"
							></simp-field-error>
						</div>
					</div>
					<div class="simp-row" *ngIf="additionalApplicantMemberNumberConfig?.visible === 'Visible'">
						<simp-input
							class="col"
							id="memberNumber"
							[formControl]="additionalApplicantMemberNumber"
							[validationErrors]="validationErrors.memberNumber"
						>
							{{ additionalApplicantMemberNumberConfig.title }}
						</simp-input>
					</div>
				</div>
			</div>
		</div>

		<div class="personal-details__buttons-container">
			<div class="col-6 col-md-3 personal-details__cancel-button">
				<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="cancelApplication()">
					{{ cancelButtonConfig.title }}
				</button>
			</div>
			<div class="col-6 col-md-3">
				<button
					type="submit"
					class="btn btn-primary btn-lg simp-button col-12"
					[disabled]="!personalDetailsForm.valid || isSubmitting"
				>
					{{ continueButtonConfig.title }}
				</button>
			</div>
		</div>
	</form>
</div>

<ng-template #maritalStatus>
	<div class="form-group col-6" *ngIf="primaryApplicantMaritalStatusConfig?.visible === 'Visible'">
		<label class="simp-label" for="maritalStatusSelect">{{ primaryApplicantMaritalStatusConfig.title }}</label>
		<ng-select
			class="simp-select"
			labelForId="maritalStatusSelect"
			placeholder=""
			[items]="primaryApplicantMaritalStatusOptions"
			[searchable]="false"
			[clearable]="false"
			[formControl]="primaryApplicantMaritalStatus"
		></ng-select>

		<simp-field-error
			*ngIf="
        (primaryApplicantMaritalStatus.dirty || primaryApplicantMaritalStatus.touched) &&
        primaryApplicantMaritalStatus.errors
      "
			[errors]="primaryApplicantMaritalStatus.errors"
			[validationErrors]="validationErrors.maritalStatus"
		></simp-field-error>
	</div>
</ng-template>

<privacy-terms [@slideInOut]="showPrivacyTerms ? 'show' : 'hide'" (goBack)="togglePrivacyTerms()"></privacy-terms>
