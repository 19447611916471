<div class="additional-info">
	<div class="additional-info__header">
		<div class="additional-info__back-button" (click)="handleBackClick()">
			<i class="far fa-arrow-left"></i>
		</div>
		<h6 class="simp-h6 additional-info__heading simp-text--grey70">{{ sectionHeader }}</h6>
	</div>

	<div class="simp-padding-small additional-info__scrollable-content" [ngSwitch]="assetType">
		<ng-template [ngSwitchCase]="'Property'">
			<property-asset-details
				(goBack)="handleBackClick()"
				(update)="updateAssetDetails($event)"
				(delete)="deleteAssetDetails($event)"
			></property-asset-details>
		</ng-template>

		<ng-template [ngSwitchCase]="'Savings'">
			<savings-asset-details
				(goBack)="handleBackClick()"
				(update)="updateAssetDetails($event)"
				(delete)="deleteAssetDetails($event)"
			></savings-asset-details>
		</ng-template>

		<ng-template [ngSwitchCase]="'Other'">
			<other-asset-details
				(goBack)="handleBackClick()"
				(update)="updateAssetDetails($event)"
				(delete)="deleteAssetDetails($event)"
			></other-asset-details>
		</ng-template>
	</div>
</div>
