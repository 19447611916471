import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { CreditCardType, EnumObject, FinancialInstitution } from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { LiabilityService } from '../../api/liability.service';
import { LiabilityType } from '../../enums/liability-type.enum';
import { CreditCard } from '../../model/credit-card.model';
import { LiabilityDetailService } from '../../service/liability-detail.service';

@Component({
	selector: 'credit-card-details',
	templateUrl: './credit-card-details.component.html',
	styleUrls: ['./credit-card-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardDetailsComponent implements OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<any> = new EventEmitter<any>();
	@Output() public delete: EventEmitter<any> = new EventEmitter<any>();

	public creditCardDetailsForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: {
		financialInstitutions: EnumObject[];
		sharedCreditCardOptions: EnumObject[];
		applicants: Applicant[];
	} = { financialInstitutions: [], sharedCreditCardOptions: [], applicants: [] };
	public isEditMode = false;
	public isSubmitting = false;

	public sectionTitle = '';
	public whoseCardConfig: FieldMetadata = {} as FieldMetadata;
	public financialInstitutionConfig: FieldMetadata = {} as FieldMetadata;
	public limitConfig: FieldMetadata = {} as FieldMetadata;
	public sharedConfig: FieldMetadata = {} as FieldMetadata;
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private applicantService: ApplicantService,
		private liabilityService: LiabilityService,
		private liabilityDetailService: LiabilityDetailService,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.creditCardDetailsForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			issuer: [null, Validators.required],
			creditLimit: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
			isShared: [null, Validators.required],
			description: ['']
		});

		this.validationErrors = {
			issuer: {
				required: 'Select a financial institution'
			},
			creditLimit: {
				required: 'We need the credit card limit',
				min: 'Credit limit cannot be zero',
				max: 'Credit limit cannot be more than $10,000,000'
			},
			isShared: {
				required: 'Select an option'
			},
			applicantId: {
				required: 'Select who is this for'
			}
		};

		this.liabilityDetailService.addEditCreditCard$
			.pipe(takeUntil(this.destroy$))
			.subscribe((creditCard: CreditCard | null) => {
				this.addEditCreditCard(creditCard);
			});
	}

	public get applicantId() {
		return this.creditCardDetailsForm.controls.applicantId as UntypedFormControl;
	}

	public get issuer() {
		return this.creditCardDetailsForm.controls.issuer as UntypedFormControl;
	}

	public get creditLimit() {
		return this.creditCardDetailsForm.controls.creditLimit as UntypedFormControl;
	}

	public get isShared() {
		return this.creditCardDetailsForm.controls.isShared as UntypedFormControl;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.creditCardDetailsForm.invalid) {
			return;
		}

		this.isSubmitting = true;
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const liabilityToSave = {
			...this.creditCardDetailsForm.value,
			creditLimit: CurrencyHelper.unformatAmount(this.creditLimit.value),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			issuer: this.issuer.value.id,
			isShared: this.isShared.value === 1,
			creditCardType: CreditCardType.OtherCreditCard,
			percent: 100
		} as CreditCard;
		this.liabilityService
			.saveCreditCard(liabilityToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.creditCardDetailsForm.controls.id.setValue(result);
				liabilityToSave.id = result;
				this.update.emit(liabilityToSave);
			})
			.add(() => {
				this.isSubmitting = false;
				this.changeDetectorRef.markForCheck();
			});
	}

	public deleteRecord(): void {
		this.liabilityService
			.deleteCreditCard(
				this.creditCardDetailsForm.controls.applicationId.value,
				this.creditCardDetailsForm.controls.id.value
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.creditCardDetailsForm.value);
			});
	}

	public populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}

	private addEditCreditCard(creditCard: CreditCard | null): void {
		this.creditCardDetailsForm.reset();
		if (creditCard) {
			this.isEditMode = true;
			this.creditCardDetailsForm.patchValue({
				id: creditCard.id,
				applicationId: creditCard.applicationId,
				applicantId: creditCard.applicantId,
				description: creditCard.description,
				issuer: EnumHelper.getEnumObject(
					FinancialInstitution as unknown as { [index: string]: number },
					creditCard.issuer
				),
				creditLimit: creditCard.creditLimit,
				isShared: creditCard.isShared ? 1 : 2
			});
			// Mark the button group as touched so that the css kicks in for the unselected buttons within each group.
			// Only required while loading previously saved values from api.
			this.isShared.markAsTouched();
		} else {
			this.isEditMode = false;
			this.creditCardDetailsForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				description: '',
				issuer: null,
				creditLimit: null,
				isShared: null
			});
		}
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Liabilities',
				LiabilityType.CreditCard,
				LiabilityType.CreditCard
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about this card`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseCardConfig = this.metadataService.getFieldByName('WhoseCard', fields);
			this.financialInstitutionConfig = this.metadataService.getFieldByName('FinancialInstitution', fields);
			this.limitConfig = this.metadataService.getFieldByName('Limit', fields);
			this.sharedConfig = this.metadataService.getFieldByName('Shared', fields);

			this.options = {
				financialInstitutions: this.populateOptions(this.financialInstitutionConfig.options),
				sharedCreditCardOptions: [
					{ id: 1, label: 'Yes' },
					{ id: 2, label: 'No' }
				],
				applicants: this.applicantService.getApplicants(true)
			};

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}
}
