import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BrandService } from '../shared/api/brand.service';
import { MetadataService } from '../shared/api/metadata.service';
import { AreaMetadata } from '../shared/model/area-metadata.model';
import { BrandConfig } from '../shared/model/brand.config';
import { TextMetadata } from '../shared/model/text-metadata.model';

@Component({
	selector: 'privacy-terms',
	templateUrl: './privacy-terms.component.html',
	styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit, OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();

	public brandName = '';
	public privacyPolicyUrl = '';
	public header = '';
	public message1: TextMetadata = {} as TextMetadata;
	public message2: TextMetadata = {} as TextMetadata;
	public message3: TextMetadata = {} as TextMetadata;
	public privacyLink: TextMetadata = {} as TextMetadata;

	private destroy$: Subject<void> = new Subject();
	headerConfig: any;

	constructor(private brandService: BrandService, private metadataService: MetadataService) {
		this.brandService.brandConfig$.pipe(takeUntil(this.destroy$)).subscribe((brandConfig: BrandConfig) => {
			this.brandName = brandConfig.brandName;
			this.privacyPolicyUrl = brandConfig.privacyPolicyUrl;
		});
	}
	ngOnInit(): void {
		this.setMetadata();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	private setMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const privacyTermsArea: AreaMetadata = this.metadataService.getAreaMetadataByName('PrivacyTerms');
			this.header = this.metadataService.getTextByName('Header', privacyTermsArea.texts);
			this.message1 = privacyTermsArea.texts?.find((text) => text.name === 'Message1') as TextMetadata;
			this.message2 = privacyTermsArea.texts?.find((text) => text.name === 'Message2') as TextMetadata;
			this.message3 = privacyTermsArea.texts?.find((text) => text.name === 'Message3') as TextMetadata;
			this.privacyLink = privacyTermsArea.texts?.find((text) => text.name === 'PrivacyLink') as TextMetadata;
		});
	}
}
