import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PersonalDetailService } from '../personal-details/api/personal-detail.service';
import { AwaitingFinalisation } from '../personal-details/model/awaiting-finalisation.model';
import { BrandService } from '../shared/api/brand.service';
import { Constant } from '../shared/helper/constant';
import { AuthenticationService } from '../shared/service/authentication.service';

@Component({
	selector: 'register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
	public passwordSetForm: UntypedFormGroup;
	public registerScreenHeight = '0px';
	public brandName = '';
	public enablePasswordSet = false;
	public firstName!: string;

	private key!: string;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private brandService: BrandService,
		private personalDetailService: PersonalDetailService,
		private authenticationService: AuthenticationService
	) {
		this.brandService.currentBrandName$
			.pipe(takeUntil(this.destroy$))
			.subscribe((brandName: string) => (this.brandName = brandName));

		this.passwordSetForm = this.formBuilder.group({
			password: ['']
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.calculateHomeScreenHeight();
	}
	public get password(): UntypedFormControl {
		return this.passwordSetForm.get('password') as UntypedFormControl;
	}

	public ngOnInit(): void {
		this.calculateHomeScreenHeight();

		this.key = this.route.snapshot.queryParams['key'] as string;

		if (!this.key) {
			this.authenticationService.logIn();
		} else {
			this.getPersonFinalisation();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public onSubmit(): void {
		if (this.passwordSetForm.invalid) {
			return;
		}

		this.finaliseRegistration();
	}

	private getPersonFinalisation(): void {
		this.personalDetailService
			.getPersonFinalisation(this.key)
			.pipe(takeUntil(this.destroy$))
			.subscribe((response: AwaitingFinalisation) => {
				if (response.isAwaitingFinalisation) {
					this.enablePasswordSet = true;
					this.firstName = response.firstName;
				} else {
					this.authenticationService.logIn();
				}
			});
	}

	private finaliseRegistration(): void {
		this.personalDetailService
			.finalise({
				registrationKey: this.key,
				password: this.password.value as string
			})
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				() => {
					this.authenticationService.logIn();
				},
				(error) => {
					// Retry password set
				}
			);
	}

	private calculateHomeScreenHeight(): void {
		this.registerScreenHeight = `${window.innerHeight - Constant.appFooterHeight}px`;
	}
}
