import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OverlayService {
	private count = 0;
	private overlay$ = new BehaviorSubject<string>('');
	private overlayContent$ = new BehaviorSubject<TemplateRef<HTMLElement> | null>(null);

	public getOverlayObserver(): Observable<string> {
		return this.overlay$.asObservable();
	}

	public getOverlayContentObserver(): Observable<TemplateRef<HTMLElement>> {
		return this.overlayContent$.asObservable() as Observable<TemplateRef<HTMLElement>>;
	}

	public startOverlay(contentTemplate: TemplateRef<HTMLElement> | null) {
		if (++this.count === 1) {
			this.overlay$.next('start');
			this.overlayContent$.next(contentTemplate);
		}
	}

	public stopOverlay() {
		if (this.count === 0 || --this.count === 0) {
			this.overlay$.next('stop');
		}
	}
}
