import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { ApplicantResponse } from '../model/applicant-response.model';
import { AwaitingFinalisation } from '../model/awaiting-finalisation.model';
import { PersonFinalisation } from '../model/person-finalisation.model';
import { PersonalDetail } from '../model/personal-detail.model';

@Injectable({ providedIn: 'root' })
export class PersonalDetailService extends BaseApiService<PersonalDetail> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/Applicant');
	}

	public getByApplication(applicationId: number): Observable<PersonalDetail[]> {
		return <Observable<PersonalDetail[]>>this.getCustom(`${applicationId}`);
	}

	public save(detail: PersonalDetail): Observable<ApplicantResponse> {
		return <Observable<ApplicantResponse>>this.post('', detail);
	}

	public checkValidApplicant(detail: PersonalDetail): Observable<boolean> {
		if (detail.id > 0) {
			return of(true);
		}

		return <Observable<boolean>>this.post('CheckValidApplicant', detail);
	}

	public deleteApplicant(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`${applicationId}/${id}`);
	}

	public getPersonFinalisation(key: string): Observable<AwaitingFinalisation> {
		return <Observable<AwaitingFinalisation>>this.getCustom(`GetPersonFinalisation?key=${key}`);
	}

	public finalise(data: PersonFinalisation): Observable<void> {
		return <Observable<void>>this.postCustom('Finalise', data);
	}
}
