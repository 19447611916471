import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { Income } from '../model/income.model';
import { OtherIncome } from '../model/other-income.model';
import { RentalIncomeResponse } from '../model/rental-income-response.model';
import { RentalIncome } from '../model/rental-income.model';
import { WorkIncome } from '../model/work-income.model';

@Injectable({ providedIn: 'root' })
export class IncomeService extends BaseApiService<Income> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/Income');
	}

	public getByApplication(applicationId: number): Observable<Income> {
		return <Observable<Income>>this.getCustom(`${applicationId}`);
	}

	public saveWorkIncome(income: WorkIncome): Observable<number> {
		return <Observable<number>>this.postCustom('Work', income);
	}

	public saveRentalIncome(income: RentalIncome): Observable<RentalIncomeResponse> {
		return <Observable<RentalIncomeResponse>>this.postCustom('Rental', income);
	}

	public saveOtherIncome(income: OtherIncome): Observable<number> {
		return <Observable<number>>this.postCustom('Other', income);
	}

	public deleteWorkIncome(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`Work/${applicationId}/${id}`);
	}

	public deleteRentalIncome(applicationId: number, id: number, isFuture: boolean): Observable<void> {
		return <Observable<void>>this.delete(`Rental/${applicationId}/${id}/${isFuture}`);
	}

	public deleteOtherIncome(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`Other/${applicationId}/${id}`);
	}
}
