/* eslint-disable @typescript-eslint/member-ordering */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '@simpology/authentication';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { ServiceabilityService } from '../serviceability-result/service/serviceability.service';
import { ApplicationService } from '../shared/api/application.service';
import { BrandService } from '../shared/api/brand.service';
import { Constant } from '../shared/helper/constant';
import { JourneyStatus } from '../shared/model/enum.model';
import { Journey } from '../shared/model/journey.model';
import { AuthenticationService } from '../shared/service/authentication.service';
import { Guid } from 'guid-typescript';
import { MetadataService } from '../shared/api/metadata.service';
import { AreaMetadata } from '../shared/model/area-metadata.model';
import { ButtonMetadata } from '../shared/model/button-metadata.model';

@Component({
	selector: 'home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.calculateHomeScreenHeight();
	}

	public homeScreenHeight = '0px';
	public brandName = '';
	public header = '';
	public subHeader = '';
	public message = '';
	public message2 = '';
	public loginMessage = '';
	public getStartedButton: ButtonMetadata = {} as ButtonMetadata;
	public loginButton: ButtonMetadata = {} as ButtonMetadata;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private metadataService: MetadataService,
		private brandService: BrandService,
		private authenticationService: AuthenticationService,
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private serviceabilityService: ServiceabilityService,
		private route: ActivatedRoute,
		private authService: AuthService
	) {
		this.brandService.currentBrandName$
			.pipe(takeUntil(this.destroy$))
			.subscribe((brandName: string) => (this.brandName = brandName));
	}

	public ngOnInit(): void {
		this.calculateHomeScreenHeight();
		this.handleRouteParams();
		this.setMetadata();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public proceedToApp(): void {
		const userId = this.authService.anonymousUserId ?? Guid.create().toString();
		this.proceedToAppWithAuthentication(userId);
	}

	private proceedToAppWithAuthentication(anonymousUserId: string): void {
		this.authenticationService
			.authenticateAnonymousUser(anonymousUserId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				if (result) {
					const appId = this.applicationService.getStoredApplicationId();
					if (!appId) {
						this.goToFirstStep();
						return;
					}
					this.applicationService
						.getCurrentJourney(appId)
						.pipe(takeUntil(this.destroy$))
						.subscribe((journeyResult: Journey) => {
							if (
								!journeyResult ||
								journeyResult.status === JourneyStatus.DataEntry ||
								journeyResult.status === JourneyStatus.NotStarted
							) {
								this.goToFirstStep();
							} else {
								this.serviceabilityService.serviceabilityResult(journeyResult.status === JourneyStatus.Approved);
								this.navigationService.goToResult();
							}
						});
				}
			});
	}

	private goToFirstStep(): void {
		this.navigationService.syncStepsFromServer();
		this.navigationService.goToStep(1); // First step of the application
	}

	public logIn(): void {
		this.authenticationService.logIn();
	}

	private calculateHomeScreenHeight(): void {
		this.homeScreenHeight = `${window.innerHeight - Constant.appFooterHeight}px`;
	}

	private handleRouteParams(): void {
		let anonymousUserId = '';
		this.route.params
			.pipe(
				map((params: Params) => {
					if (params['brokerUserId'] !== undefined) {
						this.applicationService.setBrokerUserId(+params['brokerUserId']);
					}
					if (params['anonymousUserId'] !== undefined) {
						anonymousUserId = params['anonymousUserId'] as string;
					}
					if (params['applicationId'] !== undefined) {
						this.applicationService.setApplicationId(+params['applicationId']);
					}
					if (anonymousUserId) {
						this.proceedToAppWithAuthentication(anonymousUserId);
					}
				})
			)
			.subscribe();
	}

	private setMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const homeArea: AreaMetadata = this.metadataService.getAreaMetadataByName('Home');
			this.header = this.metadataService.getTextByName('Header', homeArea.texts);
			this.subHeader = this.metadataService.getTextByName('SubHeader', homeArea.texts);
			this.message = this.metadataService.getTextByName('Message', homeArea.texts);
			this.message2 = this.metadataService.getTextByName('Message2', homeArea.texts);
			this.loginMessage = this.metadataService.getTextByName('LoginMessage', homeArea.texts);

			this.getStartedButton = this.metadataService.getButtonByName('GetStarted', homeArea.buttons);
			this.loginButton = this.metadataService.getButtonByName('Login', homeArea.buttons);
		});
	}
}
