import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoanDetailService } from 'src/app/loan-details/api/loan-detail.service';
import { LoanDetail } from 'src/app/loan-details/model/loan-detail.model';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ExpenseService } from '../../../expense-details/api/expense.service';
import { Expense } from '../../../expense-details/model/expense.model';
import { NavigationService } from '../../../navigation/navigation.service';
import { ApplicationService } from '../../../shared/api/application.service';
import { CalculationHelper } from '../../../shared/helper/calculation-helper';
import { Constant } from '../../../shared/helper/constant';
import {
	EnumObject,
	ExpenseClassificationExpenseClass,
	FrequencyFull,
	JourneyStatus,
	StepType
} from '../../../shared/model/enum.model';

@Component({
	selector: 'expenses-review',
	templateUrl: './expenses-review.component.html',
	styleUrls: ['./expenses-review.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpensesReviewComponent implements OnInit {
	public expenseDetails: Expense[] = [];
	public primaryHouseholdExpenses: Expense[] = [];
	public additionalHouseholdExpenses: Expense[] = [];
	public stepType = StepType;
	public expenseForm: UntypedFormGroup;
	public options: { expenseFrequencies: EnumObject[] } = { expenseFrequencies: [] };
	public toggleExpenseDetails = false;
	public isMultiHousehold = false;
	public isPrimarySelected = true;
	public isReviewEnabled = true;
	public primaryHouseholdHasDetails = false;
	public additionalHouseholdHasDetails = false;
	public primaryApplicant: Applicant;
	public additionalApplicant: Applicant;

	private existingApplicationId = Constant.newId;
	private journeyStatus?: JourneyStatus;
	private expenseClassifications: EnumObject[] = [];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private changeDetectorRef: ChangeDetectorRef,
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private expenseService: ExpenseService,
		private loanDetailService: LoanDetailService,
		private metadataService: MetadataService
	) {
		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.primaryApplicant = this.applicationService.getStoredPrimaryApplicant() ?? ({} as Applicant);
		this.additionalApplicant = this.applicationService.getStoredAdditionalApplicant() ?? ({} as Applicant);
		this.journeyStatus = this.applicationService.getStoredJourneyStatus();

		const fields = this.metadataService.getSubSectionMetadataByName(
			'Expenses',
			'ExpenseDetails',
			'ExpenseDetails'
		).fields;

		this.options = {
			expenseFrequencies: this.metadataService.getFieldByName('Frequency', fields).options as EnumObject[]
		};
		this.expenseClassifications = this.sortClassifications(
			this.metadataService.getFieldByName('Classification', fields).options
		);

		this.expenseForm = this.formBuilder.group({
			details: this.formBuilder.array(this.buildDetail().map((classification) => this.addNewDetail(classification)))
		});
		this.changeDetectorRef.markForCheck();
	}

	public get shouldShowEdit(): boolean {
		return (this.toggleExpenseDetails || !this.isReviewEnabled) && this.isAppEditable;
	}

	public get isAppEditable(): boolean {
		return this.journeyStatus === undefined || this.journeyStatus === JourneyStatus.DataEntry;
	}

	public get totalExpense(): number {
		if (this.expenseDetails.length === 1) {
			return this.expenseDetails[0].amount;
		} else {
			return this.expenseDetails
				?.map((i) => CalculationHelper.CalculateMonthlyAmount(i.amount, i.frequency))
				.reduce((a, b) => a + b, 0);
		}
	}

	public get primaryHouseholdTotalExpense(): number {
		return CalculationHelper.CalculateMonthlyAmount(
			this.primaryHouseholdExpenses[0].amount,
			this.primaryHouseholdExpenses[0].frequency
		);
	}

	public get additionalHouseholdTotalExpense(): number {
		if (this.additionalHouseholdExpenses.length === 0) {
			return 0;
		}

		return CalculationHelper.CalculateMonthlyAmount(
			this.additionalHouseholdExpenses[0].amount,
			this.additionalHouseholdExpenses[0].frequency
		);
	}

	public get form(): any {
		return this.expenseForm.controls;
	}

	public get details(): UntypedFormArray {
		return this.expenseForm.get('details') as UntypedFormArray;
	}

	public get showApplicantTabs(): boolean {
		return this.isMultiHousehold && (this.primaryHouseholdHasDetails || this.additionalHouseholdHasDetails);
	}

	public get showExpenseForm(): boolean {
		if (!this.isReviewEnabled) {
			return false;
		}

		if (this.showApplicantTabs) {
			if (this.isPrimarySelected) {
				return this.primaryHouseholdHasDetails;
			} else {
				return this.additionalHouseholdHasDetails;
			}
		} else {
			return !this.isMultiHousehold;
		}
	}

	public ngOnInit(): void {
		if (this.existingApplicationId !== Constant.newId) {
			this.loadData(this.existingApplicationId);
			this.expenseForm.disable();
		}
	}

	public editSection(section: StepType, editable: boolean): void {
		if (editable) {
			this.navigationService.goToStep(section);
			return;
		}

		this.toggleExpenseDetails = !this.toggleExpenseDetails;
	}

	public changeHousehold(isPrimary: boolean): void {
		this.isPrimarySelected = isPrimary;
		this.populateExpenseForm(isPrimary ? this.primaryHouseholdExpenses : this.additionalHouseholdExpenses);
	}

	private loadData(existingApplicationId: number): void {
		const loanDetails$ = this.loanDetailService.getByApplication(existingApplicationId);
		const expenseDetails$ = this.expenseService.getByApplication(existingApplicationId);

		combineLatest([loanDetails$, expenseDetails$])
			.pipe(take(1))
			.subscribe(([loanDetails, expenseDetails]: [LoanDetail, Expense[]]): void => {
				this.isMultiHousehold = loanDetails.numberOfApplicants > 1 && loanDetails.isMultiHousehold;
				this.expenseDetails = expenseDetails;
				this.primaryHouseholdExpenses = this.expenseDetails.filter(
					(expense) => expense.applicantId === this.primaryApplicant.id
				);
				if (this.isMultiHousehold) {
					this.additionalHouseholdExpenses = this.expenseDetails.filter(
						(expense) => expense.applicantId === this.additionalApplicant.id
					);
				}

				if (this.isMultiHousehold) {
					this.isReviewEnabled = true;
					const isPrimaryHouseholdExpenseOnlyTotal =
						this.primaryHouseholdExpenses.length === 1 &&
						this.primaryHouseholdExpenses[0].expenseClass === ExpenseClassificationExpenseClass.Other;
					const isAdditionalHouseholdExpenseOnlyTotal =
						this.additionalHouseholdExpenses.length === 1 &&
						this.additionalHouseholdExpenses[0].expenseClass === ExpenseClassificationExpenseClass.Other;

					if (isPrimaryHouseholdExpenseOnlyTotal) {
						if (isAdditionalHouseholdExpenseOnlyTotal) {
							this.primaryHouseholdHasDetails = false;
							this.additionalHouseholdHasDetails = false;
						} else {
							this.primaryHouseholdHasDetails = false;
							this.additionalHouseholdHasDetails = true;
						}
					} else {
						if (isAdditionalHouseholdExpenseOnlyTotal) {
							this.primaryHouseholdHasDetails = true;
							this.additionalHouseholdHasDetails = false;
						} else {
							this.primaryHouseholdHasDetails = true;
							this.additionalHouseholdHasDetails = true;
						}
						this.populateExpenseForm(this.primaryHouseholdExpenses);
					}
				} else {
					if (
						this.primaryHouseholdExpenses.length === 1 &&
						this.primaryHouseholdExpenses[0].expenseClass === ExpenseClassificationExpenseClass.Other
					) {
						this.isReviewEnabled = false;
					} else {
						this.isReviewEnabled = true;
						this.populateExpenseForm(this.primaryHouseholdExpenses);
					}
				}
				this.changeDetectorRef.markForCheck();
			});
	}

	private buildDetail(): EnumObject[] {
		const availableDetails: EnumObject[] = [];
		this.expenseClassifications.forEach((classification: EnumObject) => {
			availableDetails.push(classification);
		});
		return availableDetails;
	}

	private addNewDetail(classification: EnumObject): UntypedFormGroup {
		return this.formBuilder.group({
			id: [Constant.newId],
			expenseClass: [classification.id],
			label: [classification.label],
			expense: this.formBuilder.group({
				amount: [],
				frequency: [this.getDefaultFrequency()?.id]
			})
		});
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.options.expenseFrequencies.find((frequency: EnumObject) => frequency.id === FrequencyFull.Monthly);
	}

	private populateExpenseForm(expenses: Expense[]): void {
		this.details.controls.map((control: AbstractControl) => {
			const value = control.value as Expense;
			const matchingExpense = expenses.find((expense: Expense) => expense.expenseClass === value.expenseClass);

			if (matchingExpense) {
				control.patchValue({
					expense: {
						amount: matchingExpense.amount,
						frequency: matchingExpense.frequency
					}
				});
			} else {
				control.patchValue({
					expense: {
						amount: 0,
						frequency: this.getDefaultFrequency()?.id
					}
				});
			}
		});
	}

	private sortClassifications(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);
		return options as EnumObject[];
	}
}
