import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, IdentityBaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENV_CONFIG } from 'src/env-config';
import { TokenResult } from '../model/token-result.model';

@Injectable({ providedIn: 'root' })
export class AnonymousUserAuthenticationService extends IdentityBaseApiService {
	private authService: AuthService;
	constructor(http: HttpClient, toastr: ToastrService, authService: AuthService) {
		super(http, toastr, ENV_CONFIG);
		this.setRoute('api/ExtraAuth');
		this.authService = authService;
	}

	public getAnonymousAuthToken(channelKey: string, userId: string): Observable<boolean> {
		return this.getAuthToken(ENV_CONFIG.clientId, channelKey, userId).pipe(
			map((result: TokenResult) => {
				this.authService.createUserWithToken(result.token, userId);
				return true;
			})
		);
	}
}
