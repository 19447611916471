import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@simpology/authentication';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LoanDetailService } from '../loan-details/api/loan-detail.service';
import { LoanDetail } from '../loan-details/model/loan-detail.model';
import { NavigationService } from '../navigation/navigation.service';
import { PersonalDetailService } from '../personal-details/api/personal-detail.service';
import { PersonalDetail } from '../personal-details/model/personal-detail.model';
import { ApplicationService } from '../shared/api/application.service';
import { BrandService } from '../shared/api/brand.service';
import { MetadataService } from '../shared/api/metadata.service';
import { AreaMetadata } from '../shared/model/area-metadata.model';
import { JourneyStatus } from '../shared/model/enum.model';
import { Journey } from '../shared/model/journey.model';
import { ApplicantService } from '../shared/service/applicant.service';
import { AuthenticationService } from '../shared/service/authentication.service';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
	public completedLoading = false;
	public loggedInUserFirstName = '';
	public referenceNumber = '';
	public brandName = '';
	public headerMessage = '';
	public subHeader = '';
	public messageOption1 = '';
	public messageOption2 = '';

	private journeyStatus: JourneyStatus = JourneyStatus.Processing;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private authService: AuthService,
		private brandService: BrandService,
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private authenticationService: AuthenticationService,
		private personalDetailService: PersonalDetailService,
		private loanDetailService: LoanDetailService,
		private applicantService: ApplicantService,
		private changeDetectorRef: ChangeDetectorRef,
		private metadataService: MetadataService
	) {
		this.brandService.currentBrandName$
			.pipe(takeUntil(this.destroy$))
			.subscribe((brandName: string) => (this.brandName = brandName));
	}

	public get canShowCompleteApplication(): boolean {
		return this.journeyStatus === JourneyStatus.DataEntry;
	}

	public get applicationStatus(): string {
		if (this.journeyStatus === JourneyStatus.NotStarted || this.journeyStatus === JourneyStatus.DataEntry) {
			return 'Almost ready';
		} else {
			return 'Being processed';
		}
	}

	public get message(): string {
		if (this.journeyStatus === JourneyStatus.NotStarted || this.journeyStatus === JourneyStatus.DataEntry) {
			return this.messageOption1;
		} else {
			return this.messageOption2;
		}
	}

	public ngOnInit(): void {
		this.applicationService
			.getMyJourney()
			.pipe(takeUntil(this.destroy$))
			.subscribe((journeyResult: Journey) => {
				if (journeyResult) {
					this.loadApplicants(journeyResult.applicationId)
						.pipe(takeUntil(this.destroy$))
						.subscribe(() => {
							this.journeyStatus = journeyResult.status;
							this.applicationService.setJourneyStatus(this.journeyStatus);
							this.navigationService.updateStepResponses(journeyResult.steps);
							this.referenceNumber = journeyResult.reference;
							this.completedLoading = true;
						});
					this.setMultiHousehold(journeyResult.applicationId);
				}
			});
		this.setMetadata();
	}

	public ngAfterViewInit(): void {
		setTimeout(() => {
			this.navigationService.toggleProgressTracker();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public review(): void {
		this.navigationService.goToReview();
	}

	public complete(): void {
		this.navigationService.reviewWorkflowCompletion();
	}

	public close(): void {
		this.authenticationService
			.logOut()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				sessionStorage.clear();
			});
	}

	private loadApplicants(applicationId: number): Observable<void> {
		return this.personalDetailService.getByApplication(applicationId).pipe(
			map((result: PersonalDetail[]) => {
				this.applicantService.setApplicantsLocally(result);
				const extractedLoggedInUserName = this.authService.name.split(' ')[0];
				this.loggedInUserFirstName =
					result.find((person: PersonalDetail) => person.firstName.includes(extractedLoggedInUserName))?.firstName ??
					extractedLoggedInUserName;
				this.changeDetectorRef.markForCheck();
			})
		);
	}

	private setMultiHousehold(applicationId: number): void {
		this.loanDetailService.loanDetails$.pipe(takeUntil(this.destroy$)).subscribe((result: LoanDetail | null) => {
			this.applicationService.setMultiHouseholdOption(!!result?.isMultiHousehold);
		});
	}

	private setMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const dashboard: AreaMetadata = this.metadataService.getAreaMetadataByName('Dashboard');

			this.headerMessage = this.metadataService.getTextByName('HeaderMessage', dashboard.texts);
			this.subHeader = this.metadataService.getTextByName('SubHeader', dashboard.texts);
			this.messageOption1 = this.metadataService.getTextByName('MessageOption1', dashboard.texts);
			this.messageOption2 = this.metadataService.getTextByName('MessageOption2', dashboard.texts);

			this.changeDetectorRef.markForCheck();
		});
	}
}
