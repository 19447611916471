import { Address } from '../model/address.model';
import { AddressType, AuState, CountryCodeLimited, CountryCodeLimitedLabel, StreetType } from '../model/enum.model';
import { Constant } from './constant';
import { EnumHelper } from './enum-helper';

export class AddressHelper {
	public static allowedCountries = [
		{ id: 1012, label: 'Australia' },
		{ id: 1170, label: 'New Zealand' }
	];

	public static ExtractAddress(address: Address): string {
		const addressFields = [
			'unit',
			'streetNumber',
			'streetName',
			'streetType',
			'suburb',
			'state',
			'australianPostCode',
			'country'
		];
		let formattedAddress = '';
		addressFields.forEach((addressField) => {
			switch (addressField) {
				case 'unit':
					formattedAddress += address[addressField] ? address[addressField] + '/' : '';
					break;
				case 'streetType':
					formattedAddress += address[addressField]
						? (EnumHelper.getEnumKeyByValue(
								StreetType as unknown as { [index: string]: number },
								address[addressField]
						  ) as string) + ', '
						: '';
					break;
				case 'state':
					formattedAddress += address[addressField]
						? (EnumHelper.getEnumKeyByValue(
								AuState as unknown as { [index: string]: number },
								address[addressField]
						  ) as string) + ', '
						: '';
					break;
				case 'australianPostCode':
					formattedAddress += address[addressField] ? address[addressField] + ', ' : '';
					break;
				case 'country':
					formattedAddress += address[addressField]
						? (EnumHelper.getEnumKeyByValue(
								CountryCodeLimited as unknown as { [index: string]: number },
								address[addressField]
						  ) as string) + ', '
						: '';
					break;
				default:
					formattedAddress += address[addressField] ? (address[addressField] as string) + ' ' : '';
					break;
			}
		});
		return formattedAddress;
	}

	public static getEmptyAddress(): Address {
		return {
			...({} as Address),
			id: Constant.newId,
			addressType: AddressType.Standard
		};
	}

	public static buildAddressLine(address: Address): string {
		const onelineAddr = `${address.unit ? address.unit + '/' : ''}${address.streetNumber} ${address.streetName} ${
			EnumHelper.getDescription(StreetType, address.streetType) ?? ''
		}, ${address.suburb ?? ''} ${EnumHelper.getDescription(AuState, address.state) ?? ''} ${
			address.australianPostCode
		}`;

		return onelineAddr.replace(/\s+/g, ' ').replace(/ ,/g, ',');
	}

	public static isValidAddress(address: Address): boolean {
		if (!address) {
			return false;
		}
		return !!address.streetNumber && !!address.streetName && !!address.country && !!address.australianPostCode;
	}

	public static getMatchingAddressId(addressToMatch: Address, addressList: Address[]): number | undefined {
		return addressList.find((address: Address) => {
			let matchFound = true;
			for (const key in address) {
				if (['id', 'isPrimaryProperty', 'applicationId'].includes(key) || (!address[key] && !addressToMatch[key])) {
					continue;
				}

				if (address[key] !== addressToMatch[key]) {
					matchFound = false;
					break;
				}
			}

			return matchFound ? address : undefined;
		})?.id;
	}
}
