<div class="home" [style.height]="homeScreenHeight">
	<header class="home__header simp-padding-left-small"></header>

	<div class="home__contents-wrapper">
		<div class="home__contents simp-padding-small">
			<div class="home__section">
				<img
					class="home__brand-logo"
					src="{{ './../assets/brands/' + brandName + '/images/brand-logo.png' }}"
					alt="logo"
				/>
				<h3 class="simp-h3 home__heading1">{{ header }}</h3>
				<h4 class="simp-h4 home__heading2">{{ subHeader }}</h4>
			</div>

			<div class="home__section">
				<div
					class="home__navigation simp-margin-bottom-small"
					(click)="proceedToApp()"
					*ngIf="getStartedButton?.visible === 'Visible'"
				>
					<div class="home__nav-item">
						<div class="home__nav-item-icon simp-margin-right-small">
							<i class="fas fa-clipboard-check simp-text--tertiary"></i>
						</div>
						<h3 class="simp-h3">{{ getStartedButton.title }}</h3>
					</div>
					<div class="home__nav-arrow"><i class="fas fa-arrow-right"></i></div>
				</div>

				<h5 class="simp-h5">{{ message }}</h5>

				<div class="simp-padding-top-medium" *ngIf="message2">
					<h5 class="simp-h5">{{ message2 }}</h5>
				</div>

				<div class="home__existing-applicant simp-margin-bottom-medium">
					<h4 class="simp-h4 home__existing-applicant-header">{{ loginMessage }}</h4>
					<button type="button" class="btn btn-md simp-button home__log-on simp-margin-left-small" (click)="logIn()">
						{{ loginButton.title }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
