import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@simpology/authentication';
import { AuthenticationService } from '../shared/service/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService,
		private authenticationService: AuthenticationService
	) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authService.isAuthenticated()) {
			return true;
		}
		const isAnonymousUser = this.authService.anonymousUserId;
		if (isAnonymousUser) {
			void this.router.navigate(['/home']);
		} else {
			this.authenticationService.logIn();
		}
		return false;
	}
}
