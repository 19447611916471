import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { EnumObject, FinancialAssetType } from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { AssetService } from '../../api/asset.service';
import { AssetType } from '../../enums/asset-type.enum';
import { SavingsAsset } from '../../model/savings-asset.model';
import { AssetDetailService } from '../../service/asset-detail.service';

@Component({
	selector: 'savings-asset-details',
	templateUrl: './savings-asset-details.component.html',
	styleUrls: ['./savings-asset-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavingsAssetDetailsComponent implements OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<SavingsAsset> = new EventEmitter<SavingsAsset>();
	@Output() public delete: EventEmitter<SavingsAsset> = new EventEmitter<SavingsAsset>();

	public savingsAssetForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public savingsTypes: EnumObject[] = [];
	public showOtherSavingsType = false;
	public applicants: Applicant[] = [];
	public isEditMode = false;
	public isSubmitting = false;

	public sectionTitle = '';
	public whoseSavingsConfig: FieldMetadata = {} as FieldMetadata;
	public typeConfig: FieldMetadata = {} as FieldMetadata;
	public descriptionConfig: FieldMetadata = {} as FieldMetadata;
	public valueConfig: FieldMetadata = {} as FieldMetadata;
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private assetService: AssetService,
		private applicantService: ApplicantService,
		private assetDetailService: AssetDetailService,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.savingsAssetForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			value: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
			type: [null, Validators.required],
			description: ['', Validators.maxLength(200)]
		});

		this.validationErrors = {
			value: {
				required: 'We need the savings amount',
				min: 'Amount cannot be zero',
				max: 'Amount cannot be more than $10,000,000'
			},
			type: {
				required: 'Select a type'
			},
			applicantId: {
				required: 'Select who is this for'
			},
			description: {
				maxlength: 'Description cannot be more than 200 characters'
			}
		};

		this.applicants = this.applicantService.getApplicants(true);
		this.assetDetailService.addEditSaving$
			.pipe(takeUntil(this.destroy$))
			.subscribe((savingsAsset: SavingsAsset | null) => {
				this.addEditSaving(savingsAsset);
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public get applicantId() {
		return this.savingsAssetForm.controls.applicantId as UntypedFormControl;
	}

	public get value() {
		return this.savingsAssetForm.controls.value as UntypedFormControl;
	}

	public get type() {
		return this.savingsAssetForm.controls.type as UntypedFormControl;
	}

	public get percent() {
		return this.savingsAssetForm.controls.percent as UntypedFormControl;
	}

	public get description() {
		return this.savingsAssetForm.controls.description as UntypedFormControl;
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.savingsAssetForm.invalid) {
			return;
		}

		this.isSubmitting = true;
		const assetToSave = {
			...this.savingsAssetForm.value,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			applicantId: this.applicantId.value,
			value: CurrencyHelper.unformatAmount(this.value.value),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			type: this.type.value.id,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			description: this.description.value.trim(),
			percent: 100
		} as SavingsAsset;
		this.assetService
			.saveSavingsAsset(assetToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.savingsAssetForm.controls.id.setValue(result);
				assetToSave.id = result;
				this.update.emit(assetToSave);
			})
			.add(() => {
				this.isSubmitting = false;
				this.changeDetectorRef.markForCheck();
			});
	}

	public deleteRecord(): void {
		this.assetService
			.deleteSavings(this.savingsAssetForm.controls.applicationId.value, this.savingsAssetForm.controls.id.value)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.savingsAssetForm.value);
			});
	}

	public populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}

	private addEditSaving(savingsAsset: SavingsAsset | null): void {
		if (savingsAsset) {
			this.isEditMode = true;
			this.savingsAssetForm.reset();
			this.savingsAssetForm.patchValue({
				id: savingsAsset.id,
				applicationId: savingsAsset.applicationId,
				applicantId: savingsAsset.applicantId,
				value: savingsAsset.value,
				type: EnumHelper.getEnumObject(FinancialAssetType as unknown as { [index: string]: number }, savingsAsset.type),
				description: savingsAsset.description
			});
			this.applicantId.disable();
		} else {
			this.isEditMode = false;
			this.savingsAssetForm.reset();
			this.savingsAssetForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				value: null,
				type: null,
				description: ''
			});
			this.applicantId.enable();
		}
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Assets',
				AssetType.Savings,
				AssetType.Savings
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about these savings`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseSavingsConfig = this.metadataService.getFieldByName('WhoseSavings', fields);
			this.typeConfig = this.metadataService.getFieldByName('Type', fields);
			this.savingsTypes = this.populateOptions(this.typeConfig.options);
			this.descriptionConfig = this.metadataService.getFieldByName('Description', fields);
			this.valueConfig = this.metadataService.getFieldByName('Value', fields);

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}
}
