import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { AreaMetadata } from '@simpology/client-components/utils';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { LoanDetailService } from 'src/app/loan-details/api/loan-detail.service';
import { LoanDetail } from 'src/app/loan-details/model/loan-detail.model';
import { OverlayService } from 'src/app/overlay/overlay.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { AddressHelper } from 'src/app/shared/helper/address-helper';
import { Constant } from 'src/app/shared/helper/constant';
import { Address } from 'src/app/shared/model/address.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import {
	FinancialAssetType,
	NonRealEstateAssetType,
	RbaLendingPurpose,
	StepStatus,
	StepType
} from 'src/app/shared/model/enum.model';
import { SectionMetadata } from 'src/app/shared/model/section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { ApplicationStepService } from 'src/app/shared/service/application-step.service';
import { NavigationService } from '../../../navigation/navigation.service';
import { AssetService } from '../../api/asset.service';
import { AssetType } from '../../enums/asset-type.enum';
import { Asset } from '../../model/asset.model';
import { OtherAsset } from '../../model/other-asset.model';
import { PropertyAsset } from '../../model/property-asset.model';
import { SavingsAsset } from '../../model/savings-asset.model';
import { AssetDetailService } from '../../service/asset-detail.service';

@Component({
	selector: 'asset-details',
	templateUrl: './asset-details.component.html',
	styleUrls: ['./asset-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideInOut', [
			state(
				'show',
				style({
					transform: 'translate3d(0,0,0)'
				})
			),
			state(
				'hide',
				style({
					transform: 'translate3d(100%, 0, 0)'
				})
			),
			transition('show => hide', animate('400ms ease-in-out')),
			transition('hide => show', animate('400ms ease-in-out'))
		])
	]
})
export class AssetDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('overlayContent', { static: false })
	public overlayContent!: TemplateRef<HTMLElement>;

	public additionalInfoState: 'show' | 'hide' = 'hide';
	public additionalInfoType: AssetType = AssetType.Property;
	public assetType = AssetType;
	public asset: Asset;
	public numberOfApplicants: number;
	public popTrigger: string;
	public isRefinance = false;

	public areaMetadata: AreaMetadata = {} as AreaMetadata;
	public propertyAssetSection: SectionMetadata = {} as SectionMetadata;
	public savingsAssetSection: SectionMetadata = {} as SectionMetadata;
	public otherAssetSection: SectionMetadata = {} as SectionMetadata;
	public finishButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public doneButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private existingApplicationId = Constant.newId;
	private applicantId = Constant.newId;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private assetService: AssetService,
		private changeDetectorRef: ChangeDetectorRef,
		private applicantService: ApplicantService,
		private assetDetailService: AssetDetailService,
		private applicationStepService: ApplicationStepService,
		private overlayService: OverlayService,
		private metadataService: MetadataService,
		private loanDetailService: LoanDetailService
	) {
		this.setSectionMetadata();

		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.applicantId = this.applicationService.getStoredPrimaryApplicant()?.id ?? Constant.newId;
		this.asset = {
			propertyAsset: [],
			savingsAsset: [],
			otherAsset: []
		};
		this.numberOfApplicants = this.applicantService.getApplicants(false).length;

		this.popTrigger = this.isTouchDevice() ? 'click' : 'hover';

		this.loanDetailService.loanDetails$.pipe(takeUntil(this.destroy$)).subscribe((loanDetail: LoanDetail | null) => {
			this.isRefinance = loanDetail?.plan === RbaLendingPurpose.Refinance;
			this.changeDetectorRef.markForCheck();
		});
	}

	public get totalAssets(): number {
		const propertyAssetAmount = this.asset.propertyAsset
			.map((i) => (i.value * i.percent) / 100)
			.reduce((a, b) => a + b, 0);
		const savingsAssetAmount = this.asset.savingsAsset.map((i) => i.value).reduce((a, b) => a + b, 0);
		const otherAssetAmount = this.asset.otherAsset.map((i) => i.value).reduce((a, b) => a + b, 0);
		return propertyAssetAmount + savingsAssetAmount + otherAssetAmount;
	}

	public get hasAsset(): boolean {
		return (
			(!!this.asset?.propertyAsset?.length || !!this.asset?.savingsAsset?.length || !!this.asset?.otherAsset?.length) &&
			this.totalAssets !== 0
		);
	}

	public get financialAssetType(): typeof FinancialAssetType {
		return FinancialAssetType;
	}

	public get nonRealEstateAssetType(): typeof NonRealEstateAssetType {
		return NonRealEstateAssetType;
	}

	public ngOnInit(): void {
		this.navigationService.syncNavigation(StepType.Assets);
		this.loadAssets();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public getApplicantName(applicantId: number): string {
		return this.applicantService.getApplicantName(applicantId)?.name ?? '';
	}

	public getCalculatedAssetValue(assetRecord: PropertyAsset): number {
		return (assetRecord.value * assetRecord.percent) / 100;
	}

	public buildAddressLine(address: Address): string {
		return AddressHelper.buildAddressLine(address);
	}

	public onSubmit(): void {
		this.navigationService.goToNextStep(SimpProgressBarStatus.Complete);
		this.applicationStepService.updateStep(StepType.Assets, StepStatus.Complete);
	}

	public toggleAdditionalInfoPage(): void {
		this.additionalInfoState = this.additionalInfoState === 'hide' ? 'show' : 'hide';
	}

	public addEditAdditionalAsset(assetType: AssetType, asset?: PropertyAsset | SavingsAsset | OtherAsset): void {
		this.additionalInfoType = assetType;
		switch (assetType) {
			case AssetType.Property:
				this.assetDetailService.addEditProperty(asset as PropertyAsset);
				break;
			case AssetType.Savings:
				this.assetDetailService.addEditSaving(asset as SavingsAsset);
				break;
			case AssetType.Other:
				this.assetDetailService.addEditOtherAsset(asset as OtherAsset);
				break;
		}
		this.toggleAdditionalInfoPage();
	}

	public updateAsset(asset: any): void {
		this.toggleAdditionalInfoPage();
		switch (this.additionalInfoType) {
			case AssetType.Property:
				this.updatePropertyAsset();
				break;
			case AssetType.Savings:
				this.updateSavingsAsset(asset);
				break;
			case AssetType.Other:
				this.updateOtherAsset(asset);
				break;
		}
	}

	public handleAssetDelete(asset: OtherAsset): void {
		this.toggleAdditionalInfoPage();
		let index = -1;
		switch (this.additionalInfoType) {
			case AssetType.Property:
				{
					const propertyAssets = this.asset.propertyAsset;
					index = propertyAssets.findIndex((i) => i.id === asset.id);
					if (index > -1) {
						this.asset.propertyAsset.splice(index, 1);
					}
				}
				break;
			case AssetType.Savings:
				{
					const savingsAssets = this.asset.savingsAsset;
					index = savingsAssets.findIndex((i) => i.id === asset.id);
					if (index > -1) {
						this.asset.savingsAsset.splice(index, 1);
					}
				}
				break;
			case AssetType.Other:
				{
					const otherAssets = this.asset.otherAsset;
					index = otherAssets.findIndex((i) => i.id === asset.id);
					if (index > -1) {
						this.asset.otherAsset.splice(index, 1);
					}
				}
				break;
		}

		if (!this.hasAsset) {
			this.applicationStepService.updateStep(StepType.Assets, StepStatus.Incomplete);
			this.navigationService.updateStepStatus(StepType.Assets, SimpProgressBarStatus.NotStarted);
		}
	}

	public finishLater(): void {
		this.overlayService.startOverlay(this.overlayContent);
	}

	public closeOverlay(exit = false): void {
		this.overlayService.stopOverlay();
		if (exit) {
			this.navigationService.exitApp();
		}
	}

	public handlePopoverShown(): void {
		if (this.isTouchDevice()) {
			this.overlayService.startOverlay(null);
		}
	}

	public handlePopoverHidden(): void {
		if (this.isTouchDevice()) {
			this.overlayService.stopOverlay();
		}
	}

	private updatePropertyAsset(): void {
		this.assetService
			.getPropertiesByApplication(this.existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.asset.propertyAsset = result;
				this.changeDetectorRef.markForCheck();
			});
	}

	private updateSavingsAsset(savingsAsset: SavingsAsset): void {
		const savingsAssets = this.asset.savingsAsset;
		const index = savingsAssets.findIndex((asset) => asset.id === savingsAsset.id);
		if (index > -1) {
			this.asset.savingsAsset.splice(index, 1, savingsAsset);
		} else {
			this.asset.savingsAsset.push(savingsAsset);
		}
	}

	private updateOtherAsset(otherAsset: OtherAsset): void {
		const otherAssets = this.asset.otherAsset;
		const index = otherAssets.findIndex((asset) => asset.id === otherAsset.id);
		if (index > -1) {
			this.asset.otherAsset.splice(index, 1, otherAsset);
		} else {
			this.asset.otherAsset.push(otherAsset);
		}
	}

	private loadAssets(): void {
		if (this.existingApplicationId !== Constant.newId && this.applicantId !== Constant.newId) {
			const getPropertyAssets = this.assetService
				.getPropertiesByApplication(this.existingApplicationId)
				.pipe(catchError((error) => of([])));
			const getSavings = this.assetService
				.getSavingsByApplication(this.existingApplicationId)
				.pipe(catchError((error) => of([])));
			const getOtherAssets = this.assetService
				.getOtherAssetsByApplication(this.existingApplicationId)
				.pipe(catchError((error) => of([])));
			forkJoin([getPropertyAssets, getSavings, getOtherAssets])
				.pipe(takeUntil(this.destroy$))
				.subscribe((results) => {
					this.asset.propertyAsset = [...results[0]];
					this.asset.savingsAsset = [...results[1]];
					this.asset.otherAsset = [...results[2]];
					this.changeDetectorRef.markForCheck();
				});
		} else {
			this.navigationService.goToHome();
		}
	}

	private isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	private setSectionMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.areaMetadata = this.metadataService.getAreaMetadataByName('Assets');
			this.propertyAssetSection = this.metadataService.getSectionMetadataByName('Assets', AssetType.Property);
			this.savingsAssetSection = this.metadataService.getSectionMetadataByName('Assets', AssetType.Savings);
			this.otherAssetSection = this.metadataService.getSectionMetadataByName('Assets', AssetType.Other);

			const buttons: ButtonMetadata[] = this.areaMetadata?.buttons;
			this.finishButtonConfig = this.metadataService.getButtonByName('Finish', buttons);
			this.doneButtonConfig = this.metadataService.getButtonByName('Done', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}
}
