import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@simpology/authentication';
import { SimpProgressTrackerStep } from '@simpology/client-components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { OverlayService } from '../overlay/overlay.service';
import { BrandService } from '../shared/api/brand.service';
import { MetadataService } from '../shared/api/metadata.service';
import { Metadata } from '../shared/model/metadata.model';

@Component({
	selector: 'app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
	@ViewChild('overlayContent', { static: false })
	public overlayContent!: TemplateRef<HTMLElement>;

	public currentStep: SimpProgressTrackerStep = {} as SimpProgressTrackerStep;
	public showProgressTracker = true;
	public appTitle = '';
	public brandName = '';

	private destroy$: Subject<void> = new Subject();

	constructor(
		private router: Router,
		private authService: AuthService,
		private navigationService: NavigationService,
		private metadataService: MetadataService,
		private overlayService: OverlayService,
		private brandService: BrandService
	) {
		this.brandService.currentBrandName$
			.pipe(takeUntil(this.destroy$))
			.subscribe((brandName: string) => (this.brandName = brandName));
		this.setMetadata();
	}

	public ngOnInit(): void {
		this.navigationService.currentStep$
			.pipe(takeUntil(this.destroy$))
			.subscribe((step: SimpProgressTrackerStep) => (this.currentStep = step));

		this.navigationService.showProgressTracker$
			.pipe(takeUntil(this.destroy$))
			.subscribe((showProgressTracker: boolean) => (this.showProgressTracker = showProgressTracker));
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public goBack(): void {
		if (!this.showProgressTracker) {
			return;
		}

		if (this.currentStep.stepId === 1) {
			this.navigationService.goToHome();
		} else {
			this.navigationService.goToPreviousStep();
		}
	}

	public exitApp(): void {
		if (this.router.url.indexOf('review') > -1) {
			const isAnonymousUser = this.authService.anonymousUserId;
			if (!isAnonymousUser) {
				this.navigationService.goToHome();
				return;
			}
		}
		this.overlayService.startOverlay(this.overlayContent);
	}

	public closeOverlay(exit = false): void {
		this.overlayService.stopOverlay();
		if (exit) {
			this.navigationService.exitApp();
		}
	}

	private setMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe((metadata: Metadata) => {
			this.appTitle = metadata.title;
		});
	}
}
