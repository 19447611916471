<h4 class="simp-h4 simp-text--primary">{{ sectionTitle }}</h4>
<form
	class="additional-info__form simp-padding-top-large"
	[formGroup]="homeLoanDetailsForm"
	(ngSubmit)="onSubmit()"
	autocomplete="off"
>
	<div class="simp-row" *ngIf="whoseLoanConfig?.visible === 'Visible' && options.applicants.length > 2">
		<div class="form-group col-12">
			<label class="simp-label" for="applicantSelect">{{ whoseLoanConfig.title }}</label>
			<ng-select
				class="simp-select"
				labelForId="applicantSelect"
				placeholder="Select"
				[items]="options.applicants"
				[searchable]="false"
				[clearable]="false"
				[bindLabel]="'name'"
				[bindValue]="'id'"
				[formControl]="applicantId"
			>
			</ng-select>

			<simp-field-error
				*ngIf="(applicantId.dirty || applicantId.touched) && applicantId.errors"
				[errors]="applicantId.errors"
				[validationErrors]="validationErrors.applicantId"
			></simp-field-error>
		</div>
	</div>

	<div class="simp-row" *ngIf="financialInstitutionConfig?.visible === 'Visible'">
		<div class="form-group col-12">
			<label class="simp-label" for="financialInstitution">{{ financialInstitutionConfig.title }}</label>
			<ng-select
				class="simp-select"
				labelForId="financialInstitution"
				placeholder="Select"
				[items]="options.financialInstitutions"
				[searchable]="true"
				[clearable]="false"
				[formControl]="financialInstitution"
			></ng-select>

			<simp-field-error
				*ngIf="(financialInstitution.dirty || financialInstitution.touched) && financialInstitution.errors"
				[errors]="financialInstitution.errors"
				[validationErrors]="validationErrors.financialInstitution"
			>
			</simp-field-error>
		</div>
	</div>

	<div
		class="simp-row"
		[class.simp-margin-bottom-small]="addressId.value !== -1"
		*ngIf="addressConfig?.visible === 'Visible'"
	>
		<div class="col-12">
			<label for="" class="simp-label additional-info__property-label">{{ addressConfig.title }}</label>
			<div class="form-check" *ngFor="let existingAddress of existingAddresses">
				<input
					class="form-check-input"
					type="radio"
					[value]="existingAddress.id"
					[id]="existingAddress.id"
					formControlName="addressId"
					(change)="handleExistingAddressSelect($event)"
				/>
				<label class="form-check-label" [for]="existingAddress.id">
					{{ existingAddress.label }}
					<i
						class="fas fa-info-circle simp-text--grey70"
						[ngbPopover]="popContent"
						[triggers]="popTrigger"
						container="body"
						popoverClass="purchasing-help-popover"
						(shown)="handlePopoverShown()"
						(hidden)="handlePopoverHidden()"
						(click)="$event.stopPropagation()"
						#popover2="ngbPopover"
						*ngIf="existingAddress.address.isPrimaryProperty"
					></i>
					<ng-template #popContent>
						<div class="simp-text simp-text-small">
							This is the property being {{ isRefinance ? "refinanced" : "purchased" }}
						</div>
					</ng-template>
				</label>
			</div>
			<div class="form-check">
				<input
					class="form-check-input"
					type="radio"
					[value]="-1"
					id="newAddress"
					formControlName="addressId"
					(change)="handleNewAddressSelect($event)"
				/>
				<label class="form-check-label" for="newAddress"> Add new address </label>
			</div>
		</div>
	</div>

	<div class="simp-row additional-info__new-address" *ngIf="addressId.value === -1">
		<simp-address-input
			class="col-12"
			[formControl]="address"
			[countries]="allowedCountries"
			[validationErrors]="validationErrors.address"
			(addressChange)="updateAddress($event)"
		>
		</simp-address-input>
	</div>

	<div class="simp-row" *ngIf="loanAmountConfig?.visible === 'Visible'">
		<simp-amount-input class="col-12" [formControl]="loanAmount" [validationErrors]="validationErrors.loanAmount">
			{{ loanAmountConfig.title }}
		</simp-amount-input>
	</div>

	<div class="simp-row" *ngIf="repaymentConfig?.visible === 'Visible'">
		<simp-amount-select-input
			class="col-12"
			id="repayment"
			[formGroup]="repayment"
			[options]="populateOptions(repaymentFrequencyConfig.options)"
			[validationErrors]="validationErrors.repaymentAmount"
			[label]="repaymentConfig.title"
		>
		</simp-amount-select-input>
	</div>

	<div class="simp-row" *ngIf="isRefinance">
		<simp-button-group
			id="clearingFromThisLoan"
			class="col-12"
			[formControl]="clearingFromThisLoan"
			[validationErrors]="validationErrors.clearingFromThisLoan"
			[options]="options.clearingFromThisLoanOptions"
		>
			{{ refinancedConfig.title }}
		</simp-button-group>
	</div>

	<div class="simp-row simp-margin-top-large simp-margin-bottom-small">
		<div class="col-6">
			<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="handleBackClick()">
				{{ cancelButtonConfig.title }}
			</button>
		</div>
		<div class="col-6">
			<button type="submit" class="btn btn-primary btn-lg simp-button col-12" [disabled]="invalidData || isSubmitting">
				{{ continueButtonConfig.title }}
			</button>
		</div>
	</div>
</form>

<button
	type="button"
	class="btn btn-tertiary btn-lg simp-button additional-info__delete-button col-12 simp-margin-vertical-medium"
	(click)="deleteRecord()"
	*ngIf="isEditMode"
>
	Delete<i class="fas fa-trash-alt simp-margin-left-base"></i>
</button>
