/*eslint max-len: ["error", { "code": 180 }]*/

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { SimpologyComponentModule } from '@simpology/client-components';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { IncomeDetailsComponent } from './income-details/components/income-details/income-details.component';
import { ExpenseDetailsComponent } from './expense-details/expense-details.component';
import { AssetDetailsComponent } from './asset-details/components/asset-details/asset-details.component';
import { LiabilityDetailsComponent } from './liability-details/components/liability-details/liability-details.component';
import { SimpAuthenticationModule } from '@simpology/authentication';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { HomeComponent } from './home/home.component';
import { AdditionalIncomeDetailsComponent } from './income-details/components/additional-income-details/additional-income-details.component';
import { AdditionalExpenseDetailsComponent } from './expense-details/additional-expense-details/additional-expense-details.component';
import { AdditionalAssetDetailsComponent } from './asset-details/components/additional-asset-details/additional-asset-details.component';
import { AdditionalLiabilityDetailsComponent } from './liability-details/components/additional-liability-details/additional-liability-details.component';
import { EmploymentIncomeDetailsComponent } from './income-details/components/employment-income-details/employment-income-details.component';
import { RentalIncomeDetailsComponent } from './income-details/components/rental-income-details/rental-income-details.component';
import { OtherIncomeDetailsComponent } from './income-details/components/other-income-details/other-income-details.component';
import { EnumDesc } from './shared/pipe/enum-desc.pipe';
import { PropertyAssetDetailsComponent } from './asset-details/components/property-asset-details/property-asset-details.component';
import { SavingsAssetDetailsComponent } from './asset-details/components/savings-asset-details/savings-asset-details.component';
import { OtherAssetDetailsComponent } from './asset-details/components/other-asset-details/other-asset-details.component';
import { CreditCardDetailsComponent } from './liability-details/components/credit-card-details/credit-card-details.component';
import { PersonalLoanDetailsComponent } from './liability-details/components/personal-loan-details/personal-loan-details.component';
import { HomeLoanDetailsComponent } from './liability-details/components/home-loan-details/home-loan-details.component';
import { OtherLiabilityDetailsComponent } from './liability-details/components/other-liability-details/other-liability-details.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExpiredComponent } from './expired/expired.component';
import { ExpensesReviewComponent } from './review-details/components/expenses-review/expenses-review.component';
import { ReviewDetailsComponent } from './review-details/components/review-details/review-details.component';
import { ServiceabilityResultComponent } from './serviceability-result/serviceability-result.component';
import { OverlayComponent } from './overlay/overlay.component';
import { DatePipe } from '@angular/common';
import { NgbDateCustomParserFormatter } from './shared/helper/ngb-date-custom-parser-formatter';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { RegisterComponent } from './register/register.component';
import { ENV_CONFIG } from 'src/env-config';

@NgModule({
	declarations: [
		AppComponent,
		LayoutComponent,
		AppFooterComponent,
		AppHeaderComponent,
		LoanDetailsComponent,
		PersonalDetailsComponent,
		IncomeDetailsComponent,
		ExpenseDetailsComponent,
		AssetDetailsComponent,
		LiabilityDetailsComponent,
		HomeComponent,
		AdditionalIncomeDetailsComponent,
		AdditionalExpenseDetailsComponent,
		AdditionalAssetDetailsComponent,
		AdditionalLiabilityDetailsComponent,
		EmploymentIncomeDetailsComponent,
		RentalIncomeDetailsComponent,
		OtherIncomeDetailsComponent,
		EnumDesc,
		PropertyAssetDetailsComponent,
		SavingsAssetDetailsComponent,
		OtherAssetDetailsComponent,
		CreditCardDetailsComponent,
		PersonalLoanDetailsComponent,
		HomeLoanDetailsComponent,
		OtherLiabilityDetailsComponent,
		ReviewDetailsComponent,
		AuthCallbackComponent,
		DashboardComponent,
		ExpiredComponent,
		ExpensesReviewComponent,
		ServiceabilityResultComponent,
		OverlayComponent,
		PrivacyTermsComponent,
		RegisterComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgbModule,
		SimpologyComponentModule,
		SimpAuthenticationModule.forRoot({
			environment: ENV_CONFIG
		}),
		ToastrModule.forRoot({
			closeButton: true,
			positionClass: 'toast-top-center',
			preventDuplicates: true
		})
	],
	providers: [
		DatePipe,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
