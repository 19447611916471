import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoanDetailService } from '../loan-details/api/loan-detail.service';
import { LoanDetail } from '../loan-details/model/loan-detail.model';
import { NavigationService } from '../navigation/navigation.service';
import { ApplicationService } from '../shared/api/application.service';
import { BrandService } from '../shared/api/brand.service';
import { MetadataService } from '../shared/api/metadata.service';
import { Constant } from '../shared/helper/constant';
import { AreaMetadata } from '../shared/model/area-metadata.model';
import { BrandConfig } from '../shared/model/brand.config';
import { ButtonMetadata } from '../shared/model/button-metadata.model';
import { AuState, RbaLendingPurpose } from '../shared/model/enum.model';
import { Journey } from '../shared/model/journey.model';
import { SubSectionMetadata } from '../shared/model/sub-section-metadata.model';
import { ServiceabilityService } from './service/serviceability.service';

@Component({
	selector: 'serviceability-result',
	templateUrl: './serviceability-result.component.html',
	styleUrls: ['./serviceability-result.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceabilityResultComponent implements OnInit, AfterViewInit, OnDestroy {
	public auState = AuState;
	public rbaLendingPurpose = RbaLendingPurpose;
	public serviceabilityResult = false;
	public loanDetails: LoanDetail = {} as LoanDetail;
	public contactEmail = '';
	public contactPhone = '';
	public referenceNumber = '';

	public areaMetadata: AreaMetadata = {} as AreaMetadata;
	public headerText = '';
	public subHeaderText = '';
	public messageText = '';
	public chatHeaderText = '';
	public chatMessageText = '';
	public emailButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public callButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private existingApplicationId = Constant.newId;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private navigationService: NavigationService,
		private serviceabilityService: ServiceabilityService,
		private applicationService: ApplicationService,
		private loanDetailService: LoanDetailService,
		private metadataService: MetadataService,
		private brandService: BrandService
	) {
		this.serviceabilityService.serviceabilityResult$.pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
			this.serviceabilityResult = result;
			this.setSectionMetadata();
		});
		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;

		this.brandService.brandConfig$.pipe(takeUntil(this.destroy$)).subscribe((brandConfig: BrandConfig) => {
			this.contactEmail = brandConfig.contactEmail;
			this.contactPhone = brandConfig.contactPhone;
		});
	}

	public ngOnInit(): void {
		this.loadApplicationDetail(this.existingApplicationId);
	}

	public ngAfterViewInit(): void {
		setTimeout(() => {
			this.navigationService.toggleProgressTracker();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	private loadApplicationDetail(existingApplicationId: number): void {
		this.loanDetailService.loanDetails$
			.pipe(takeUntil(this.destroy$))
			.subscribe((loanDetailResult: LoanDetail | null) => {
				this.loanDetails = loanDetailResult as LoanDetail;
				this.changeDetectorRef.markForCheck();
			});

		this.applicationService
			.getCurrentJourney(existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((journeyResult: Journey) => {
				this.referenceNumber = journeyResult?.reference;
				this.changeDetectorRef.markForCheck();
			});
	}

	private setSectionMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.areaMetadata = this.metadataService.getAreaMetadataByName('Decision');

			const subSection: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Decision',
				'Decision',
				this.serviceabilityResult ? 'Pass' : 'Fail'
			);

			this.headerText = this.metadataService.getTextByName('Header', subSection.texts);
			this.subHeaderText = this.metadataService.getTextByName('SubHeader', subSection.texts);
			this.messageText = this.metadataService.getTextByName('Message', subSection.texts);
			this.chatHeaderText = this.metadataService.getTextByName('ChatHeader', subSection.texts);
			this.chatMessageText = this.metadataService.getTextByName('ChatMessage', subSection.texts);

			const buttons: ButtonMetadata[] = this.areaMetadata.buttons;
			this.emailButtonConfig = this.metadataService.getButtonByName('Email', buttons);
			this.callButtonConfig = this.metadataService.getButtonByName('Call', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}
}
