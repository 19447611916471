export class CurrencyHelper {
	public static unformatAmount(amount: string): number {
		if (!amount) {
			return 0;
		}
		if (+amount) {
			return +amount;
		}

		return +amount.replace(/[^0-9.]/g, '');
	}
}
