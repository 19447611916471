import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { SectionMetadata } from 'src/app/shared/model/section-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { TextMetadata } from 'src/app/shared/model/text-metadata.model';
import { LiabilityType } from '../../enums/liability-type.enum';

@Component({
	selector: 'additional-liability-details',
	templateUrl: './additional-liability-details.component.html',
	styleUrls: ['./additional-liability-details.component.scss']
})
export class AdditionalLiabilityDetailsComponent implements OnChanges {
	@Input() public liabilityType: LiabilityType = LiabilityType.CreditCard;
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public updateLiability: EventEmitter<any> = new EventEmitter<any>();
	@Output() public deleteLiability: EventEmitter<any> = new EventEmitter<any>();

	public sectionHeader = '';

	constructor(private metadataService: MetadataService) {}

	public ngOnChanges(): void {
		const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
			'Liabilities',
			this.liabilityType,
			this.liabilityType
		);
		this.sectionHeader = this.metadataService.getTextByName('Header', sectionMetadata.texts);
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public updateLiabilityDetails(liabilityDetails: any): void {
		this.updateLiability.emit(liabilityDetails);
	}

	public deleteLiabilityDetails(liabilityDetails: any): void {
		this.deleteLiability.emit(liabilityDetails);
	}
}
