import { animate, state, style, transition, trigger } from '@angular/animations';
import { CurrencyPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { AreaMetadata } from '@simpology/client-components/utils';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { OverlayService } from '../overlay/overlay.service';
import { ApplicationService } from '../shared/api/application.service';
import { MetadataService } from '../shared/api/metadata.service';
import { CalculationHelper } from '../shared/helper/calculation-helper';
import { Constant } from '../shared/helper/constant';
import { CurrencyHelper } from '../shared/helper/currency-helper';
import { ValidationHelper } from '../shared/helper/validation-helper';
import { ButtonMetadata } from '../shared/model/button-metadata.model';
import {
	EnumObject,
	ExpenseClassificationExpenseClass,
	FrequencyFull,
	StepStatus,
	StepType
} from '../shared/model/enum.model';
import { SectionMetadata } from '../shared/model/section-metadata.model';
import { ApplicationStepService } from '../shared/service/application-step.service';
import { ExpenseService } from './api/expense.service';
import { Expense } from './model/expense.model';
import { ExpensesDetailsService } from './service/expense-details.service';

@Component({
	selector: 'expense-details',
	templateUrl: './expense-details.component.html',
	styleUrls: ['./expense-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideInOut', [
			state(
				'show',
				style({
					transform: 'translate3d(0,0,0)'
				})
			),
			state(
				'hide',
				style({
					transform: 'translate3d(100%, 0, 0)'
				})
			),
			transition('show => hide', animate('400ms ease-in-out')),
			transition('hide => show', animate('400ms ease-in-out'))
		])
	]
})
export class ExpenseDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('overlayContent', { static: false })
	public overlayContent!: TemplateRef<HTMLElement>;

	public expenseDetailsForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public additionalInfoState: 'show' | 'hide' = 'hide';
	public frequencyOptions: EnumObject[] = [];
	public isMultiHousehold = false;
	public householdExpensesPrimary: Expense[] = [];
	public householdExpensesAdditional: Expense[] = [];
	public additionalApplicantName: string;
	public isSubmitting = false;

	public areaMetadata: AreaMetadata = {} as AreaMetadata;
	public totalExpensesSection: SectionMetadata = {} as SectionMetadata;
	public finishButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public doneButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public helpCalculateButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private existingApplicationId = Constant.newId;
	private primaryApplicantId = Constant.newId;
	private additionalApplicantId = Constant.newId;
	private householdBeingUpdated: 'Primary' | 'Additional' = 'Primary';
	private destroy$: Subject<void> = new Subject();

	public constructor(
		private formBuilder: UntypedFormBuilder,
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private expenseService: ExpenseService,
		private applicationStepService: ApplicationStepService,
		private expensesDetailsService: ExpensesDetailsService,
		private overlayService: OverlayService,
		private changeDetectorRef: ChangeDetectorRef,
		private currencyPipe: CurrencyPipe,
		private metadataService: MetadataService
	) {
		this.setSectionMetadata();

		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.primaryApplicantId = this.applicationService.getStoredPrimaryApplicant()?.id ?? Constant.newId;
		this.additionalApplicantId = this.applicationService.getStoredAdditionalApplicant()?.id ?? Constant.newId;
		this.additionalApplicantName = this.applicationService.getStoredAdditionalApplicant()?.name ?? '';
		this.isMultiHousehold = this.applicationService.getStoredMultiHouseholdOption();

		this.expenseDetailsForm = this.formBuilder.group({
			primaryApplicantGroup: this.formBuilder.group({
				id: [Constant.newId],
				applicationId: [this.applicationService.getStoredApplicationId()],
				applicantId: this.primaryApplicantId,
				primaryApplicantTotalExpense: this.formBuilder.group({
					amount: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
					frequency: [this.getDefaultFrequency()?.id, Validators.required]
				}),
				isCalculatedValue: [false]
			})
		});

		if (this.isMultiHousehold) {
			this.expenseDetailsForm.addControl(
				'additionalApplicantGroup',
				this.formBuilder.group({
					id: [Constant.newId],
					applicationId: [this.applicationService.getStoredApplicationId()],
					applicantId: this.additionalApplicantId,
					additionalApplicantTotalExpense: this.formBuilder.group({
						amount: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
						frequency: [this.getDefaultFrequency()?.id, Validators.required]
					}),
					isCalculatedValue: [false]
				})
			);
		}

		this.validationErrors = {
			expenseAmount: {
				required: 'Enter an amount',
				min: 'Expense amount cannot be zero',
				max: 'Expense amount cannot be more than $10,000,000'
			}
		};
	}

	public get primaryApplicantGroup() {
		return this.expenseDetailsForm.controls.primaryApplicantGroup as UntypedFormGroup;
	}

	public get additionalApplicantGroup() {
		return this.expenseDetailsForm.controls.additionalApplicantGroup as UntypedFormGroup;
	}

	public get primaryApplicantTotalExpense() {
		return this.primaryApplicantGroup.controls.primaryApplicantTotalExpense as UntypedFormGroup;
	}

	public get additionalApplicantTotalExpense() {
		return this.additionalApplicantGroup.controls.additionalApplicantTotalExpense as UntypedFormGroup;
	}

	public get hasExpenses(): boolean {
		if (this.isMultiHousehold) {
			return (
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
				CurrencyHelper.unformatAmount(this.primaryApplicantTotalExpense.value.amount) !== 0 &&
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
				CurrencyHelper.unformatAmount(this.additionalApplicantTotalExpense.value.amount) !== 0
			);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
			return CurrencyHelper.unformatAmount(this.primaryApplicantTotalExpense.value.amount) !== 0;
		}
	}

	public ngOnInit(): void {
		this.navigationService.syncNavigation(StepType.Expenses);
		this.getHouseholdExpense();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public onSubmit(updateStepCompletion = true): void {
		if (this.expenseDetailsForm.invalid) {
			return;
		}

		this.isSubmitting = true;

		let savePrimaryHouseholdExpenses$: Observable<number | void>;
		if (this.householdExpensesPrimary.length > 0) {
			savePrimaryHouseholdExpenses$ = this.expenseService.saveHousehold(this.householdExpensesPrimary);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			savePrimaryHouseholdExpenses$ = this.expenseService.saveHouseholdTotal({
				...this.primaryApplicantGroup.value,
				expenseClass: ExpenseClassificationExpenseClass.Other,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
				amount: CurrencyHelper.unformatAmount(this.primaryApplicantTotalExpense.value.amount),
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
				frequency: this.primaryApplicantTotalExpense.value.frequency
			});
		}

		if (this.isMultiHousehold) {
			let saveAdditionalHouseholdExpenses$: Observable<number | void>;
			if (this.householdExpensesAdditional.length > 0) {
				saveAdditionalHouseholdExpenses$ = this.expenseService.saveHousehold(this.householdExpensesAdditional);
			} else {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				saveAdditionalHouseholdExpenses$ = this.expenseService.saveHouseholdTotal({
					...this.additionalApplicantGroup.value,
					expenseClass: ExpenseClassificationExpenseClass.Other,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					amount: CurrencyHelper.unformatAmount(this.additionalApplicantTotalExpense.value.amount),
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
					frequency: this.additionalApplicantTotalExpense.value.frequency
				});
			}
			forkJoin([savePrimaryHouseholdExpenses$, saveAdditionalHouseholdExpenses$])
				.subscribe((results) => {
					if (updateStepCompletion) {
						this.markStepAsComplete();
					}
				})
				.add(() => {
					this.isSubmitting = false;
					this.changeDetectorRef.markForCheck();
				});
		} else {
			savePrimaryHouseholdExpenses$
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					if (updateStepCompletion) {
						this.markStepAsComplete();
					}
				})
				.add(() => {
					this.isSubmitting = false;
					this.changeDetectorRef.markForCheck();
				});
		}
	}

	public calculateExpenses(isPrimaryHousehold = false): void {
		this.householdBeingUpdated = isPrimaryHousehold ? 'Primary' : 'Additional';
		this.expensesDetailsService.addEditExpenses(
			isPrimaryHousehold ? this.householdExpensesPrimary : this.householdExpensesAdditional
		);
		this.toggleAdditionalInfoPage();
	}

	public toggleAdditionalInfoPage(): void {
		this.additionalInfoState = this.additionalInfoState === 'hide' ? 'show' : 'hide';
	}

	public updateExpenseAmount(expenses: Expense[]): void {
		this.toggleAdditionalInfoPage();
		if (expenses.length > 0) {
			expenses.forEach((expense) => {
				expense.applicationId = this.existingApplicationId;
				expense.applicantId =
					this.householdBeingUpdated === 'Primary' ? this.primaryApplicantId : this.additionalApplicantId;
			});

			if (this.householdBeingUpdated === 'Primary') {
				this.householdExpensesPrimary = [...expenses];
				this.primaryApplicantGroup.patchValue({
					primaryApplicantTotalExpense: {
						amount: this.currencyPipe.transform(this.calculateMonthlyExpenseAmount(expenses), 'USD', '', '1.0-2'),
						frequency: this.getDefaultFrequency()?.id
					},
					isCalculatedValue: expenses.length > 0
				});
				this.primaryApplicantTotalExpense.disable();
			} else {
				this.householdExpensesAdditional = [...expenses];
				this.additionalApplicantGroup.patchValue({
					additionalApplicantTotalExpense: {
						amount: this.currencyPipe.transform(this.calculateMonthlyExpenseAmount(expenses), 'USD', '', '1.0-2'),
						frequency: this.getDefaultFrequency()?.id
					},
					isCalculatedValue: expenses.length > 0
				});
				this.additionalApplicantTotalExpense.disable();
			}
			this.changeDetectorRef.markForCheck();
			this.expenseDetailsForm.markAsDirty();
		}
	}

	public finishLater(): void {
		this.overlayService.startOverlay(this.overlayContent);
	}

	public closeOverlay(exit = false): void {
		this.overlayService.stopOverlay();
		if (exit) {
			this.onSubmit(false);
			this.navigationService.exitApp();
		}
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.frequencyOptions.find((frequency: EnumObject) => frequency.id === FrequencyFull.Monthly) as EnumObject;
	}

	private getHouseholdExpense(): void {
		if (this.existingApplicationId !== Constant.newId) {
			this.expenseService.getByApplication(this.existingApplicationId).subscribe((result: Expense[]) => {
				this.populatePrimaryApplicantHousehold(result);
				if (this.isMultiHousehold) {
					this.populateAdditionalApplicantHousehold(result);
				}
			});
		} else {
			this.navigationService.goToHome();
		}
	}

	private populatePrimaryApplicantHousehold(expenseResult: Expense[]): void {
		const expenses = expenseResult.filter((expense) => expense.applicantId === this.primaryApplicantId);
		if (expenses.length === 0) {
			return;
		}

		if (expenses.length === 1 && expenses[0].expenseClass === ExpenseClassificationExpenseClass.Other) {
			this.primaryApplicantGroup.patchValue({
				id: expenses[0].id,
				primaryApplicantTotalExpense: {
					amount: expenses[0].amount,
					frequency: expenses[0].frequency
				},
				isCalculatedValue: false
			});
		} else {
			this.householdExpensesPrimary = expenses;
			const monthlyExpense = this.calculateMonthlyExpenseAmount(expenses);
			this.primaryApplicantGroup.patchValue({
				primaryApplicantTotalExpense: {
					amount: monthlyExpense,
					frequency: this.getDefaultFrequency()?.id
				},
				isCalculatedValue: true
			});

			this.primaryApplicantTotalExpense.disable();
		}
	}

	private populateAdditionalApplicantHousehold(expenseResult: Expense[]): void {
		const expenses = expenseResult.filter((expense) => expense.applicantId === this.additionalApplicantId);
		if (expenses.length === 0) {
			return;
		}

		if (expenses.length === 1 && expenses[0].expenseClass === ExpenseClassificationExpenseClass.Other) {
			this.additionalApplicantGroup.patchValue({
				id: expenses[0].id,
				additionalApplicantTotalExpense: {
					amount: expenses[0].amount,
					frequency: expenses[0].frequency
				},
				isCalculatedValue: false
			});
		} else {
			this.householdExpensesAdditional = expenses;
			const monthlyExpense = this.calculateMonthlyExpenseAmount(expenses);
			this.additionalApplicantGroup.patchValue({
				additionalApplicantTotalExpense: {
					amount: monthlyExpense,
					frequency: this.getDefaultFrequency()?.id
				},
				isCalculatedValue: true
			});

			this.additionalApplicantTotalExpense.disable();
		}
	}

	private calculateMonthlyExpenseAmount(expenses: Expense[]): number {
		return expenses
			.map((expense) => CalculationHelper.CalculateMonthlyAmount(expense.amount, expense.frequency))
			.reduce((a, b) => a + b, 0);
	}

	private markStepAsComplete(): void {
		this.navigationService.goToNextStep(SimpProgressBarStatus.Complete);
		this.applicationStepService.updateStep(StepType.Expenses, StepStatus.Complete);
	}

	private setSectionMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.areaMetadata = this.metadataService.getAreaMetadataByName('Expenses');
			this.totalExpensesSection = this.metadataService.getSectionMetadataByName('Expenses', 'TotalExpenses');

			const totalExpensesSubsection = this.metadataService.getSubSectionMetadataByName(
				'Expenses',
				'TotalExpenses',
				'TotalExpenses'
			);
			this.frequencyOptions = this.metadataService.getFieldByName('Frequency', totalExpensesSubsection.fields)
				.options as EnumObject[];

			const buttons: ButtonMetadata[] = this.areaMetadata?.buttons;
			this.finishButtonConfig = this.metadataService.getButtonByName('Finish', buttons);
			this.doneButtonConfig = this.metadataService.getButtonByName('Done', buttons);
			this.helpCalculateButtonConfig = this.metadataService.getButtonByName('HelpCalculate', buttons);
		});
	}
}
