import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { BrandService } from '../shared/api/brand.service';
import { Constant } from '../shared/helper/constant';

@Component({
	selector: 'expired',
	templateUrl: './expired.component.html',
	styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit, OnDestroy {
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.calculateHomeScreenHeight();
	}

	public homeScreenHeight = '0px';
	public brandName = '';

	private destroy$: Subject<void> = new Subject();

	constructor(private navigationService: NavigationService, private brandService: BrandService) {
		this.brandService.currentBrandName$
			.pipe(takeUntil(this.destroy$))
			.subscribe((brandName: string) => (this.brandName = brandName));
	}

	public ngOnInit(): void {
		this.navigationService.toggleProgressTracker();
		this.calculateHomeScreenHeight();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public exitApp(): void {
		this.navigationService.exitApp();
	}

	private calculateHomeScreenHeight(): void {
		this.homeScreenHeight = `${window.innerHeight - Constant.appFooterHeight}px`;
	}
}
