import { FrequencyFull, FrequencyShort } from '../model/enum.model';

export class CalculationHelper {
	public static CalculateAnnualAmount(amount: number, frequency: FrequencyShort | FrequencyFull): number {
		switch (frequency) {
			case FrequencyFull.Weekly:
				return amount * 52;
			case FrequencyFull.Fortnightly:
				return amount * 26;
			case FrequencyFull.Monthly:
				return amount * 12;
			case FrequencyFull.Quarterly:
				return amount * 4;
			case FrequencyFull.HalfYearly:
				return amount * 2;
			case FrequencyFull.Yearly:
				return amount;
			default:
				return amount;
		}
	}

	public static CalculateMonthlyAmount(amount: number, frequency: FrequencyShort | FrequencyFull): number {
		const annualAmount = this.CalculateAnnualAmount(amount, frequency);
		return annualAmount / 12;
	}
}
