<div class="additional-info">
	<div class="additional-info__header">
		<div class="additional-info__back-button" (click)="handleBackClick()">
			<i class="far fa-arrow-left"></i>
		</div>
		<h6 class="simp-h6 additional-info__heading simp-text--grey70">{{ sectionHeader }}</h6>
	</div>

	<div class="simp-padding-small additional-info__scrollable-content" [ngSwitch]="liabilityType">
		<ng-template [ngSwitchCase]="'CreditCard'">
			<credit-card-details
				(goBack)="handleBackClick()"
				(update)="updateLiabilityDetails($event)"
				(delete)="deleteLiabilityDetails($event)"
			></credit-card-details>
		</ng-template>

		<ng-template [ngSwitchCase]="'PersonalLoan'">
			<personal-loan-details
				(goBack)="handleBackClick()"
				(update)="updateLiabilityDetails($event)"
				(delete)="deleteLiabilityDetails($event)"
			></personal-loan-details>
		</ng-template>

		<ng-template [ngSwitchCase]="'HomeLoan'">
			<home-loan-details
				(goBack)="handleBackClick()"
				(update)="updateLiabilityDetails($event)"
				(delete)="deleteLiabilityDetails($event)"
			></home-loan-details>
		</ng-template>

		<ng-template [ngSwitchCase]="'Other'">
			<other-liability-details
				(goBack)="handleBackClick()"
				(update)="updateLiabilityDetails($event)"
				(delete)="deleteLiabilityDetails($event)"
			></other-liability-details>
		</ng-template>
	</div>
</div>
