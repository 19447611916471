import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@simpology/authentication';

@Component({
	selector: 'auth-callback',
	templateUrl: './auth-callback.component.html',
	styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
	constructor(private authService: AuthService, private router: Router) {}

	async ngOnInit(): Promise<void> {
		await this.authService.completeAuthentication();
		void this.router.navigateByUrl('/dashboard');
	}
}
