import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
	parse(value: string): NgbDateStruct | null {
		if (value) {
			const dateParts = value.trim().split('/');
			if (dateParts.length === 3 && !isNaN(+dateParts[0]) && !isNaN(+dateParts[1]) && !isNaN(+dateParts[2])) {
				return { day: +dateParts[0], month: +dateParts[1], year: +dateParts[2] };
			} else {
				return null;
			}
		}
		return null;
	}

	format(date: NgbDateStruct): string {
		return date
			? `${date.day ? this.appendZero(date.day) : ''}/${date.month ? this.appendZero(date.month) : ''}/${date.year}`
			: '';
	}

	appendZero(dateMonth: number): string {
		return `${dateMonth}`.length === 1 ? `0${dateMonth}` : `${dateMonth}`;
	}
}
