<h4 class="simp-h4 simp-text--primary">{{ sectionTitle }}</h4>
<form
	class="additional-info__form simp-padding-top-large"
	[formGroup]="propertyAssetForm"
	(ngSubmit)="onSubmit()"
	autocomplete="off"
>
	<div class="simp-row" *ngIf="whoseAssetConfig?.visible === 'Visible' && applicants.length > 2">
		<div class="form-group col-12">
			<label class="simp-label" for="applicantSelect">{{ whoseAssetConfig.title }}</label>
			<ng-select
				class="simp-select"
				labelForId="applicantSelect"
				placeholder="Select"
				[items]="applicants"
				[searchable]="false"
				[clearable]="false"
				[bindLabel]="'name'"
				[bindValue]="'id'"
				[formControl]="applicantId"
			>
			</ng-select>

			<simp-field-error
				*ngIf="(applicantId.dirty || applicantId.touched) && applicantId.errors"
				[errors]="applicantId.errors"
				[validationErrors]="validationErrors.applicantId"
			></simp-field-error>
		</div>
	</div>

	<div class="simp-row" *ngIf="addressConfig?.visible === 'Visible'">
		<simp-address-input
			class="col-12"
			[formControl]="address"
			[countries]="allowedCountries"
			[validationErrors]="validationErrors.propertyAddress"
			(addressChange)="updateAddress($event)"
		>
			{{ addressConfig.title }}
		</simp-address-input>
	</div>

	<div class="simp-row additional-info__different-property">
		<div
			class="simp-field-error simp-text-small simp-text--red col-12"
			*ngIf="maxAllowedOwnership === 0 && percent.disabled"
		>
			100% share of this property has already been allocated.
		</div>
	</div>

	<div class="simp-row" *ngIf="descriptionConfig?.visible === 'Visible'">
		<simp-input class="col-12" [formControl]="description" [validationErrors]="validationErrors.description">
			{{ descriptionConfig.title }}
		</simp-input>
	</div>

	<div class="simp-row" *ngIf="valueConfig?.visible === 'Visible'">
		<simp-amount-input class="col-12" [formControl]="value" [validationErrors]="validationErrors.value">
			{{ valueConfig.title }}
			<i
				class="fas fa-info-circle simp-text--grey70"
				[ngbPopover]="popContent2"
				[triggers]="popTrigger"
				container="body"
				popoverClass="purchasing-help-popover"
				(shown)="handlePopoverShown()"
				(hidden)="handlePopoverHidden()"
				(click)="$event.stopPropagation()"
				#popover2="ngbPopover"
				*ngIf="isPrimaryProperty"
			></i>
			<ng-template #popContent2>
				<div class="simp-text simp-text-small">
					The property value has been calculated from the information you provided us in Step 1.
				</div>
			</ng-template>
		</simp-amount-input>
	</div>

	<div class="simp-row" *ngIf="ownershipConfig?.visible === 'Visible'">
		<simp-percentage-input
			class="col-auto"
			[formControl]="percent"
			[validationErrors]="validationErrors.percent"
			[min]="1"
			[max]="maxAllowedOwnership"
		>
			What's your {{ applicantId.value === -1 ? "combined" : "" }} share of ownership?
		</simp-percentage-input>
	</div>

	<div class="simp-row simp-margin-top-large simp-margin-bottom-small">
		<div class="col-6">
			<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="handleBackClick()">
				{{ cancelButtonConfig.title }}
			</button>
		</div>
		<div class="col-6">
			<button type="submit" class="btn btn-primary btn-lg simp-button col-12" [disabled]="invalidData || isSubmitting">
				{{ continueButtonConfig.title }}
			</button>
		</div>
	</div>
</form>

<button
	type="button"
	class="btn btn-tertiary btn-lg simp-button col-12 simp-margin-vertical-medium"
	(click)="deleteRecord()"
	*ngIf="isEditMode && !isPrimaryProperty"
>
	Delete<i class="fas fa-trash-alt simp-margin-left-base"></i>
</button>
