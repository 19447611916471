<div class="privacy-terms simp-padding-small">
	<div class="privacy-terms__title-container">
		<i class="fas fa-user-shield fa-2x simp-margin-right-small simp-text--tertiary"></i>
		<h4 class="simp-h4 simp-text--primary privacy-terms__title">{{ header }}</h4>
	</div>

	<h5
		class="simp-h5 simp-text--grey70 simp-text--light"
		[innerHtml]="message1.title"
		*ngIf="message1.visible === 'Visible'"
	></h5>
	<h5 class="simp-h5 simp-text--grey70 simp-text--light" *ngIf="message2.visible === 'Visible'">
		{{ message2.title }}<a [href]="privacyPolicyUrl" target="_blank" class="simp-link">{{ privacyLink.title }}</a>.
	</h5>
	<h5
		class="simp-h5 simp-text--grey70 simp-text--light"
		[innerHtml]="message3.title"
		*ngIf="message3.visible === 'Visible'"
	></h5>

	<div class="col-6 privacy-terms__button">
		<button class="btn btn-primary btn-lg simp-button col-12" (click)="handleBackClick()">Back</button>
	</div>
</div>
