<h4 class="simp-h4 simp-text--primary">{{ sectionTitle }}</h4>
<form class="simp-padding-top-large" [formGroup]="otherIncomeForm" (ngSubmit)="onSubmit()" autocomplete="off">
	<div class="simp-row" *ngIf="whoseIncomeConfig?.visible === 'Visible' && options.applicants.length > 2">
		<div class="form-group col-12">
			<label class="simp-label" for="applicantSelect">{{ whoseIncomeConfig.title }}</label>
			<ng-select
				class="simp-select"
				labelForId="applicantSelect"
				placeholder="Select"
				[items]="options.applicants"
				[searchable]="false"
				[clearable]="false"
				[bindLabel]="'name'"
				[bindValue]="'id'"
				[formControl]="applicantId"
			>
			</ng-select>

			<simp-field-error
				*ngIf="(applicantId.dirty || applicantId.touched) && applicantId.errors"
				[errors]="applicantId.errors"
				[validationErrors]="validationErrors.applicantId"
			></simp-field-error>
		</div>
	</div>

	<div class="simp-row" *ngIf="otherIncomeTypeConfig?.visible === 'Visible'">
		<div class="form-group col-12">
			<label class="simp-label" for="incomeTypeSelect">{{ otherIncomeTypeConfig.title }}</label>
			<ng-select
				class="simp-select"
				labelForId="incomeTypeSelect"
				placeholder="Select"
				[items]="otherIncomeTypeOptions"
				[searchable]="false"
				[clearable]="false"
				[formControl]="type"
			>
			</ng-select>

			<simp-field-error
				*ngIf="(type.dirty || type.touched) && type.errors"
				[errors]="type.errors"
				[validationErrors]="validationErrors.type"
			></simp-field-error>
		</div>
	</div>

	<div class="simp-row" *ngIf="showOtherIncomeType">
		<simp-input class="col-12" [formControl]="description" [validationErrors]="validationErrors.description">
			Specify
		</simp-input>
	</div>

	<div class="simp-row" *ngIf="otherIncomeAmountConfig?.visible === 'Visible'">
		<simp-amount-select-input
			class="col-12"
			id="otherIncome"
			[formGroup]="otherIncome"
			[options]="otherIncomeFrequencyOptions"
			[validationErrors]="validationErrors.amount"
			[label]="otherIncomeAmountConfig.title"
		>
		</simp-amount-select-input>
	</div>

	<div class="simp-row simp-margin-top-large simp-margin-bottom-small">
		<div class="col-6">
			<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="handleBackClick()">
				{{ cancelButtonConfig.title }}
			</button>
		</div>
		<div class="col-6">
			<button
				type="submit"
				class="btn btn-primary btn-lg simp-button col-12"
				[disabled]="!otherIncomeForm.valid || isSubmitting"
			>
				{{ continueButtonConfig.title }}
			</button>
		</div>
	</div>
</form>

<button
	type="button"
	class="btn btn-tertiary btn-lg simp-button col-12 simp-margin-vertical-medium"
	(click)="deleteRecord()"
	*ngIf="isEditMode"
>
	Delete<i class="fas fa-trash-alt simp-margin-left-base"></i>
</button>
