<div class="serviceability">
	<div class="serviceability__result-container simp-box-shadow">
		<i class="fas fa-thumbs-up serviceability__thumbs-up"></i>
		<h2 class="simp-h2 serviceability__header">{{ headerText }}</h2>
		<h5 class="simp-h5 simp-text--bold">{{ subHeaderText }}</h5>
		<h5
			class="simp-h5 simp-text--extra-light simp-text--grey70 serviceability__result-explanation"
			[class.serviceability__result-explanation--fail]="!serviceabilityResult"
			[innerHTML]="messageText"
		></h5>
		<h5 class="simp-h5 simp-text--extra-light simp-text--grey70" *ngIf="serviceabilityResult">
			Loan amount: {{ loanDetails.loanAmount | currency: "USD":"symbol":"1.0-0" }}
			<br />
			<div *ngIf="loanDetails.deposit && loanDetails.deposit > 0">
				Deposit: {{ loanDetails.deposit | currency: "USD":"symbol":"1.0-0" }}
			</div>
			<div *ngIf="loanDetails.plan === rbaLendingPurpose.Refinance && loanDetails.estimatedValue">
				Property value: {{ loanDetails.estimatedValue | currency: "USD":"symbol":"1.0-0" }}
			</div>
			<div *ngIf="loanDetails.state">Location: {{ loanDetails.state | enumDesc: auState }}</div>
		</h5>
	</div>

	<div class="serviceability__next-step simp-box-shadow simp-margin-vertical-large simp-padding-small">
		<h5 class="simp-h5 simp-text--bold">{{ chatHeaderText }}</h5>
		<h6 class="simp-h6 serviceability__next-step-explanation">
			{{ chatMessageText }} Feel free to call us anytime on {{ contactPhone }}.
		</h6>
	</div>
	<div class="serviceability__reference-container">
		<h5 class="simp-h5 simp-text--bold">Your reference number</h5>
		<h5 class="simp-h3 simp-text--bold serviceability__reference-number">{{ referenceNumber }}</h5>
	</div>

	<div class="simp-row serviceability__buttons-container">
		<div class="col-6">
			<a class="btn btn-primary btn-lg simp-button col-12" href="mailto:{{ contactEmail }}"
				>{{ emailButtonConfig.title }}</a
			>
		</div>
		<div class="col-6">
			<a class="btn btn-primary btn-lg simp-button col-12" href="tel:{{ contactPhone }}">Call us</a>
		</div>
	</div>
</div>
