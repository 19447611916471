import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { AreaMetadata, OptionMetadata, SimpAddress } from '@simpology/client-components/utils';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { filter, last, map, switchMap, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { ApplicationService } from '../shared/api/application.service';
import { MetadataService } from '../shared/api/metadata.service';
import { AddressHelper } from '../shared/helper/address-helper';
import { Constant } from '../shared/helper/constant';
import { EnumHelper } from '../shared/helper/enum-helper';
import { ValidationHelper } from '../shared/helper/validation-helper';
import { Address } from '../shared/model/address.model';
import { ButtonMetadata } from '../shared/model/button-metadata.model';
import { EnumObject, MaritalStatus, NameTitle, StepStatus, StepType } from '../shared/model/enum.model';
import { FieldMetadata } from '../shared/model/field-metadata.model';
import { ApplicantService } from '../shared/service/applicant.service';
import { ApplicationStepService } from '../shared/service/application-step.service';
import { PersonalDetailService } from './api/personal-detail.service';
import { ApplicantResponse } from './model/applicant-response.model';
import { PersonalDetail } from './model/personal-detail.model';

@Component({
	selector: 'personal-details',
	templateUrl: './personal-details.component.html',
	styleUrls: ['./personal-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideInOut', [
			state(
				'show',
				style({
					transform: 'translate3d(0,0,0)'
				})
			),
			state(
				'hide',
				style({
					transform: 'translate3d(100%, 0, 0)'
				})
			),
			transition('show => hide', animate('400ms ease-in-out')),
			transition('hide => show', animate('400ms ease-in-out'))
		])
	]
})
export class PersonalDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('dob')
	public dob!: NgbDatepicker;
	public personalDetailsForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: { titles: EnumObject[]; maritalStatuses: EnumObject[]; partnerCoApplicant: EnumObject[] } = {
		titles: [],
		maritalStatuses: [],
		partnerCoApplicant: []
	};
	public isMultiApplicant = false;
	public enableAdditionalMaritalStatus = true;
	public allowedCountries = AddressHelper.allowedCountries;
	public isSubmitting = false;
	public minDateOfBirth: NgbDateStruct;
	public maxDateOfBirth: NgbDateStruct;
	public showPrivacyTerms = false;

	public privacyContent1 = '';
	public privacyContentLinkText = '';
	public privacyContent2 = '';
	public primaryApplicantSectionTitle = '';
	public additionalApplicantSectionTitle = '';
	public primaryApplicantTitleConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantTitleOptions: EnumObject[] = [];
	public primaryApplicantFirstNameConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantLastNameConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantEmailConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantMobileConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantMaritalStatusOptions: EnumObject[] = [];
	public primaryApplicantMaritalStatusConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantPartnerCoApplicantConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantDateOfBirthConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantMemberNumberConfig: FieldMetadata = {} as FieldMetadata;
	public primaryApplicantDateOfBirthRequiredConfig: FieldMetadata = {} as FieldMetadata;

	public additionalApplicantTitleConfig: FieldMetadata = {} as FieldMetadata;
	public additionalApplicantFirstNameConfig: FieldMetadata = {} as FieldMetadata;
	public additionalApplicantLastNameConfig: FieldMetadata = {} as FieldMetadata;
	public additionalApplicantEmailConfig: FieldMetadata = {} as FieldMetadata;
	public additionalApplicantMobileConfig: FieldMetadata = {} as FieldMetadata;
	public additionalApplicantMaritalStatusConfig: FieldMetadata = {} as FieldMetadata;
	public additionalApplicantMemberNumberConfig: FieldMetadata = {} as FieldMetadata;
	public additionalApplicantEmailRequiredConfig: FieldMetadata = {} as FieldMetadata;
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private areaMetadata: AreaMetadata = {} as AreaMetadata;
	private destroy$: Subject<void> = new Subject();
	private selectedAddress: SimpAddress;

	public constructor(
		private formBuilder: UntypedFormBuilder,
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private personalDetailService: PersonalDetailService,
		private applicationStepService: ApplicationStepService,
		private applicantService: ApplicantService,
		private changeDetectorRef: ChangeDetectorRef,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.isMultiApplicant =
			this.applicationService.getNumberOfApplicants() > 1 || this.applicationService.getStoredApplicants().length > 1;

		this.personalDetailsForm = this.formBuilder.group({
			primaryApplicantGroup: this.formBuilder.group({
				id: [Constant.newId],
				applicationId: [null, Validators.required],
				title: [null, Validators.required],
				firstName: [null, [Validators.required, ValidationHelper.personName]],
				lastName: [null, [Validators.required, ValidationHelper.personName]],
				email: [null, [Validators.required, ValidationHelper.email]],
				mobilePhoneNumber: [null, [Validators.required, ValidationHelper.mobileNumber]],
				maritalStatus: [null, Validators.required],
				dateOfBirth: [null],
				isPartnerCoApplicant: [null],
				primaryApplicant: [true],
				address: [null],
				privacyChecked: [null, Validators.requiredTrue],
				memberNumber: [
					null,
					[Validators.maxLength(this.primaryApplicantMemberNumberConfig.max ?? 20), ValidationHelper.numericOnly]
				]
			})
		});

		if (this.isMultiApplicant) {
			this.personalDetailsForm.addControl(
				'additionalApplicantGroup',
				this.formBuilder.group({
					id: [Constant.newId],
					applicationId: [null, Validators.required],
					title: [null, Validators.required],
					firstName: [null, [Validators.required, ValidationHelper.personName]],
					lastName: [null, [Validators.required, ValidationHelper.personName]],
					email: ['', [ValidationHelper.email]],
					mobilePhoneNumber: [null, [Validators.required, ValidationHelper.mobileNumber]],
					maritalStatus: [null, Validators.required],
					primaryApplicant: [false],
					memberNumber: [
						null,
						[Validators.maxLength(this.additionalApplicantMemberNumberConfig.max ?? 20), ValidationHelper.numericOnly]
					]
				})
			);
		}

		this.validationErrors = {
			title: {
				required: 'Select a title'
			},
			firstName: {
				required: 'We need your first name',
				invalidName: `That's not a valid name`
			},
			lastName: {
				required: 'We need your last name',
				invalidName: `That's not a valid name`
			},
			email: {
				required: 'We need an email address',
				email: `That's not a valid email`,
				sameAsPrimaryEmail: 'Email must be different to that of primary applicant',
				existingEmail:
					'Application already exists for this email. Exit and then log in to see your application, or use another email'
			},
			mobilePhoneNumber: {
				required: 'We need a mobile number',
				invalid: `That's not a valid mobile`
			},
			maritalStatus: {
				required: 'Select a status'
			},
			isPartnerCoApplicant: {
				required: 'Select an option'
			},
			address: {
				error: `That's not a valid address`
			},
			dateOfBirth: {
				required: 'We need your date of birth',
				ngbDate: `That's not a valid date`
			}
		};

		this.options = {
			titles: EnumHelper.getEnumArray(NameTitle as unknown as { [index: string]: number }),
			maritalStatuses: EnumHelper.getEnumArray(MaritalStatus as unknown as { [index: string]: number }),
			partnerCoApplicant: [
				{ id: 1, label: 'Yes' },
				{ id: 2, label: 'No' }
			]
		};

		this.selectedAddress = AddressHelper.getEmptyAddress();

		const current = new Date();
		// Min age allowed 18 years
		current.setFullYear(current.getFullYear() - 18);
		this.maxDateOfBirth = {
			year: current.getFullYear(),
			month: current.getMonth() + 1,
			day: current.getDate()
		};

		// Max age allowed 150 years. May be updated later
		current.setFullYear(current.getFullYear() - 132);
		this.minDateOfBirth = {
			year: current.getFullYear(),
			month: current.getMonth() + 1,
			day: current.getDate()
		};
	}

	public get primaryApplicantGroup() {
		return this.personalDetailsForm.controls.primaryApplicantGroup as UntypedFormGroup;
	}

	public get additionalApplicantGroup() {
		return this.personalDetailsForm.controls.additionalApplicantGroup as UntypedFormGroup;
	}

	public get primaryApplicantTitle() {
		return this.primaryApplicantGroup.controls.title as UntypedFormControl;
	}

	public get primaryApplicantFirstName() {
		return this.primaryApplicantGroup.controls.firstName as UntypedFormControl;
	}

	public get primaryApplicantLastName() {
		return this.primaryApplicantGroup.controls.lastName as UntypedFormControl;
	}

	public get primaryApplicantEmail() {
		return this.primaryApplicantGroup.controls.email as UntypedFormControl;
	}

	public get primaryApplicantMobilePhoneNumber() {
		return this.primaryApplicantGroup.controls.mobilePhoneNumber as UntypedFormControl;
	}

	public get primaryApplicantMaritalStatus() {
		return this.primaryApplicantGroup.controls.maritalStatus as UntypedFormControl;
	}

	public get primaryApplicantPartnerCoApplicant() {
		return this.primaryApplicantGroup.controls.isPartnerCoApplicant as UntypedFormControl;
	}

	public get primaryApplicantDateOfBirth() {
		return this.primaryApplicantGroup.controls.dateOfBirth as UntypedFormControl;
	}

	public get primaryApplicantAddress() {
		return this.primaryApplicantGroup.controls.address as UntypedFormControl;
	}

	public get primaryApplicantMemberNumber() {
		return this.primaryApplicantGroup.controls.memberNumber as UntypedFormControl;
	}

	public get primaryApplicantPrivacyChecked() {
		return this.primaryApplicantGroup.controls.privacyChecked as UntypedFormControl;
	}

	public get additionalApplicantTitle() {
		return this.additionalApplicantGroup.controls.title as UntypedFormControl;
	}

	public get additionalApplicantFirstName() {
		return this.additionalApplicantGroup.controls.firstName as UntypedFormControl;
	}

	public get additionalApplicantLastName() {
		return this.additionalApplicantGroup.controls.lastName as UntypedFormControl;
	}

	public get additionalApplicantEmail() {
		return this.additionalApplicantGroup.controls.email as UntypedFormControl;
	}

	public get additionalApplicantMaritalStatus() {
		return this.additionalApplicantGroup.controls.maritalStatus as UntypedFormControl;
	}

	public get additionalApplicantMobilePhoneNumber() {
		return this.additionalApplicantGroup.controls.mobilePhoneNumber as UntypedFormControl;
	}

	public get additionalApplicantMemberNumber() {
		return this.additionalApplicantGroup.controls.memberNumber as UntypedFormControl;
	}

	public get showPartnerCoApplicant(): boolean {
		return (
			this.isMultiApplicant &&
			[MaritalStatus.DeFacto, MaritalStatus.Married].includes(
				(this.primaryApplicantMaritalStatus?.value as EnumObject)?.id
			) &&
			this.primaryApplicantPartnerCoApplicantConfig?.visible === 'Visible'
		);
	}

	public ngOnInit(): void {
		this.navigationService.syncNavigation(StepType.PersonalDetails);
		this.fieldValidatorCheck();
		this.loadDetail();
		this.configureForChangingIsPartnerCoApplicant();
	}
	public fieldValidatorCheck(): void {
		if (this.isMultiApplicant) {
			if (this.additionalApplicantEmailRequiredConfig.title === 'true') {
				this.additionalApplicantEmail.setValidators(Validators.required);
			}
		}
		if (this.primaryApplicantDateOfBirthRequiredConfig.title === 'true') {
			this.primaryApplicantDateOfBirth.setValidators(Validators.required);
		}
	}
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public onSubmit(): void {
		if (this.personalDetailsForm.invalid) {
			return;
		}

		if (!this.primaryApplicantAddress.value) {
			this.selectedAddress = AddressHelper.getEmptyAddress();
		}

		this.isSubmitting = true;
		this.primaryApplicantAddress.disable();

		const dateOfBirth = new Date();
		const personalDateOfBirth = this.primaryApplicantDateOfBirth.value as NgbDateStruct;

		if (personalDateOfBirth) {
			dateOfBirth.setFullYear(personalDateOfBirth.year);
			dateOfBirth.setMonth(personalDateOfBirth.month);
			dateOfBirth.setDate(personalDateOfBirth.day);
		}

		const personalDetailsToSaveForPrimaryApplicant: PersonalDetail = {
			...(this.primaryApplicantGroup.value as PersonalDetail),
			title: (this.primaryApplicantTitle.value as EnumObject).id,
			maritalStatus: (this.primaryApplicantMaritalStatus.value as EnumObject).id,
			address: AddressHelper.isValidAddress(this.selectedAddress as Address)
				? (this.selectedAddress as Address)
				: undefined,
			isPartnerCoApplicant: this.primaryApplicantPartnerCoApplicant.value === 1,
			dateOfBirth: this.primaryApplicantDateOfBirth.value
				? // eslint-disable-next-line max-len
				  `${personalDateOfBirth.year}-${personalDateOfBirth.month}-${personalDateOfBirth.day}`
				: ''
		} as PersonalDetail;

		const savePrimaryApplicantPersonalDetails = this.personalDetailService.save(
			personalDetailsToSaveForPrimaryApplicant
		);

		if (this.isMultiApplicant) {
			const personalDetailsToSaveForAdditionalApplicant: PersonalDetail = {
				...(this.additionalApplicantGroup.value as PersonalDetail),
				title: (this.additionalApplicantTitle.value as EnumObject).id,
				maritalStatus: (this.additionalApplicantMaritalStatus.value as EnumObject).id,
				address: undefined
			} as PersonalDetail;

			const saveAdditionalApplicantPersonalDetails = this.personalDetailService.save(
				personalDetailsToSaveForAdditionalApplicant
			);

			const checkPrimary = this.personalDetailService.checkValidApplicant(personalDetailsToSaveForPrimaryApplicant);
			let checkSecondary: Observable<boolean>;

			if (!this.isCoApplicantEmailEmptyOrSameAsPrimaryApplicant()) {
				checkSecondary = this.personalDetailService.checkValidApplicant(personalDetailsToSaveForAdditionalApplicant);
			} else {
				checkSecondary = of(true);
			}

			forkJoin([checkPrimary, checkSecondary])
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					(results: boolean[]) => {
						if (!results[0] || !results[1]) {
							if (!results[0]) {
								this.handleExistingEmail('primary');
							}
							if (!results[1]) {
								this.handleExistingEmail('additional');
							}
							this.resetSubmittingStatus();
							return;
						}

						savePrimaryApplicantPersonalDetails
							.pipe(
								last(),
								switchMap((primaryDetails) =>
									saveAdditionalApplicantPersonalDetails.pipe(
										last(),
										map((additionalDetails) => [primaryDetails, additionalDetails])
									)
								)
							)
							.subscribe((data: ApplicantResponse[]) => {
								if (data[0].alreadyExists || data[1].alreadyExists) {
									if (data[0].alreadyExists) {
										this.handleExistingEmail('primary');
									}
									if (data[1].alreadyExists) {
										this.handleExistingEmail('additional');
									}
								} else {
									this.primaryApplicantGroup.controls.id.setValue(data[0].applicantId);
									this.additionalApplicantGroup.controls.id.setValue(data[1].applicantId);
									this.applicantService.setApplicantsLocally([
										this.primaryApplicantGroup.value,
										this.additionalApplicantGroup.value
									]);
									this.markStepAsComplete();
								}
							})
							.add(() => {
								this.resetSubmittingStatus();
							});
					},
					(error) => {
						this.resetSubmittingStatus();
					}
				);
		} else {
			savePrimaryApplicantPersonalDetails
				.pipe(takeUntil(this.destroy$))
				.subscribe((personalDetailIdResult: ApplicantResponse) => {
					if (personalDetailIdResult.alreadyExists) {
						this.handleExistingEmail('primary');
					} else {
						this.primaryApplicantGroup.controls.id.setValue(personalDetailIdResult.applicantId);
						this.applicantService.setApplicantsLocally([this.primaryApplicantGroup.value]);
						this.markStepAsComplete();
					}
				})
				.add(() => {
					this.resetSubmittingStatus();
				});
		}
	}

	public populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}

	public cancelApplication(): void {
		this.navigationService.goToHome();
	}

	public updateAddress(address: SimpAddress): void {
		if (!AddressHelper.isValidAddress(address as Address)) {
			this.primaryApplicantAddress.setErrors({ error: true });
		}
		this.selectedAddress = { ...AddressHelper.getEmptyAddress(), ...address };
	}

	public togglePrivacyTerms(): void {
		this.showPrivacyTerms = !this.showPrivacyTerms;
	}

	private resetSubmittingStatus(): void {
		this.isSubmitting = false;
		this.primaryApplicantAddress.enable();
		this.changeDetectorRef.markForCheck();
	}

	private loadDetail(): void {
		const existingApplicationId = this.applicationService.getStoredApplicationId();
		if (existingApplicationId) {
			this.primaryApplicantGroup.controls.applicationId.setValue(existingApplicationId);
			if (this.isMultiApplicant) {
				this.additionalApplicantGroup.controls.applicationId.setValue(existingApplicationId);
			}
			this.personalDetailService
				.getByApplication(existingApplicationId)
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: PersonalDetail[]) => {
					if (result.length > 0) {
						this.populateForm(result);
					}
				});
		} else {
			this.navigationService.goToHome();
		}
	}

	private populateForm(details: PersonalDetail[]): void {
		const primaryApplicant = details.find((personalDetails: PersonalDetail) => personalDetails.primaryApplicant);
		if (primaryApplicant) {
			const dateOfBirth = primaryApplicant.dateOfBirth ? new Date(primaryApplicant.dateOfBirth) : null;
			this.primaryApplicantGroup.patchValue({
				id: primaryApplicant.id,
				title: this.options.titles.find((title: EnumObject) => title.id === primaryApplicant.title),
				firstName: primaryApplicant.firstName,
				lastName: primaryApplicant.lastName,
				email: primaryApplicant.email,
				mobilePhoneNumber: primaryApplicant.mobilePhoneNumber,
				maritalStatus: this.options.maritalStatuses.find(
					(maritalStatus: EnumObject) => maritalStatus.id === primaryApplicant.maritalStatus
				),
				primaryApplicant: true,
				address: primaryApplicant.address ? AddressHelper.ExtractAddress(primaryApplicant.address) : null,
				isPartnerCoApplicant: primaryApplicant.isPartnerCoApplicant ? 1 : 2,
				dateOfBirth: dateOfBirth
					? {
							day: dateOfBirth.getDate(),
							month: dateOfBirth.getMonth() + 1,
							year: dateOfBirth.getFullYear()
					  }
					: null,
				privacyChecked: true,
				memberNumber: primaryApplicant.memberNumber
			});
			// Mark the button group as touched so that the css kicks in for the unselected buttons within each group.
			// Only required while loading previously saved values from api.
			this.primaryApplicantPartnerCoApplicant.markAsTouched();

			if (primaryApplicant.address) {
				this.selectedAddress = { ...primaryApplicant.address };
			}
			this.primaryApplicantEmail.disable();
			this.primaryApplicantPrivacyChecked.disable();
		}

		const additionalApplicant = details.find((personalDetails: PersonalDetail) => !personalDetails.primaryApplicant);
		if (this.isMultiApplicant && additionalApplicant) {
			this.additionalApplicantGroup.patchValue({
				id: additionalApplicant.id,
				title: this.options.titles.find((title: EnumObject) => title.id === additionalApplicant.title),
				firstName: additionalApplicant.firstName,
				lastName: additionalApplicant.lastName,
				email: additionalApplicant.email,
				mobilePhoneNumber: additionalApplicant.mobilePhoneNumber,
				maritalStatus: this.options.maritalStatuses.find(
					(maritalStatus: EnumObject) => maritalStatus.id === additionalApplicant.maritalStatus
				),
				primaryApplicant: false,
				memberNumber: additionalApplicant.memberNumber
			});
			this.additionalApplicantEmail.disable();
		}
	}

	private markStepAsComplete(): void {
		const applicationId = this.applicationService.getStoredApplicationId();

		this.applicationService.createSecureApplication(applicationId ?? 0).subscribe(() => {
			this.navigationService.goToNextStep(SimpProgressBarStatus.Complete);
			this.applicationStepService.updateStep(StepType.PersonalDetails, StepStatus.Complete);
		});
	}

	private configureForChangingIsPartnerCoApplicant(): void {
		this.primaryApplicantMaritalStatus.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.primaryApplicantPartnerCoApplicant.setValue(null);
			this.enableAdditionalMaritalStatus = true;
		});

		this.primaryApplicantPartnerCoApplicant.valueChanges
			.pipe(filter((value) => value !== null))
			.pipe(takeUntil(this.destroy$))
			.subscribe((isPartnerCoApplicant: number) => {
				switch (isPartnerCoApplicant) {
					case 1:
						this.enableAdditionalMaritalStatus = false;
						this.additionalApplicantMaritalStatus.setValue(this.primaryApplicantMaritalStatus.value);
						break;
					case 2:
						this.enableAdditionalMaritalStatus = true;
				}
			});
	}

	private handleExistingEmail(applicantType: 'primary' | 'additional'): void {
		this.personalDetailsForm
			.get(`${applicantType}ApplicantGroup.email`)
			?.setAsyncValidators(() => of({ existingEmail: true }));
		this.personalDetailsForm
			.get(`${applicantType}ApplicantGroup.email`)
			?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
		this.personalDetailsForm.get(`${applicantType}ApplicantGroup.email`)?.setAsyncValidators(null);
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.areaMetadata = this.metadataService.getAreaMetadataByName('ApplicantDetails');

			this.primaryApplicantSectionTitle = this.metadataService.getSectionMetadataByName(
				'ApplicantDetails',
				'PrimaryApplicant'
			)?.title;
			const primaryApplicantFields: FieldMetadata[] = this.metadataService.getSubSectionMetadataByName(
				'ApplicantDetails',
				'PrimaryApplicant',
				'PrimaryApplicant'
			)?.fields;
			this.primaryApplicantTitleConfig = this.metadataService.getFieldByName('NameTitle', primaryApplicantFields);
			this.primaryApplicantTitleOptions = this.populateOptions(this.primaryApplicantTitleConfig?.options);
			this.primaryApplicantFirstNameConfig = this.metadataService.getFieldByName('FirstName', primaryApplicantFields);
			this.primaryApplicantLastNameConfig = this.metadataService.getFieldByName('LastName', primaryApplicantFields);
			this.primaryApplicantEmailConfig = this.metadataService.getFieldByName('Email', primaryApplicantFields);
			this.primaryApplicantMobileConfig = this.metadataService.getFieldByName('Mobile', primaryApplicantFields);
			this.primaryApplicantMaritalStatusConfig = this.metadataService.getFieldByName(
				'MaritalStatus',
				primaryApplicantFields
			);
			this.primaryApplicantMaritalStatusOptions = this.populateOptions(
				this.primaryApplicantMaritalStatusConfig?.options
			);
			this.primaryApplicantPartnerCoApplicantConfig = this.metadataService.getFieldByName(
				'PartnerCoApplicant',
				primaryApplicantFields
			);
			this.primaryApplicantDateOfBirthConfig = this.metadataService.getFieldByName(
				'DateOfBirth',
				primaryApplicantFields
			);
			this.primaryApplicantDateOfBirthRequiredConfig = this.metadataService.getFieldByName(
				'IsDateOfBirthRequired',
				primaryApplicantFields
			);
			this.primaryApplicantMemberNumberConfig = this.metadataService.getFieldByName(
				'MemberNumber',
				primaryApplicantFields
			);

			this.additionalApplicantSectionTitle = this.metadataService.getSectionMetadataByName(
				'ApplicantDetails',
				'SecondaryApplicant'
			)?.title;
			const additionalApplicantFields: FieldMetadata[] = this.metadataService.getSubSectionMetadataByName(
				'ApplicantDetails',
				'SecondaryApplicant',
				'SecondaryApplicant'
			)?.fields;
			this.additionalApplicantTitleConfig = this.metadataService.getFieldByName('NameTitle', additionalApplicantFields);
			this.additionalApplicantFirstNameConfig = this.metadataService.getFieldByName(
				'FirstName',
				additionalApplicantFields
			);
			this.additionalApplicantLastNameConfig = this.metadataService.getFieldByName(
				'LastName',
				additionalApplicantFields
			);
			this.additionalApplicantEmailConfig = this.metadataService.getFieldByName('Email', additionalApplicantFields);
			this.additionalApplicantMobileConfig = this.metadataService.getFieldByName('Mobile', additionalApplicantFields);
			this.additionalApplicantMaritalStatusConfig = this.metadataService.getFieldByName(
				'MaritalStatus',
				additionalApplicantFields
			);
			this.additionalApplicantMemberNumberConfig = this.metadataService.getFieldByName(
				'MemberNumber',
				additionalApplicantFields
			);

			this.additionalApplicantEmailRequiredConfig = this.metadataService.getFieldByName(
				'IsEmailRequired',
				additionalApplicantFields
			);

			const buttons: ButtonMetadata[] = this.areaMetadata?.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			const applicantDetailsArea: AreaMetadata = this.metadataService.getAreaMetadataByName('ApplicantDetails');
			this.privacyContent1 = this.metadataService.getTextByName('PrivacyContent1', applicantDetailsArea.texts);
			this.privacyContentLinkText = this.metadataService.getTextByName(
				'PrivacyContentLinkText',
				applicantDetailsArea.texts
			);
			this.privacyContent2 = this.metadataService.getTextByName('PrivacyContent2', applicantDetailsArea.texts);

			this.changeDetectorRef.markForCheck();
		});
	}

	private isCoApplicantEmailEmptyOrSameAsPrimaryApplicant(): boolean {
		return (
			!this.additionalApplicantEmail.value || this.additionalApplicantEmail.value === this.primaryApplicantEmail.value
		);
	}
}
