<app-header></app-header>

<div class="app-progress-tracker">
	<div class="app-progress-tracker__inner">
		<simp-progress-tracker
			[steps]="navigationSteps"
			[currentStepId]="currentStep.stepId || 1"
			(stepClick)="goToStep($event)"
			*ngIf="showProgressTracker"
		></simp-progress-tracker>
	</div>
</div>

<section class="app-contents" [style.height]="appContentsHeight">
	<router-outlet></router-outlet>
</section>

<app-footer></app-footer>

<app-overlay></app-overlay>
