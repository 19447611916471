import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { AmountSelect } from 'src/app/shared/model/amount-select.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { EnumObject, ExpenseClassificationExpenseClass, FrequencyFull } from 'src/app/shared/model/enum.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { Expense } from '../model/expense.model';
import { ExpensesDetailsService } from '../service/expense-details.service';

@Component({
	selector: 'additional-expense-details',
	templateUrl: './additional-expense-details.component.html',
	styleUrls: ['./additional-expense-details.component.scss']
})
export class AdditionalExpenseDetailsComponent implements OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<Expense[]> = new EventEmitter<Expense[]>();

	public averageExpenseForm!: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: { expenseFrequencies: EnumObject[] };
	public sectionHeader = '';
	public sectionTitle = '';
	public calculateButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private destroy$: Subject<void> = new Subject();
	private expenseClassifications: EnumObject[] = [];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private expensesDetailsService: ExpensesDetailsService,
		private metadataService: MetadataService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
			'Expenses',
			'ExpenseDetails',
			'ExpenseDetails'
		);

		this.sectionHeader = this.metadataService.getTextByName('Header', sectionMetadata.texts);

		const defaultTitle = `Enter your average spend`;
		this.sectionTitle = sectionMetadata.title ?? defaultTitle;

		this.calculateButtonConfig = this.metadataService.getButtonByName('Calculate', sectionMetadata.buttons);

		const fields = sectionMetadata.fields;
		this.options = {
			expenseFrequencies: this.metadataService.getFieldByName('Frequency', fields).options as EnumObject[]
		};
		this.expenseClassifications = this.sortClassifications(
			this.metadataService.getFieldByName('Classification', fields).options
		);

		this.averageExpenseForm = this.formBuilder.group({
			details: this.formBuilder.array(this.buildDetail().map((classification) => this.addNewDetail(classification)))
		});
		this.changeDetectorRef.markForCheck();

		this.validationErrors = {
			expenseAmount: {
				required: 'We need an amount',
				max: 'Expense amount cannot be more than $10,000,000'
			}
		};

		this.expensesDetailsService.addEditExpenses$.pipe(takeUntil(this.destroy$)).subscribe((expenses: Expense[]) => {
			this.addEditExpenses(expenses);
		});
	}

	public get form(): any {
		return this.averageExpenseForm?.controls;
	}

	public get details(): UntypedFormArray {
		return this.averageExpenseForm?.get('details') as UntypedFormArray;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public calculateTotalSpend(): void {
		const expenses: Expense[] = [];
		const details = this.averageExpenseForm.controls.details.value as {
			id: number;
			expenseClass: ExpenseClassificationExpenseClass;
			expense: AmountSelect;
		}[];
		details.forEach((detail) => {
			if (detail.expense.amount) {
				expenses.push({
					id: detail.id,
					applicationId: Constant.newId,
					applicantId: Constant.newId,
					expenseClass: detail.expenseClass,
					amount: CurrencyHelper.unformatAmount(detail.expense.amount),
					frequency: detail.expense.frequency
				});
			}
		});

		this.update.emit(expenses);
	}

	private addEditExpenses(expenses: Expense[]): void {
		this.details.controls.map((control: AbstractControl) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const matchingExpense = expenses.find((expense: Expense) => expense.expenseClass === control.value.expenseClass);

			if (matchingExpense) {
				control.patchValue({
					id: matchingExpense.id,
					expenseClass: matchingExpense.expenseClass,
					expense: {
						amount: matchingExpense.amount,
						frequency: matchingExpense.frequency
					}
				});
			} else {
				control.patchValue({
					expense: {
						amount: 0,
						frequency: this.getDefaultFrequency()?.id
					}
				});
			}
		});
	}

	private buildDetail(): EnumObject[] {
		const availableDetails: EnumObject[] = [];
		this.expenseClassifications.forEach((classification: EnumObject) => {
			availableDetails.push(classification);
		});
		return availableDetails;
	}

	private addNewDetail(classification: EnumObject): UntypedFormGroup {
		return this.formBuilder.group({
			expenseClass: [classification.id],
			label: [classification.label],
			expense: this.formBuilder.group({
				amount: [0, [Validators.required, ValidationHelper.maximumAmount]],
				frequency: [this.getDefaultFrequency()?.id]
			})
		});
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.options.expenseFrequencies.find((frequency: EnumObject) => frequency.id === FrequencyFull.Monthly);
	}

	private sortClassifications(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);
		return options as EnumObject[];
	}
}
