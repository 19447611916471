import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Expense } from '../model/expense.model';

@Injectable({ providedIn: 'root' })
export class ExpensesDetailsService {
	public addEditExpenses$: Observable<Expense[]>;
	private addEditExpensesSource = new BehaviorSubject<Expense[]>([]);

	constructor() {
		this.addEditExpenses$ = this.addEditExpensesSource.asObservable();
	}

	public addEditExpenses(expenses?: Expense[]): void {
		this.addEditExpensesSource.next(expenses ?? []);
	}
}
