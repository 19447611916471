<div class="review-details__category-title simp-padding-top-base">
	Expenses
	<div class="review-details__income-summary">{{ totalExpense | currency: "USD":"symbol":"1.0-0" }} p.m.</div>
	<div class="review-details__empty-space" *ngIf="!isAppEditable && !isReviewEnabled"></div>
	<button
		type="button"
		class="btn btn-sm simp-button review-details__category-add"
		[class.review-details__category-add--no-chevron]="!isReviewEnabled"
		(click)="editSection(stepType.Expenses, shouldShowEdit)"
		*ngIf="isAppEditable || isReviewEnabled"
	>
		{{ shouldShowEdit ? "Edit" : "Review" }}<i class="fas fa-pencil simp-margin-left-base" *ngIf="shouldShowEdit"></i>
	</button>
	<i
		class="fas review-details__chevron simp-text--grey70 simp-margin-left-base"
		[class.fa-chevron-down]="!toggleExpenseDetails"
		[class.fa-chevron-up]="toggleExpenseDetails"
		(click)="toggleExpenseDetails = !toggleExpenseDetails"
		*ngIf="isReviewEnabled"
	></i>
</div>

<div class="review-details__summary simp-padding-small simp-margin-top-small" *ngIf="toggleExpenseDetails">
	<div class="review-details__applicants-container simp-margin-bottom-medium" *ngIf="showApplicantTabs">
		<div
			class="review-details__applicant"
			[class.review-details__applicant--selected]="isPrimarySelected"
			(click)="changeHousehold(true)"
		>
			<h5 class="simp-h5 simp-text--extra-light simp-text--primary">{{ primaryApplicant.name }}</h5>
		</div>
		<div
			class="review-details__applicant"
			[class.review-details__applicant--selected]="!isPrimarySelected"
			(click)="changeHousehold(false)"
		>
			<h5 class="simp-h5 simp-text--extra-light simp-text--primary">{{ additionalApplicant.name }}</h5>
		</div>
	</div>
	<form [formGroup]="expenseForm" *ngIf="showExpenseForm">
		<div formArrayName="details" *ngFor="let detail of form.details.controls; let a = index">
			<div [formGroupName]="a">
				<div class="simp-row review-details__expense-item">
					<simp-amount-select-input
						class="col-12"
						[formGroup]="detail.controls.expense"
						[options]="options.expenseFrequencies"
						[label]="detail.controls.label.value"
					>
					</simp-amount-select-input>
				</div>
			</div>
		</div>
	</form>

	<div class="simp-row" *ngIf="isReviewEnabled && !showExpenseForm">
		<div class="col-6" *ngIf="!primaryHouseholdHasDetails">
			<h6 class="simp-h6 simp-text--grey70" *ngIf="!additionalHouseholdHasDetails">{{ primaryApplicant.name }}</h6>
			<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
				{{ primaryHouseholdTotalExpense | currency: "USD":"symbol":"1.0-0" }} p.m.
			</h5>
		</div>

		<div class="col-6" *ngIf="!additionalHouseholdHasDetails">
			<h6 class="simp-h6 simp-text--grey70" *ngIf="!primaryHouseholdHasDetails">{{ additionalApplicant.name }}</h6>
			<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
				{{ additionalHouseholdTotalExpense | currency: "USD":"symbol":"1.0-0" }} p.m.
			</h5>
		</div>
	</div>
</div>
