import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { SectionMetadata } from 'src/app/shared/model/section-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { TextMetadata } from 'src/app/shared/model/text-metadata.model';
import { IncomeType } from '../../enums/income-type.enum';

@Component({
	selector: 'additional-income-details',
	templateUrl: './additional-income-details.component.html',
	styleUrls: ['./additional-income-details.component.scss']
})
export class AdditionalIncomeDetailsComponent implements OnChanges {
	@Input() public incomeType: IncomeType = IncomeType.Employment;
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public updateIncome: EventEmitter<any> = new EventEmitter<any>();
	@Output() public deleteIncome: EventEmitter<any> = new EventEmitter<any>();

	public sectionHeader = '';

	constructor(private metadataService: MetadataService) {}

	public ngOnChanges(): void {
		const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
			'Income',
			this.incomeType,
			this.incomeType
		);
		this.sectionHeader = this.metadataService.getTextByName('Header', sectionMetadata.texts);
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public updateIncomeDetails(incomeDetails: any): void {
		this.updateIncome.emit(incomeDetails);
	}

	public deleteIncomeDetails(incomeDetails: any): void {
		this.deleteIncome.emit(incomeDetails);
	}
}
