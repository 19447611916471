<div class="additional-info">
	<div class="additional-info__header">
		<div class="additional-info__back-button" (click)="handleBackClick()">
			<i class="far fa-arrow-left"></i>
		</div>
		<h6 class="simp-h6 additional-info__heading simp-text--grey70">{{ sectionHeader }}</h6>
	</div>

	<div class="simp-padding-small additional-info__scrollable-content" [ngSwitch]="incomeType">
		<ng-template [ngSwitchCase]="'Employment'">
			<employment-income-details
				(goBack)="handleBackClick()"
				(update)="updateIncomeDetails($event)"
				(delete)="deleteIncomeDetails($event)"
			></employment-income-details>
		</ng-template>

		<ng-template [ngSwitchCase]="'Rental'">
			<rental-income-details
				(goBack)="handleBackClick()"
				(update)="updateIncomeDetails($event)"
				(delete)="deleteIncomeDetails($event)"
			></rental-income-details>
		</ng-template>

		<ng-template [ngSwitchCase]="'Other'">
			<other-income-details
				(goBack)="handleBackClick()"
				(update)="updateIncomeDetails($event)"
				(delete)="deleteIncomeDetails($event)"
			></other-income-details>
		</ng-template>
	</div>
</div>
