import { JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ENV_CONFIG } from 'src/env-config';
import { Applicant } from '../model/applicant.model';
import { JourneyStatus, StepStatus, StepType } from '../model/enum.model';
import { Journey } from '../model/journey.model';
import { StepResponse } from '../model/step-response.model';

@Injectable({ providedIn: 'root' })
export class ApplicationService extends BaseApiService<any> {
	private journeyStatusKey = 'simp:journeyStatus';
	private applicationKey = 'simp:application';
	private primaryApplicantKey = 'simp:primaryApplicant';
	private additionalApplicantKey = 'simp:additionalApplicant';
	private multiHouseholdKey = 'simp:multiHousehold';
	private numberOfApplicantsKey = 'simp:numberOfApplicants';
	private brokerUserIdKey = 'simp:brokerUserId';

	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/Application');
	}

	public createApplication(): Observable<number> {
		const brokerUserId = sessionStorage.getItem(this.brokerUserIdKey);
		const model = { referrer: document.referrer, brokerUserId: brokerUserId ? +brokerUserId : undefined };
		return this.postCustom(``, model).pipe(
			tap((applicationId) => {
				this.setApplicationId(applicationId);
			})
		);
	}

	public createSecureApplication(applicationId: number): Observable<void> {
		return <Observable<void>>this.postCustom(`Secure/${applicationId}`, undefined);
	}

	public getMyJourney(): Observable<Journey> {
		return this.getCustom('GetForUser').pipe(
			tap((journey: Journey) => {
				this.setApplicationId(journey.applicationId);
			})
		);
	}

	public getCurrentJourney(applicationId: number): Observable<Journey> {
		return <Observable<Journey>>this.getCustom(`GetByApplication/${applicationId}`);
	}

	public setStepStatus(applicationId: number, type: StepType, status: StepStatus): Observable<void> {
		return <Observable<void>>this.patch(`SetStep/${applicationId}/${type}/${status}`, undefined);
	}

	public getStepStatus(applicationId: number): Observable<StepResponse[]> {
		return <Observable<StepResponse[]>>this.getCustom(`GetSteps/${applicationId}`);
	}

	public submitApplication(applicationId: number): Observable<number> {
		return <Observable<number>>this.postCustom(`Finalise/${applicationId}`, undefined);
	}

	public setApplicationId(applicationId: number): void {
		sessionStorage.setItem(this.applicationKey, applicationId.toString());
	}

	public setJourneyStatus(status: JourneyStatus): void {
		sessionStorage.setItem(this.journeyStatusKey, status.toString());
	}

	public setBrokerUserId(brokerUserIdKey: number): void {
		sessionStorage.setItem(this.brokerUserIdKey, brokerUserIdKey.toString());
	}

	public getStoredApplicationId(): number | undefined {
		const existingApplicationId = sessionStorage.getItem(this.applicationKey);
		return existingApplicationId ? +existingApplicationId : undefined;
	}

	public getStoredJourneyStatus(): JourneyStatus | undefined {
		const status = sessionStorage.getItem(this.journeyStatusKey);
		return status ? +status : undefined;
	}

	public setPrimaryApplicant(applicant: Applicant): void {
		sessionStorage.setItem(this.primaryApplicantKey, JSON.stringify(applicant));
	}

	public getStoredPrimaryApplicant(): Applicant | undefined {
		const existingApplicant = sessionStorage.getItem(this.primaryApplicantKey);
		return existingApplicant ? (JSON.parse(existingApplicant) as Applicant) : undefined;
	}

	public setAdditionalApplicant(applicant: Applicant): void {
		sessionStorage.setItem(this.additionalApplicantKey, JSON.stringify(applicant));
	}

	public getStoredAdditionalApplicant(): Applicant | undefined {
		const existingApplicant = sessionStorage.getItem(this.additionalApplicantKey);
		return existingApplicant ? (JSON.parse(existingApplicant) as Applicant) : undefined;
	}

	public removeAdditionalApplicant(): void {
		sessionStorage.removeItem(this.additionalApplicantKey);
	}

	public getStoredApplicants(): Applicant[] {
		return this.getStoredAdditionalApplicant()
			? ([...[this.getStoredPrimaryApplicant()], this.getStoredAdditionalApplicant()] as Applicant[])
			: ([this.getStoredPrimaryApplicant()] as Applicant[]);
	}

	public setMultiHouseholdOption(value: boolean): void {
		sessionStorage.setItem(this.multiHouseholdKey, value.toString());
	}

	public getStoredMultiHouseholdOption(): boolean {
		const value = sessionStorage.getItem(this.multiHouseholdKey);
		return value === 'true';
	}

	public setNumberOfApplicants(value: number): void {
		sessionStorage.setItem(this.numberOfApplicantsKey, value.toString());
	}

	public getNumberOfApplicants(): number {
		const value = sessionStorage.getItem(this.numberOfApplicantsKey);
		return value ? +value : 1;
	}
}
