<div class="expense-details">
	<form
		class="expense-details__form col-12"
		[formGroup]="expenseDetailsForm"
		(ngSubmit)="onSubmit()"
		autocomplete="off"
	>
		<div class="expense-details__scrollable-area">
			<div class="expense-details__section simp-row">
				<div class="expense-details__section-title col-12 col-md-6">
					<i class="fas fa-wallet fa-2x simp-margin-right-small simp-text--tertiary"></i>
					<h4 class="simp-h4 simp-text--primary expense-details__title--mobile">{{ areaMetadata.title }}</h4>
					<h1 class="simp-h1 simp-text--primary expense-details__title--desktop">{{ areaMetadata.title }}</h1>
				</div>

				<div formGroupName="primaryApplicantGroup" class="col-md-6">
					<div class="col-12">
						<div class="expense-details__category">
							<div class="expense-details__category-title simp-padding-vertical-base">
								Total expenses
								<button
									type="button"
									class="btn btn-sm simp-button expense-details__category-add"
									(click)="calculateExpenses(true)"
								>
									{{ helpCalculateButtonConfig.title }}<i class="fal fa-chevron-right simp-margin-left-base"></i>
								</button>
							</div>
						</div>

						<div class="simp-row expense-details__expense-row">
							<simp-amount-select-input
								class="col-12"
								id="primaryApplicantTotalExpense"
								[formGroup]="primaryApplicantTotalExpense"
								[options]="frequencyOptions"
								[validationErrors]="validationErrors.expenseAmount"
							>
							</simp-amount-select-input>
						</div>
					</div>
				</div>
			</div>

			<div class="expense-details__section simp-row">
				<div class="expense-details__section-title col-12 col-md-6" *ngIf="isMultiHousehold">
					<i class="fas fa-wallet fa-2x simp-margin-right-small simp-text--tertiary"></i>
					<h4 class="simp-h4 simp-text--primary expense-details__title--mobile">
						Tell us about {{ additionalApplicantName }}'s monthly spend
					</h4>
					<h1 class="simp-h1 simp-text--primary expense-details__title--desktop">
						Tell us about {{ additionalApplicantName }}'s monthly spend
					</h1>
				</div>

				<div
					formGroupName="additionalApplicantGroup"
					class="col-md-6 expense-details__additional-applicant"
					*ngIf="isMultiHousehold"
				>
					<div class="col-12">
						<div class="expense-details__category">
							<div class="expense-details__category-title simp-padding-vertical-base">
								Total expenses
								<button
									type="button"
									class="btn btn-sm simp-button expense-details__category-add"
									(click)="calculateExpenses()"
								>
									Help me calculate<i class="fal fa-chevron-right simp-margin-left-base"></i>
								</button>
							</div>
						</div>

						<div class="simp-row expense-details__expense-row">
							<simp-amount-select-input
								class="col-12"
								id="additionalApplicantTotalExpense"
								[formGroup]="additionalApplicantTotalExpense"
								[options]="frequencyOptions"
								[validationErrors]="validationErrors.expenseAmount"
							>
							</simp-amount-select-input>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="expense-details__buttons-container simp-row simp-margin-bottom-medium">
			<div class="col-6 col-md-3 expense-details__cancel-button">
				<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="finishLater()">
					{{ finishButtonConfig.title }}
				</button>
			</div>
			<div class="col-6 col-md-3">
				<button
					type="submit"
					class="btn btn-primary btn-lg simp-button col-12"
					[disabled]="!hasExpenses || isSubmitting"
				>
					{{ doneButtonConfig.title }}
				</button>
			</div>
		</div>
	</form>
</div>

<additional-expense-details
	[@slideInOut]="additionalInfoState"
	(goBack)="toggleAdditionalInfoPage()"
	(update)="updateExpenseAmount($event)"
></additional-expense-details>

<ng-template #overlayContent>
	<h4 class="simp-h4 overlay__message">We've saved where you're up to. Return and continue any time.</h4>
	<div class="simp-row overlay__buttons-container">
		<div class="col-6">
			<button class="btn btn-secondary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay()">
				Keep going
			</button>
		</div>
		<div class="col-6">
			<button class="btn btn-primary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay(true)">
				Exit
			</button>
		</div>
	</div>
</ng-template>
