import { Injectable } from '@angular/core';
import { AuthService } from '@simpology/authentication';
import { from, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnonymousUserAuthenticationService } from '../api/anonymous-user-authentication.service';
import { BrandService } from '../api/brand.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private destroy$: Subject<void> = new Subject();
	public brandName = '';

	constructor(
		private anonymousUserAuthenticationService: AnonymousUserAuthenticationService,
		private authService: AuthService,
		private brandService: BrandService
	) {
		this.brandService.currentBrandName$
			.pipe(takeUntil(this.destroy$))
			.subscribe((brandName: string) => (this.brandName = brandName));
	}

	public authenticateAnonymousUser(userId: string): Observable<boolean> {
		if (this.brandService.currentChannelKey) {
			return this.anonymousUserAuthenticationService.getAnonymousAuthToken(this.brandService.currentChannelKey, userId);
		}
		return of(false);
	}

	public logIn(): void {
		sessionStorage.clear();
		void this.authService.login({
			extraQueryParams: {
				brandLogo: this.brandService.currentBrandUrl,
				brandName: this.brandName
			}
		});
	}

	public logOut(): Observable<void> {
		return from(this.authService.signout());
	}
}
