<div class="loan-details">
	<div class="loan-details__section-title col-12 col-md-6">
		<i class="fas fa-file-alt fa-2x simp-margin-right-small simp-text--tertiary"></i>
		<h4 class="simp-h4 simp-text--primary loan-details__title--mobile">{{ areaMetadata.title }}</h4>
		<h1 class="simp-h1 simp-text--primary loan-details__title--desktop">{{ areaMetadata.title }}</h1>
	</div>

	<div class="loan-details__form-container col-12 col-md-6">
		<form class="loan-details__form col-12" [formGroup]="loanDetailsForm" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="loan-details__form-fields">
				<div class="simp-row" *ngIf="planConfig?.visible === 'Visible'">
					<simp-button-group
						id="plan"
						class="col-12"
						[formControl]="plan"
						[validationErrors]="validationErrors.plan"
						[options]="populateOptions(planConfig.options)"
					>
						{{ planConfig.title }}
					</simp-button-group>
				</div>

				<div class="simp-row" *ngIf="autoPopulateFields || fieldDisplayCount > 0">
					<simp-button-group
						id="primaryPurpose"
						class="col-12"
						[formControl]="primaryPurpose"
						[validationErrors]="validationErrors.primaryPurpose"
						[options]="populateOptions(propertyTypeConfig.options)"
						*ngIf="propertyTypeConfig?.visible === 'Visible'"
					>
						{{ propertyTypeConfig.title }}
					</simp-button-group>
				</div>

				<div class="simp-row" *ngIf="autoPopulateFields || fieldDisplayCount > 1">
					<simp-button-group
						id="applicantType"
						class="col-12"
						[formControl]="numberOfApplicants"
						[validationErrors]="validationErrors.numberOfApplicants"
						[options]="populateOptions(applicantNumberConfig.options)"
					>
						{{ applicantNumberConfig.title }}
					</simp-button-group>
				</div>

				<div class="simp-row" *ngIf="showMultipleHouseholds">
					<simp-button-group
						id="isMultiHousehold"
						class="col-12"
						[formControl]="isMultiHousehold"
						[validationErrors]="validationErrors.isMultiHousehold"
						[options]="options.multiHouseholdOptions"
					>
						{{ sameHouseholdConfig.title }}
					</simp-button-group>
				</div>

				<div class="loan-details__call-us-message simp-padding-horizontal-small" *ngIf="showCallUsMessage">
					<h5 class="simp-h5 simp-text--extra-light">
						Call us if there are more than two of you. To kick off the process, you can select "Just me" and enter the
						combined information for all of you.
					</h5>
				</div>

				<div class="simp-row" *ngIf="showRemainingFields">
					<div class="form-group col-6">
						<label class="simp-label" for="noOfDependants">{{ noDependantsConfig.title }}</label>
						<ng-select
							class="simp-select"
							labelForId="noOfDependants"
							placeholder="Select"
							[items]="options.noOfDependants"
							[searchable]="false"
							[clearable]="false"
							[formControl]="noOfDependants"
						>
						</ng-select>
					</div>
				</div>

				<div class="simp-row" *ngIf="showRemainingFields">
					<div class="col-6" *ngIf="showEstimatedvalue">
						<simp-amount-input
							id="estimatedValue"
							[formControl]="estimatedValue"
							[validationErrors]="validationErrors.estimatedValue"
						>
							{{ estimatedValueConfig.title }}
						</simp-amount-input>
					</div>
					<div class="col-6">
						<simp-amount-input
							id="loanAmount"
							[formControl]="loanAmount"
							[validationErrors]="validationErrors.loanAmount"
						>
							{{ loanAmountConfig.title }}
						</simp-amount-input>
					</div>

					<div class="col-6 loan-details__deposit" *ngIf="!showEstimatedvalue">
						<simp-amount-input id="depositAmount" [formControl]="deposit" [validationErrors]="validationErrors.deposit">
							{{ depositConfig.title }}
						</simp-amount-input>
						<i
							class="fas fa-question-circle simp-text--grey70 loan-details__deposit-help"
							[ngbPopover]="popContent"
							[triggers]="popTrigger"
							placement="top-right"
							(shown)="handlePopoverShown()"
							(hidden)="handlePopoverHidden()"
							#popover="ngbPopover"
						></i>
						<ng-template #popContent>
							<i class="fas fa-times-circle loan-details__popover-close" (click)="popover.close()"></i>
							<h6 class="simp-h6 simp-text--bold loan-details__popover-title">Deposit</h6>
							<div class="simp-text simp-text-small">
								How much do you have to contribute to a property purchase and costs (i.e. deposit, stamp duty,
								government fees, etc.)? Equity in an existing property can also be included as part of your
								contribution.
							</div>
						</ng-template>
					</div>
				</div>

				<div class="simp-row" *ngIf="showRemainingFields && showKnownAddress">
					<simp-button-group
						id="knownAddress"
						class="col-12"
						[formControl]="knownAddress"
						[validationErrors]="validationErrors.knownAddress"
						[options]="options.knownAddressOptions"
					>
						{{ addressKnownConfig.title }}
					</simp-button-group>
				</div>

				<div class="simp-row" *ngIf="showRemainingFields && showState" #stateSelection>
					<div class="form-group col-6">
						<label class="simp-label" for="stateSelect">{{ stateConfig.title }}</label>
						<ng-select
							class="simp-select"
							labelForId="stateSelect"
							placeholder="Select"
							[items]="stateOptions"
							[searchable]="false"
							[clearable]="false"
							[formControl]="state"
						>
						</ng-select>

						<simp-field-error
							*ngIf="(state.dirty || state.touched) && state.errors"
							[errors]="state.errors"
							[validationErrors]="validationErrors.state"
						></simp-field-error>
					</div>
				</div>

				<div class="simp-row" *ngIf="showRemainingFields && showAddress" #propertyAddress>
					<simp-address-input
						class="col-12"
						id="address"
						[formControl]="address"
						[countries]="allowedCountries"
						[validationErrors]="validationErrors.address"
						(addressChange)="updateAddress($event)"
					>
						{{ addressConfig.title }}
					</simp-address-input>
				</div>
			</div>

			<div class="loan-details__buttons-container simp-row simp-margin-bottom-medium">
				<div class="col-6" *ngIf="!showCallUsMessage">
					<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="cancelApplication()">
						{{ cancelButtonConfig.title }}
					</button>
				</div>
				<div class="col-6" *ngIf="!showCallUsMessage">
					<button
						type="submit"
						class="btn btn-primary btn-lg simp-button col-12"
						[disabled]="!loanDetailsForm.valid || isSubmitting"
					>
						{{ continueButtonConfig.title }}
					</button>
				</div>
				<div class="loan-details__call-us-button" *ngIf="showCallUsMessage">
					<a href="tel:{{ contactPhone }}" class="btn btn-primary btn-lg simp-button col-12">
						Call {{ contactPhone }}
					</a>
				</div>
			</div>
		</form>
	</div>
</div>

<ng-template #overlayContent>
	<h4 class="simp-h4 overlay__message">
		The number of applicants has been modified. All records related to your co-applicant will be deleted.
	</h4>
	<div class="simp-row overlay__buttons-container">
		<div class="col-6">
			<button class="btn btn-secondary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay()">
				Cancel
			</button>
		</div>
		<div class="col-6">
			<button class="btn btn-primary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay(true)">
				Delete
			</button>
		</div>
	</div>
</ng-template>
