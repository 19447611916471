import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@simpology/authentication';
import { SimpProgressTrackerStep, SimpProgressBarStatus } from '@simpology/client-components';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from '../shared/api/application.service';
import { StepStatus, StepType } from '../shared/model/enum.model';
import { StepResponse } from '../shared/model/step-response.model';
import { MetadataService } from '../shared/api/metadata.service';
import { AreaMetadata } from '../shared/model/area-metadata.model';

@Injectable({
	providedIn: 'root'
})
export class NavigationService implements OnDestroy {
	public navigationSteps$: Observable<SimpProgressTrackerStep[]>;
	public currentStep$: Observable<SimpProgressTrackerStep>;
	public showProgressTracker$: Observable<boolean>;
	public loanDetails = '';
	private navStepsValue = NAVIGATION_STEPS;
	private currentStepValue: SimpProgressTrackerStep = this.navStepsValue[0];
	private showProgressTrackerValue = false;
	private destroy$: Subject<void> = new Subject();

	private navigationSteps: BehaviorSubject<SimpProgressTrackerStep[]> = new BehaviorSubject<SimpProgressTrackerStep[]>(
		this.navStepsValue
	);
	private currentStep: BehaviorSubject<SimpProgressTrackerStep> = new BehaviorSubject<SimpProgressTrackerStep>(
		this.currentStepValue
	);
	private showProgressTracker: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.showProgressTrackerValue);

	constructor(
		private metadataService: MetadataService,
		private router: Router,
		private applicationService: ApplicationService,
		private authService: AuthService
	) {
		this.navigationSteps$ = this.navigationSteps.asObservable();
		this.currentStep$ = this.currentStep.asObservable();
		this.showProgressTracker$ = this.showProgressTracker.asObservable();
		this.setMetadata();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public goToNextStep(stepStatus?: SimpProgressBarStatus): void {
		if (stepStatus) {
			this.updateStepStatus(this.currentStepValue.stepId, stepStatus);
		}
		this.goToStep(this.currentStepValue.stepId + 1);
	}

	public goToPreviousStep(stepStatus?: SimpProgressBarStatus): void {
		if (stepStatus) {
			this.updateStepStatus(this.currentStepValue.stepId, stepStatus);
		}
		this.goToStep(this.currentStepValue.stepId - 1);
	}

	public goToStep(stepId: number): void {
		if (stepId < 1 || stepId > this.navStepsValue.length) {
			return;
		}

		if (stepId > 1 && !this.hasMinimumStepsCompleted(stepId)) {
			return;
		}

		this.currentStepValue = this.navStepsValue[stepId - 1];
		this.currentStep.next(this.currentStepValue);

		void this.router.navigateByUrl(this.navStepsValue[stepId - 1].stepUrl);

		if (!this.showProgressTrackerValue) {
			this.toggleProgressTracker(true);
		}
	}

	public updateStepStatus(stepId: number, stepStatus: SimpProgressBarStatus): void {
		this.navStepsValue[stepId - 1].stepStatus = stepStatus;
		this.navigationSteps.next(this.navStepsValue);
	}

	public goToHome(): void {
		const isAnonymousUser = this.authService.anonymousUserId;
		if (isAnonymousUser) {
			void this.router.navigateByUrl('/home');
		} else {
			if (this.router.url.indexOf('dashboard') > -1) {
				this.exitApp();
			} else {
				if (this.authService.isAuthenticated()) {
					void this.router.navigateByUrl('/dashboard');
				} else {
					void this.router.navigateByUrl('/home');
				}
			}
		}
	}

	public exitApp(): void {
		this.resetAllSteps();
		let isValidUser = true;
		try {
			isValidUser = this.authService.id > 0;
		} catch (err) {
			isValidUser = false;
		}

		if (this.authService.isAuthenticated() && isValidUser) {
			from(this.authService.signout())
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					sessionStorage.clear();
				});
		} else {
			sessionStorage.clear();
			void this.router.navigateByUrl('/home');
		}
	}

	public syncNavigation(stepType: StepType): void {
		if (stepType !== this.currentStepValue.stepId) {
			this.currentStepValue = this.navStepsValue[stepType - 1];
			this.currentStep.next(this.currentStepValue);
		}
	}

	public syncStepsFromServer(): void {
		const existingApplicationId = this.applicationService.getStoredApplicationId();
		if (existingApplicationId) {
			this.applicationService
				.getStepStatus(existingApplicationId)
				.pipe(takeUntil(this.destroy$))
				.subscribe((stepResponses: StepResponse[]) => {
					this.updateStepResponses(stepResponses);
				});
		}
	}

	public updateStepResponses(stepResponses: StepResponse[]): void {
		this.navStepsValue.map((step: SimpProgressTrackerStep) => {
			const matchingStep = stepResponses.find((stepFromService: StepResponse) => stepFromService.type === step.stepId);
			if (matchingStep) {
				step.stepStatus =
					matchingStep.status === StepStatus.Complete
						? SimpProgressBarStatus.Complete
						: SimpProgressBarStatus.NotStarted;
			}
		});
	}

	public reviewWorkflowCompletion(): void {
		const incompleteStep = this.navStepsValue.find(
			(step: SimpProgressTrackerStep) => step.stepStatus !== SimpProgressBarStatus.Complete
		);

		if (incompleteStep) {
			this.goToStep(incompleteStep.stepId);
		} else {
			this.goToReview();
		}
	}

	public toggleProgressTracker(show = false): void {
		this.showProgressTrackerValue = show;
		this.showProgressTracker.next(show);
	}

	public goToResult(): void {
		void this.router.navigateByUrl('/result');
	}

	public goToReview(): void {
		void this.router.navigateByUrl('/review');
	}

	public hasAnyIncompleteStep(): boolean {
		return this.navStepsValue.some(
			(step: SimpProgressTrackerStep) => step.stepStatus !== SimpProgressBarStatus.Complete
		);
	}

	public resetStepStatuses(): void {
		this.navStepsValue.map((step: SimpProgressTrackerStep) => {
			if (step.stepId !== 1) {
				step.stepStatus = SimpProgressBarStatus.NotStarted;
			}
		});
	}

	private resetAllSteps(): void {
		this.navStepsValue.forEach((step: SimpProgressTrackerStep) => {
			step.stepStatus = SimpProgressBarStatus.NotStarted;
		});
	}

	private hasMinimumStepsCompleted(selectedStep: number): boolean {
		if (selectedStep === 2) {
			return this.navStepsValue[0].stepStatus === SimpProgressBarStatus.Complete;
		} else {
			return (
				this.navStepsValue[0].stepStatus === SimpProgressBarStatus.Complete &&
				this.navStepsValue[1].stepStatus === SimpProgressBarStatus.Complete
			);
		}
	}
	private setMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const homeArea: AreaMetadata = this.metadataService.getAreaMetadataByName('Home');
			this.loanDetails = this.metadataService.getTextByName('LoanDetails', homeArea.texts);
			if (this.loanDetails) {
				this.navStepsValue[0].stepName = this.loanDetails;
			}
		});
	}
}

export const NAVIGATION_STEPS = [
	{
		stepId: 1,
		stepStatus: SimpProgressBarStatus.NotStarted,
		stepUrl: 'loan-details',
		stepName: 'Loan details'
	},
	{
		stepId: 2,
		stepStatus: SimpProgressBarStatus.NotStarted,
		stepUrl: 'personal-details',
		stepName: 'Personal details'
	},
	{
		stepId: 3,
		stepStatus: SimpProgressBarStatus.NotStarted,
		stepUrl: 'income',
		stepName: 'Income'
	},
	{
		stepId: 4,
		stepStatus: SimpProgressBarStatus.NotStarted,
		stepUrl: 'expenses',
		stepName: 'Expenses'
	},
	{
		stepId: 5,
		stepStatus: SimpProgressBarStatus.NotStarted,
		stepUrl: 'asset-details',
		stepName: 'Assets'
	},
	{
		stepId: 6,
		stepStatus: SimpProgressBarStatus.NotStarted,
		stepUrl: 'liabilities',
		stepName: 'Liabilities'
	}
];
