<h4 class="simp-h4 simp-text--primary">{{ sectionTitle }}</h4>
<form
	class="additional-info__form simp-padding-top-large"
	[formGroup]="creditCardDetailsForm"
	(ngSubmit)="onSubmit()"
	autocomplete="off"
>
	<div class="simp-row" *ngIf="whoseCardConfig?.visible === 'Visible' && options.applicants.length > 2">
		<div class="form-group col-12">
			<label class="simp-label" for="applicantSelect">{{ whoseCardConfig.title }}</label>
			<ng-select
				class="simp-select"
				labelForId="applicantSelect"
				placeholder="Select"
				[items]="options.applicants"
				[searchable]="false"
				[clearable]="false"
				[bindLabel]="'name'"
				[bindValue]="'id'"
				[formControl]="applicantId"
			>
			</ng-select>

			<simp-field-error
				*ngIf="(applicantId.dirty || applicantId.touched) && applicantId.errors"
				[errors]="applicantId.errors"
				[validationErrors]="validationErrors.applicantId"
			></simp-field-error>
		</div>
	</div>

	<div class="simp-row" *ngIf="financialInstitutionConfig?.visible === 'Visible'">
		<div class="form-group col-12">
			<label class="simp-label" for="issuer">{{ financialInstitutionConfig.title }}</label>
			<ng-select
				class="simp-select"
				labelForId="issuer"
				placeholder="Select"
				[items]="populateOptions(financialInstitutionConfig.options)"
				[searchable]="true"
				[clearable]="false"
				[formControl]="issuer"
			></ng-select>

			<simp-field-error
				*ngIf="(issuer.dirty || issuer.touched) && issuer.errors"
				[errors]="issuer.errors"
				[validationErrors]="validationErrors.issuer"
			></simp-field-error>
		</div>
	</div>

	<div class="simp-row" *ngIf="limitConfig?.visible === 'Visible'">
		<simp-amount-input class="col-12" [formControl]="creditLimit" [validationErrors]="validationErrors.creditLimit">
			{{ limitConfig.title }}
		</simp-amount-input>
	</div>

	<div class="simp-row" *ngIf="sharedConfig?.visible === 'Visible'">
		<simp-button-group
			id="isShared"
			class="col-12"
			[formControl]="isShared"
			[validationErrors]="validationErrors.isShared"
			[options]="options.sharedCreditCardOptions"
		>
			{{ sharedConfig.title }}
		</simp-button-group>
	</div>

	<div class="simp-row simp-margin-top-large simp-margin-bottom-small">
		<div class="col-6">
			<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="handleBackClick()">
				{{ cancelButtonConfig.title }}
			</button>
		</div>
		<div class="col-6">
			<button
				type="submit"
				class="btn btn-primary btn-lg simp-button col-12"
				[disabled]="!creditCardDetailsForm.valid || isSubmitting"
			>
				{{ continueButtonConfig.title }}
			</button>
		</div>
	</div>
</form>

<button
	type="button"
	class="btn btn-tertiary btn-lg simp-button additional-info__delete-button col-12 simp-margin-vertical-medium"
	(click)="deleteRecord()"
	*ngIf="isEditMode"
>
	Delete<i class="fas fa-trash-alt simp-margin-left-base"></i>
</button>
