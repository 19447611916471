import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssetDetailsComponent } from './asset-details/components/asset-details/asset-details.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExpenseDetailsComponent } from './expense-details/expense-details.component';
import { ExpiredComponent } from './expired/expired.component';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { IncomeDetailsComponent } from './income-details/components/income-details/income-details.component';
import { LiabilityDetailsComponent } from './liability-details/components/liability-details/liability-details.component';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { RegisterComponent } from './register/register.component';
import { ReviewDetailsComponent } from './review-details/components/review-details/review-details.component';
import { ServiceabilityResultComponent } from './serviceability-result/serviceability-result.component';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', component: HomeComponent },
	{ path: 'home/:brokerUserId', component: HomeComponent },
	{ path: 'home/:brokerUserId/:anonymousUserId/:applicationId', component: HomeComponent },
	{ path: 'auth-callback', component: AuthCallbackComponent },
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'loan-details', component: LoanDetailsComponent, canActivate: [AuthGuard] },
	{ path: 'personal-details', component: PersonalDetailsComponent, canActivate: [AuthGuard] },
	{ path: 'income', component: IncomeDetailsComponent, canActivate: [AuthGuard] },
	{ path: 'expenses', component: ExpenseDetailsComponent, canActivate: [AuthGuard] },
	{ path: 'asset-details', component: AssetDetailsComponent, canActivate: [AuthGuard] },
	{ path: 'liabilities', component: LiabilityDetailsComponent, canActivate: [AuthGuard] },
	{ path: 'review', component: ReviewDetailsComponent, canActivate: [AuthGuard] },
	{ path: 'result', component: ServiceabilityResultComponent, canActivate: [AuthGuard] },
	{ path: 'access/expired', component: ExpiredComponent },
	{ path: 'register', component: RegisterComponent },
	{ path: '**', redirectTo: 'home' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
