import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OverlayService } from './overlay.service';

@Component({
	selector: 'app-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnDestroy {
	public showOverlay = false;
	public showContent = false;
	public overlayContentRef!: TemplateRef<HTMLElement>;
	private destroy$: Subject<void> = new Subject();

	constructor(private overlayService: OverlayService) {}

	ngOnInit(): void {
		this.overlayService
			.getOverlayObserver()
			.pipe(takeUntil(this.destroy$))
			.subscribe((status: string) => {
				this.showOverlay = status === 'start';
			});

		this.overlayService
			.getOverlayContentObserver()
			.pipe(takeUntil(this.destroy$))
			.subscribe((templateRef: TemplateRef<HTMLElement> | null) => {
				if (templateRef) {
					this.overlayContentRef = templateRef;
					this.showContent = true;
				} else {
					this.showContent = false;
				}
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}
