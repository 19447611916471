import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { Expense } from '../model/expense.model';

@Injectable({ providedIn: 'root' })
export class ExpenseService extends BaseApiService<Expense> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/Expense');
	}

	public getByApplication(applicationId: number): Observable<Expense[]> {
		return <Observable<Expense[]>>this.getCustom(`${applicationId}`);
	}

	public saveHousehold(expenses: Expense[]): Observable<void> {
		return <Observable<void>>this.postCustom('', expenses);
	}

	public saveHouseholdTotal(expense: Expense): Observable<number> {
		return <Observable<number>>this.postCustom('Total', expense);
	}
}
