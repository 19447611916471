<div class="home" [style.height]="homeScreenHeight">
	<header class="home__header simp-padding-left-small"></header>

	<div class="home__contents-wrapper">
		<div class="home__contents simp-padding-small">
			<img
				class="home__brand-logo"
				src="{{ './../assets/brands/' + brandName + '/images/brand-logo.png' }}"
				alt="close"
			/>
			<h2 class="simp-h2 home__heading">
				Your session has timed out, please click the Exit button below to start over.
			</h2>
			<button type="button" class="btn btn-primary btn-lg simp-button home__log-on" (click)="exitApp()">Exit</button>
		</div>
	</div>
</div>
