/* eslint-disable @typescript-eslint/member-ordering */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SimpProgressTrackerStep } from '@simpology/client-components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from './navigation/navigation.service';
import { BrandService } from './shared/api/brand.service';
import { MetadataService } from './shared/api/metadata.service';
import { Constant } from './shared/helper/constant';
import { Metadata } from './shared/model/metadata.model';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.calculateContentsHeight();
	}

	public navigationSteps: SimpProgressTrackerStep[] = [];
	public currentStep: SimpProgressTrackerStep = {} as SimpProgressTrackerStep;
	public showProgressTracker = false;
	public appContentsHeight = '0px';

	private destroy$: Subject<void> = new Subject();

	public constructor(
		private navigationService: NavigationService,
		private brandService: BrandService,
		private metadataService: MetadataService,
		private title: Title
	) {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe((metadata: Metadata) => {
			this.title.setTitle(metadata.title);
		});
	}

	public ngOnInit(): void {
		this.brandService.setBrand();
		this.metadataService.getMetadata();

		this.navigationService.navigationSteps$
			.pipe(takeUntil(this.destroy$))
			.subscribe((steps: SimpProgressTrackerStep[]) => (this.navigationSteps = steps));

		this.navigationService.currentStep$
			.pipe(takeUntil(this.destroy$))
			.subscribe((step: SimpProgressTrackerStep) => (this.currentStep = step));

		this.navigationService.showProgressTracker$
			.pipe(takeUntil(this.destroy$))
			.subscribe((showProgressTracker: boolean) => {
				this.showProgressTracker = showProgressTracker;
				this.calculateContentsHeight();
			});

		this.calculateContentsHeight();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public goToStep(stepId: number): void {
		this.navigationService.goToStep(stepId);
	}

	private calculateContentsHeight(): void {
		this.appContentsHeight = `${
			window.innerHeight -
			Constant.appHeaderHeight -
			Constant.appFooterHeight -
			(this.showProgressTracker ? Constant.progressTrackerHeight : 0)
		}px`;
	}
}
