import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { SectionMetadata } from 'src/app/shared/model/section-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { TextMetadata } from 'src/app/shared/model/text-metadata.model';
import { AssetType } from '../../enums/asset-type.enum';

@Component({
	selector: 'additional-asset-details',
	templateUrl: './additional-asset-details.component.html',
	styleUrls: ['./additional-asset-details.component.scss']
})
export class AdditionalAssetDetailsComponent implements OnChanges {
	@Input() public assetType: AssetType = AssetType.Property;
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public updateAsset: EventEmitter<any> = new EventEmitter<any>();
	@Output() public deleteAsset: EventEmitter<any> = new EventEmitter<any>();

	public sectionHeader = '';

	constructor(private metadataService: MetadataService) {}

	public ngOnChanges(): void {
		const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
			'Assets',
			this.assetType,
			this.assetType
		);
		this.sectionHeader = this.metadataService.getTextByName('Header', sectionMetadata.texts);
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public updateAssetDetails(assetDetails: any): void {
		this.updateAsset.emit(assetDetails);
	}

	public deleteAssetDetails(assetDetails: any): void {
		this.deleteAsset.emit(assetDetails);
	}
}
