import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { OptionMetadata, SimpAddress } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { OverlayService } from '../overlay/overlay.service';
import { PersonalDetailService } from '../personal-details/api/personal-detail.service';
import { PersonalDetail } from '../personal-details/model/personal-detail.model';
import { ApplicationService } from '../shared/api/application.service';
import { BrandService } from '../shared/api/brand.service';
import { MetadataService } from '../shared/api/metadata.service';
import { AddressHelper } from '../shared/helper/address-helper';
import { Constant } from '../shared/helper/constant';
import { CurrencyHelper } from '../shared/helper/currency-helper';
import { EnumHelper } from '../shared/helper/enum-helper';
import { ValidationHelper } from '../shared/helper/validation-helper';
import { Address } from '../shared/model/address.model';
import { Applicant } from '../shared/model/applicant.model';
import { AreaMetadata } from '../shared/model/area-metadata.model';
import { BrandConfig } from '../shared/model/brand.config';
import { ButtonMetadata } from '../shared/model/button-metadata.model';
import {
	AuState,
	EnumObject,
	PrimaryPurposeLoanPurpose,
	RbaLendingPurpose,
	StepStatus,
	StepType
} from '../shared/model/enum.model';
import { FieldMetadata } from '../shared/model/field-metadata.model';
import { SectionMetadata } from '../shared/model/section-metadata.model';
import { SubSectionMetadata } from '../shared/model/sub-section-metadata.model';
import { TextMetadata } from '../shared/model/text-metadata.model';
import { ApplicationStepService } from '../shared/service/application-step.service';
import { LoanDetailService } from './api/loan-detail.service';
import { LoanDetail } from './model/loan-detail.model';

@Component({
	selector: 'loan-details',
	templateUrl: './loan-details.component.html',
	styleUrls: ['./loan-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('overlayContent', { static: false })
	public overlayContent!: TemplateRef<HTMLElement>;
	@ViewChild('stateSelection', { static: false })
	public stateSelection!: ElementRef;
	@ViewChild('propertyAddress', { static: false }) public propertyAddress!: ElementRef;

	public contactPhone = '';
	public loanDetailsForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: {
		plan: EnumObject[];
		primaryPurpose: EnumObject[];
		noOfDependants: EnumObject[];
		states: EnumObject[];
		multiHouseholdOptions: EnumObject[];
		applicants: EnumObject[];
		knownAddressOptions: EnumObject[];
	} = {
		plan: [],
		primaryPurpose: [],
		noOfDependants: [],
		states: [],
		multiHouseholdOptions: [],
		applicants: [],
		knownAddressOptions: []
	};
	public autoPopulateFields = false;
	public fieldDisplayCount = 0;
	public showCallUsMessage = false;
	public enableMultipleHouseholds = false;
	public isSubmitting = false;
	public allowedCountries = AddressHelper.allowedCountries;
	public popTrigger: string;

	public areaMetadata: AreaMetadata = {} as AreaMetadata;
	public planConfig: FieldMetadata = {} as FieldMetadata;
	public propertyTypeConfig: FieldMetadata = {} as FieldMetadata;
	public applicantNumberConfig: FieldMetadata = {} as FieldMetadata;
	public sameHouseholdConfig: FieldMetadata = {} as FieldMetadata;
	public noDependantsConfig: FieldMetadata = {} as FieldMetadata;
	public loanAmountConfig: FieldMetadata = {} as FieldMetadata;
	public depositConfig: FieldMetadata = {} as FieldMetadata;
	public estimatedValueConfig: FieldMetadata = {} as FieldMetadata;
	public addressKnownConfig: FieldMetadata = {} as FieldMetadata;
	public addressConfig: FieldMetadata = {} as FieldMetadata;
	public stateConfig: FieldMetadata = {} as FieldMetadata;
	public stateOptions: EnumObject[] = [];
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	private maxDepositPercentCheck: TextMetadata = {} as TextMetadata;
	private minDepositPercentCheck: TextMetadata = {} as TextMetadata;
	private maxDepositPercentage = 0.95;
	private minDepositPercentage = 0.05;
	private destroy$: Subject<void> = new Subject();
	private additionalAplicant: PersonalDetail = {} as PersonalDetail;

	private minDepositValidator = (control: UntypedFormControl): ValidatorFn =>
		this.checkMinimumDeposit(
			control.value,
			this.loanDetailsForm?.controls['loanAmount'].value,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose
		) as ValidatorFn;

	private maxDepositValidator = (control: UntypedFormControl): ValidatorFn => {
		return this.checkMaximumDeposit(
			control.value as string,
			this.loanDetailsForm?.controls['loanAmount'].value as string,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose
		) as ValidatorFn;
	};

	private stateValidator = (control: UntypedFormControl): ValidatorFn =>
		this.checkIfStateIsRequired(
			control.value as string,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose,
			this.loanDetailsForm?.controls['knownAddress'].value as number
		) as ValidatorFn;

	private knownAddressValidator = (control: UntypedFormControl): ValidatorFn =>
		this.checkIfKnownAddressIsRequired(
			control.value as number,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose
		) as ValidatorFn;

	private addressValidator = (control: UntypedFormControl): ValidatorFn =>
		this.checkIfAddressIsRequired(
			control.value as string,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose,
			this.loanDetailsForm?.controls['knownAddress'].value as number
		) as ValidatorFn;

	private minEstimatedValueValidator = (control: UntypedFormControl): ValidatorFn =>
		this.checkMinimumEstimatedValue(
			control.value as string,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose
		) as ValidatorFn;

	private maxEstimatedValueValidator = (control: UntypedFormControl): ValidatorFn =>
		this.checkMaximumEstimatedValue(
			control.value as string,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose
		) as ValidatorFn;

	private maxBorrowingAgainstEstimatedValueValidator = (control: UntypedFormControl): ValidatorFn =>
		this.checkBorrowingAgainstEstimatedValue(
			control.value as string,
			this.loanDetailsForm?.controls['estimatedValue'].value as string,
			this.loanDetailsForm?.controls['plan'].value as RbaLendingPurpose
		) as ValidatorFn;

	private selectedAddress: SimpAddress;

	private trueValue = 1;
	private falseValue = 0;

	// eslint-disable-next-line @typescript-eslint/member-ordering
	public constructor(
		private formBuilder: UntypedFormBuilder,
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private loanDetailService: LoanDetailService,
		private changeDetectorRef: ChangeDetectorRef,
		private applicationStepService: ApplicationStepService,
		private personalDetailService: PersonalDetailService,
		private overlayService: OverlayService,
		private metadataService: MetadataService,
		private brandService: BrandService
	) {
		this.setFieldMetadata();

		this.options = {
			plan: [
				{
					id: RbaLendingPurpose.PurchaseExistingDwelling,
					label: 'Purchase'
				},
				{
					id: RbaLendingPurpose.Refinance,
					label: EnumHelper.getDescription(RbaLendingPurpose, RbaLendingPurpose.Refinance) as string
				}
			],
			primaryPurpose: [
				{ id: PrimaryPurposeLoanPurpose.OwnerOccupied, label: 'A home to live in' },
				{ id: PrimaryPurposeLoanPurpose.InvestmentResidential, label: 'An investment' }
			],
			applicants: [
				{ id: 1, label: 'Just me' },
				{ id: 2, label: 'Two of us' },
				{ id: 3, label: 'More' }
			],
			states: EnumHelper.getEnumArray(AuState as unknown as { [index: string]: number }),
			multiHouseholdOptions: [
				{ id: 1, label: 'Yes' },
				{ id: 0, label: 'No' }
			],
			noOfDependants: [
				{ id: 0, label: '0' },
				{ id: 1, label: '1' },
				{ id: 2, label: '2' },
				{ id: 3, label: '3' },
				{ id: 4, label: '4' },
				{ id: 5, label: '5' },
				{ id: 6, label: '6' },
				{ id: 7, label: '7' },
				{ id: 8, label: '8' },
				{ id: 9, label: '9' },
				{ id: 10, label: '10' }
			],
			knownAddressOptions: [
				{ id: this.trueValue, label: 'Yes' },
				{ id: this.falseValue, label: 'No' }
			]
		};

		this.selectedAddress = AddressHelper.getEmptyAddress();

		this.loanDetailsForm = this.formBuilder.group(
			{
				id: [Constant.newId],
				applicationId: [null, Validators.required],
				primaryPurpose: [null, Validators.required],
				plan: [null],
				numberOfApplicants: [null, Validators.required],
				loanAmount: [
					null,
					[
						Validators.required,
						// eslint-disable-next-line @typescript-eslint/unbound-method
						this.minimumLoanAmount,
						// eslint-disable-next-line @typescript-eslint/unbound-method
						this.maximumLoanAmount,
						this.maxBorrowingAgainstEstimatedValueValidator
					]
				],
				deposit: [null, [this.minDepositValidator, this.maxDepositValidator]],
				estimatedValue: [null, [this.minEstimatedValueValidator, this.maxEstimatedValueValidator]],
				state: [null, [this.stateValidator]],
				isMultiHousehold: [null],
				noOfDependants: [this.options.noOfDependants[0]],
				// eslint-disable-next-line no-sparse-arrays
				knownAddress: [, [this.knownAddressValidator]],
				address: [null, [this.addressValidator]]
			},
			{
				validator: [
					ValidationHelper.conditionalValueValidator(
						'plan',
						'deposit',
						RbaLendingPurpose.PurchaseExistingDwelling,
						Validators.required
					),
					ValidationHelper.conditionalValueValidator(
						'plan',
						'estimatedValue',
						RbaLendingPurpose.Refinance,
						Validators.required
					)
				]
			}
		);

		this.validationErrors = {
			primaryPurpose: {
				required: 'Select a purpose'
			},
			plan: {
				required: 'Select a plan'
			},
			numberOfApplicants: {
				required: 'Select applicant type'
			},
			loanAmount: {
				required: 'We need the borrowing amount',
				min: 'The borrowing amount must be at least $20,000',
				max: 'The borrowing amount must be below $10,000,000',
				overDraft: 'Maximum borrowing allowed is 95%'
			},
			deposit: {
				required: 'We need the deposit amount',
				min: 'At least 5% deposit required',
				max: 'Maximum deposit allowed is 95%'
			},
			estimatedValue: {
				required: 'We need the estimated value',
				min: 'The estimated value must be at least $100,000',
				max: 'The estimated value must be below $10,000,000'
			},
			state: {
				required: 'Select a state'
			},
			address: {
				required: 'We need the property address',
				error: `That's not a valid address`
			},
			knownAddress: {
				required: 'Select an option'
			}
		};

		this.popTrigger = this.isTouchDevice() ? 'click' : 'hover';

		this.brandService.brandConfig$.pipe(takeUntil(this.destroy$)).subscribe((brandConfig: BrandConfig) => {
			this.contactPhone = brandConfig.contactPhone;
		});
	}

	public get primaryPurpose() {
		return this.loanDetailsForm.controls.primaryPurpose as UntypedFormControl;
	}

	public get plan() {
		return this.loanDetailsForm.controls.plan as UntypedFormControl;
	}

	public get numberOfApplicants() {
		return this.loanDetailsForm.controls.numberOfApplicants as UntypedFormControl;
	}

	public get loanAmount() {
		return this.loanDetailsForm.controls.loanAmount as UntypedFormControl;
	}

	public get deposit() {
		return this.loanDetailsForm.controls.deposit as UntypedFormControl;
	}

	public get state() {
		return this.loanDetailsForm.controls.state as UntypedFormControl;
	}

	public get isMultiHousehold() {
		return this.loanDetailsForm.controls.isMultiHousehold as UntypedFormControl;
	}

	public get noOfDependants() {
		return this.loanDetailsForm.controls.noOfDependants as UntypedFormControl;
	}

	public get knownAddress() {
		return this.loanDetailsForm.controls.knownAddress as UntypedFormControl;
	}

	public get address() {
		return this.loanDetailsForm.controls.address as UntypedFormControl;
	}

	public get estimatedValue() {
		return this.loanDetailsForm.controls.estimatedValue as UntypedFormControl;
	}

	public get showRemainingFields(): boolean {
		return !this.showCallUsMessage && (this.autoPopulateFields || this.fieldDisplayCount > 3);
	}

	public get showMultipleHouseholds(): boolean {
		return (
			(this.autoPopulateFields && this.enableMultipleHouseholds) ||
			(this.fieldDisplayCount > 2 && this.enableMultipleHouseholds)
		);
	}

	public get showKnownAddress(): boolean {
		return this.plan.value === RbaLendingPurpose.PurchaseExistingDwelling;
	}

	public get showState(): boolean {
		return (
			this.plan.value === RbaLendingPurpose.PurchaseExistingDwelling && this.knownAddress.value === this.falseValue
		);
	}

	public get showAddress(): boolean {
		return (
			this.plan.value === RbaLendingPurpose.Refinance ||
			(this.plan.value === RbaLendingPurpose.PurchaseExistingDwelling && this.knownAddress.value === this.trueValue)
		);
	}

	public get showEstimatedvalue(): boolean {
		return this.plan.value === RbaLendingPurpose.Refinance;
	}

	public ngOnInit(): void {
		this.navigationService.syncNavigation(StepType.LoanDetails);
		this.initaliseApplication();
		this.buildForm();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public updateAddress(address: SimpAddress): void {
		if (!AddressHelper.isValidAddress(address as Address)) {
			this.address.setErrors({ error: true });
		}
		this.selectedAddress = { ...AddressHelper.getEmptyAddress(), ...address };
		this.address.updateValueAndValidity();
	}

	public onSubmit(): void {
		if (this.loanDetailsForm.invalid) {
			return;
		}

		if (!this.address.value) {
			this.selectedAddress = AddressHelper.getEmptyAddress();
		}

		this.address.disable();

		if (this.numberOfApplicants.value === 1 && this.additionalAplicant?.id) {
			this.overlayService.startOverlay(this.overlayContent);
		} else {
			this.saveLoanDetails();
		}
	}

	public closeOverlay(deleteAdditionalApplicant = false): void {
		this.overlayService.stopOverlay();
		if (deleteAdditionalApplicant) {
			this.saveLoanDetails();
		}
	}

	public cancelApplication(): void {
		this.navigationService.goToHome();
	}

	public handlePopoverShown(): void {
		if (this.isTouchDevice()) {
			this.overlayService.startOverlay(null);
		}
	}

	public handlePopoverHidden(): void {
		if (this.isTouchDevice()) {
			this.overlayService.stopOverlay();
		}
	}

	public populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);
		return options as EnumObject[];
	}

	private initaliseApplication(): void {
		const existingApplicationId = this.applicationService.getStoredApplicationId();
		if (existingApplicationId) {
			this.loanDetailsForm.controls.applicationId.setValue(existingApplicationId);
			this.loadExistingLoanDetails(+existingApplicationId);
			this.getAdditionalApplicant(+existingApplicationId);
		} else {
			this.createNewApplication();
		}
	}

	private createNewApplication(): void {
		this.applicationService
			.createApplication()
			.pipe(takeUntil(this.destroy$))
			.subscribe((applicationId: number) => {
				this.loanDetailsForm.controls.applicationId.setValue(applicationId);
				this.loanDetailService.clearLoanDetailsCache();
			});
	}

	private loadExistingLoanDetails(existingApplicationId: number): void {
		this.loanDetailService
			.getByApplication(existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((loanDetailResult: LoanDetail) => {
				if (loanDetailResult.address) {
					this.selectedAddress = { ...loanDetailResult.address };
				}
				this.loanDetailsForm.patchValue({
					id: loanDetailResult.id,
					applicationId: loanDetailResult.applicationId,
					plan: loanDetailResult.plan,
					primaryPurpose: loanDetailResult.primaryPurpose,
					numberOfApplicants: loanDetailResult.numberOfApplicants,
					loanAmount: loanDetailResult.loanAmount,
					deposit: loanDetailResult.deposit,
					estimatedValue: loanDetailResult.estimatedValue,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					state: this.options.states.find((state: EnumObject) => state.id === loanDetailResult.state),
					isMultiHousehold: loanDetailResult.isMultiHousehold ? 0 : 1,
					noOfDependants:
						this.options.noOfDependants.find((option: EnumObject) => option.id === loanDetailResult.noOfDependants) ??
						this.options.noOfDependants[0],
					address: loanDetailResult.address ? AddressHelper.ExtractAddress(loanDetailResult.address) : null,
					knownAddress: loanDetailResult.address ? this.trueValue : this.falseValue
				});
				this.applicationService.setMultiHouseholdOption(loanDetailResult.isMultiHousehold);
				this.autoPopulateFields = true;
				this.enableMultipleHouseholds = loanDetailResult.numberOfApplicants === 2;
				this.changeDetectorRef.markForCheck();

				// Mark all fields as touched so the validation for each field will trigger
				// Only required while loading previously saved values from api.
				this.loanDetailsForm.markAllAsTouched();
			});
	}

	private getAdditionalApplicant(existingApplicationId: number): void {
		this.personalDetailService
			.getByApplication(existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: PersonalDetail[]) => {
				if (result.length > 1) {
					this.additionalAplicant = result[1];
				} else {
					this.additionalAplicant = {} as PersonalDetail;
				}
			});
	}

	private buildForm(): void {
		this.loanAmount.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.deposit.updateValueAndValidity();
		});

		this.plan.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.knownAddress.setValue(null);
			this.knownAddress.updateValueAndValidity();
			this.loanAmount.setValue(null);
			this.deposit.setValue(null);
			this.estimatedValue.setValue(null);
			this.deposit.updateValueAndValidity();
			this.estimatedValue.updateValueAndValidity();
		});

		this.knownAddress.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			if (value === null) {
				return;
			}
			if (value === this.trueValue) {
				this.state.setErrors(null);
				this.changeDetectorRef.detectChanges();
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
				this.propertyAddress.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
			} else {
				this.address.setErrors(null);
				this.changeDetectorRef.detectChanges();
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
				this.stateSelection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
			}
		});

		this.estimatedValue.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.loanAmount.updateValueAndValidity();
		});

		this.plan.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (this.fieldDisplayCount === 0) {
				this.fieldDisplayCount++;
			}
		});

		this.primaryPurpose.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (this.fieldDisplayCount === 1) {
				this.fieldDisplayCount++;
			}
		});

		this.numberOfApplicants.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((applicants) => {
			switch (applicants) {
				case 1:
					this.showCallUsMessage = false;
					this.enableMultipleHouseholds = false;
					this.fieldDisplayCount = 4;
					this.applicationService.setNumberOfApplicants(1);
					break;

				case 2:
					this.showCallUsMessage = false;
					this.enableMultipleHouseholds = true;
					if (this.isMultiHousehold.value !== null) {
						this.fieldDisplayCount = 4;
					} else {
						this.fieldDisplayCount = 3;
					}

					this.applicationService.setNumberOfApplicants(2);
					break;

				case 3:
					this.showCallUsMessage = true;
					this.enableMultipleHouseholds = false;
					break;
			}
		});

		this.isMultiHousehold.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (this.fieldDisplayCount === 3) {
				this.fieldDisplayCount++;
			}
		});
	}

	private saveLoanDetails(): void {
		this.isSubmitting = true;
		const loanDetailsToSave = {
			...(this.loanDetailsForm.value as LoanDetail),
			loanAmount: CurrencyHelper.unformatAmount(this.loanDetailsForm.controls.loanAmount.value),
			deposit: CurrencyHelper.unformatAmount(this.loanDetailsForm.controls.deposit.value),
			estimatedValue: CurrencyHelper.unformatAmount(this.loanDetailsForm.controls.estimatedValue.value),
			state: (this.loanDetailsForm.controls.state.value as EnumObject)?.id,
			isMultiHousehold: this.numberOfApplicants.value === 1 ? false : this.isMultiHousehold.value === 0,
			noOfDependants: (this.noOfDependants.value as EnumObject).id,
			address: AddressHelper.isValidAddress(this.selectedAddress as Address) ? this.selectedAddress : null
		} as LoanDetail;
		this.updateModelPriorToSave(loanDetailsToSave);
		this.loanDetailService
			.save(loanDetailsToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((loanDetailIdResult: number) => {
				this.loanDetailsForm.controls.id.setValue(loanDetailIdResult);
				const existingApplicationId = this.applicationService.getStoredApplicationId();
				if (existingApplicationId) {
					this.loanDetailService.fetchLoanDetails(existingApplicationId);
				}

				if (this.numberOfApplicants.value === 1 && this.additionalAplicant?.id) {
					this.personalDetailService
						.deleteApplicant(this.loanDetailsForm.get('applicationId')?.value, this.additionalAplicant.id)
						.pipe(takeUntil(this.destroy$))
						.subscribe(() => {
							this.applicationService.removeAdditionalApplicant();
							this.applicationService.setMultiHouseholdOption(loanDetailsToSave.isMultiHousehold);
							this.navigationService.goToNextStep(SimpProgressBarStatus.Complete);
							this.updateStep();
						});

					return;
				}

				if (this.numberOfApplicants.value === 2 && !this.additionalAplicant?.id) {
					this.navigationService.resetStepStatuses();
					this.resetRemainingSteps();
				}
				this.applicationService.setMultiHouseholdOption(loanDetailsToSave.isMultiHousehold);
				this.navigationService.goToNextStep(SimpProgressBarStatus.Complete);
				this.updateStep();
			})
			.add(() => {
				this.isSubmitting = false;
				this.address.enable();
				this.changeDetectorRef.markForCheck();
			});
	}

	private updateStep(): void {
		this.applicationStepService.updateStep(StepType.LoanDetails, StepStatus.Complete);
	}

	private resetRemainingSteps(): void {
		this.applicationStepService.updateStep(StepType.PersonalDetails, StepStatus.Incomplete);
		this.applicationStepService.updateStep(StepType.Income, StepStatus.Incomplete);
		this.applicationStepService.updateStep(StepType.Expenses, StepStatus.Incomplete);
		this.applicationStepService.updateStep(StepType.Assets, StepStatus.Incomplete);
		this.applicationStepService.updateStep(StepType.Liabilities, StepStatus.Incomplete);
	}

	private minimumLoanAmount(control: UntypedFormControl): any {
		if (!control.value && control.value !== 0) {
			return null;
		}
		const value = control.value as string;

		if (CurrencyHelper.unformatAmount(value) < 20000) {
			return { min: true };
		}
	}

	private maximumLoanAmount(control: UntypedFormControl): any {
		if (!control.value) {
			return null;
		}
		const value = control.value as string;
		if (CurrencyHelper.unformatAmount(value) > 10000000) {
			return { max: true };
		}
	}

	private checkMinimumDeposit(deposit: number, loanAmount: number, plan: RbaLendingPurpose): any {
		if (!deposit && deposit !== 0) {
			return null;
		}
		const validationError = this.validationErrors['deposit'] as ValidationErrors;

		const unformattedLoanAmount = CurrencyHelper.unformatAmount(loanAmount?.toString());
		const unformattedDeposit = CurrencyHelper.unformatAmount(deposit?.toString());
		if (this.minDepositPercentCheck) {
			this.minDepositPercentage = Number(this.minDepositPercentCheck.title);
		}
		if (
			plan === RbaLendingPurpose.PurchaseExistingDwelling &&
			unformattedDeposit < this.minDepositPercentage * unformattedLoanAmount
		) {
			if (this.minDepositPercentCheck) {
				validationError.min = `At least ${Math.round(this.minDepositPercentage * 100)}% deposit required`;
			}
			return { min: true };
		}
	}

	private checkMaximumDeposit(deposit: string, loanAmount: string, plan: RbaLendingPurpose): any {
		if (!deposit) {
			return null;
		}
		const validationError = this.validationErrors['deposit'] as ValidationErrors;
		const unformattedLoanAmount = CurrencyHelper.unformatAmount('' + loanAmount);
		const unformattedDeposit = CurrencyHelper.unformatAmount('' + deposit);
		if (this.maxDepositPercentCheck) {
			this.maxDepositPercentage = Number(this.maxDepositPercentCheck.title);
		}
		if (
			plan === RbaLendingPurpose.PurchaseExistingDwelling &&
			unformattedDeposit > this.maxDepositPercentage * unformattedLoanAmount
		) {
			if (this.maxDepositPercentCheck) {
				validationError.max = `Maximum deposit allowed is ${Math.round(this.maxDepositPercentage * 100)}%`;
			}
			return { max: true };
		}
	}

	private checkIfKnownAddressIsRequired(knownAddress: number, plan: RbaLendingPurpose): any {
		if (plan === RbaLendingPurpose.PurchaseExistingDwelling && knownAddress === null) {
			return { required: true };
		}

		return null;
	}

	private checkIfStateIsRequired(state: any, plan: RbaLendingPurpose, knownAddress: number): any {
		if (plan === RbaLendingPurpose.PurchaseExistingDwelling && knownAddress === this.falseValue && state === null) {
			return { required: true };
		}

		return null;
	}

	private checkIfAddressIsRequired(address: any, plan: RbaLendingPurpose, knownAddress: number): any {
		if (
			(plan === RbaLendingPurpose.Refinance ||
				(plan === RbaLendingPurpose.PurchaseExistingDwelling && knownAddress === this.trueValue)) &&
			(address === '' || !AddressHelper.isValidAddress(this.selectedAddress as Address))
		) {
			return { required: true };
		}

		return null;
	}

	private checkMinimumEstimatedValue(estimatedValue: string, plan: RbaLendingPurpose): any {
		if (!estimatedValue) {
			return null;
		}

		if (plan === RbaLendingPurpose.Refinance && CurrencyHelper.unformatAmount('' + estimatedValue) < 100000) {
			return { min: true };
		}
	}

	private checkMaximumEstimatedValue(estimatedValue: string, plan: RbaLendingPurpose): any {
		if (!estimatedValue) {
			return null;
		}

		if (plan === RbaLendingPurpose.Refinance && CurrencyHelper.unformatAmount('' + estimatedValue) > 10000000) {
			return { max: true };
		}
	}

	private checkBorrowingAgainstEstimatedValue(
		borrowingAmount: string,
		estimatedValue: string,
		plan: RbaLendingPurpose
	): any {
		if (!borrowingAmount) {
			return null;
		}

		const unformattedEstimatedValue = CurrencyHelper.unformatAmount('' + estimatedValue);
		const unformattedBorrowingAmount = CurrencyHelper.unformatAmount('' + borrowingAmount);
		if (plan === RbaLendingPurpose.Refinance && unformattedEstimatedValue * 0.95 < unformattedBorrowingAmount) {
			return { overDraft: true };
		}
	}

	private updateModelPriorToSave(loanDetail: LoanDetail): void {
		switch (loanDetail.plan) {
			case RbaLendingPurpose.PurchaseExistingDwelling:
				if (this.knownAddress.value === this.trueValue) {
					loanDetail.state = loanDetail.address?.state;
				} else {
					loanDetail.address = undefined;
				}
				break;
			case RbaLendingPurpose.Refinance:
				loanDetail.state = loanDetail.address?.state;
				break;
		}
	}

	private isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.areaMetadata = this.metadataService.getAreaMetadataByName('LoanDetails');

			const subSection: SubSectionMetadata =
				this.metadataService.getSubSectionMetadataByName('LoanDetails', 'LoanDetails', 'LoanDetails') ??
				({} as SectionMetadata);

			const fields: FieldMetadata[] = subSection?.fields;
			this.planConfig = this.metadataService.getFieldByName('Plan', fields);
			this.propertyTypeConfig = this.metadataService.getFieldByName('PropertyType', fields);
			this.applicantNumberConfig = this.metadataService.getFieldByName('ApplicantNumber', fields);
			this.sameHouseholdConfig = this.metadataService.getFieldByName('SameHousehold', fields);
			this.noDependantsConfig = this.metadataService.getFieldByName('NoDependants', fields);
			this.loanAmountConfig = this.metadataService.getFieldByName('LoanAmount', fields);
			this.depositConfig = this.metadataService.getFieldByName('Deposit', fields);
			this.estimatedValueConfig = this.metadataService.getFieldByName('EstimatedValue', fields);
			this.addressKnownConfig = this.metadataService.getFieldByName('AddressKnown', fields);
			this.addressConfig = this.metadataService.getFieldByName('Address', fields);
			this.stateConfig = this.metadataService.getFieldByName('State', fields);
			this.stateOptions = this.populateOptions(this.stateConfig.options);
			this.maxDepositPercentCheck = this.areaMetadata.texts?.find(
				(text: TextMetadata) => text.name === 'MaximumDepositPercentage'
			) as TextMetadata;
			this.minDepositPercentCheck = this.areaMetadata.texts?.find(
				(text: TextMetadata) => text.name === 'MinimumDepositPercentage'
			) as TextMetadata;
			const buttons: ButtonMetadata[] = this.areaMetadata?.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);
			this.changeDetectorRef.markForCheck();
		});
	}
}
