<div class="register" [style.height]="registerScreenHeight" *ngIf="enablePasswordSet">
	<header class="register__header simp-padding-left-small"></header>
	<div class="register__contents-wrapper">
		<div class="register__contents simp-padding-small">
			<div class="register__section">
				<img
					class="register__brand-logo"
					src="{{ './../assets/brands/' + brandName + '/images/brand-logo.png' }}"
					alt="logo"
				/>
			</div>
			<h4 class="simp-h4 register__message">
				Hi {{ firstName }}, please set a password so we can keep your info locked down tight.
			</h4>

			<form class="register__form" [formGroup]="passwordSetForm" (ngSubmit)="onSubmit()" autocomplete="off">
				<div class="simp-row">
					<simp-password class="col-12" id="password1" [formControl]="password" [minimumStrength]="2">
						Password
					</simp-password>
				</div>

				<div class="register__button-container simp-margin-top-small">
					<button type="submit" class="btn btn-primary btn-lg simp-button" [disabled]="!passwordSetForm.valid">
						Set password
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
