/* eslint-disable @typescript-eslint/naming-convention */
export interface EnumMapper {
	id: string;
	enumType: any;
	mapping: Map<number, string>;
}

export interface EnumObject {
	id: number;
	label: string;
}

// Code belo is pasted from auto generated

// export enum ReferenceType {
//   AbsLendingPurposeCode = 0,
//   AbsOccupationCode = 1,
//   AccountHolding = 2,
//   AccountStatus = 3,
//   AddressType = 4,
//   AggregatedTransactionsCategoryType = 5,
//   AgriculturalAssetType = 6,
//   AircraftType = 7,
//   AnzscoOccupationCode = 8,
//   AnzsicIndustryCode = 9,
//   ApplicantType = 10,
//   ApplicationDocType = 11,
//   ApplicationType = 12,
//   AssetTransaction = 13,
//   AttachmentType = 14,
//   AuDialingCode = 15,
//   AuState = 16,
//   BalloonRVInputPattern = 17,
//   BenefitEnquiries = 18,
//   BuilderType = 19,
//   BusinessChannelType = 20,
//   BusinessStructureExcludingTrust = 21,
//   BusinessStructureFull = 22,
//   CalculationBasis = 23,
//   CapacityType = 24,
//   CleaningEquipmentType = 25,
//   ClearingFromOtherSource = 26,
//   ClearingFromThisLoan = 27,
//   CoApplicantBenefit = 28,
//   CollateralClass = 29,
//   CollateralType = 30,
//   CommercialType = 31,
//   CommissionBeneficiary = 32,
//   CommissionStructure = 33,
//   Condition = 34,
//   ConstructionType = 35,
//   ContactEmailType = 36,
//   ContributionFundsType = 37,
//   CountryCode = 38,
//   CreditCardType = 39,
//   CreditHistoryIssue = 40,
//   CreditStatus = 41,
//   CurrencyCode = 42,
//   DataSource = 43,
//   DayOfWeek = 44,
//   DepositAccountType = 45,
//   DiscountReason = 46,
//   DocumentationDeliveryMethod = 47,
//   DocumentationInstructionsMethod = 48,
//   DocumentCategory = 49,
//   DocumentType = 50,
//   DurationUnits = 51,
//   EarthMovingMiningAndConstructionSubtype = 52,
//   EarthMovingMiningAndConstructionType = 53,
//   EmployerType = 54,
//   EmploymentStatus = 55,
//   EncumbranceInFavourOfCapacity = 56,
//   EstimateBasisNonRealEstateAsset = 57,
//   EstimateBasisRealEstateAsset = 58,
//   EstimateMethodology = 59,
//   ExcessUsageCalculationMethod = 60,
//   ExemptStatus = 61,
//   FeeCategory = 62,
//   FeePaymentTiming = 63,
//   FeeType = 64,
//   FinancialAssetType = 65,
//   FinancialInstitution = 66,
//   FinancialTransactionType = 67,
//   ForeignEmployedBasis = 68,
//   ForeignTaxAssociationStatus = 69,
//   FrequencyFull = 70,
//   FrequencyShort = 71,
//   Funder = 72,
//   FundsAccessType = 73,
//   Gender = 74,
//   GoodToBeUsedLocation = 75,
//   GovernmentBenefitsType = 76,
//   GuarantorAccess = 77,
//   Holding = 78,
//   HospitalityAndLeisureType = 79,
//   HousingStatus = 80,
//   Importance = 81,
//   IncomeStatusOnOrBeforeSettlement = 82,
//   IncomeVerification = 83,
//   IndependentAdviceTypeRequired = 84,
//   IndustrialType = 85,
//   InstalmentsFrequency = 86,
//   InsuranceType = 87,
//   Insurer = 88,
//   InterestChargeFrequency = 89,
//   InterestOnlyReason = 90,
//   InterestRateType = 91,
//   InterestType = 92,
//   ItAndAVEquipmentType = 93,
//   KinRelationship = 94,
//   LandAreaUnits = 95,
//   LandValueEstimateBasis = 96,
//   LendingGuaranteeType = 97,
//   LevelType = 98,
//   LiabilityType = 99,
//   LicenceType = 100,
//   LivingExpenseCategory = 101,
//   LoanPaymentScheduleType = 102,
//   LoanTermUnits = 103,
//   LoanType = 104,
//   MaritalStatus = 105,
//   MaterialsHandlingAndLiftingSubtype = 106,
//   MaterialsHandlingAndLiftingType = 107,
//   MedicalEquipmentType = 108,
//   MedicareCardColour = 109,
//   MitigantFactor = 110,
//   MmMonth = 111,
//   MobileComputingType = 112,
//   NameTitle = 113,
//   NccpStatus = 114,
//   NonRealEstateAssetEncumbrance = 115,
//   NonRealEstateAssetType = 116,
//   NonRealEstateSecurityPriority = 117,
//   NrasConsortium = 118,
//   Occupancy = 119,
//   OecdCRSStatus = 120,
//   OfficeEquipmentType = 121,
//   OtherAssetType = 122,
//   OtherCommitmentCategory = 123,
//   OtherIncomeType = 124,
//   PartnerType = 125,
//   PayFeesFrom = 126,
//   PaygBasis = 127,
//   PaymentTiming = 128,
//   PaymentType = 129,
//   PlantEquipmentAndIndustrialType = 130,
//   PlanType = 131,
//   PoBoxType = 132,
//   PoolType = 133,
//   PowerOfAttorneyType = 134,
//   PreferredContactCompany = 135,
//   PreferredContactPerson = 136,
//   PrimaryPurposeLoanPurpose = 137,
//   PrimaryPurposeRealEstateAsset = 138,
//   PrimaryUsage = 139,
//   PrincipalRefinancingReason = 140,
//   ProofCodeCompany = 141,
//   ProofCodeNotEmployed = 142,
//   ProofCodeOther = 143,
//   ProofCodePAYG = 144,
//   ProofCodeSelfEmployed = 145,
//   ProofCodeTrust = 146,
//   PropertyPartType = 147,
//   Proportions = 148,
//   ProposedRepaymentMethod = 149,
//   PurposeOfApplication = 150,
//   RbaLendingPurpose = 151,
//   RealEstateAssetEncumbrance = 152,
//   RealEstateAssetStatus = 153,
//   ReasonForDifferentName = 154,
//   RegistrationEventType = 155,
//   RentalIncomeBasis = 156,
//   RepaymentFrequency = 157,
//   ResidencyStatus = 158,
//   ResidentialType = 159,
//   RuralType = 160,
//   RuralUsage = 161,
//   SalesChannelType = 162,
//   SecurityPriority = 163,
//   SelfEmployedBasis = 164,
//   SendDocumentsTo = 165,
//   SendDocumentsToPerson = 166,
//   ShareType = 167,
//   SignificantChange = 168,
//   SourceOfFundsType = 169,
//   StatementCycle = 170,
//   StreetSuffix = 171,
//   StreetType = 172,
//   SupplierType = 173,
//   SurplusFundsDisbursementMethod = 174,
//   TaxDeclarationExemptionCategory = 175,
//   TaxDepreciationMethod = 176,
//   TenureType = 177,
//   TinNotProvidedReason = 178,
//   TitleSystem = 179,
//   ToolsOfTradeType = 180,
//   TotalTermType = 181,
//   TransactionType = 182,
//   TrustPurpose = 183,
//   TrustStructure = 184,
//   TypeOfIncorporation = 185,
//   UnitType = 186,
//   UsageUnitOfMeasurement = 187,
//   ValuationProgram = 188,
//   VaryOnValuation = 189,
//   VehicleSubtype = 190,
//   VehicleType = 191,
//   VisitContactType = 192,
//   Week = 193,
//   YesNoIntent = 194,
//   YyYear = 195,
// }

// export const ReferenceTypeLabel = new Map<number, string>([
//   [ReferenceType.AbsLendingPurposeCode, 'AbsLendingPurposeCode'],
//   [ReferenceType.AbsOccupationCode, 'AbsOccupationCode'],
//   [ReferenceType.AccountHolding, 'AccountHolding'],
//   [ReferenceType.AccountStatus, 'AccountStatus'],
//   [ReferenceType.AddressType, 'AddressType'],
//   [
//     ReferenceType.AggregatedTransactionsCategoryType,
//     'AggregatedTransactionsCategoryType',
//   ],
//   [ReferenceType.AgriculturalAssetType, 'AgriculturalAssetType'],
//   [ReferenceType.AircraftType, 'AircraftType'],
//   [ReferenceType.AnzscoOccupationCode, 'AnzscoOccupationCode'],
//   [ReferenceType.AnzsicIndustryCode, 'AnzsicIndustryCode'],
//   [ReferenceType.ApplicantType, 'ApplicantType'],
//   [ReferenceType.ApplicationDocType, 'ApplicationDocType'],
//   [ReferenceType.ApplicationType, 'ApplicationType'],
//   [ReferenceType.AssetTransaction, 'AssetTransaction'],
//   [ReferenceType.AttachmentType, 'AttachmentType'],
//   [ReferenceType.AuDialingCode, 'AuDialingCode'],
//   [ReferenceType.AuState, 'AuState'],
//   [ReferenceType.BalloonRVInputPattern, 'BalloonRVInputPattern'],
//   [ReferenceType.BenefitEnquiries, 'BenefitEnquiries'],
//   [ReferenceType.BuilderType, 'BuilderType'],
//   [ReferenceType.BusinessChannelType, 'BusinessChannelType'],
//   [
//     ReferenceType.BusinessStructureExcludingTrust,
//     'BusinessStructureExcludingTrust',
//   ],
//   [ReferenceType.BusinessStructureFull, 'BusinessStructureFull'],
//   [ReferenceType.CalculationBasis, 'CalculationBasis'],
//   [ReferenceType.CapacityType, 'CapacityType'],
//   [ReferenceType.CleaningEquipmentType, 'CleaningEquipmentType'],
//   [ReferenceType.ClearingFromOtherSource, 'ClearingFromOtherSource'],
//   [ReferenceType.ClearingFromThisLoan, 'ClearingFromThisLoan'],
//   [ReferenceType.CoApplicantBenefit, 'CoApplicantBenefit'],
//   [ReferenceType.CollateralClass, 'CollateralClass'],
//   [ReferenceType.CollateralType, 'CollateralType'],
//   [ReferenceType.CommercialType, 'CommercialType'],
//   [ReferenceType.CommissionBeneficiary, 'CommissionBeneficiary'],
//   [ReferenceType.CommissionStructure, 'CommissionStructure'],
//   [ReferenceType.Condition, 'Condition'],
//   [ReferenceType.ConstructionType, 'ConstructionType'],
//   [ReferenceType.ContactEmailType, 'ContactEmailType'],
//   [ReferenceType.ContributionFundsType, 'ContributionFundsType'],
//   [ReferenceType.CountryCode, 'CountryCode'],
//   [ReferenceType.CreditCardType, 'CreditCardType'],
//   [ReferenceType.CreditHistoryIssue, 'CreditHistoryIssue'],
//   [ReferenceType.CreditStatus, 'CreditStatus'],
//   [ReferenceType.CurrencyCode, 'CurrencyCode'],
//   [ReferenceType.DataSource, 'DataSource'],
//   [ReferenceType.DayOfWeek, 'DayOfWeek'],
//   [ReferenceType.DepositAccountType, 'DepositAccountType'],
//   [ReferenceType.DiscountReason, 'DiscountReason'],
//   [ReferenceType.DocumentationDeliveryMethod, 'DocumentationDeliveryMethod'],
//   [
//     ReferenceType.DocumentationInstructionsMethod,
//     'DocumentationInstructionsMethod',
//   ],
//   [ReferenceType.DocumentCategory, 'DocumentCategory'],
//   [ReferenceType.DocumentType, 'DocumentType'],
//   [ReferenceType.DurationUnits, 'DurationUnits'],
//   [
//     ReferenceType.EarthMovingMiningAndConstructionSubtype,
//     'EarthMovingMiningAndConstructionSubtype',
//   ],
//   [
//     ReferenceType.EarthMovingMiningAndConstructionType,
//     'EarthMovingMiningAndConstructionType',
//   ],
//   [ReferenceType.EmployerType, 'EmployerType'],
//   [ReferenceType.EmploymentStatus, 'EmploymentStatus'],
//   [
//     ReferenceType.EncumbranceInFavourOfCapacity,
//     'EncumbranceInFavourOfCapacity',
//   ],
//   [
//     ReferenceType.EstimateBasisNonRealEstateAsset,
//     'EstimateBasisNonRealEstateAsset',
//   ],
//   [ReferenceType.EstimateBasisRealEstateAsset, 'EstimateBasisRealEstateAsset'],
//   [ReferenceType.EstimateMethodology, 'EstimateMethodology'],
//   [ReferenceType.ExcessUsageCalculationMethod, 'ExcessUsageCalculationMethod'],
//   [ReferenceType.ExemptStatus, 'ExemptStatus'],
//   [ReferenceType.FeeCategory, 'FeeCategory'],
//   [ReferenceType.FeePaymentTiming, 'FeePaymentTiming'],
//   [ReferenceType.FeeType, 'FeeType'],
//   [ReferenceType.FinancialAssetType, 'FinancialAssetType'],
//   [ReferenceType.FinancialInstitution, 'FinancialInstitution'],
//   [ReferenceType.FinancialTransactionType, 'FinancialTransactionType'],
//   [ReferenceType.ForeignEmployedBasis, 'ForeignEmployedBasis'],
//   [ReferenceType.ForeignTaxAssociationStatus, 'ForeignTaxAssociationStatus'],
//   [ReferenceType.FrequencyFull, 'FrequencyFull'],
//   [ReferenceType.FrequencyShort, 'FrequencyShort'],
//   [ReferenceType.Funder, 'Funder'],
//   [ReferenceType.FundsAccessType, 'FundsAccessType'],
//   [ReferenceType.Gender, 'Gender'],
//   [ReferenceType.GoodToBeUsedLocation, 'GoodToBeUsedLocation'],
//   [ReferenceType.GovernmentBenefitsType, 'GovernmentBenefitsType'],
//   [ReferenceType.GuarantorAccess, 'GuarantorAccess'],
//   [ReferenceType.Holding, 'Holding'],
//   [ReferenceType.HospitalityAndLeisureType, 'HospitalityAndLeisureType'],
//   [ReferenceType.HousingStatus, 'HousingStatus'],
//   [ReferenceType.Importance, 'Importance'],
//   [
//     ReferenceType.IncomeStatusOnOrBeforeSettlement,
//     'IncomeStatusOnOrBeforeSettlement',
//   ],
//   [ReferenceType.IncomeVerification, 'IncomeVerification'],
//   [
//     ReferenceType.IndependentAdviceTypeRequired,
//     'IndependentAdviceTypeRequired',
//   ],
//   [ReferenceType.IndustrialType, 'IndustrialType'],
//   [ReferenceType.InstalmentsFrequency, 'InstalmentsFrequency'],
//   [ReferenceType.InsuranceType, 'InsuranceType'],
//   [ReferenceType.Insurer, 'Insurer'],
//   [ReferenceType.InterestChargeFrequency, 'InterestChargeFrequency'],
//   [ReferenceType.InterestOnlyReason, 'InterestOnlyReason'],
//   [ReferenceType.InterestRateType, 'InterestRateType'],
//   [ReferenceType.InterestType, 'InterestType'],
//   [ReferenceType.ItAndAVEquipmentType, 'ItAndAVEquipmentType'],
//   [ReferenceType.KinRelationship, 'KinRelationship'],
//   [ReferenceType.LandAreaUnits, 'LandAreaUnits'],
//   [ReferenceType.LandValueEstimateBasis, 'LandValueEstimateBasis'],
//   [ReferenceType.LendingGuaranteeType, 'LendingGuaranteeType'],
//   [ReferenceType.LevelType, 'LevelType'],
//   [ReferenceType.LiabilityType, 'LiabilityType'],
//   [ReferenceType.LicenceType, 'LicenceType'],
//   [ReferenceType.LivingExpenseCategory, 'LivingExpenseCategory'],
//   [ReferenceType.LoanPaymentScheduleType, 'LoanPaymentScheduleType'],
//   [ReferenceType.LoanTermUnits, 'LoanTermUnits'],
//   [ReferenceType.LoanType, 'LoanType'],
//   [ReferenceType.MaritalStatus, 'MaritalStatus'],
//   [
//     ReferenceType.MaterialsHandlingAndLiftingSubtype,
//     'MaterialsHandlingAndLiftingSubtype',
//   ],
//   [
//     ReferenceType.MaterialsHandlingAndLiftingType,
//     'MaterialsHandlingAndLiftingType',
//   ],
//   [ReferenceType.MedicalEquipmentType, 'MedicalEquipmentType'],
//   [ReferenceType.MedicareCardColour, 'MedicareCardColour'],
//   [ReferenceType.MitigantFactor, 'MitigantFactor'],
//   [ReferenceType.MmMonth, 'MmMonth'],
//   [ReferenceType.MobileComputingType, 'MobileComputingType'],
//   [ReferenceType.NameTitle, 'NameTitle'],
//   [ReferenceType.NccpStatus, 'NccpStatus'],
//   [
//     ReferenceType.NonRealEstateAssetEncumbrance,
//     'NonRealEstateAssetEncumbrance',
//   ],
//   [ReferenceType.NonRealEstateAssetType, 'NonRealEstateAssetType'],
//   [
//     ReferenceType.NonRealEstateSecurityPriority,
//     'NonRealEstateSecurityPriority',
//   ],
//   [ReferenceType.NrasConsortium, 'NrasConsortium'],
//   [ReferenceType.Occupancy, 'Occupancy'],
//   [ReferenceType.OecdCRSStatus, 'OecdCRSStatus'],
//   [ReferenceType.OfficeEquipmentType, 'OfficeEquipmentType'],
//   [ReferenceType.OtherAssetType, 'OtherAssetType'],
//   [ReferenceType.OtherCommitmentCategory, 'OtherCommitmentCategory'],
//   [ReferenceType.OtherIncomeType, 'OtherIncomeType'],
//   [ReferenceType.PartnerType, 'PartnerType'],
//   [ReferenceType.PayFeesFrom, 'PayFeesFrom'],
//   [ReferenceType.PaygBasis, 'PaygBasis'],
//   [ReferenceType.PaymentTiming, 'PaymentTiming'],
//   [ReferenceType.PaymentType, 'PaymentType'],
//   [
//     ReferenceType.PlantEquipmentAndIndustrialType,
//     'PlantEquipmentAndIndustrialType',
//   ],
//   [ReferenceType.PlanType, 'PlanType'],
//   [ReferenceType.PoBoxType, 'PoBoxType'],
//   [ReferenceType.PoolType, 'PoolType'],
//   [ReferenceType.PowerOfAttorneyType, 'PowerOfAttorneyType'],
//   [ReferenceType.PreferredContactCompany, 'PreferredContactCompany'],
//   [ReferenceType.PreferredContactPerson, 'PreferredContactPerson'],
//   [ReferenceType.PrimaryPurposeLoanPurpose, 'PrimaryPurposeLoanPurpose'],
//   [
//     ReferenceType.PrimaryPurposeRealEstateAsset,
//     'PrimaryPurposeRealEstateAsset',
//   ],
//   [ReferenceType.PrimaryUsage, 'PrimaryUsage'],
//   [ReferenceType.PrincipalRefinancingReason, 'PrincipalRefinancingReason'],
//   [ReferenceType.ProofCodeCompany, 'ProofCodeCompany'],
//   [ReferenceType.ProofCodeNotEmployed, 'ProofCodeNotEmployed'],
//   [ReferenceType.ProofCodeOther, 'ProofCodeOther'],
//   [ReferenceType.ProofCodePAYG, 'ProofCodePAYG'],
//   [ReferenceType.ProofCodeSelfEmployed, 'ProofCodeSelfEmployed'],
//   [ReferenceType.ProofCodeTrust, 'ProofCodeTrust'],
//   [ReferenceType.PropertyPartType, 'PropertyPartType'],
//   [ReferenceType.Proportions, 'Proportions'],
//   [ReferenceType.ProposedRepaymentMethod, 'ProposedRepaymentMethod'],
//   [ReferenceType.PurposeOfApplication, 'PurposeOfApplication'],
//   [ReferenceType.RbaLendingPurpose, 'RbaLendingPurpose'],
//   [ReferenceType.RealEstateAssetEncumbrance, 'RealEstateAssetEncumbrance'],
//   [ReferenceType.RealEstateAssetStatus, 'RealEstateAssetStatus'],
//   [ReferenceType.ReasonForDifferentName, 'ReasonForDifferentName'],
//   [ReferenceType.RegistrationEventType, 'RegistrationEventType'],
//   [ReferenceType.RentalIncomeBasis, 'RentalIncomeBasis'],
//   [ReferenceType.RepaymentFrequency, 'RepaymentFrequency'],
//   [ReferenceType.ResidencyStatus, 'ResidencyStatus'],
//   [ReferenceType.ResidentialType, 'ResidentialType'],
//   [ReferenceType.RuralType, 'RuralType'],
//   [ReferenceType.RuralUsage, 'RuralUsage'],
//   [ReferenceType.SalesChannelType, 'SalesChannelType'],
//   [ReferenceType.SecurityPriority, 'SecurityPriority'],
//   [ReferenceType.SelfEmployedBasis, 'SelfEmployedBasis'],
//   [ReferenceType.SendDocumentsTo, 'SendDocumentsTo'],
//   [ReferenceType.SendDocumentsToPerson, 'SendDocumentsToPerson'],
//   [ReferenceType.ShareType, 'ShareType'],
//   [ReferenceType.SignificantChange, 'SignificantChange'],
//   [ReferenceType.SourceOfFundsType, 'SourceOfFundsType'],
//   [ReferenceType.StatementCycle, 'StatementCycle'],
//   [ReferenceType.StreetSuffix, 'StreetSuffix'],
//   [ReferenceType.StreetType, 'StreetType'],
//   [ReferenceType.SupplierType, 'SupplierType'],
//   [
//     ReferenceType.SurplusFundsDisbursementMethod,
//     'SurplusFundsDisbursementMethod',
//   ],
//   [
//     ReferenceType.TaxDeclarationExemptionCategory,
//     'TaxDeclarationExemptionCategory',
//   ],
//   [ReferenceType.TaxDepreciationMethod, 'TaxDepreciationMethod'],
//   [ReferenceType.TenureType, 'TenureType'],
//   [ReferenceType.TinNotProvidedReason, 'TinNotProvidedReason'],
//   [ReferenceType.TitleSystem, 'TitleSystem'],
//   [ReferenceType.ToolsOfTradeType, 'ToolsOfTradeType'],
//   [ReferenceType.TotalTermType, 'TotalTermType'],
//   [ReferenceType.TransactionType, 'TransactionType'],
//   [ReferenceType.TrustPurpose, 'TrustPurpose'],
//   [ReferenceType.TrustStructure, 'TrustStructure'],
//   [ReferenceType.TypeOfIncorporation, 'TypeOfIncorporation'],
//   [ReferenceType.UnitType, 'UnitType'],
//   [ReferenceType.UsageUnitOfMeasurement, 'UsageUnitOfMeasurement'],
//   [ReferenceType.ValuationProgram, 'ValuationProgram'],
//   [ReferenceType.VaryOnValuation, 'VaryOnValuation'],
//   [ReferenceType.VehicleSubtype, 'VehicleSubtype'],
//   [ReferenceType.VehicleType, 'VehicleType'],
//   [ReferenceType.VisitContactType, 'VisitContactType'],
//   [ReferenceType.Week, 'Week'],
//   [ReferenceType.YesNoIntent, 'YesNoIntent'],
//   [ReferenceType.YyYear, 'YyYear'],
// ]);

export enum ExpenseSubClassificationExpenseSubClass {
	todo = 1
}

export const ExpenseSubClassificationExpenseSubClassLabel = new Map<number, string>([
	[ExpenseSubClassificationExpenseSubClass.todo, 'todo']
]);

export enum LoanDetailApply {
	Single = 1,
	Couple = 2,
	More = 3
}

export const LoanDetailApplyLabel = new Map<number, string>([
	[LoanDetailApply.Single, 'Single'],
	[LoanDetailApply.Couple, 'Couple'],
	[LoanDetailApply.More, 'More']
]);

export enum ChannelStatus {
	Active = 1,
	Inactive = 2,
	Deleted = -1
}

export const ChannelStatusLabel = new Map<number, string>([
	[ChannelStatus.Active, 'Active'],
	[ChannelStatus.Inactive, 'Inactive'],
	[ChannelStatus.Deleted, 'Deleted']
]);

export enum ApplicationAccessApplicationAccessType {
	None = 0,
	Read = 1,
	Write = 2
}

export const ApplicationAccessApplicationAccessTypeLabel = new Map<number, string>([
	[ApplicationAccessApplicationAccessType.None, 'None'],
	[ApplicationAccessApplicationAccessType.Read, 'Read'],
	[ApplicationAccessApplicationAccessType.Write, 'Write']
]);

export enum ApplicationAccessStatus {
	Active = 1,
	Inactive = 2,
	Deleted = -1
}

export const ApplicationAccessStatusLabel = new Map<number, string>([
	[ApplicationAccessStatus.Active, 'Active'],
	[ApplicationAccessStatus.Inactive, 'Inactive'],
	[ApplicationAccessStatus.Deleted, 'Deleted']
]);

export enum JourneyStatus {
	NotStarted = 1,
	DataEntry = 2,
	Processing = 3,
	Approved = 4,
	Declined = 5,
	Abandoned = 6,
	Deleted = -1
}

export const JourneyStatusLabel = new Map<number, string>([
	[JourneyStatus.NotStarted, 'Not started'],
	[JourneyStatus.DataEntry, 'Data entry in progress'],
	[JourneyStatus.Processing, 'Processing'],
	[JourneyStatus.Approved, 'Approved'],
	[JourneyStatus.Declined, 'Declined'],
	[JourneyStatus.Abandoned, 'Abandoned'],
	[JourneyStatus.Deleted, 'Deleted']
]);

export enum JourneyJourneyType {
	ApprovalInPrinciple = 1,
	FactFind = 2,
	StatementOfPosition = 3,
	FullApplication = 4
}

export const JourneyJourneyTypeLabel = new Map<number, string>([
	[JourneyJourneyType.ApprovalInPrinciple, 'Approval in principle'],
	[JourneyJourneyType.FactFind, 'Fact find'],
	[JourneyJourneyType.StatementOfPosition, 'Statement of position'],
	[JourneyJourneyType.FullApplication, 'Full application']
]);

export enum StepStatus {
	Incomplete = 1,
	Complete = 2,
	Deleted = -1
}

export const StepStatusLabel = new Map<number, string>([
	[StepStatus.Incomplete, 'Incomplete'],
	[StepStatus.Complete, 'Complete'],
	[StepStatus.Deleted, 'Deleted']
]);

export enum StepType {
	LoanDetails = 1,
	PersonalDetails = 2,
	Income = 3,
	Expenses = 4,
	Assets = 5,
	Liabilities = 6
}

export const StepTypeLabel = new Map<number, string>([
	[StepType.LoanDetails, 'Loan Details'],
	[StepType.PersonalDetails, 'Personal Details'],
	[StepType.Income, 'Income'],
	[StepType.Expenses, 'Expenses'],
	[StepType.Assets, 'Assets'],
	[StepType.Liabilities, 'Liabilities']
]);

export enum OriginationSource {
	LoanApp = 1000,
	ApprovalInPrinciple = 1001
}

export const OriginationSourceLabel = new Map<number, string>([
	[OriginationSource.LoanApp, 'LoanApp'],
	[OriginationSource.ApprovalInPrinciple, 'Approval In Principle']
]);

export enum AuState {
	ACT = 1000,
	NSW = 1001,
	NT = 1002,
	QLD = 1003,
	SA = 1004,
	TAS = 1005,
	VIC = 1006,
	WA = 1007
}

export const AuStateLabel = new Map<number, string>([
	[AuState.ACT, 'ACT'],
	[AuState.NSW, 'NSW'],
	[AuState.NT, 'NT'],
	[AuState.QLD, 'QLD'],
	[AuState.SA, 'SA'],
	[AuState.TAS, 'TAS'],
	[AuState.VIC, 'VIC'],
	[AuState.WA, 'WA']
]);

export enum FrequencyShort {
	Fortnightly = 1000,
	Monthly = 1001,
	Weekly = 1002,
	Yearly = 1003
}

export const FrequencyShortLabel = new Map<number, string>([
	[FrequencyShort.Fortnightly, 'Fortnightly'],
	[FrequencyShort.Monthly, 'Monthly'],
	[FrequencyShort.Weekly, 'Weekly'],
	[FrequencyShort.Yearly, 'Yearly']
]);

export enum FrequencyFull {
	//TODO: some of these have been removed for homestar, we need to use the meta data to exclude items when we implement the next customer

	Fortnightly = 1000,
	Monthly = 1001,
	Weekly = 1002,
	Yearly = 1003,
	Quarterly = 1005,
	HalfYearly = 1008
}

export const FrequencyFullLabel = new Map<number, string>([
	[FrequencyFull.Fortnightly, 'Fortnightly'],
	[FrequencyFull.Monthly, 'Monthly'],
	[FrequencyFull.Weekly, 'Weekly'],
	[FrequencyFull.Yearly, 'Yearly'],
	[FrequencyFull.Quarterly, 'Quarterly'],
	[FrequencyFull.HalfYearly, 'Half Yearly']
]);

export enum RepaymentFrequency {
	Daily = 1000,
	Fortnightly = 1001,
	HalfYearly = 1002,
	Monthly = 1003,
	OneOff = 1004,
	Quarterly = 1005,
	Seasonal = 1006,
	Weekly = 1007,
	Yearly = 1008
}

export const RepaymentFrequencyLabel = new Map<number, string>([
	[RepaymentFrequency.Daily, 'Daily'],
	[RepaymentFrequency.Fortnightly, 'Fortnightly'],
	[RepaymentFrequency.HalfYearly, 'Half Yearly'],
	[RepaymentFrequency.Monthly, 'Monthly'],
	[RepaymentFrequency.OneOff, 'One Off'],
	[RepaymentFrequency.Quarterly, 'Quarterly'],
	[RepaymentFrequency.Seasonal, 'Seasonal'],
	[RepaymentFrequency.Weekly, 'Weekly'],
	[RepaymentFrequency.Yearly, 'Yearly']
]);

export enum EmploymentStatus {
	Previous = 1000,
	Primary = 1001,
	Secondary = 1002
}

export const EmploymentStatusLabel = new Map<number, string>([
	[EmploymentStatus.Previous, 'Previous'],
	[EmploymentStatus.Primary, 'Primary'],
	[EmploymentStatus.Secondary, 'Secondary']
]);

export enum NameTitle {
	Mr = 1004,
	Ms = 1006,
	Mrs = 1005,
	Dr = 1000,
	Prof = 1008
}

export const NameTitleLabel = new Map<number, string>([
	[NameTitle.Mr, 'Mr'],
	[NameTitle.Ms, 'Ms'],
	[NameTitle.Mrs, 'Mrs'],
	[NameTitle.Dr, 'Dr'],
	[NameTitle.Prof, 'Prof']
]);

export enum PaygBasis {
	FullTime = 1003,
	PartTime = 1004,
	Casual = 1000,
	Contract = 1002,
	CommissionOnly = 1001,
	Seasonal = 1005,
	Temporary = 1006
}

export const PaygBasisLabel = new Map<number, string>([
	[PaygBasis.FullTime, 'Full Time'],
	[PaygBasis.PartTime, 'Part Time'],
	[PaygBasis.Casual, 'Casual'],
	[PaygBasis.Contract, 'Contract'],
	[PaygBasis.CommissionOnly, 'Commission Only'],
	[PaygBasis.Seasonal, 'Seasonal'],
	[PaygBasis.Temporary, 'Temporary']
]);

export enum FinancialAssetType {
	ManagedFunds = 1002,
	PensionAccount = 1004,
	SavingsAccount = 1006,
	SharesandDebentures = 1007,
	Superannuation = 1008,
	TermDeposit = 1009,
	Other = 1003
}

export const FinancialAssetTypeLabel = new Map<number, string>([
	[FinancialAssetType.ManagedFunds, 'Managed Funds'],
	[FinancialAssetType.PensionAccount, 'Pension Account'],
	[FinancialAssetType.SavingsAccount, 'Savings Account'],
	[FinancialAssetType.SharesandDebentures, 'Shares and Debentures'],
	[FinancialAssetType.Superannuation, 'Superannuation'],
	[FinancialAssetType.TermDeposit, 'Term Deposit'],
	[FinancialAssetType.Other, 'Other']
]);

export enum RbaLendingPurpose {
	Construction = 1000,
	FurtherAdvance = 1001,
	PurchaseExistingDwelling = 1003,
	PurchaseNewDwelling = 1004,
	PurchaseOther = 1005,
	Refinance = 1006,
	Other = 1002
}

export const RbaLendingPurposeLabel = new Map<number, string>([
	[RbaLendingPurpose.Construction, 'Construction'],
	[RbaLendingPurpose.FurtherAdvance, 'Further Advance'],
	[RbaLendingPurpose.PurchaseExistingDwelling, 'Purchase - Existing Dwelling'],
	[RbaLendingPurpose.PurchaseNewDwelling, 'Purchase - New Dwelling'],
	[RbaLendingPurpose.PurchaseOther, 'Purchase - Other'],
	[RbaLendingPurpose.Refinance, 'Refinance'],
	[RbaLendingPurpose.Other, 'Other']
]);

export enum PrimaryUsage {
	Commercial = 1000,
	Industrial = 1001,
	Residential = 1002,
	Rural = 1003
}

export const PrimaryUsageLabel = new Map<number, string>([
	[PrimaryUsage.Commercial, 'Commercial'],
	[PrimaryUsage.Industrial, 'Industrial'],
	[PrimaryUsage.Residential, 'Residential'],
	[PrimaryUsage.Rural, 'Rural']
]);

export enum PrimaryPurposeLoanPurpose {
	Business = 1000,
	InvestmentNonResidential = 1001,
	InvestmentResidential = 1002,
	OwnerOccupied = 1003,
	Personal = 1004
}

export const PrimaryPurposeLoanPurposeLabel = new Map<number, string>([
	[PrimaryPurposeLoanPurpose.Business, 'Business'],
	[PrimaryPurposeLoanPurpose.InvestmentNonResidential, 'Investment Non Residential'],
	[PrimaryPurposeLoanPurpose.InvestmentResidential, 'Investment Residential'],
	[PrimaryPurposeLoanPurpose.OwnerOccupied, 'Owner Occupied'],
	[PrimaryPurposeLoanPurpose.Personal, 'Personal']
]);

export enum LivingExpenseCategory {
	BodyCorporateFeesAndTax = 1000,
	Childcare = 1001,
	ClothingAndPersonalcare = 1002,
	Education = 1003,
	GeneralBasicInsurances = 1004,
	Groceries = 1005,
	HealthInsurance = 1006,
	HigherEducation = 1007,
	Insurance = 1008,
	InvestmentPropertyRunningCosts = 1009,
	InvestmentPropertyUtilitiesAndRates = 1010,
	MedicalAndHealth = 1011,
	Other = 1012,
	OwnerOccupiedPropertyUtilitiesAndCosts = 1013,
	PetCare = 1014,
	PrimaryResidenceRunningCosts = 1015,
	PrivateSchoolingandTuition = 1016,
	PublicOrGovernmentPrimaryAndSecondaryEducation = 1017,
	RecreationAndEntertainment = 1018,
	SecondaryResidenceRunningCosts = 1019,
	SicknessAndPersonalAccidentAndLifeInsurance = 1020,
	TelephoneInternetPayTV = 1021,
	Transport = 1022
}

export const LivingExpenseCategoryLabel = new Map<number, string>([
	[
		LivingExpenseCategory.BodyCorporateFeesAndTax,
		'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence'
	],
	[LivingExpenseCategory.Childcare, 'Childcare'],
	[LivingExpenseCategory.ClothingAndPersonalcare, 'Clothing and personal care'],
	[LivingExpenseCategory.Education, 'Education'],
	[LivingExpenseCategory.GeneralBasicInsurances, 'General Basic Insurances'],
	[LivingExpenseCategory.Groceries, 'Groceries'],
	[LivingExpenseCategory.HealthInsurance, 'Health Insurance'],
	[LivingExpenseCategory.HigherEducation, 'Higher Education, Vocational Training and Professional Fees'],
	[LivingExpenseCategory.Insurance, 'Insurance'],
	[LivingExpenseCategory.InvestmentPropertyRunningCosts, 'Investment Property Running Costs'],
	[LivingExpenseCategory.InvestmentPropertyUtilitiesAndRates, 'Investment property utilities, rates and related costs'],
	[LivingExpenseCategory.MedicalAndHealth, 'Medical and health'],
	[LivingExpenseCategory.Other, 'Other'],
	[
		LivingExpenseCategory.OwnerOccupiedPropertyUtilitiesAndCosts,
		'Owner occupied property utilities, rates and related costs'
	],
	[LivingExpenseCategory.PetCare, 'Pet Care'],
	[LivingExpenseCategory.PrimaryResidenceRunningCosts, 'Primary Residence Running Costs'],
	[LivingExpenseCategory.PrivateSchoolingandTuition, 'Private Schooling and Tuition'],
	[
		LivingExpenseCategory.PublicOrGovernmentPrimaryAndSecondaryEducation,
		'Public or Government Primary and Secondary Education'
	],
	[LivingExpenseCategory.RecreationAndEntertainment, 'Recreation and entertainment'],
	[LivingExpenseCategory.SecondaryResidenceRunningCosts, 'Secondary Residence Running Costs'],
	[
		LivingExpenseCategory.SicknessAndPersonalAccidentAndLifeInsurance,
		'Sickness and Personal Accident Insurance, Life Insurance'
	],
	[LivingExpenseCategory.TelephoneInternetPayTV, 'Telephone, internet, pay TV and media streaming subscriptions'],
	[LivingExpenseCategory.Transport, 'Transport']
]);

export enum ApplicantType {
	Borrower = 1000,
	BorrowerandGuarantor = 1001,
	Guarantor = 1002
}

export const ApplicantTypeLabel = new Map<number, string>([
	[ApplicantType.Borrower, 'Borrower'],
	[ApplicantType.BorrowerandGuarantor, 'Borrower and Guarantor'],
	[ApplicantType.Guarantor, 'Guarantor']
]);

export enum OtherAssetType {
	ArtWorks = 1000,
	GoodWillofBusiness = 1001,
	HomeContents = 1002,
	IntellectualProperty = 1003,
	StockandInventory = 1005,
	Other = 1004
}

export const OtherAssetTypeLabel = new Map<number, string>([
	[OtherAssetType.ArtWorks, 'Art Works'],
	[OtherAssetType.GoodWillofBusiness, 'Good Will of Business'],
	[OtherAssetType.HomeContents, 'Home Contents'],
	[OtherAssetType.IntellectualProperty, 'Intellectual Property'],
	[OtherAssetType.StockandInventory, 'Stock and Inventory'],
	[OtherAssetType.Other, 'Other']
]);

export enum FinancialInstitution {
	_86400 = 1000,
	AdelaideBank = 1004,
	AFGHomeLoans = 1006,
	AMP = 1015,
	ANZBank = 1017,
	ArabBankofAustralia = 1021,
	AthenaHomeLoans = 1024,
	AussieHomeLoans = 1025,
	BankAustralia = 1044,
	BankofMelbourne = 1047,
	BankSA = 1050,
	Bankwest = 1052,
	BendigoBank = 1057,
	BeyondBankAustralia = 1061,
	Bluestone = 1063,
	BOQ = 1065,
	CBA = 1077,
	CUA = 1113,
	GreaterBank = 1171,
	HSBC = 1182,
	IMBLtd = 1188,
	ING = 1190,
	Liberty = 1203,
	Macquarie = 1212,
	MEBank = 1216,
	MortgageEzy = 1232,
	MortgageHouse = 1233,
	MortgageMart = 1234,
	NAB = 1238,
	PeoplesChoiceCreditUnion = 1257,
	Pepper = 1258,
	QudosBank = 1275,
	StGeorge = 1307,
	Suncorp = 1314,
	TeachersMutual = 1320,
	TictocOnline = 1324,
	UBank = 1330,
	WestpacBank = 1342,
	Wizard = 1345,
	Other = 1252
}

export const FinancialInstitutionLabel = new Map<number, string>([
	[FinancialInstitution._86400, '86 400'],
	[FinancialInstitution.AdelaideBank, 'Adelaide Bank'],
	[FinancialInstitution.AFGHomeLoans, 'AFG Home Loans'],
	[FinancialInstitution.AMP, 'AMP'],
	[FinancialInstitution.ANZBank, 'ANZ Bank'],
	[FinancialInstitution.ArabBankofAustralia, 'Arab Bank of Australia'],
	[FinancialInstitution.AthenaHomeLoans, 'Athena Home Loans'],
	[FinancialInstitution.AussieHomeLoans, 'Aussie Home Loans'],
	[FinancialInstitution.BankAustralia, 'Bank Australia'],
	[FinancialInstitution.BankofMelbourne, 'Bank of Melbourne'],
	[FinancialInstitution.BankSA, 'Bank SA'],
	[FinancialInstitution.Bankwest, 'Bankwest'],
	[FinancialInstitution.BendigoBank, 'Bendigo Bank'],
	[FinancialInstitution.BeyondBankAustralia, 'Beyond Bank Australia'],
	[FinancialInstitution.Bluestone, 'Bluestone'],
	[FinancialInstitution.BOQ, 'BOQ'],
	[FinancialInstitution.CBA, 'CBA'],
	[FinancialInstitution.CUA, 'CUA'],
	[FinancialInstitution.GreaterBank, 'Greater Bank'],
	[FinancialInstitution.HSBC, 'HSBC'],
	[FinancialInstitution.IMBLtd, 'IMB Ltd'],
	[FinancialInstitution.ING, 'ING'],
	[FinancialInstitution.Liberty, 'Liberty'],
	[FinancialInstitution.Macquarie, 'Macquarie'],
	[FinancialInstitution.MEBank, 'ME Bank'],
	[FinancialInstitution.MortgageEzy, 'Mortgage Ezy'],
	[FinancialInstitution.MortgageHouse, 'Mortgage House'],
	[FinancialInstitution.MortgageMart, 'Mortgage Mart'],
	[FinancialInstitution.NAB, 'NAB'],
	[FinancialInstitution.PeoplesChoiceCreditUnion, "People's Choice Credit Union"],
	[FinancialInstitution.Pepper, 'Pepper'],
	[FinancialInstitution.QudosBank, 'Qudos Bank'],
	[FinancialInstitution.StGeorge, 'St George'],
	[FinancialInstitution.Suncorp, 'Suncorp'],
	[FinancialInstitution.TeachersMutual, 'Teachers Mutual'],
	[FinancialInstitution.TictocOnline, 'Tictoc Online'],
	[FinancialInstitution.UBank, 'UBank'],
	[FinancialInstitution.WestpacBank, 'Westpac Bank'],
	[FinancialInstitution.Wizard, 'Wizard'],
	[FinancialInstitution.Other, 'Other']
]);

export enum LiabilityType {
	BridgingFinance = 1000,
	CarLoan = 1001,
	CommercialBill = 1002,
	ContingentLiability = 1003,
	CreditCard = 1004,
	HECSHELP = 1005,
	HirePurchase = 1006,
	InvoiceFinancingLoan = 1007,
	Lease = 1008,
	LineofCredit = 1009,
	LoanasGuarantor = 1010,
	MarginLoan = 1011,
	MortgageLoan = 1012,
	Other = 1013,
	OtherLoan = 1014,
	OutstandingTaxation = 1015,
	Overdraft = 1016,
	PersonalLoan = 1017,
	StoreCard = 1018,
	TermLoan = 1019,
	TradeFinanceLoan = 1020
}

export const LiabilityTypeLabel = new Map<number, string>([
	[LiabilityType.BridgingFinance, 'Bridging Finance'],
	[LiabilityType.CarLoan, 'Car Loan'],
	[LiabilityType.CommercialBill, 'Commercial Bill'],
	[LiabilityType.ContingentLiability, 'Contingent Liability'],
	[LiabilityType.CreditCard, 'Credit Card'],
	[LiabilityType.HECSHELP, 'HECS-HELP'],
	[LiabilityType.HirePurchase, 'Hire Purchase'],
	[LiabilityType.InvoiceFinancingLoan, 'Invoice Financing Loan'],
	[LiabilityType.Lease, 'Lease'],
	[LiabilityType.LineofCredit, 'Line of Credit'],
	[LiabilityType.LoanasGuarantor, 'Loan as Guarantor'],
	[LiabilityType.MarginLoan, 'Margin Loan'],
	[LiabilityType.MortgageLoan, 'Mortgage Loan'],
	[LiabilityType.Other, 'Other'],
	[LiabilityType.OtherLoan, 'Other Loan'],
	[LiabilityType.OutstandingTaxation, 'Outstanding Taxation'],
	[LiabilityType.Overdraft, 'Overdraft'],
	[LiabilityType.PersonalLoan, 'Personal Loan'],
	[LiabilityType.StoreCard, 'Store Card'],
	[LiabilityType.TermLoan, 'Term Loan'],
	[LiabilityType.TradeFinanceLoan, 'Trade Finance Loan']
]);

export enum MaritalStatus {
	Single = 1004,
	Married = 1002,
	DeFacto = 1000,
	Separated = 1003,
	Divorced = 1001,
	Widowed = 1006,
	Unknown = 1005
}

export const MaritalStatusLabel = new Map<number, string>([
	[MaritalStatus.Single, 'Single'],
	[MaritalStatus.Married, 'Married'],
	[MaritalStatus.DeFacto, 'De Facto'],
	[MaritalStatus.Separated, 'Separated'],
	[MaritalStatus.Divorced, 'Divorced'],
	[MaritalStatus.Widowed, 'Widowed'],
	[MaritalStatus.Unknown, 'Other']
]);

export enum ContactEmailType {
	Home = 1000,
	Work = 1001
}

export const ContactEmailTypeLabel = new Map<number, string>([
	[ContactEmailType.Home, 'Home'],
	[ContactEmailType.Work, 'Work']
]);

export enum StreetType {
	Access = 1000,
	Alley = 1001,
	Alleyway = 1002,
	Amble = 1003,
	Anchorage = 1004,
	Approach = 1005,
	Arcade = 1006,
	Arterial = 1007,
	Artery = 1008,
	Avenue = 1009,
	Banan = 1010,
	Bank = 1011,
	Basin = 1012,
	Bay = 1013,
	Beach = 1014,
	Bend = 1015,
	Block = 1016,
	Boardwalk = 1017,
	Boulevard = 1018,
	Boulevarde = 1019,
	Bowl = 1020,
	Brace = 1021,
	Brae = 1022,
	Break = 1023,
	Bridge = 1024,
	Broadway = 1025,
	Brow = 1026,
	Bypass = 1027,
	Byway = 1028,
	Canal = 1029,
	Causeway = 1030,
	Centre = 1031,
	Centreway = 1032,
	Chase = 1033,
	Circle = 1034,
	Circlet = 1035,
	Circuit = 1036,
	Circus = 1037,
	Close = 1038,
	Cluster = 1039,
	Colonnade = 1040,
	Common = 1041,
	Concourse = 1042,
	Connection = 1043,
	Copse = 1044,
	Corner = 1045,
	Corso = 1046,
	Course = 1047,
	Court = 1048,
	Courtyard = 1049,
	Cove = 1050,
	Crescent = 1051,
	Crest = 1052,
	Cross = 1053,
	Crossing = 1054,
	Crossroad = 1055,
	Crossway = 1056,
	Cruiseway = 1057,
	CulDeSac = 1058,
	Cutting = 1059,
	Dale = 1060,
	Dell = 1061,
	Dene = 1062,
	Deviation = 1063,
	Dip = 1064,
	Distributor = 1065,
	Divide = 1066,
	Dock = 1067,
	Domain = 1068,
	Drive = 1069,
	Driveway = 1070,
	Edge = 1071,
	Elbow = 1072,
	End = 1073,
	Entrance = 1074,
	Esplanade = 1075,
	Estate = 1076,
	Estuary = 1077,
	Expressway = 1078,
	Extension = 1079,
	Fairway = 1080,
	FireTrack = 1081,
	Firebreak = 1082,
	Fireline = 1083,
	Firetrack = 1084,
	Firetrail = 1085,
	Flat = 1086,
	Follow = 1087,
	Footway = 1088,
	Ford = 1089,
	Foreshore = 1090,
	Formation = 1091,
	Freeway = 1092,
	Front = 1093,
	Frontage = 1094,
	Gap = 1095,
	Garden = 1096,
	Gardens = 1097,
	Gate = 1098,
	Gates = 1099,
	Gateway = 1100,
	Glade = 1101,
	Glen = 1102,
	Grange = 1103,
	Green = 1104,
	Ground = 1105,
	Grove = 1106,
	Gully = 1107,
	Harbour = 1108,
	Heath = 1109,
	Heights = 1110,
	Highroad = 1111,
	Highway = 1112,
	Hill = 1113,
	Hollow = 1114,
	Hub = 1115,
	Interchange = 1116,
	Intersection = 1117,
	Junction = 1118,
	Key = 1119,
	Keys = 1120,
	Landing = 1121,
	Lane = 1122,
	Laneway = 1123,
	Lees = 1124,
	Line = 1125,
	Link = 1126,
	Little = 1127,
	Lookout = 1128,
	Loop = 1129,
	Lower = 1130,
	Mall = 1131,
	Manor = 1132,
	Meander = 1133,
	Mears = 1134,
	Mew = 1135,
	Mews = 1136,
	Mile = 1137,
	Motorway = 1138,
	Mount = 1139,
	Nook = 1140,
	Outlet = 1141,
	Outlook = 1142,
	Parade = 1143,
	Park = 1144,
	Parklands = 1145,
	Parkway = 1146,
	Part = 1147,
	Pass = 1148,
	Path = 1149,
	Pathway = 1150,
	Piazza = 1151,
	Pier = 1152,
	Place = 1153,
	Plateau = 1154,
	Plaza = 1155,
	Pocket = 1156,
	Point = 1157,
	Port = 1158,
	Portal = 1159,
	Promenade = 1160,
	Pursuit = 1161,
	Quad = 1162,
	Quadrangle = 1163,
	Quadrant = 1164,
	Quay = 1165,
	Quays = 1166,
	Ramble = 1167,
	Ramp = 1168,
	Range = 1169,
	Reach = 1170,
	Reserve = 1171,
	Rest = 1172,
	Retreat = 1173,
	Return = 1174,
	Ride = 1175,
	Ridge = 1176,
	Ridgeway = 1177,
	RightOfWay = 1178,
	Ring = 1179,
	Rise = 1180,
	Rising = 1181,
	River = 1182,
	Riverway = 1183,
	Riviera = 1184,
	Road = 1185,
	Roads = 1186,
	Roadside = 1187,
	Roadway = 1188,
	Ronde = 1189,
	Rosebowl = 1190,
	Rotary = 1191,
	Round = 1192,
	Route = 1193,
	Row = 1194,
	Rue = 1195,
	Run = 1196,
	ServiceWay = 1197,
	Shunt = 1198,
	Siding = 1199,
	Slope = 1200,
	Sound = 1201,
	Spur = 1202,
	Square = 1203,
	Stairs = 1204,
	StateHighway = 1205,
	Steps = 1206,
	Strand = 1207,
	Street = 1208,
	Streets = 1209,
	Strip = 1210,
	Subway = 1211,
	Tarn = 1212,
	TarniceWay = 1213,
	Terrace = 1214,
	Thoroughfare = 1215,
	Throughway = 1216,
	Tollway = 1217,
	Top = 1218,
	Tor = 1219,
	Towers = 1220,
	Track = 1221,
	Trail = 1222,
	Trailer = 1223,
	Triangle = 1224,
	Trunkway = 1225,
	Turn = 1226,
	Twist = 1227,
	Underpass = 1228,
	Upper = 1229,
	Vale = 1230,
	Viaduct = 1231,
	View = 1232,
	Villas = 1233,
	Vista = 1234,
	Wade = 1235,
	Walk = 1236,
	Walkway = 1237,
	Waterway = 1238,
	Way = 1239,
	Wharf = 1240,
	Woods = 1241,
	Wynd = 1242,
	Yard = 1243
}

export const StreetTypeLabel = new Map<number, string>([
	[StreetType.Access, 'Access'],
	[StreetType.Alley, 'Alley'],
	[StreetType.Alleyway, 'Alleyway'],
	[StreetType.Amble, 'Amble'],
	[StreetType.Anchorage, 'Anchorage'],
	[StreetType.Approach, 'Approach'],
	[StreetType.Arcade, 'Arcade'],
	[StreetType.Arterial, 'Arterial'],
	[StreetType.Artery, 'Artery'],
	[StreetType.Avenue, 'Avenue'],
	[StreetType.Banan, 'Banan'],
	[StreetType.Bank, 'Bank'],
	[StreetType.Basin, 'Basin'],
	[StreetType.Bay, 'Bay'],
	[StreetType.Beach, 'Beach'],
	[StreetType.Bend, 'Bend'],
	[StreetType.Block, 'Block'],
	[StreetType.Boardwalk, 'Boardwalk'],
	[StreetType.Boulevard, 'Boulevard'],
	[StreetType.Boulevarde, 'Boulevarde'],
	[StreetType.Bowl, 'Bowl'],
	[StreetType.Brace, 'Brace'],
	[StreetType.Brae, 'Brae'],
	[StreetType.Break, 'Break'],
	[StreetType.Bridge, 'Bridge'],
	[StreetType.Broadway, 'Broadway'],
	[StreetType.Brow, 'Brow'],
	[StreetType.Bypass, 'Bypass'],
	[StreetType.Byway, 'Byway'],
	[StreetType.Canal, 'Canal'],
	[StreetType.Causeway, 'Causeway'],
	[StreetType.Centre, 'Centre'],
	[StreetType.Centreway, 'Centreway'],
	[StreetType.Chase, 'Chase'],
	[StreetType.Circle, 'Circle'],
	[StreetType.Circlet, 'Circlet'],
	[StreetType.Circuit, 'Circuit'],
	[StreetType.Circus, 'Circus'],
	[StreetType.Close, 'Close'],
	[StreetType.Cluster, 'Cluster'],
	[StreetType.Colonnade, 'Colonnade'],
	[StreetType.Common, 'Common'],
	[StreetType.Concourse, 'Concourse'],
	[StreetType.Connection, 'Connection'],
	[StreetType.Copse, 'Copse'],
	[StreetType.Corner, 'Corner'],
	[StreetType.Corso, 'Corso'],
	[StreetType.Course, 'Course'],
	[StreetType.Court, 'Court'],
	[StreetType.Courtyard, 'Courtyard'],
	[StreetType.Cove, 'Cove'],
	[StreetType.Crescent, 'Crescent'],
	[StreetType.Crest, 'Crest'],
	[StreetType.Cross, 'Cross'],
	[StreetType.Crossing, 'Crossing'],
	[StreetType.Crossroad, 'Crossroad'],
	[StreetType.Crossway, 'Crossway'],
	[StreetType.Cruiseway, 'Cruiseway'],
	[StreetType.CulDeSac, 'Cul-De-Sac'],
	[StreetType.Cutting, 'Cutting'],
	[StreetType.Dale, 'Dale'],
	[StreetType.Dell, 'Dell'],
	[StreetType.Dene, 'Dene'],
	[StreetType.Deviation, 'Deviation'],
	[StreetType.Dip, 'Dip'],
	[StreetType.Distributor, 'Distributor'],
	[StreetType.Divide, 'Divide'],
	[StreetType.Dock, 'Dock'],
	[StreetType.Domain, 'Domain'],
	[StreetType.Drive, 'Drive'],
	[StreetType.Driveway, 'Driveway'],
	[StreetType.Edge, 'Edge'],
	[StreetType.Elbow, 'Elbow'],
	[StreetType.End, 'End'],
	[StreetType.Entrance, 'Entrance'],
	[StreetType.Esplanade, 'Esplanade'],
	[StreetType.Estate, 'Estate'],
	[StreetType.Estuary, 'Estuary'],
	[StreetType.Expressway, 'Expressway'],
	[StreetType.Extension, 'Extension'],
	[StreetType.Fairway, 'Fairway'],
	[StreetType.FireTrack, 'Fire Track'],
	[StreetType.Firebreak, 'Firebreak'],
	[StreetType.Fireline, 'Fireline'],
	[StreetType.Firetrack, 'Firetrack'],
	[StreetType.Firetrail, 'Firetrail'],
	[StreetType.Flat, 'Flat'],
	[StreetType.Follow, 'Follow'],
	[StreetType.Footway, 'Footway'],
	[StreetType.Ford, 'Ford'],
	[StreetType.Foreshore, 'Foreshore'],
	[StreetType.Formation, 'Formation'],
	[StreetType.Freeway, 'Freeway'],
	[StreetType.Front, 'Front'],
	[StreetType.Frontage, 'Frontage'],
	[StreetType.Gap, 'Gap'],
	[StreetType.Garden, 'Garden'],
	[StreetType.Gardens, 'Gardens'],
	[StreetType.Gate, 'Gate'],
	[StreetType.Gates, 'Gates'],
	[StreetType.Gateway, 'Gateway'],
	[StreetType.Glade, 'Glade'],
	[StreetType.Glen, 'Glen'],
	[StreetType.Grange, 'Grange'],
	[StreetType.Green, 'Green'],
	[StreetType.Ground, 'Ground'],
	[StreetType.Grove, 'Grove'],
	[StreetType.Gully, 'Gully'],
	[StreetType.Harbour, 'Harbour'],
	[StreetType.Heath, 'Heath'],
	[StreetType.Heights, 'Heights'],
	[StreetType.Highroad, 'Highroad'],
	[StreetType.Highway, 'Highway'],
	[StreetType.Hill, 'Hill'],
	[StreetType.Hollow, 'Hollow'],
	[StreetType.Hub, 'Hub'],
	[StreetType.Interchange, 'Interchange'],
	[StreetType.Intersection, 'Intersection'],
	[StreetType.Junction, 'Junction'],
	[StreetType.Key, 'Key'],
	[StreetType.Keys, 'Keys'],
	[StreetType.Landing, 'Landing'],
	[StreetType.Lane, 'Lane'],
	[StreetType.Laneway, 'Laneway'],
	[StreetType.Lees, 'Lees'],
	[StreetType.Line, 'Line'],
	[StreetType.Link, 'Link'],
	[StreetType.Little, 'Little'],
	[StreetType.Lookout, 'Lookout'],
	[StreetType.Loop, 'Loop'],
	[StreetType.Lower, 'Lower'],
	[StreetType.Mall, 'Mall'],
	[StreetType.Manor, 'Manor'],
	[StreetType.Meander, 'Meander'],
	[StreetType.Mears, 'Mears'],
	[StreetType.Mew, 'Mew'],
	[StreetType.Mews, 'Mews'],
	[StreetType.Mile, 'Mile'],
	[StreetType.Motorway, 'Motorway'],
	[StreetType.Mount, 'Mount'],
	[StreetType.Nook, 'Nook'],
	[StreetType.Outlet, 'Outlet'],
	[StreetType.Outlook, 'Outlook'],
	[StreetType.Parade, 'Parade'],
	[StreetType.Park, 'Park'],
	[StreetType.Parklands, 'Parklands'],
	[StreetType.Parkway, 'Parkway'],
	[StreetType.Part, 'Part'],
	[StreetType.Pass, 'Pass'],
	[StreetType.Path, 'Path'],
	[StreetType.Pathway, 'Pathway'],
	[StreetType.Piazza, 'Piazza'],
	[StreetType.Pier, 'Pier'],
	[StreetType.Place, 'Place'],
	[StreetType.Plateau, 'Plateau'],
	[StreetType.Plaza, 'Plaza'],
	[StreetType.Pocket, 'Pocket'],
	[StreetType.Point, 'Point'],
	[StreetType.Port, 'Port'],
	[StreetType.Portal, 'Portal'],
	[StreetType.Promenade, 'Promenade'],
	[StreetType.Pursuit, 'Pursuit'],
	[StreetType.Quad, 'Quad'],
	[StreetType.Quadrangle, 'Quadrangle'],
	[StreetType.Quadrant, 'Quadrant'],
	[StreetType.Quay, 'Quay'],
	[StreetType.Quays, 'Quays'],
	[StreetType.Ramble, 'Ramble'],
	[StreetType.Ramp, 'Ramp'],
	[StreetType.Range, 'Range'],
	[StreetType.Reach, 'Reach'],
	[StreetType.Reserve, 'Reserve'],
	[StreetType.Rest, 'Rest'],
	[StreetType.Retreat, 'Retreat'],
	[StreetType.Return, 'Return'],
	[StreetType.Ride, 'Ride'],
	[StreetType.Ridge, 'Ridge'],
	[StreetType.Ridgeway, 'Ridgeway'],
	[StreetType.RightOfWay, 'Right Of Way'],
	[StreetType.Ring, 'Ring'],
	[StreetType.Rise, 'Rise'],
	[StreetType.Rising, 'Rising'],
	[StreetType.River, 'River'],
	[StreetType.Riverway, 'Riverway'],
	[StreetType.Riviera, 'Riviera'],
	[StreetType.Road, 'Road'],
	[StreetType.Roads, 'Roads'],
	[StreetType.Roadside, 'Roadside'],
	[StreetType.Roadway, 'Roadway'],
	[StreetType.Ronde, 'Ronde'],
	[StreetType.Rosebowl, 'Rosebowl'],
	[StreetType.Rotary, 'Rotary'],
	[StreetType.Round, 'Round'],
	[StreetType.Route, 'Route'],
	[StreetType.Row, 'Row'],
	[StreetType.Rue, 'Rue'],
	[StreetType.Run, 'Run'],
	[StreetType.ServiceWay, 'Service Way'],
	[StreetType.Shunt, 'Shunt'],
	[StreetType.Siding, 'Siding'],
	[StreetType.Slope, 'Slope'],
	[StreetType.Sound, 'Sound'],
	[StreetType.Spur, 'Spur'],
	[StreetType.Square, 'Square'],
	[StreetType.Stairs, 'Stairs'],
	[StreetType.StateHighway, 'State Highway'],
	[StreetType.Steps, 'Steps'],
	[StreetType.Strand, 'Strand'],
	[StreetType.Street, 'Street'],
	[StreetType.Streets, 'Streets'],
	[StreetType.Strip, 'Strip'],
	[StreetType.Subway, 'Subway'],
	[StreetType.Tarn, 'Tarn'],
	[StreetType.TarniceWay, 'Tarnice Way'],
	[StreetType.Terrace, 'Terrace'],
	[StreetType.Thoroughfare, 'Thoroughfare'],
	[StreetType.Throughway, 'Throughway'],
	[StreetType.Tollway, 'Tollway'],
	[StreetType.Top, 'Top'],
	[StreetType.Tor, 'Tor'],
	[StreetType.Towers, 'Towers'],
	[StreetType.Track, 'Track'],
	[StreetType.Trail, 'Trail'],
	[StreetType.Trailer, 'Trailer'],
	[StreetType.Triangle, 'Triangle'],
	[StreetType.Trunkway, 'Trunkway'],
	[StreetType.Turn, 'Turn'],
	[StreetType.Twist, 'Twist'],
	[StreetType.Underpass, 'Underpass'],
	[StreetType.Upper, 'Upper'],
	[StreetType.Vale, 'Vale'],
	[StreetType.Viaduct, 'Viaduct'],
	[StreetType.View, 'View'],
	[StreetType.Villas, 'Villas'],
	[StreetType.Vista, 'Vista'],
	[StreetType.Wade, 'Wade'],
	[StreetType.Walk, 'Walk'],
	[StreetType.Walkway, 'Walkway'],
	[StreetType.Waterway, 'Waterway'],
	[StreetType.Way, 'Way'],
	[StreetType.Wharf, 'Wharf'],
	[StreetType.Woods, 'Woods'],
	[StreetType.Wynd, 'Wynd'],
	[StreetType.Yard, 'Yard']
]);

export enum CountryCodeLimited {
	AU = 1012,
	NZ = 1170
}

export const CountryCodeLimitedLabel = new Map<number, string>([
	[CountryCodeLimited.AU, 'Australia'],
	[CountryCodeLimited.NZ, 'New Zealand']
]);

export enum AddressType {
	DXBox = 1000,
	NonStandard = 1001,
	POBoxorBag = 1002,
	Standard = 1003
}

export const AddressTypeLabel = new Map<number, string>([
	[AddressType.DXBox, 'DX Box'],
	[AddressType.NonStandard, 'Non Standard'],
	[AddressType.POBoxorBag, 'PO Box or Bag'],
	[AddressType.Standard, 'Standard']
]);

export enum CreditCardType {
	AmericanExpress = 1000,
	BankCard = 1001,
	DinersClub = 1002,
	MasterCard = 1003,
	Visa = 1005,
	OtherCreditCard = 1004
}

export const CreditCardTypeLabel = new Map<number, string>([
	[CreditCardType.AmericanExpress, 'American Express'],
	[CreditCardType.BankCard, 'Bank Card'],
	[CreditCardType.DinersClub, 'Diners Club'],
	[CreditCardType.MasterCard, 'MasterCard'],
	[CreditCardType.Visa, 'Visa'],
	[CreditCardType.OtherCreditCard, 'Other Credit Card']
]);

export enum ResidencyStatus {
	NonResident = 1000,
	PermanentlyinAustralia = 1001,
	TemporarilyinAustralia = 1002
}

export const ResidencyStatusLabel = new Map<number, string>([
	[ResidencyStatus.NonResident, 'Non Resident'],
	[ResidencyStatus.PermanentlyinAustralia, 'Permanently in Australia'],
	[ResidencyStatus.TemporarilyinAustralia, 'Temporarily in Australia']
]);

export enum BusinessStructureFull {
	Company = 1000,
	Partnership = 1001,
	SoleTrader = 1002,
	Trust = 1003
}

export const BusinessStructureFullLabel = new Map<number, string>([
	[BusinessStructureFull.Company, 'Company'],
	[BusinessStructureFull.Partnership, 'Partnership'],
	[BusinessStructureFull.SoleTrader, 'Sole Trader'],
	[BusinessStructureFull.Trust, 'Trust']
]);

export enum OtherIncomeType {
	//TODO: some of these have been removed for homestar, we need to use the meta data to exclude items when we implement the next customer
	ChildMaintenance = 1001,
	Dividends = 1002,
	ForeignSourced = 1003,
	GovernmentBenefits = 1004,
	InterestIncome = 1005,
	PrivatePension = 1008,
	Superannuation = 1013,
	WorkersCompensation = 1014,
	OtherIncome = 1007
}

export const OtherIncomeTypeLabel = new Map<number, string>([
	[OtherIncomeType.ChildMaintenance, 'Child Maintenance'],
	[OtherIncomeType.Dividends, 'Dividends'],
	[OtherIncomeType.ForeignSourced, 'Foreign Sourced'],
	[OtherIncomeType.GovernmentBenefits, 'Government Benefits'],
	[OtherIncomeType.InterestIncome, 'Interest Income'],
	[OtherIncomeType.PrivatePension, 'Private Pension'],
	[OtherIncomeType.Superannuation, 'Superannuation'],
	[OtherIncomeType.WorkersCompensation, 'Workers Compensation'],
	[OtherIncomeType.OtherIncome, 'Other Income']
]);

export enum GovernmentBenefitsType {
	AgePension = 1001,
	ChildSupport = 1004,
	FamilyAllowance = 1007,
	Other = 1009
}

export const GovernmentBenefitsTypeLabel = new Map<number, string>([
	[GovernmentBenefitsType.AgePension, 'Age Pension'],
	[GovernmentBenefitsType.ChildSupport, 'Child Support'],
	[GovernmentBenefitsType.FamilyAllowance, 'Family Allowance'],
	[GovernmentBenefitsType.Other, 'Other']
]);

export enum NonRealEstateAssetType {
	//TODO: some of these have been removed for homestar, we need to use the meta data to exclude items when we implement the next customer

	// Agricultural = 1000,
	// Aircraft = 1001,
	Business = 1002,
	// Cleaning = 1003,
	// EarthMovingMiningAndConstruction = 1004,
	// FinancialAsset = 1005,
	// HospitalityandLeisure = 1006,
	// ITandAVEquipment = 1007,
	// MaterialsHandlingandLifting = 1008,
	// Medical = 1009,
	// MobileComputing = 1010,
	MotorVehicleandTransport = 1011,
	// OfficeEquipment = 1012,
	// PlantEquipmentandIndustrial = 1014,
	// ToolsofTrade = 1015,
	// Watercraft = 1016,
	Other = 1013
}

export const NonRealEstateAssetTypeLabel = new Map<number, string>([
	// [NonRealEstateAssetType.Agricultural, 'Agricultural'],
	// [NonRealEstateAssetType.Aircraft, 'Aircraft'],
	[NonRealEstateAssetType.Business, 'Business'],
	// [NonRealEstateAssetType.Cleaning, 'Cleaning'],
	// [NonRealEstateAssetType.EarthMovingMiningAndConstruction, 'Earth Moving, Mining and Construction'],
	// [NonRealEstateAssetType.FinancialAsset, 'Financial Asset'],
	// [NonRealEstateAssetType.HospitalityandLeisure, 'Hospitality and Leisure'],
	// [NonRealEstateAssetType.ITandAVEquipment, 'IT and AV Equipment'],
	// [NonRealEstateAssetType.MaterialsHandlingandLifting, 'Materials Handling and Lifting'],
	// [NonRealEstateAssetType.Medical, 'Medical'],
	// [NonRealEstateAssetType.MobileComputing, 'Mobile Computing'],
	[NonRealEstateAssetType.MotorVehicleandTransport, 'Motor Vehicle and Transport'],
	// [NonRealEstateAssetType.OfficeEquipment, 'Office Equipment'],
	// [NonRealEstateAssetType.PlantEquipmentandIndustrial, 'Plant, Equipment and Industrial'],
	// [NonRealEstateAssetType.ToolsofTrade, 'Tools of Trade'],
	// [NonRealEstateAssetType.Watercraft, 'Watercraft'],
	[NonRealEstateAssetType.Other, 'Other']
]);

export enum LiabilityTypeLimited {
	//TODO: some of these have been removed for homestar, we need to use the meta data to exclude items when we implement the next customer
	CarLoan = 1001,
	CommercialBill = 1002,
	ContingentLiability = 1003,
	HECSHELP = 1005,
	HirePurchase = 1006,
	Lease = 1008,
	LineofCredit = 1009,
	LoanasGuarantor = 1010,
	MarginLoan = 1011,
	OutstandingTaxation = 1015,
	Overdraft = 1016,
	StoreCard = 1018,
	TermLoan = 1019,
	OtherLoan = 1014,
	Other = 1013
}

export const LiabilityTypeLimitedLabel = new Map<number, string>([
	[LiabilityTypeLimited.CarLoan, 'Car Loan'],
	[LiabilityTypeLimited.CommercialBill, 'Commercial Bill'],
	[LiabilityTypeLimited.ContingentLiability, 'Contingent Liability'],
	[LiabilityTypeLimited.HECSHELP, 'HECS-HELP'],
	[LiabilityTypeLimited.HirePurchase, 'Hire Purchase'],
	[LiabilityTypeLimited.Lease, 'Lease'],
	[LiabilityTypeLimited.LineofCredit, 'Line of Credit'],
	[LiabilityTypeLimited.LoanasGuarantor, 'Loan as Guarantor'],
	[LiabilityTypeLimited.MarginLoan, 'Margin Loan'],
	[LiabilityTypeLimited.OutstandingTaxation, 'Outstanding Taxation'],
	[LiabilityTypeLimited.Overdraft, 'Overdraft'],
	[LiabilityTypeLimited.StoreCard, 'Store Card'],
	[LiabilityTypeLimited.TermLoan, 'Term Loan'],
	[LiabilityTypeLimited.OtherLoan, 'Other Loan'],
	[LiabilityTypeLimited.Other, 'Other']
]);

export enum ExpenseClassificationExpenseClass {
	Children = 1,
	Education = 2,
	Groceries = 3,
	HomeAndUtilities = 4,
	InsuranceAndSuper = 5,
	PersonalAndMedical = 7,
	Pets = 8,
	RecreationAndEntertainment = 9,
	TransportAndVehicles = 10,
	Other = 6
}

export const ExpenseClassificationExpenseClassLabel = new Map<number, string>([
	[ExpenseClassificationExpenseClass.Children, 'Children'],
	[ExpenseClassificationExpenseClass.Education, 'Education'],
	[ExpenseClassificationExpenseClass.Groceries, 'Groceries'],
	[ExpenseClassificationExpenseClass.HomeAndUtilities, 'Home & Utilities'],
	[ExpenseClassificationExpenseClass.InsuranceAndSuper, 'Insurance & Super'],
	[ExpenseClassificationExpenseClass.PersonalAndMedical, 'Personal & Medical'],
	[ExpenseClassificationExpenseClass.Pets, 'Pets'],
	[ExpenseClassificationExpenseClass.RecreationAndEntertainment, 'Recreation & Entertainment'],
	[ExpenseClassificationExpenseClass.TransportAndVehicles, 'Transport & vehicles'],
	[ExpenseClassificationExpenseClass.Other, 'Other']
]);

export enum ClearingFromThisLoan {
	No = 1000,
	Partial = 1001,
	Yes = 1002
}

export const ClearingFromThisLoanLabel = new Map<number, string>([
	[ClearingFromThisLoan.No, 'No'],
	[ClearingFromThisLoan.Partial, 'Partial'],
	[ClearingFromThisLoan.Yes, 'Yes']
]);

export enum Proportions {
	Equal = 1000,
	NotSpecified = 1001,
	Specified = 1002
}

export const ProportionsLabel = new Map<number, string>([
	[Proportions.Equal, 'Equal'],
	[Proportions.NotSpecified, 'Not Specified'],
	[Proportions.Specified, 'Specified']
]);

export enum RentalIncomeBasis {
	ApplicantEstimate = 1000,
	RPData = 1001,
	TenancyAgreement = 1002
}

export const RentalIncomeBasisLabel = new Map<number, string>([
	[RentalIncomeBasis.ApplicantEstimate, 'Applicant Estimate'],
	[RentalIncomeBasis.RPData, 'RP Data'],
	[RentalIncomeBasis.TenancyAgreement, 'Tenancy Agreement']
]);

export enum PrimaryPurposeRealEstateAsset {
	Business = 1000,
	Investment = 1001,
	OwnerOccupied = 1002
}

export const PrimaryPurposeRealEstateAssetLabel = new Map<number, string>([
	[PrimaryPurposeRealEstateAsset.Business, 'Business'],
	[PrimaryPurposeRealEstateAsset.Investment, 'Investment'],
	[PrimaryPurposeRealEstateAsset.OwnerOccupied, 'Owner Occupied']
]);

export enum NumberOfApplicants {
	Single = 1,
	Couple = 2,
	More = 3
}

export const NumberOfApplicantsLabel = new Map<number, string>([
	[NumberOfApplicants.Single, 'Single'],
	[NumberOfApplicants.Couple, 'Couple'],
	[NumberOfApplicants.More, 'More']
]);

export enum ServiceabilityResult {
	Approved = 0,
	Declined = 1
}

export const ServiceabilityResultLabel = new Map<number, string>([
	[ServiceabilityResult.Approved, 'Approved'],
	[ServiceabilityResult.Declined, 'Declined']
]);

export const EnumLabelAuto: EnumMapper[] = [
	//  {
	//    id: 'ReferenceType',
	//    enumType: ReferenceType,
	//    mapping: ReferenceTypeLabel
	//  },
	{
		id: 'ExpenseClassificationExpenseClass',
		enumType: ExpenseClassificationExpenseClass,
		mapping: ExpenseClassificationExpenseClassLabel
	},
	{
		id: 'ExpenseSubClassificationExpenseSubClass',
		enumType: ExpenseSubClassificationExpenseSubClass,
		mapping: ExpenseSubClassificationExpenseSubClassLabel
	},
	{
		id: 'LoanDetailApply',
		enumType: LoanDetailApply,
		mapping: LoanDetailApplyLabel
	},
	{
		id: 'ChannelStatus',
		enumType: ChannelStatus,
		mapping: ChannelStatusLabel
	},
	{
		id: 'ApplicationAccessApplicationAccessType',
		enumType: ApplicationAccessApplicationAccessType,
		mapping: ApplicationAccessApplicationAccessTypeLabel
	},
	{
		id: 'ApplicationAccessStatus',
		enumType: ApplicationAccessStatus,
		mapping: ApplicationAccessStatusLabel
	},
	{
		id: 'JourneyStatus',
		enumType: JourneyStatus,
		mapping: JourneyStatusLabel
	},
	{
		id: 'JourneyJourneyType',
		enumType: JourneyJourneyType,
		mapping: JourneyJourneyTypeLabel
	},
	{
		id: 'StepStatus',
		enumType: StepStatus,
		mapping: StepStatusLabel
	},
	{
		id: 'StepType',
		enumType: StepType,
		mapping: StepTypeLabel
	},
	{
		id: 'OriginationSource',
		enumType: OriginationSource,
		mapping: OriginationSourceLabel
	},
	{
		id: 'AuState',
		enumType: AuState,
		mapping: AuStateLabel
	},
	{
		id: 'FrequencyShort',
		enumType: FrequencyShort,
		mapping: FrequencyShortLabel
	},
	{
		id: 'FrequencyFull',
		enumType: FrequencyFull,
		mapping: FrequencyFullLabel
	},
	{
		id: 'RepaymentFrequency',
		enumType: RepaymentFrequency,
		mapping: RepaymentFrequencyLabel
	},
	{
		id: 'EmploymentStatus',
		enumType: EmploymentStatus,
		mapping: EmploymentStatusLabel
	},
	{
		id: 'NameTitle',
		enumType: NameTitle,
		mapping: NameTitleLabel
	},
	{
		id: 'PaygBasis',
		enumType: PaygBasis,
		mapping: PaygBasisLabel
	},
	{
		id: 'FinancialAssetType',
		enumType: FinancialAssetType,
		mapping: FinancialAssetTypeLabel
	},
	{
		id: 'RbaLendingPurpose',
		enumType: RbaLendingPurpose,
		mapping: RbaLendingPurposeLabel
	},
	{
		id: 'PrimaryUsage',
		enumType: PrimaryUsage,
		mapping: PrimaryUsageLabel
	},
	{
		id: 'PrimaryPurposeLoanPurpose',
		enumType: PrimaryPurposeLoanPurpose,
		mapping: PrimaryPurposeLoanPurposeLabel
	},
	{
		id: 'LivingExpenseCategory',
		enumType: LivingExpenseCategory,
		mapping: LivingExpenseCategoryLabel
	},
	{
		id: 'ApplicantType',
		enumType: ApplicantType,
		mapping: ApplicantTypeLabel
	},
	{
		id: 'OtherAssetType',
		enumType: OtherAssetType,
		mapping: OtherAssetTypeLabel
	},
	{
		id: 'FinancialInstitution',
		enumType: FinancialInstitution,
		mapping: FinancialInstitutionLabel
	},
	{
		id: 'LiabilityType',
		enumType: LiabilityType,
		mapping: LiabilityTypeLabel
	},
	{
		id: 'MaritalStatus',
		enumType: MaritalStatus,
		mapping: MaritalStatusLabel
	},
	{
		id: 'ContactEmailType',
		enumType: ContactEmailType,
		mapping: ContactEmailTypeLabel
	},
	{
		id: 'StreetType',
		enumType: StreetType,
		mapping: StreetTypeLabel
	},
	{
		id: 'CountryCodeLimited',
		enumType: CountryCodeLimited,
		mapping: CountryCodeLimitedLabel
	},
	{
		id: 'AddressType',
		enumType: AddressType,
		mapping: AddressTypeLabel
	},
	{
		id: 'CreditCardType',
		enumType: CreditCardType,
		mapping: CreditCardTypeLabel
	},
	{
		id: 'ResidencyStatus',
		enumType: ResidencyStatus,
		mapping: ResidencyStatusLabel
	},
	{
		id: 'BusinessStructureFull',
		enumType: BusinessStructureFull,
		mapping: BusinessStructureFullLabel
	},
	{
		id: 'OtherIncomeType',
		enumType: OtherIncomeType,
		mapping: OtherIncomeTypeLabel
	},
	{
		id: 'GovernmentBenefitsType',
		enumType: GovernmentBenefitsType,
		mapping: GovernmentBenefitsTypeLabel
	},
	{
		id: 'NonRealEstateAssetType',
		enumType: NonRealEstateAssetType,
		mapping: NonRealEstateAssetTypeLabel
	},
	{
		id: 'LiabilityTypeLimited',
		enumType: LiabilityTypeLimited,
		mapping: LiabilityTypeLimitedLabel
	},
	{
		id: 'ExpenseClassificationExpenseClass',
		enumType: ExpenseClassificationExpenseClass,
		mapping: ExpenseClassificationExpenseClassLabel
	},
	{
		id: 'ClearingFromThisLoan',
		enumType: ClearingFromThisLoan,
		mapping: ClearingFromThisLoanLabel
	},
	{
		id: 'Proportions',
		enumType: Proportions,
		mapping: ProportionsLabel
	},
	{
		id: 'RentalIncomeBasis',
		enumType: RentalIncomeBasis,
		mapping: RentalIncomeBasisLabel
	},
	{
		id: 'PrimaryPurposeRealEstateAsset',
		enumType: PrimaryPurposeRealEstateAsset,
		mapping: PrimaryPurposeRealEstateAssetLabel
	},
	{
		id: 'NumberOfApplicants',
		enumType: NumberOfApplicants,
		mapping: NumberOfApplicantsLabel
	},
	{
		id: 'ServiceabilityResult',
		enumType: ServiceabilityResult,
		mapping: ServiceabilityResultLabel
	}
];
