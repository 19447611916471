import { EnumLabelAuto, EnumObject } from '../model/enum.model';

export class EnumHelper {
	public static getDescription<T>(enumeration: T, enumValue: any): string | undefined {
		const mapper = EnumLabelAuto.find((e) => e.enumType === enumeration);
		if (mapper) {
			return mapper.mapping ? mapper.mapping.get(enumValue) : undefined;
		}
		return undefined;
	}

	public static getEnumArray<T>(enumeration: { [index: string]: number }): EnumObject[] {
		return Object.keys(enumeration)
			.filter((value) => isNaN(Number(value)))
			.map((key) => {
				const description = this.getDescription(enumeration, enumeration[key]);
				return {
					id: enumeration[key],
					label: description ? description : key
				};
			});
	}

	public static getFilteredEnumArray<T>(
		enumeration: { [index: string]: number },
		filterFunction: Function
	): EnumObject[] {
		const filtered = Object.keys(enumeration)
			.filter((value) => isNaN(Number(value)) && filterFunction(value))
			.reduce((ac, key) => {
				const enumObject = {};
				enumObject[key] = enumeration[key];
				ac = {
					...ac,
					...enumObject
				};
				return ac;
			}, {});

		return this.getEnumArray(filtered);
	}

	public static getEnumObject<T>(enumeration: { [index: string]: number }, enumValue: number): EnumObject {
		return { id: enumValue, label: this.getEnumKeyByValue(enumeration, enumValue) as string };
	}

	public static getEnumKeyByValue<T>(enumeration: { [index: string]: number }, enumValue: any): string | undefined {
		const keys = Object.keys(enumeration).filter((x) => enumeration[x] === enumValue);
		if (keys.length > 0) {
			const description = this.getDescription(enumeration, enumValue);
			return description ? description : keys[0];
		}
		return undefined;
	}

	public static getEnumArrayFromFlaggedValue(enumValue: any): number[] {
		const values: number[] = [];
		while (enumValue) {
			const bit = enumValue & (~enumValue + 1);
			values.push(bit);
			enumValue ^= bit;
		}
		return values;
	}
}
