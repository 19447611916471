import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreditCard } from '../model/credit-card.model';
import { HomeLoan } from '../model/home-loan.model';
import { OtherLiability } from '../model/other-liability.model';
import { PersonalLoan } from '../model/personal-loan.model';

@Injectable({ providedIn: 'root' })
export class LiabilityDetailService {
	public addEditCreditCard$: Observable<CreditCard | null>;
	public addEditHomeLoan$: Observable<HomeLoan | null>;
	public addEditPersonalLoan$: Observable<PersonalLoan | null>;
	public addEditOtherLiability$: Observable<OtherLiability | null>;
	private addEditCreditCardSource = new BehaviorSubject<CreditCard | null>(null);
	private addEditHomeLoanSource = new BehaviorSubject<HomeLoan | null>(null);
	private addEditPersonalLoanSource = new BehaviorSubject<PersonalLoan | null>(null);
	private addEditOtherLiabilitySource = new BehaviorSubject<OtherLiability | null>(null);

	constructor() {
		this.addEditCreditCard$ = this.addEditCreditCardSource.asObservable();
		this.addEditHomeLoan$ = this.addEditHomeLoanSource.asObservable();
		this.addEditPersonalLoan$ = this.addEditPersonalLoanSource.asObservable();
		this.addEditOtherLiability$ = this.addEditOtherLiabilitySource.asObservable();
	}

	public addEditCreditCard(creditCard?: CreditCard | null): void {
		this.addEditCreditCardSource.next(creditCard ?? null);
	}

	public addEditHomeLoan(homeLoan?: HomeLoan | null): void {
		this.addEditHomeLoanSource.next(homeLoan ?? null);
	}

	public addEditPersonalLoan(personalLoan?: PersonalLoan | null): void {
		this.addEditPersonalLoanSource.next(personalLoan ?? null);
	}

	public addEditOtherLiability(otherLiability?: OtherLiability | null): void {
		this.addEditOtherLiabilitySource.next(otherLiability ?? null);
	}
}
