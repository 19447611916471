/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EnumObject, SimpAddress } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OverlayService } from 'src/app/overlay/overlay.service';
import { AddressService } from 'src/app/shared/api/address.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { AddressHelper } from 'src/app/shared/helper/address-helper';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Address } from 'src/app/shared/model/address.model';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { AssetService } from '../../api/asset.service';
import { AssetType } from '../../enums/asset-type.enum';
import { PropertyAsset } from '../../model/property-asset.model';
import { AssetDetailService } from '../../service/asset-detail.service';

@Component({
	selector: 'property-asset-details',
	templateUrl: './property-asset-details.component.html',
	styleUrls: ['./property-asset-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyAssetDetailsComponent implements OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<PropertyAsset> = new EventEmitter<PropertyAsset>();
	@Output() public delete: EventEmitter<PropertyAsset> = new EventEmitter<PropertyAsset>();

	public propertyAssetForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public propertyTypes: EnumObject[];
	public applicants: Applicant[] = [];
	public existingAddresses: Array<Address> = [];
	public isEditMode = false;
	public allowedCountries = AddressHelper.allowedCountries;
	public isSubmitting = false;
	public maxAllowedOwnership = 100;
	public popTrigger: string;
	public isPrimaryProperty = false;

	public sectionTitle = '';
	public whoseAssetConfig: FieldMetadata = {} as FieldMetadata;
	public addressConfig: FieldMetadata = {} as FieldMetadata;
	public descriptionConfig: FieldMetadata = {} as FieldMetadata;
	public valueConfig: FieldMetadata = {} as FieldMetadata;
	public ownershipConfig: FieldMetadata = {} as FieldMetadata;
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private selectedAddress: Address;
	private destroy$: Subject<void> = new Subject();
	private propertyAssets: PropertyAsset[] = [];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private addressService: AddressService,
		private assetService: AssetService,
		private changeDetectorRef: ChangeDetectorRef,
		private applicantService: ApplicantService,
		private assetDetailService: AssetDetailService,
		private overlayService: OverlayService,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.propertyTypes = [
			{ id: 1, label: 'Unit' },
			{ id: 2, label: 'House' }
		];

		this.applicants = this.applicantService.getApplicants(true);

		this.propertyAssetForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			description: ['', Validators.maxLength(200)],
			address: [null],
			addressId: [null, Validators.required],
			value: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
			percent: [100, Validators.required]
		});

		this.validationErrors = {
			propertyAddress: {
				required: 'We need the property address',
				error: `That's not a valid address`
			},
			value: {
				required: 'We need the property value',
				min: 'Property value cannot be zero',
				max: 'Property value cannot be more than $10,000,000'
			},
			propertyType: {
				required: 'Select a type'
			},
			percent: {
				required: 'We need a value'
			},
			applicantId: {
				required: 'Select who is this for'
			},
			description: {
				maxlength: 'Description cannot be more than 200 characters'
			}
		};

		this.selectedAddress = AddressHelper.getEmptyAddress();
		this.assetDetailService.addEditProperty$
			.pipe(takeUntil(this.destroy$))
			.subscribe((propertyAsset: PropertyAsset | null) => {
				this.addEditProperty(propertyAsset);
				this.loadAddress();
			});

		const existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.assetService
			.getPropertiesByApplication(existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((assets: PropertyAsset[]) => {
				this.propertyAssets = assets;
			});

		this.popTrigger = this.isTouchDevice() ? 'click' : 'hover';

		this.applicantId.valueChanges
			.pipe(filter((value) => !!value))
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				this.setMaxAllowedOwnership(this.addressId.value);
			});

		this.addressId.valueChanges
			.pipe(filter((value) => !!value))
			.pipe(takeUntil(this.destroy$))
			.subscribe((addressId: number) => {
				this.setMaxAllowedOwnership(addressId);
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public get address() {
		return this.propertyAssetForm.controls.address as UntypedFormControl;
	}

	public get value() {
		return this.propertyAssetForm.controls.value as UntypedFormControl;
	}

	public get percent() {
		return this.propertyAssetForm.controls.percent as UntypedFormControl;
	}

	public get applicantId() {
		return this.propertyAssetForm.controls.applicantId as UntypedFormControl;
	}

	public get addressId() {
		return this.propertyAssetForm.controls.addressId as UntypedFormControl;
	}

	public get description() {
		return this.propertyAssetForm.controls.description as UntypedFormControl;
	}

	public get invalidData(): boolean {
		return (
			!this.propertyAssetForm.valid ||
			(this.addressId.value <= 0 && !this.selectedAddress?.streetName) ||
			this.percent.disabled
		);
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.propertyAssetForm.invalid) {
			return;
		}

		if (this.addressId.value <= 0 && !this.address.value) {
			this.address.setErrors({ required: true });
			return;
		}

		this.isSubmitting = true;
		this.address.disable();
		const assetToSave = {
			...this.propertyAssetForm.value,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			applicantId: this.applicantId.value,
			value: CurrencyHelper.unformatAmount(this.value.value as string),
			percent: +this.percent.value,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			address: this.getApplicantAddress(this.addressId.value),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			description: this.description.value.trim()
		} as PropertyAsset;
		this.assetService
			.savePropertyAsset(assetToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.propertyAssetForm.controls.id.setValue(result.propertyId);
				assetToSave.id = result.propertyId;
				if (assetToSave.address.id <= 0) {
					assetToSave.address.id = result.addressId;
				}
				this.update.emit(assetToSave);
				this.fetchProperties();
			})
			.add(() => {
				this.isSubmitting = false;
				this.address.enable();
				this.changeDetectorRef.markForCheck();
			});
	}

	public updateAddress(address: SimpAddress): void {
		if (!AddressHelper.isValidAddress(address as Address)) {
			this.address.setErrors({ error: true });
		}

		this.selectedAddress = { ...AddressHelper.getEmptyAddress(), ...address };
		this.selectedAddress.id =
			AddressHelper.getMatchingAddressId(this.selectedAddress, this.existingAddresses) ?? Constant.newId;
		this.propertyAssetForm.patchValue({
			addressId: this.selectedAddress.id
		});
	}

	public deleteRecord(): void {
		this.assetService
			.deleteProperty(
				this.propertyAssetForm.controls.applicationId.value,
				this.propertyAssetForm.controls.id.value,
				this.propertyAssetForm.controls.applicantId.value
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.propertyAssetForm.value);
				this.fetchProperties();
			});
	}

	public handlePopoverShown(): void {
		if (this.isTouchDevice()) {
			this.overlayService.startOverlay(null);
		}
	}

	public handlePopoverHidden(): void {
		if (this.isTouchDevice()) {
			this.overlayService.stopOverlay();
		}
	}

	private loadAddress(): void {
		const existingApplicationId = this.applicationService.getStoredApplicationId();
		if (existingApplicationId) {
			this.addressService
				.getByApplication(existingApplicationId)
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: Address[]) => {
					this.existingAddresses = result;
				});
		}
	}

	private getApplicantAddress(selectedAddressId: number): Address {
		if (selectedAddressId !== Constant.newId) {
			this.selectedAddress = {
				...this.existingAddresses.find((address) => address.id === selectedAddressId)
			} as Address;
		}

		return {
			...this.selectedAddress,
			id: selectedAddressId
		} as Address;
	}

	private addEditProperty(propertyAsset: PropertyAsset | null): void {
		if (propertyAsset) {
			this.isEditMode = true;
			this.propertyAssetForm.reset();
			this.propertyAssetForm.patchValue({
				id: propertyAsset.id,
				applicationId: propertyAsset.applicationId,
				applicantId: propertyAsset.applicantId,
				description: propertyAsset.description,
				address: propertyAsset.address ? AddressHelper.ExtractAddress(propertyAsset.address) : null,
				addressId: propertyAsset.address.id,
				value: propertyAsset.value,
				percent: propertyAsset.percent
			});
			this.applicantId.disable();
			this.addressId.disable();
			this.address.disable();

			if (propertyAsset.address.isPrimaryProperty) {
				this.value.disable();
				this.isPrimaryProperty = true;
			} else {
				this.value.enable();
				this.isPrimaryProperty = false;
			}
		} else {
			this.isEditMode = false;
			this.propertyAssetForm.reset();
			this.propertyAssetForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				description: '',
				address: null,
				addressId: null,
				value: null,
				percent: 100
			});
			this.applicantId.enable();
			this.addressId.enable();
			this.address.enable();
			this.value.enable();
			this.isPrimaryProperty = false;
		}
		this.changeDetectorRef.markForCheck();
	}

	private setMaxAllowedOwnership(addressId: number): void {
		this.percent.enable();
		if (addressId === Constant.newId || addressId === null) {
			this.maxAllowedOwnership = 100;
			return;
		}

		const matchingAssets = this.propertyAssets.filter(
			(asset: PropertyAsset) => asset.address.id === addressId && asset.applicantId !== this.applicantId.value
		);
		this.maxAllowedOwnership =
			100 - matchingAssets.map((asset: PropertyAsset) => asset.percent).reduce((a, b) => a + b, 0);

		if (this.maxAllowedOwnership === 0) {
			this.percent.disable();
		}

		if (!this.isEditMode) {
			this.percent.patchValue(this.maxAllowedOwnership);
		}
		this.changeDetectorRef.markForCheck();
	}

	private isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Assets',
				AssetType.Property,
				AssetType.Property
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about this property`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseAssetConfig = this.metadataService.getFieldByName('WhoseAsset', fields);
			this.addressConfig = this.metadataService.getFieldByName('Address', fields);
			this.descriptionConfig = this.metadataService.getFieldByName('Description', fields);
			this.valueConfig = this.metadataService.getFieldByName('Value', fields);
			this.ownershipConfig = this.metadataService.getFieldByName('Ownership', fields);

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}

	private fetchProperties(): void {
		const existingApplicationId = this.applicationService.getStoredApplicationId();
		if (existingApplicationId) {
			this.assetService
				.getPropertiesByApplication(existingApplicationId)
				.pipe(takeUntil(this.destroy$))
				.subscribe((assets: PropertyAsset[]) => {
					this.propertyAssets = assets;
				});
		}
	}
}
