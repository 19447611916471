/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BrandService } from '../shared/api/brand.service';
import { MetadataService } from '../shared/api/metadata.service';
import { AreaMetadata } from '../shared/model/area-metadata.model';
import { BrandConfig } from '../shared/model/brand.config';
import { ButtonMetadata } from '../shared/model/button-metadata.model';

@Component({
	selector: 'app-footer',
	templateUrl: './app-footer.component.html',
	styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnDestroy {
	@HostListener('document:click', ['$event'])
	clickout(event: Event) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.closePopup();
		}
	}
	public showPopup = false;
	public contactEmail = '';
	public contactPhone = '';
	public needHelpMessage = '';
	public contactUsButton: ButtonMetadata = {} as ButtonMetadata;
	public emailButton: ButtonMetadata = {} as ButtonMetadata;
	public callButton: ButtonMetadata = {} as ButtonMetadata;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private elementRef: ElementRef,
		private brandService: BrandService,
		private metadataService: MetadataService
	) {
		this.setMetadata();
		this.brandService.brandConfig$.pipe(takeUntil(this.destroy$)).subscribe((brandConfig: BrandConfig) => {
			this.contactEmail = brandConfig.contactEmail;
			this.contactPhone = brandConfig.contactPhone;
		});
	}

	public closePopup(): void {
		this.showPopup = false;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	private setMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const homeArea: AreaMetadata = this.metadataService.getAreaMetadataByName('Home');
			this.needHelpMessage = this.metadataService.getTextByName('NeedHelp', homeArea.texts);

			this.contactUsButton = this.metadataService.getButtonByName('ContactUs', homeArea.buttons);
			this.emailButton = this.metadataService.getButtonByName('Email', homeArea.buttons);
			this.callButton = this.metadataService.getButtonByName('Call', homeArea.buttons);
		});
	}
}
