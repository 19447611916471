import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { AmountSelect } from 'src/app/shared/model/amount-select.model';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { EnumObject, FinancialInstitution, RepaymentFrequency } from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { LiabilityService } from '../../api/liability.service';
import { LiabilityType } from '../../enums/liability-type.enum';
import { PersonalLoan } from '../../model/personal-loan.model';
import { LiabilityDetailService } from '../../service/liability-detail.service';

@Component({
	selector: 'personal-loan-details',
	templateUrl: './personal-loan-details.component.html',
	styleUrls: ['./personal-loan-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalLoanDetailsComponent implements OnInit, OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<PersonalLoan> = new EventEmitter<PersonalLoan>();
	@Output() public delete: EventEmitter<PersonalLoan> = new EventEmitter<PersonalLoan>();

	public personalLoanDetailsForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: { repaymentFrequencies: EnumObject[]; financialInstitutions: EnumObject[]; applicants: Applicant[] } =
		{ repaymentFrequencies: [], financialInstitutions: [], applicants: [] };
	public isEditMode = false;
	public isSubmitting = false;

	public sectionTitle = '';
	public whoseLoanConfig: FieldMetadata = {} as FieldMetadata;
	public financialInstitutionConfig: FieldMetadata = {} as FieldMetadata;
	public loanAmountConfig: FieldMetadata = {} as FieldMetadata;
	public repaymentConfig: FieldMetadata = {} as FieldMetadata;
	public repaymentFrequencyConfig: FieldMetadata = {} as FieldMetadata;
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private applicantService: ApplicantService,
		private liabilityService: LiabilityService,
		private liabilityDetailService: LiabilityDetailService,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.personalLoanDetailsForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			financialInstitution: [null, Validators.required],
			loanAmount: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
			repayment: this.formBuilder.group({
				amount: [null, [Validators.required, ValidationHelper.minimumAmount, this.maxRepaymentValidator]],
				frequency: [this.getDefaultFrequency()?.id, Validators.required]
			}),
			description: ['']
		});

		this.validationErrors = {
			financialInstitution: {
				required: 'Select a financial institution'
			},
			loanAmount: {
				required: 'We need the loan amount',
				min: 'Loan amount cannot be zero',
				max: 'Loan amount cannot be more than $10,000,000'
			},
			applicantId: {
				required: 'Select who is this for'
			},
			repaymentAmount: {
				required: 'We need the repayment amount',
				min: 'Repayment amount cannot be zero',
				max: 'Repayment amount cannot be more than the loan amount'
			}
		};
		this.liabilityDetailService.addEditPersonalLoan$
			.pipe(takeUntil(this.destroy$))
			.subscribe((personalLoan: PersonalLoan | null) => {
				this.addEditPersonalLoan(personalLoan);
			});
	}

	public get applicantId() {
		return this.personalLoanDetailsForm.controls.applicantId as UntypedFormControl;
	}

	public get financialInstitution() {
		return this.personalLoanDetailsForm.controls.financialInstitution as UntypedFormControl;
	}

	public get loanAmount() {
		return this.personalLoanDetailsForm.controls.loanAmount as UntypedFormControl;
	}

	public get repayment() {
		return this.personalLoanDetailsForm.controls.repayment as UntypedFormGroup;
	}

	public ngOnInit(): void {
		this.loanAmount.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.repayment.controls.amount.updateValueAndValidity();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.personalLoanDetailsForm.invalid) {
			return;
		}

		this.isSubmitting = true;
		const repaymentValue = this.repayment.value as AmountSelect;
		const liabilityToSave = {
			...this.personalLoanDetailsForm.value,
			loanAmount: CurrencyHelper.unformatAmount(this.loanAmount.value),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			financialInstitution: this.financialInstitution.value.id,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
			repaymentAmount: repaymentValue.amount,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			repaymentFrequency: repaymentValue.frequency,
			percent: 100
		} as PersonalLoan;
		this.liabilityService
			.savePersonalLoan(liabilityToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.personalLoanDetailsForm.controls.id.setValue(result);
				liabilityToSave.id = result;
				this.update.emit(liabilityToSave);
			})
			.add(() => {
				this.isSubmitting = false;
				this.changeDetectorRef.markForCheck();
			});
	}

	public deleteRecord(): void {
		this.liabilityService
			.deletePersonalLoan(
				this.personalLoanDetailsForm.controls.applicationId.value,
				this.personalLoanDetailsForm.controls.id.value
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.personalLoanDetailsForm.value);
			});
	}

	public populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.options.repaymentFrequencies.find(
			(frequency: EnumObject) => frequency.id === RepaymentFrequency.Yearly
		);
	}
	private destroy$: Subject<void> = new Subject();
	private maxRepaymentValidator = (control: UntypedFormControl): ValidatorFn =>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		ValidationHelper.maximumAmount(
			control,
			CurrencyHelper.unformatAmount(this.personalLoanDetailsForm?.controls['loanAmount'].value)
		);

	private addEditPersonalLoan(personalLoan: PersonalLoan | null): void {
		this.personalLoanDetailsForm.reset();
		if (personalLoan) {
			this.isEditMode = true;
			this.personalLoanDetailsForm.patchValue({
				id: personalLoan.id,
				applicationId: personalLoan.applicationId,
				applicantId: personalLoan.applicantId,
				description: personalLoan.description,
				financialInstitution: EnumHelper.getEnumObject(
					FinancialInstitution as unknown as { [index: string]: number },
					personalLoan.financialInstitution
				),
				loanAmount: personalLoan.loanAmount,
				repayment: {
					amount: personalLoan.repaymentAmount,
					frequency: personalLoan.repaymentFrequency
				}
			});
		} else {
			this.isEditMode = false;
			this.personalLoanDetailsForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				description: '',
				financialInstitution: null,
				loanAmount: null,
				repayment: {
					amount: null,
					frequency: this.getDefaultFrequency()?.id
				}
			});
		}
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Liabilities',
				LiabilityType.PersonalLoan,
				LiabilityType.PersonalLoan
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about this loan`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseLoanConfig = this.metadataService.getFieldByName('WhoseLoan', fields);
			this.financialInstitutionConfig = this.metadataService.getFieldByName('FinancialInstitution', fields);
			this.loanAmountConfig = this.metadataService.getFieldByName('LoanAmount', fields);
			this.repaymentConfig = this.metadataService.getFieldByName('Repayment', fields);
			this.repaymentFrequencyConfig = this.metadataService.getFieldByName('RepaymentFrequency', fields);

			this.options = {
				financialInstitutions: this.populateOptions(this.financialInstitutionConfig.options),
				repaymentFrequencies: this.populateOptions(this.repaymentFrequencyConfig.options),
				applicants: this.applicantService.getApplicants(true)
			};

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}
}
