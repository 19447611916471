<div class="asset-details">
	<div class="asset-details__section-title col-12 col-md-6">
		<i class="fal fa-car-building fa-2x simp-margin-right-small simp-text--tertiary"></i>
		<h4 class="simp-h4 simp-text--primary asset-details__title--mobile">{{ areaMetadata.title }}</h4>
		<h1 class="simp-h1 simp-text--primary asset-details__title--desktop">{{ areaMetadata.title }}</h1>
	</div>

	<div class="asset-details__form-container col-12 col-md-6">
		<form class="asset-details__form col-12" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="asset-details__scrollable-area">
				<div class="asset-details__category simp-margin-bottom-large">
					<div class="asset-details__category-title simp-padding-vertical-base">
						{{ propertyAssetSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button asset-details__category-add"
							(click)="addEditAdditionalAsset(assetType.Property)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="asset-details__asset-summary simp-padding-small"
						*ngFor="let assetRecord of asset.propertyAsset"
						(click)="addEditAdditionalAsset(assetType.Property, assetRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light asset-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(assetRecord.applicantId) + ", " : "" }}{{
							getCalculatedAssetValue(assetRecord) | currency: "USD":"symbol":"1.0-0" }}, {{
							buildAddressLine(assetRecord.address) }}{{ assetRecord.description ? " - " + assetRecord.description : ""
							}}
						</h5>
						<i
							class="fas fa-info-circle simp-text--grey70 simp-margin-right-base"
							[ngbPopover]="popContent"
							[triggers]="popTrigger"
							placement="left"
							popoverClass="purchasing-help-popover"
							(shown)="handlePopoverShown()"
							(hidden)="handlePopoverHidden()"
							(click)="$event.stopPropagation()"
							#popover2="ngbPopover"
							*ngIf="assetRecord.isPrimaryProperty"
						></i>
						<ng-template #popContent>
							<div class="simp-text simp-text-small">
								This is the property being {{ isRefinance ? "refinanced" : "purchased" }}
							</div>
						</ng-template>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>

				<div class="asset-details__category simp-margin-bottom-large">
					<div class="asset-details__category-title simp-padding-vertical-base">
						{{ savingsAssetSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button asset-details__category-add"
							(click)="addEditAdditionalAsset(assetType.Savings)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="asset-details__asset-summary simp-padding-small"
						*ngFor="let assetRecord of asset.savingsAsset"
						(click)="addEditAdditionalAsset(assetType.Savings, assetRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light asset-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(assetRecord.applicantId) + ", " : "" }}{{ assetRecord.value |
							currency: "USD":"symbol":"1.0-0" }}, {{ assetRecord.type | enumDesc: financialAssetType }}{{
							assetRecord.description ? " - " + assetRecord.description : "" }}
						</h5>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>

				<div class="asset-details__category">
					<div class="asset-details__category-title simp-padding-vertical-base">
						{{ otherAssetSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button asset-details__category-add"
							(click)="addEditAdditionalAsset(assetType.Other)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="asset-details__asset-summary simp-padding-small"
						*ngFor="let assetRecord of asset.otherAsset"
						(click)="addEditAdditionalAsset(assetType.Other, assetRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light asset-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(assetRecord.applicantId) + ", " : "" }}{{ assetRecord.value |
							currency: "USD":"symbol":"1.0-0" }}, {{ assetRecord.type | enumDesc: nonRealEstateAssetType }}{{
							assetRecord.description ? " - " + assetRecord.description : "" }}
						</h5>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>
			</div>

			<div class="asset-details__summary simp-margin-vertical-medium simp-padding-horizontal-small">
				<div>{{ totalAssets | currency: "USD":"symbol":"1.0-0" }}</div>
				<div>total assets</div>
			</div>

			<div class="asset-details__buttons-container simp-row simp-margin-bottom-medium">
				<div class="col-6">
					<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="finishLater()">
						{{ finishButtonConfig.title }}
					</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary btn-lg simp-button col-12" [disabled]="!hasAsset">
						{{ doneButtonConfig.title }}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<additional-asset-details
	[@slideInOut]="additionalInfoState"
	[assetType]="additionalInfoType"
	(goBack)="toggleAdditionalInfoPage()"
	(updateAsset)="updateAsset($event)"
	(deleteAsset)="handleAssetDelete($event)"
></additional-asset-details>

<ng-template #overlayContent>
	<h4 class="simp-h4 overlay__message">We've saved where you're up to. Return and continue any time.</h4>
	<div class="simp-row overlay__buttons-container">
		<div class="col-6">
			<button class="btn btn-secondary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay()">
				Keep going
			</button>
		</div>
		<div class="col-6">
			<button class="btn btn-primary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay(true)">
				Exit
			</button>
		</div>
	</div>
</ng-template>
