import { Injectable } from '@angular/core';
import { PersonalDetail } from 'src/app/personal-details/model/personal-detail.model';
import { ApplicationService } from '../api/application.service';
import { Constant } from '../helper/constant';
import { Applicant } from '../model/applicant.model';

@Injectable({ providedIn: 'root' })
export class ApplicantService {
	private jointApplicant: Applicant = { id: Constant.newId, name: 'Joint' };
	constructor(private applicationService: ApplicationService) {}

	public getApplicants(includeJoint: boolean): Applicant[] {
		if (includeJoint) {
			return [...this.applicationService.getStoredApplicants(), this.jointApplicant];
		}
		return [...this.applicationService.getStoredApplicants()];
	}

	public getApplicantName(applicantId: number): Applicant {
		if (applicantId === Constant.newId) {
			return this.jointApplicant;
		}
		const storedApplicants = this.applicationService.getStoredApplicants();
		return storedApplicants.find((applicant) => applicant.id === applicantId) as Applicant;
	}

	//TODO: I think store and retrieval of applicants should be moved from application service to here???

	public setApplicantsLocally(personalDetails: PersonalDetail[]): void {
		const primaryApplicant = personalDetails.find((personalDetail) => personalDetail.primaryApplicant);
		if (primaryApplicant) {
			this.applicationService.setPrimaryApplicant({
				id: primaryApplicant.id,
				name: `${primaryApplicant.firstName}`
			});
		}
		const additionalApplicant = personalDetails.find((personalDetail) => !personalDetail.primaryApplicant);
		if (additionalApplicant) {
			this.applicationService.setAdditionalApplicant({
				id: additionalApplicant.id,
				name: `${additionalApplicant.firstName}`
			});
		}
	}
}
