import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OptionMetadata, SimpAddress } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoanDetailService } from 'src/app/loan-details/api/loan-detail.service';
import { LoanDetail } from 'src/app/loan-details/model/loan-detail.model';
import { OverlayService } from 'src/app/overlay/overlay.service';
import { AddressService } from 'src/app/shared/api/address.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { AddressHelper } from 'src/app/shared/helper/address-helper';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Address } from 'src/app/shared/model/address.model';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { EnumObject, FrequencyFull, RbaLendingPurpose } from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { IncomeService } from '../../api/income.service';
import { IncomeType } from '../../enums/income-type.enum';
import { Income } from '../../model/income.model';
import { RentalIncome } from '../../model/rental-income.model';
import { IncomeDetailService } from '../../service/income-detail.service';

@Component({
	selector: 'rental-income-details',
	templateUrl: './rental-income-details.component.html',
	styleUrls: ['./rental-income-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RentalIncomeDetailsComponent implements OnInit, OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<any> = new EventEmitter<any>();
	@Output() public delete: EventEmitter<any> = new EventEmitter<any>();

	public rentalIncomeForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: { applicants: Applicant[]; rentFrequencies: EnumObject[] } = { applicants: [], rentFrequencies: [] };
	public existingAddresses: Array<{ id: number; label: string; address: Address }> = [];
	public isEditMode = false;
	public allowedCountries = AddressHelper.allowedCountries;
	public isSubmitting = false;
	public rentAddedForAllApplicants = false;
	public rentAddedForAnyApplicant = false;
	public rentAddedForSameApplicant = false;
	public popTrigger: string;
	public isRefinance = false;

	public sectionTitle = '';
	public whoseIncomeConfig: FieldMetadata = {} as FieldMetadata;
	public addressConfig: FieldMetadata = {} as FieldMetadata;
	public rateConfig: FieldMetadata = {} as FieldMetadata;
	public rateFrequencyConfig: FieldMetadata = {} as FieldMetadata;
	public rateFrequencyOptions: EnumObject[] = [];
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private selectedAddress: SimpAddress;
	private rentalIncomes: RentalIncome[] = [];
	private existingApplicationId: number;
	private originalAplicantId = -1;
	private originalAddressId = -1;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private incomeService: IncomeService,
		private addressService: AddressService,
		private changeDetectorRef: ChangeDetectorRef,
		private incomeDetailService: IncomeDetailService,
		private overlayService: OverlayService,
		private applicantService: ApplicantService,
		private loanDetailService: LoanDetailService,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.options = {
			rentFrequencies: EnumHelper.getEnumArray(FrequencyFull as unknown as { [index: string]: number }),
			applicants: this.applicantService.getApplicants(true)
		};

		this.rentalIncomeForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			rent: this.formBuilder.group({
				amount: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
				frequency: [this.getDefaultFrequency()?.id, Validators.required]
			}),
			addressId: [Constant.newId],
			address: [null],
			isFuture: [null]
		});

		this.validationErrors = {
			address: {
				required: 'We need the property address',
				error: `That's not a valid address`
			},
			occupancyStatus: {
				required: 'Select a value'
			},
			amount: {
				required: 'We need the rent rate',
				min: 'Rent rate cannot be zero',
				max: 'Rent rate cannot be more than $10,000,000'
			},
			rentalIncomeShare: {
				required: 'We need a value'
			},
			applicantId: {
				required: 'Select who is this for'
			}
		};

		this.selectedAddress = AddressHelper.getEmptyAddress();

		this.incomeDetailService.addEditRentalIncome$
			.pipe(takeUntil(this.destroy$))
			.subscribe((rentalIncome: RentalIncome | null) => {
				this.addEditRentalIncome(rentalIncome);
				this.loadAddress();
			});

		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.incomeService
			.getByApplication(this.existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: Income) => {
				this.rentalIncomes = [...result.rentalIncome];
			});

		this.popTrigger = this.isTouchDevice() ? 'click' : 'hover';

		this.loanDetailService.loanDetails$.pipe(takeUntil(this.destroy$)).subscribe((loanDetail: LoanDetail | null) => {
			this.isRefinance = loanDetail?.plan === RbaLendingPurpose.Refinance;
			this.changeDetectorRef.markForCheck();
		});
	}

	public get address() {
		return this.rentalIncomeForm.controls.address as UntypedFormControl;
	}

	public get addressId() {
		return this.rentalIncomeForm.controls.addressId as UntypedFormControl;
	}

	public get rent() {
		return this.rentalIncomeForm.controls.rent as UntypedFormGroup;
	}

	public get applicantId() {
		return this.rentalIncomeForm.controls.applicantId as UntypedFormControl;
	}

	public get invalidData(): boolean {
		return (
			!this.rentalIncomeForm.valid ||
			(this.addressId.value <= 0 && !this.selectedAddress?.streetName) ||
			this.rentAddedForAllApplicants ||
			this.rentAddedForAnyApplicant ||
			this.rentAddedForSameApplicant
		);
	}

	public get validationMessage(): string {
		if (this.options.applicants?.length === 2) {
			return `Rental income already entered for the selected property.`;
		} else {
			return `Rental income already entered ${
				this.rentAddedForAllApplicants
					? ' against both applicants '
					: this.rentAddedForSameApplicant
					? ' against the selected applicant '
					: ''
			}for the selected property${
				this.rentAddedForAnyApplicant ? ' against at least one applicant. Cannot add rent for Joint.' : '.'
			}`;
		}
	}

	public ngOnInit(): void {
		this.applicantId.valueChanges
			.pipe(
				filter((value) => !!value),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				if (this.addressId.value) {
					this.checkRentAddedForAllApplicants();
				}
			});

		this.addressId.valueChanges
			.pipe(
				filter((value) => !!value),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.checkRentAddedForAllApplicants();
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.rentalIncomeForm.invalid) {
			return;
		}

		if (this.addressId.value <= 0 && !this.address.value) {
			this.address.setErrors({ required: true });
			return;
		}

		this.isSubmitting = true;
		this.address.disable();
		const incomeToSave = {
			...this.rentalIncomeForm.value,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
			amount: CurrencyHelper.unformatAmount(this.rent.value.amount),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			frequency: this.rent.value.frequency,
			address: this.getApplicantAddress(this.addressId.value)
		} as RentalIncome;
		this.incomeService
			.saveRentalIncome(incomeToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.rentalIncomeForm.controls.id.setValue(result);
				incomeToSave.id = result.id;
				if (incomeToSave.address.id <= 0) {
					incomeToSave.address.id = result.addressId;
				}
				incomeToSave.isFuture = result.isFuture;
				this.update.emit(incomeToSave);
				this.refreshRentalIncomes();
			})
			.add(() => {
				this.isSubmitting = false;
				this.address.enable();
				this.changeDetectorRef.markForCheck();
			});
	}

	public updateAddress(address: SimpAddress): void {
		if (!AddressHelper.isValidAddress(address as Address)) {
			this.address.setErrors({ error: true });
		}
		this.selectedAddress = { ...AddressHelper.getEmptyAddress(), ...address };
	}

	public deleteRecord(): void {
		this.incomeService
			.deleteRentalIncome(
				this.rentalIncomeForm.controls.applicationId.value,
				this.rentalIncomeForm.controls.id.value,
				this.rentalIncomeForm.controls.isFuture.value
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.rentalIncomeForm.value);
				this.refreshRentalIncomes();
			});
	}

	public handleExistingAddressSelect(evt: Event) {
		const input = evt.target as HTMLInputElement;
		if (input.checked) {
			this.rentalIncomeForm.patchValue({
				address: null
			});
		}
	}

	public handleNewAddressSelect(evt: Event) {
		const input = evt.target as HTMLInputElement;
		if (input.checked) {
			this.rentalIncomeForm.patchValue({
				addressId: Constant.newId,
				address: null
			});
			this.selectedAddress = AddressHelper.getEmptyAddress();
		}
	}

	public handlePopoverShown(): void {
		if (this.isTouchDevice()) {
			this.overlayService.startOverlay(null);
		}
	}

	public handlePopoverHidden(): void {
		if (this.isTouchDevice()) {
			this.overlayService.stopOverlay();
		}
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.options.rentFrequencies.find((frequency: EnumObject) => frequency.id === FrequencyFull.Yearly);
	}

	private getApplicantAddress(selectedAddressId: number): Address {
		if (selectedAddressId !== -1) {
			this.selectedAddress = {
				...this.existingAddresses.find((address) => address.id === selectedAddressId)?.address
			} as SimpAddress;
		}

		return {
			...this.selectedAddress,
			id: selectedAddressId
		} as Address;
	}

	private loadAddress(): void {
		const existingApplicationId = this.applicationService.getStoredApplicationId();
		if (existingApplicationId) {
			this.addressService.getByApplication(existingApplicationId).subscribe((result: Address[]) => {
				this.existingAddresses = result.map((address) => ({
					id: address.id,
					label: AddressHelper.ExtractAddress(address),
					address
				}));

				if (this.existingAddresses.length === 0) {
					this.addressId.setValue(Constant.newId);
				}

				this.changeDetectorRef.detectChanges();
			});
		}
	}

	private addEditRentalIncome(rentalIncome: RentalIncome | null): void {
		this.rentalIncomeForm.reset();
		if (rentalIncome) {
			this.isEditMode = true;
			this.rentalIncomeForm.patchValue({
				id: rentalIncome.id,
				applicationId: rentalIncome.applicationId,
				applicantId: rentalIncome.applicantId,
				rent: {
					amount: rentalIncome.amount,
					frequency: rentalIncome.frequency
				},
				addressId: rentalIncome.address.id,
				address: rentalIncome.address,
				isFuture: rentalIncome.isFuture
			});
			this.originalAplicantId = rentalIncome.applicantId;
			this.originalAddressId = rentalIncome.address.id;
		} else {
			this.isEditMode = false;
			this.rentalIncomeForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				rent: {
					amount: null,
					frequency: this.getDefaultFrequency()?.id
				}
			});
		}
		this.checkRentAddedForAllApplicants();
	}

	private checkRentAddedForAllApplicants(): void {
		/**
		 * Following are the validation rules that are applied
		 * 1. Ensure rent can only be entered once against a particular applicant for the selected property.
		 * 2. If rent already entered individually for any or both applicants (in case there are 2 applicants)
		 *    for the selected property, do not allow entering rent against 'Joint' for the same property.
		 * 3. If rent already entered against 'Joint' for the selected property, do not allow entering rent
		 *    against any individual applicant for the same property.
		 */
		this.rentAddedForAllApplicants = false;
		this.rentAddedForAnyApplicant = false;
		this.rentAddedForSameApplicant = false;

		if (this.addressId.value === Constant.newId || this.applicantId.value === null) {
			return;
		}

		// Single applicant
		if (this.options.applicants.length === 2) {
			// Warn the user if rent has already been recorded for the selected property
			this.rentAddedForAllApplicants = this.isRentEnteredForProperty() && !this.isEditMode;
		} else {
			// If editing original record as opened from income main page, allow user to proceed
			if (this.isEditingOriginalRecord()) {
				return;
			}

			if (this.applicantId.value === Constant.newId) {
				// Joint applicant selected. Warn user if rent already added for at least one applicant for the selected property
				this.checkRentAddedForAnyApplicant();
				if (!this.rentAddedForAnyApplicant) {
					this.rentAddedForAllApplicants = this.isRentAddedForJoint() && this.isEditMode;
				}
			} else {
				// Warn user if rent already added against Joint for the selected property
				if (this.isRentAddedForJoint()) {
					this.rentAddedForAllApplicants = true;
					return;
				}

				// Warn user if rent already added against same applicant for the selected property
				this.checkRentAddedForSameApplicant();
			}
		}
		this.changeDetectorRef.markForCheck();
	}

	private refreshRentalIncomes(): void {
		this.incomeService
			.getByApplication(this.existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: Income) => {
				this.rentalIncomes = [...result.rentalIncome];
			});
	}

	private isEditingOriginalRecord(): boolean {
		return (
			this.isEditMode &&
			this.applicantId.value === this.originalAplicantId &&
			this.addressId.value === this.originalAddressId
		);
	}

	private isRentEnteredForProperty(): boolean {
		return this.rentalIncomes.some((income: RentalIncome) => income.address.id === this.addressId.value);
	}

	private checkRentAddedForAnyApplicant(): void {
		this.rentAddedForAnyApplicant = this.rentalIncomes.some(
			(income: RentalIncome) => income.address.id === this.addressId.value && income.applicantId !== Constant.newId
		);
	}

	private isRentAddedForJoint(): boolean {
		return this.rentalIncomes.some(
			(income: RentalIncome) => income.address.id === this.addressId.value && income.applicantId === Constant.newId
		);
	}

	private checkRentAddedForSameApplicant(): void {
		this.rentAddedForSameApplicant = this.rentalIncomes.some(
			(income: RentalIncome) =>
				income.address.id === this.addressId.value && income.applicantId === this.applicantId.value
		);
	}

	private isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Income',
				IncomeType.Rental,
				IncomeType.Rental
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about this property`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseIncomeConfig = this.metadataService.getFieldByName('WhoseIncome', fields);
			this.addressConfig = this.metadataService.getFieldByName('Address', fields);
			this.rateConfig = this.metadataService.getFieldByName('Rate', fields);
			this.rateFrequencyConfig = this.metadataService.getFieldByName('Frequency', fields);
			this.rateFrequencyOptions = this.populateOptions(this.rateFrequencyConfig?.options);

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}

	private populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}
}
