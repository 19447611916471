import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { CreditCard } from '../model/credit-card.model';
import { HomeLoan } from '../model/home-loan.model';
import { OtherLiability } from '../model/other-liability.model';
import { PersonalLoan } from '../model/personal-loan.model';

@Injectable({ providedIn: 'root' })
export class LiabilityService extends BaseApiService<any> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/Liability');
	}

	public getCreditCardsByApplication(applicationId: number): Observable<CreditCard[]> {
		return <Observable<CreditCard[]>>this.getCustom(`CreditCard/${applicationId}`);
	}

	public getHomeLoansByApplication(applicationId: number): Observable<HomeLoan[]> {
		return <Observable<HomeLoan[]>>this.getCustom(`HomeLoan/${applicationId}`);
	}

	public getPersonalLoansByApplication(applicationId: number): Observable<PersonalLoan[]> {
		return <Observable<PersonalLoan[]>>this.getCustom(`PersonalLoan/${applicationId}`);
	}

	public getOtherLiabilitiesByApplication(applicationId: number): Observable<OtherLiability[]> {
		return <Observable<OtherLiability[]>>this.getCustom(`OtherLiability/${applicationId}`);
	}

	public saveCreditCard(liability: CreditCard): Observable<number> {
		return <Observable<number>>this.postCustom('CreditCard', liability);
	}

	public saveHomeLoan(liability: HomeLoan): Observable<number> {
		return <Observable<number>>this.postCustom('HomeLoan', liability);
	}

	public savePersonalLoan(liability: PersonalLoan): Observable<number> {
		return <Observable<number>>this.postCustom('PersonalLoan', liability);
	}

	public saveOtherliability(liability: OtherLiability): Observable<number> {
		return <Observable<number>>this.postCustom('Otherliability', liability);
	}

	public deleteCreditCard(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`CreditCard/${applicationId}/${id}`);
	}

	public deleteHomeLoan(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`HomeLoan/${applicationId}/${id}`);
	}

	public deletePersonalLoan(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`PersonalLoan/${applicationId}/${id}`);
	}

	public deleteOtherLiability(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`OtherLiability/${applicationId}/${id}`);
	}
}
