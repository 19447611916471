/* eslint-disable no-useless-escape */
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { CurrencyHelper } from './currency-helper';

export class ValidationHelper {
	public static mobileNumber = (control: UntypedFormControl): any => {
		if (!control.value) {
			return null;
		}
		const pattern = /^(\+614|0?4)\d{8}$/;
		if (pattern.test(control.value)) {
			return null;
		} else {
			return { invalid: true };
		}
	};

	public static email = (control: UntypedFormControl): any => {
		if (!control.value) {
			return null;
		}
		// Credit: https://github.com/FluentValidation/FluentValidation/blob/10.x/src/FluentValidation/Validators/EmailValidator.cs

		const emailPattern =
			// eslint-disable-next-line max-len
			// eslint-disable-next-line no-control-regex
			/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i; // eslint-disable-next-line max-len
		if (emailPattern.test(control.value)) {
			return null;
		} else {
			return { email: true };
		}
	};

	public static personName = (control: UntypedFormControl): any => {
		if (!control.value) {
			return null;
		}
		const pattern = /^[A-Za-z][A-Za-z'-]+([ A-Za-z][A-Za-z'-]+)*/;
		if (pattern.test(control.value)) {
			return null;
		} else {
			return { invalidName: true };
		}
	};

	public static minimumAmount = (control: UntypedFormControl, minimumAmount = 1): any => {
		const value = control.value as number;
		if (!value && value !== 0) {
			return null;
		}
		if (CurrencyHelper.unformatAmount(value.toString()) < minimumAmount) {
			return { min: true };
		}
	};

	public static maximumAmount = (control: UntypedFormControl, maximumAmount = 10000000): any => {
		const value = control.value as number;
		if (!value && value !== 0) {
			return null;
		}

		if (CurrencyHelper.unformatAmount(value.toString()) > maximumAmount) {
			return { max: true };
		}
	};

	public static conditionalValueValidator =
		(conditionControl: string, controlToCheck: string, conditionalValue: any, validator: ValidatorFn): any =>
		(formGroup: UntypedFormGroup): any => {
			const condition = formGroup.controls[conditionControl];
			const control = formGroup.controls[controlToCheck];

			if (control.errors) {
				return;
			}

			if (condition.value === conditionalValue) {
				control.setErrors(validator(control));
			} else {
				control.setErrors(null);
			}
		};

	public static numericOnly = (control: UntypedFormControl): any => {
		if (!control.value) {
			return null;
		}
		const pattern = /^[0-9]*$/;
		if (pattern.test(control.value)) {
			return null;
		} else {
			return { invalid: true };
		}
	};
}
