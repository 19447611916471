import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OtherAsset } from '../model/other-asset.model';
import { PropertyAsset } from '../model/property-asset.model';
import { SavingsAsset } from '../model/savings-asset.model';

@Injectable({ providedIn: 'root' })
export class AssetDetailService {
	public addEditProperty$: Observable<PropertyAsset | null>;
	public addEditSaving$: Observable<SavingsAsset | null>;
	public addEditOtherAsset$: Observable<OtherAsset | null>;
	private addEditPropertySource = new BehaviorSubject<PropertyAsset | null>(null);
	private addEditSavingSource = new BehaviorSubject<SavingsAsset | null>(null);
	private addEditOtherAssetSource = new BehaviorSubject<OtherAsset | null>(null);

	constructor() {
		this.addEditProperty$ = this.addEditPropertySource.asObservable();
		this.addEditSaving$ = this.addEditSavingSource.asObservable();
		this.addEditOtherAsset$ = this.addEditOtherAssetSource.asObservable();
	}

	public addEditProperty(propertyAsset?: PropertyAsset): void {
		this.addEditPropertySource.next(propertyAsset ?? null);
	}

	public addEditSaving(savingsAsset?: SavingsAsset): void {
		this.addEditSavingSource.next(savingsAsset ?? null);
	}

	public addEditOtherAsset(otherAsset?: OtherAsset): void {
		this.addEditOtherAssetSource.next(otherAsset ?? null);
	}
}
