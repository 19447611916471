<div class="additional-info">
	<div class="additional-info__header simp-padding-right-small">
		<div class="additional-info__back-button" (click)="handleBackClick()">
			<i class="far fa-arrow-left"></i>
		</div>
		<h6 class="simp-h6 additional-info__heading simp-text--grey70">{{ sectionHeader }}</h6>
		<button
			type="submit"
			class="btn btn-primary btn-md simp-button additional-info__calculate-button"
			[disabled]="!averageExpenseForm.dirty || !averageExpenseForm.valid"
			(click)="calculateTotalSpend()"
		>
			{{ calculateButtonConfig.title }}
		</button>
	</div>

	<div class="additional-info__details simp-padding-small">
		<h4 class="simp-h4 simp-text--primary">{{ sectionTitle }}</h4>
		<form
			class="additional-info__form simp-padding-vertical-medium"
			[formGroup]="averageExpenseForm"
			autocomplete="off"
		>
			<div formArrayName="details" *ngFor="let detail of form.details.controls; let a = index">
				<div [formGroupName]="a">
					<div class="simp-row">
						<simp-amount-select-input
							class="col-12"
							[formGroup]="detail.controls.expense"
							[options]="options.expenseFrequencies"
							[validationErrors]="validationErrors.expenseAmount"
							[label]="detail.controls.label.value"
						>
						</simp-amount-select-input>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
