import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ENV_CONFIG } from './env-config';
import { environment } from './environments/environment';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
fetch('./assets/configs/env.config.json')
	.then((response) => response.json())
	.then((config) => {
		Object.assign(ENV_CONFIG, config);
		if (environment.production) {
			enableProdMode();
		}

		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch((err) => console.error(err));
	});
