import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OptionMetadata } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { EmploymentStatus, EnumObject, FrequencyShort, PaygBasis } from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { IncomeService } from '../../api/income.service';
import { IncomeType } from '../../enums/income-type.enum';
import { WorkIncome } from '../../model/work-income.model';
import { IncomeDetailService } from '../../service/income-detail.service';

@Component({
	selector: 'employment-income-details',
	templateUrl: './employment-income-details.component.html',
	styleUrls: ['./employment-income-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmploymentIncomeDetailsComponent implements OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<any> = new EventEmitter<any>();
	@Output() public delete: EventEmitter<any> = new EventEmitter<any>();

	public employmentIncomeForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: {
		employmentStatuses: EnumObject[];
		frequencies: EnumObject[];
		applicants: Applicant[];
	} = { applicants: [], employmentStatuses: [], frequencies: [] };
	public isEditMode = false;
	public isSubmitting = false;

	public sectionTitle = '';
	public whoseIncomeConfig: FieldMetadata = {} as FieldMetadata;
	public paygStatusConfig: FieldMetadata = {} as FieldMetadata;
	public paygStatusOptions: EnumObject[] = [];
	public salaryAmountConfig: FieldMetadata = {} as FieldMetadata;
	public salaryFrequencyConfig: FieldMetadata = {} as FieldMetadata;
	public salaryFrequencyOptions: EnumObject[] = [];
	public bonusAmountConfig: FieldMetadata = {} as FieldMetadata;
	public bonusFrequencyConfig: FieldMetadata = {} as FieldMetadata;
	public bonusFrequencyOptions: EnumObject[] = [];
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private incomeService: IncomeService,
		private applicationService: ApplicationService,
		private incomeDetailService: IncomeDetailService,
		private applicantService: ApplicantService,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();

		this.options = {
			employmentStatuses: EnumHelper.getEnumArray(PaygBasis as unknown as { [index: string]: number }),
			frequencies: EnumHelper.getEnumArray(FrequencyShort as unknown as { [index: string]: number }),
			applicants: this.applicantService.getApplicants(false)
		};

		this.employmentIncomeForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.applicationService.getStoredApplicationId()],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			status: [EmploymentStatus.Primary],
			basis: [null, Validators.required],
			salary: this.formBuilder.group({
				amount: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
				frequency: [this.options.frequencies[0].id, Validators.required]
			}),
			otherAmount: this.formBuilder.group({
				amount: [0, [Validators.required, ValidationHelper.maximumAmount]],
				frequency: [this.getDefaultFrequency()?.id, Validators.required]
			})
		});

		this.validationErrors = {
			basis: {
				required: 'Select you employment status'
			},
			salaryAmount: {
				required: 'We need the salary amount',
				min: 'Salary cannot be zero',
				max: 'Salary cannot be more than $10,000,000'
			},
			otherAmount: {
				required: 'We need the bonus amount',
				max: 'Amount cannot be more than $10,000,000'
			},
			applicantId: {
				required: 'Select who is this for'
			}
		};

		this.incomeDetailService.addEditWorkIncome$
			.pipe(takeUntil(this.destroy$))
			.subscribe((workIncome: WorkIncome | null) => {
				this.addEditWorkIncome(workIncome);
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public get basis() {
		return this.employmentIncomeForm.controls.basis as UntypedFormControl;
	}

	public get salary() {
		return this.employmentIncomeForm.controls.salary as UntypedFormGroup;
	}

	public get otherAmount() {
		return this.employmentIncomeForm.controls.otherAmount as UntypedFormGroup;
	}

	public get applicantId() {
		return this.employmentIncomeForm.controls.applicantId as UntypedFormControl;
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public onSubmit(): void {
		if (this.employmentIncomeForm.invalid) {
			return;
		}

		this.isSubmitting = true;

		const incomeToSave = {
			...this.employmentIncomeForm.value,
			basis: (this.basis.value as EnumObject).id,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			salaryAmount: CurrencyHelper.unformatAmount(this.salary.value.amount as string),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			otherAmount: CurrencyHelper.unformatAmount(this.otherAmount.value.amount as string),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			salaryFrequency: this.salary.value.frequency,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			otherFrequency: this.otherAmount.value.frequency
		} as WorkIncome;
		this.incomeService
			.saveWorkIncome(incomeToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.employmentIncomeForm.controls.id.setValue(result);
				incomeToSave.id = result;
				this.update.emit(incomeToSave);
			})
			.add(() => {
				this.isSubmitting = false;
				this.changeDetectorRef.markForCheck();
			});
	}

	public deleteRecord(): void {
		//TODO : Need confirmation?
		this.incomeService
			.deleteWorkIncome(
				this.employmentIncomeForm.controls.applicationId.value,
				this.employmentIncomeForm.controls.id.value
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.employmentIncomeForm.value);
			});
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.options.frequencies.find((frequency: EnumObject) => frequency.id === FrequencyShort.Yearly);
	}

	private addEditWorkIncome(workIncome: WorkIncome | null): void {
		this.employmentIncomeForm.reset();
		if (workIncome) {
			this.isEditMode = true;
			this.employmentIncomeForm.patchValue({
				id: workIncome.id,
				applicationId: workIncome.applicationId,
				applicantId: workIncome.applicantId,
				status: EmploymentStatus.Primary,
				basis: EnumHelper.getEnumObject(PaygBasis as unknown as { [index: string]: number }, workIncome.basis),
				salary: {
					amount: workIncome.salaryAmount,
					frequency: workIncome.salaryFrequency
				},
				otherAmount: {
					amount: workIncome.otherAmount,
					frequency: workIncome.otherFrequency
				}
			});
		} else {
			this.isEditMode = false;
			this.employmentIncomeForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				status: EmploymentStatus.Primary,
				basis: null,
				salary: {
					amount: null,
					frequency: this.getDefaultFrequency()?.id
				},
				otherAmount: {
					amount: 0,
					frequency: this.getDefaultFrequency()?.id
				}
			});
		}
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Income',
				IncomeType.Employment,
				IncomeType.Employment
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about work`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseIncomeConfig = this.metadataService.getFieldByName('WhoseIncome', fields);
			this.paygStatusConfig = this.metadataService.getFieldByName('PaygStatus', fields);
			this.paygStatusOptions = this.populateOptions(this.paygStatusConfig?.options);
			this.salaryAmountConfig = this.metadataService.getFieldByName('SalaryAmount', fields);
			this.salaryFrequencyConfig = this.metadataService.getFieldByName('SalaryFrequency', fields);
			this.salaryFrequencyOptions = this.populateOptions(this.salaryFrequencyConfig?.options);
			this.bonusAmountConfig = this.metadataService.getFieldByName('BonusAmount', fields);
			this.bonusFrequencyConfig = this.metadataService.getFieldByName('BonusFrequency', fields);
			this.bonusFrequencyOptions = this.populateOptions(this.bonusFrequencyConfig?.options);

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}

	private populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}
}
