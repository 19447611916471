<div class="income-details">
	<div class="income-details__section-title col-12 col-md-6">
		<i class="fas fa-usd-circle fa-2x simp-margin-right-small simp-text--tertiary"></i>
		<h4 class="simp-h4 simp-text--primary income-details__title--mobile">{{ areaMetadata.title }}</h4>
		<h1 class="simp-h1 simp-text--primary income-details__title--desktop">{{ areaMetadata.title }}</h1>
	</div>

	<div class="income-details__form-container col-12 col-md-6">
		<form class="income-details__form col-12" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="income-details__scrollable-area">
				<div class="income-details__category simp-margin-bottom-large">
					<div class="income-details__category-title simp-padding-vertical-base">
						{{ employmentIncomeSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button income-details__category-add"
							(click)="addEditAdditionalIncome(incomeType.Employment)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="income-details__income-summary simp-padding-small"
						*ngFor="let incomeRecord of income.workIncome"
						(click)="addEditAdditionalIncome(incomeType.Employment, incomeRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light income-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(incomeRecord.applicantId) + ", " : "" }}{{ incomeRecord.basis
							| enumDesc: paygBasis }}, {{ getYearlyWorkIncome(incomeRecord) | currency: "USD":"symbol":"1.0-0" }} per
							year
						</h5>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>

				<div class="income-details__category simp-margin-bottom-large">
					<div class="income-details__category-title simp-padding-vertical-base">
						{{ rentalIncomeSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button income-details__category-add"
							(click)="addEditAdditionalIncome(incomeType.Rental)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="income-details__income-summary simp-padding-small"
						*ngFor="let incomeRecord of income.rentalIncome"
						(click)="addEditAdditionalIncome(incomeType.Rental, incomeRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light income-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(incomeRecord.applicantId) + ", " : "" }} {{
							getYearlyRentalIncome(incomeRecord) | currency: "USD":"symbol":"1.0-0" }} per year, {{
							buildAddressLine(incomeRecord.address) }}
						</h5>
						<i
							class="fas fa-info-circle simp-text--grey70 simp-margin-right-base"
							[ngbPopover]="popContent"
							[triggers]="popTrigger"
							placement="left"
							popoverClass="purchasing-help-popover"
							(shown)="handlePopoverShown()"
							(hidden)="handlePopoverHidden()"
							(click)="$event.stopPropagation()"
							#popover2="ngbPopover"
							*ngIf="incomeRecord.address.isPrimaryProperty"
						></i>
						<ng-template #popContent>
							<div class="simp-text simp-text-small">
								This is the property being {{ isRefinance ? "refinanced" : "purchased" }}
							</div>
						</ng-template>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>

				<div class="income-details__category">
					<div class="income-details__category-title simp-padding-vertical-base">
						{{ otherIncomeSection.title }}
						<button
							type="button"
							class="btn btn-sm simp-button income-details__category-add"
							(click)="addEditAdditionalIncome(incomeType.Other)"
						>
							Add<i class="fal fa-plus simp-margin-left-base"></i>
						</button>
					</div>

					<div
						class="income-details__income-summary simp-padding-small"
						*ngFor="let incomeRecord of income.otherIncome"
						(click)="addEditAdditionalIncome(incomeType.Other, incomeRecord)"
					>
						<h5 class="simp-h5 simp-text--extra-light income-details__summary-text">
							{{ numberOfApplicants > 1 ? getApplicantName(incomeRecord.applicantId) + ", " : "" }}{{
							getYearlyOtherIncome(incomeRecord) | currency: "USD":"symbol":"1.0-0" }} per year, {{ incomeRecord.type |
							enumDesc: otherIncomeType }}{{ showOtherIncomeDescription(incomeRecord) ? " - " + incomeRecord.description
							: "" }}
						</h5>
						<i class="fal fa-chevron-right"></i>
					</div>
				</div>
			</div>

			<div class="income-details__summary simp-margin-vertical-medium simp-padding-horizontal-small">
				<div>{{ totalAnnualIncome | currency: "USD":"symbol":"1.0-0" }}</div>
				<div>total per year</div>
			</div>

			<div class="income-details__buttons-container simp-row simp-margin-bottom-medium">
				<div class="col-6">
					<button type="button" class="btn btn-secondary btn-lg simp-button col-12" (click)="finishLater()">
						{{ finishButtonConfig.title }}
					</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary btn-lg simp-button col-12" [disabled]="!hasIncome">
						{{ doneButtonConfig.title }}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<additional-income-details
	[@slideInOut]="additionalInfoState"
	[incomeType]="additionalInfoType"
	(goBack)="toggleAdditionalInfoPage()"
	(updateIncome)="updateIncome($event)"
	(deleteIncome)="handleIncomeDelete($event)"
></additional-income-details>

<ng-template #overlayContent>
	<h4 class="simp-h4 overlay__message">We've saved where you're up to. Return and continue any time.</h4>
	<div class="simp-row overlay__buttons-container">
		<div class="col-6">
			<button class="btn btn-secondary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay()">
				Keep going
			</button>
		</div>
		<div class="col-6">
			<button class="btn btn-primary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay(true)">
				Exit
			</button>
		</div>
	</div>
</ng-template>
