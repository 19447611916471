import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ENV_CONFIG } from 'src/env-config';
import { Metadata } from '../model/metadata.model';
import { AreaMetadata } from '../model/area-metadata.model';
import { SectionMetadata } from '../model/section-metadata.model';
import { TextMetadata } from '../model/text-metadata.model';
import { ButtonMetadata } from '../model/button-metadata.model';
import { FieldMetadata } from '../model/field-metadata.model';
import { BrandService } from './brand.service';
import { SubSectionMetadata } from '../model/sub-section-metadata.model';

@Injectable({ providedIn: 'root' })
export class MetadataService extends BaseApiService<any> {
	public metadata$: Observable<Metadata>;

	private metadataValue: Metadata = {} as Metadata;
	private metadata: BehaviorSubject<Metadata> = new BehaviorSubject<Metadata>(this.metadataValue);

	constructor(http: HttpClient, toastr: ToastrService, router: Router, private brandService: BrandService) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('Metadata/Metadata');
		this.metadata$ = this.metadata.asObservable().pipe(filter((data: Metadata) => JSON.stringify(data) !== '{}'));
	}

	public getMetadata(): void {
		this.getCustom(`ByKey/${this.brandService.currentChannelKey}/ApprovalInPrinciple`).subscribe(
			(metadata: Metadata) => {
				this.metadataValue = metadata;
				this.metadata.next(this.metadataValue);
			},
			(error: any) => {
				this.http.get<Metadata>('assets/default-metadata.json').subscribe((metadata: Metadata) => {
					this.metadataValue = metadata;
					this.metadata.next(this.metadataValue);
				});
			}
		);
	}

	public getAreaMetadataByName(areaName: string): AreaMetadata {
		return this.metadataValue.areas.find((area: AreaMetadata) => area.name === areaName) ?? ({} as AreaMetadata);
	}

	public getSectionMetadataByName(areaName: string, sectionName: string): SectionMetadata {
		return (
			this.metadataValue.areas
				.find((area: AreaMetadata) => area.name === areaName)
				?.sections.find((section: SectionMetadata) => section.name === sectionName) ?? ({} as SectionMetadata)
		);
	}

	public getSubSectionMetadataByName(
		areaName: string,
		sectionName: string,
		subSectionName: string
	): SubSectionMetadata {
		return (
			this.metadataValue.areas
				.find((area: AreaMetadata) => area.name === areaName)
				?.sections.find((section: SectionMetadata) => section.name === sectionName)
				?.subsections.find((subSection: SubSectionMetadata) => subSection.name === subSectionName) ??
			({} as SubSectionMetadata)
		);
	}

	public getTextByName(textName: string, texts: TextMetadata[]): string {
		return texts ? texts.find((text: TextMetadata) => text.name === textName)?.title ?? '' : '';
	}

	public getButtonByName(buttonName: string, buttons: ButtonMetadata[]): ButtonMetadata {
		if (!buttons) {
			return {} as ButtonMetadata;
		}
		return buttons.find((button: ButtonMetadata) => button.name === buttonName) ?? ({} as ButtonMetadata);
	}

	public getFieldByName(fieldName: string, fields: FieldMetadata[]): FieldMetadata {
		if (!fields) {
			return {} as FieldMetadata;
		}
		return fields.find((field: FieldMetadata) => field.name === fieldName) ?? ({} as FieldMetadata);
	}
}
