import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { LoanDetailService } from 'src/app/loan-details/api/loan-detail.service';
import { LoanDetail } from 'src/app/loan-details/model/loan-detail.model';
import { OverlayService } from 'src/app/overlay/overlay.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { AddressHelper } from 'src/app/shared/helper/address-helper';
import { Constant } from 'src/app/shared/helper/constant';
import { Address } from 'src/app/shared/model/address.model';
import { AreaMetadata } from 'src/app/shared/model/area-metadata.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import {
	FinancialInstitution,
	LiabilityTypeLimited,
	RbaLendingPurpose,
	StepStatus,
	StepType
} from 'src/app/shared/model/enum.model';
import { SectionMetadata } from 'src/app/shared/model/section-metadata.model';
import { StepResponse } from 'src/app/shared/model/step-response.model';
import { TextMetadata } from 'src/app/shared/model/text-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { ApplicationStepService } from 'src/app/shared/service/application-step.service';
import { NavigationService } from '../../../navigation/navigation.service';
import { LiabilityService } from '../../api/liability.service';
import { LiabilityType } from '../../enums/liability-type.enum';
import { CreditCard } from '../../model/credit-card.model';
import { HomeLoan } from '../../model/home-loan.model';
import { Liability } from '../../model/liability.model';
import { OtherLiability } from '../../model/other-liability.model';
import { PersonalLoan } from '../../model/personal-loan.model';
import { LiabilityDetailService } from '../../service/liability-detail.service';

@Component({
	selector: 'liability-details',
	templateUrl: './liability-details.component.html',
	styleUrls: ['./liability-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideInOut', [
			state(
				'show',
				style({
					transform: 'translate3d(0,0,0)'
				})
			),
			state(
				'hide',
				style({
					transform: 'translate3d(100%, 0, 0)'
				})
			),
			transition('show => hide', animate('400ms ease-in-out')),
			transition('hide => show', animate('400ms ease-in-out'))
		])
	]
})
export class LiabilityDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('aipCompleteContent', { static: false })
	public aipCompleteContent!: TemplateRef<HTMLElement>;
	@ViewChild('finshLaterContent', { static: false })
	public finshLaterContent!: TemplateRef<HTMLElement>;

	public additionalInfoState: 'show' | 'hide' = 'hide';
	public additionalInfoType: LiabilityType = LiabilityType.CreditCard;
	public liabilityType = LiabilityType;
	public liability: Liability;
	public creditCardRecords: Array<any> = [];
	public totalLiabilityAmount = 0;
	public numberOfApplicants: number;
	public hasSelectedLiabilityPreference = false;
	public popTrigger: string;
	public isRefinance = false;

	public areaMetadata: AreaMetadata = {} as AreaMetadata;
	public creditCardSection: SectionMetadata = {} as SectionMetadata;
	public personalLoanSection: SectionMetadata = {} as SectionMetadata;
	public homeLoanSection: SectionMetadata = {} as SectionMetadata;
	public otherLiabilitiesSection: SectionMetadata = {} as SectionMetadata;
	public finishButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public doneButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public loadingMessage1 = '';
	public loadingMessage2Config: TextMetadata = {} as TextMetadata;

	private existingApplicationId = Constant.newId;
	private applicantId = Constant.newId;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private liabilityService: LiabilityService,
		private changeDetectorRef: ChangeDetectorRef,
		private liabilityDetailService: LiabilityDetailService,
		private applicantService: ApplicantService,
		private applicationStepService: ApplicationStepService,
		private overlayService: OverlayService,
		private metadataService: MetadataService,
		private loanDetailService: LoanDetailService
	) {
		this.setSectionMetadata();

		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.applicantId = this.applicationService.getStoredPrimaryApplicant()?.id ?? Constant.newId;
		this.liability = {
			creditCard: [],
			personalLoan: [],
			homeLoan: [],
			otherLiability: []
		};
		this.numberOfApplicants = this.applicantService.getApplicants(false).length;

		this.applicationService
			.getStepStatus(this.existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((stepResponses: StepResponse[]) => {
				this.hasSelectedLiabilityPreference =
					stepResponses.find((step: StepResponse) => step.type === StepType.Liabilities)?.status ===
					StepStatus.Complete;
			});

		this.popTrigger = this.isTouchDevice() ? 'click' : 'hover';

		this.loanDetailService.loanDetails$.pipe(takeUntil(this.destroy$)).subscribe((loanDetail: LoanDetail | null) => {
			this.isRefinance = loanDetail?.plan === RbaLendingPurpose.Refinance;
			this.changeDetectorRef.markForCheck();
		});
	}

	public get totalLiabilities(): number {
		const creditCardLiabilityAmount = this.liability.creditCard.map((i) => i.creditLimit).reduce((a, b) => a + b, 0);
		const personalLoanLiabilityAmount = this.liability.personalLoan.map((i) => i.loanAmount).reduce((a, b) => a + b, 0);
		const homeLoanLiabilityAmount = this.liability.homeLoan.map((i) => i.loanAmount).reduce((a, b) => a + b, 0);
		const otherLiabilityAmount = this.liability.otherLiability
			.map((i) => i.outstandingBalance)
			.reduce((a, b) => a + b, 0);
		return creditCardLiabilityAmount + personalLoanLiabilityAmount + homeLoanLiabilityAmount + otherLiabilityAmount;
	}

	public get hasLiability(): boolean {
		return (
			(!!this.liability?.creditCard?.length ||
				!!this.liability?.personalLoan?.length ||
				!!this.liability?.homeLoan?.length ||
				!!this.liability?.otherLiability?.length) &&
			this.totalLiabilities !== 0
		);
	}

	public get financialInstitution(): typeof FinancialInstitution {
		return FinancialInstitution;
	}

	public get liabilityTypeLimited(): typeof LiabilityTypeLimited {
		return LiabilityTypeLimited;
	}

	public ngOnInit(): void {
		this.navigationService.syncNavigation(StepType.Liabilities);
		this.loadLiabilities();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public getApplicantName(applicantId: number): string {
		return this.applicantService.getApplicantName(applicantId)?.name ?? '';
	}

	public buildAddressLine(address: Address): string {
		return AddressHelper.buildAddressLine(address);
	}

	public onSubmit(): void {
		this.applicationStepService.updateStep(StepType.Liabilities, StepStatus.Complete);
		this.navigationService.goToNextStep(SimpProgressBarStatus.Complete);

		if (this.navigationService.hasAnyIncompleteStep()) {
			this.navigationService.reviewWorkflowCompletion();
		} else {
			this.overlayService.startOverlay(this.aipCompleteContent);
			setTimeout(() => {
				this.navigationService.reviewWorkflowCompletion();
				this.overlayService.stopOverlay();
			}, 2500);
		}
	}

	public toggleAdditionalInfoPage(): void {
		this.additionalInfoState = this.additionalInfoState === 'hide' ? 'show' : 'hide';
	}

	public addEditAdditionalLiability(
		liabilityType: LiabilityType,
		liability?: CreditCard | PersonalLoan | HomeLoan | OtherLiability
	): void {
		this.additionalInfoType = liabilityType;
		switch (liabilityType) {
			case LiabilityType.CreditCard:
				this.liabilityDetailService.addEditCreditCard(liability as CreditCard);
				break;
			case LiabilityType.PersonalLoan:
				this.liabilityDetailService.addEditPersonalLoan(liability as PersonalLoan);
				break;
			case LiabilityType.HomeLoan:
				this.liabilityDetailService.addEditHomeLoan(liability as HomeLoan);
				break;
			case LiabilityType.Other:
				this.liabilityDetailService.addEditOtherLiability(liability as OtherLiability);
				break;
		}
		this.toggleAdditionalInfoPage();
	}

	public updateLiability(liability: CreditCard | PersonalLoan | HomeLoan | OtherLiability): void {
		this.toggleAdditionalInfoPage();
		switch (this.additionalInfoType) {
			case LiabilityType.CreditCard:
				this.updateCreditCardDetails(liability as CreditCard);
				break;
			case LiabilityType.PersonalLoan:
				this.updatePersonalLoanDetails(liability as PersonalLoan);
				break;
			case LiabilityType.HomeLoan:
				this.updateHomeLoanDetails(liability as HomeLoan);
				break;
			case LiabilityType.Other:
				this.updateOtherLiabilityDetails(liability as OtherLiability);
				break;
		}
	}

	public handleLiabilityDelete(liability: CreditCard | PersonalLoan | HomeLoan | OtherLiability): void {
		this.toggleAdditionalInfoPage();
		let index = -1;
		switch (this.additionalInfoType) {
			case LiabilityType.CreditCard:
				{
					const creditCards = this.liability.creditCard;
					index = creditCards.findIndex((i) => i.id === liability.id);
					if (index > -1) {
						this.liability.creditCard.splice(index, 1);
					}
				}
				break;
			case LiabilityType.HomeLoan:
				{
					const homeLoans = this.liability.homeLoan;
					index = homeLoans.findIndex((i) => i.id === liability.id);
					if (index > -1) {
						this.liability.homeLoan.splice(index, 1);
					}
				}
				break;
			case LiabilityType.PersonalLoan:
				{
					const personalLoans = this.liability.personalLoan;
					index = personalLoans.findIndex((i) => i.id === liability.id);
					if (index > -1) {
						this.liability.personalLoan.splice(index, 1);
					}
				}
				break;
			case LiabilityType.Other:
				{
					const otherLiabilities = this.liability.otherLiability;
					index = otherLiabilities.findIndex((i) => i.id === liability.id);
					if (index > -1) {
						this.liability.otherLiability.splice(index, 1);
					}
				}
				break;
		}

		if (!this.hasLiability) {
			this.applicationStepService.updateStep(StepType.Liabilities, StepStatus.Incomplete);
			this.navigationService.updateStepStatus(StepType.Liabilities, SimpProgressBarStatus.NotStarted);
			this.hasSelectedLiabilityPreference = false;
		}
	}

	public updateCreditCardInfo(creditCardInfo: any): void {
		this.creditCardRecords.push({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			financialInstitution: creditCardInfo.financialInstitution,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			creditCardLimit: creditCardInfo.creditCardLimit,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			sharedCreditCard: creditCardInfo.sharedCreditCard
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		this.totalLiabilityAmount += +creditCardInfo.creditCardLimit;
	}

	public finishLater(): void {
		this.overlayService.startOverlay(this.finshLaterContent);
	}

	public closeOverlay(exit = false): void {
		this.overlayService.stopOverlay();
		if (exit) {
			this.navigationService.exitApp();
		}
	}

	public showOtherLiabilityDescription(liability: OtherLiability): boolean {
		return liability.type === LiabilityTypeLimited.Other;
	}

	public handlePopoverShown(): void {
		if (this.isTouchDevice()) {
			this.overlayService.startOverlay(null);
		}
	}

	public handlePopoverHidden(): void {
		if (this.isTouchDevice()) {
			this.overlayService.stopOverlay();
		}
	}

	private updateCreditCardDetails(creditCardDetails: CreditCard): void {
		const creditCards = this.liability.creditCard;
		const index = creditCards.findIndex((creditCard) => creditCard.id === creditCardDetails.id);
		if (index > -1) {
			this.liability.creditCard.splice(index, 1, creditCardDetails);
		} else {
			this.liability.creditCard.push(creditCardDetails);
		}
	}

	private updatePersonalLoanDetails(personalLoanDetails: PersonalLoan): void {
		const personalLoans = this.liability.personalLoan;
		const index = personalLoans.findIndex((loan) => loan.id === personalLoanDetails.id);
		if (index > -1) {
			this.liability.personalLoan.splice(index, 1, personalLoanDetails);
		} else {
			this.liability.personalLoan.push(personalLoanDetails);
		}
	}

	private updateHomeLoanDetails(homeLoanDetails: HomeLoan): void {
		const homeLoans = this.liability.homeLoan;
		const index = homeLoans.findIndex((loan) => loan.id === homeLoanDetails.id);
		if (index > -1) {
			this.liability.homeLoan.splice(index, 1, homeLoanDetails);
		} else {
			this.liability.homeLoan.push(homeLoanDetails);
		}
	}

	private updateOtherLiabilityDetails(otherLiabilityDetails: OtherLiability): void {
		const otherLiabilities = this.liability.otherLiability;
		const index = otherLiabilities.findIndex((liability) => liability.id === otherLiabilityDetails.id);
		if (index > -1) {
			this.liability.otherLiability.splice(index, 1, otherLiabilityDetails);
		} else {
			this.liability.otherLiability.push(otherLiabilityDetails);
		}
	}

	private loadLiabilities(): void {
		if (this.existingApplicationId !== Constant.newId && this.applicantId !== Constant.newId) {
			const getCreditcards = this.liabilityService
				.getCreditCardsByApplication(this.existingApplicationId)
				.pipe(catchError((error) => of([])));
			const getHomeLoans = this.liabilityService
				.getHomeLoansByApplication(this.existingApplicationId)
				.pipe(catchError((error) => of([])));
			const getPersonalLoans = this.liabilityService
				.getPersonalLoansByApplication(this.existingApplicationId)
				.pipe(catchError((error) => of([])));
			const getOtherLiabilities = this.liabilityService
				.getOtherLiabilitiesByApplication(this.existingApplicationId)
				.pipe(catchError((error) => of([])));
			forkJoin([getCreditcards, getHomeLoans, getPersonalLoans, getOtherLiabilities])
				.pipe(takeUntil(this.destroy$))
				.subscribe((results) => {
					this.liability.creditCard = [...results[0]];
					this.liability.homeLoan = [...results[1]];
					this.liability.personalLoan = [...results[2]];
					this.liability.otherLiability = [...results[3]];
					this.changeDetectorRef.markForCheck();
				});
		} else {
			this.navigationService.goToHome();
		}
	}

	private isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	private setSectionMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.areaMetadata = this.metadataService.getAreaMetadataByName('Liabilities');
			this.creditCardSection = this.metadataService.getSectionMetadataByName('Liabilities', LiabilityType.CreditCard);
			this.personalLoanSection = this.metadataService.getSectionMetadataByName(
				'Liabilities',
				LiabilityType.PersonalLoan
			);
			this.homeLoanSection = this.metadataService.getSectionMetadataByName('Liabilities', LiabilityType.HomeLoan);
			this.otherLiabilitiesSection = this.metadataService.getSectionMetadataByName('Liabilities', LiabilityType.Other);

			const buttons: ButtonMetadata[] = this.areaMetadata.buttons;
			this.finishButtonConfig = this.metadataService.getButtonByName('Finish', buttons);
			this.doneButtonConfig = this.metadataService.getButtonByName('Done', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.loadingMessage1 = this.metadataService.getTextByName('LoadingMessage1', this.areaMetadata.texts);

			this.loadingMessage2Config = this.areaMetadata.texts?.find(
				(text: TextMetadata) => text.name === 'LoadingMessage2'
			) as TextMetadata;
		});
	}
}
