<div class="dashboard">
	<div
		class="dashboard__welcome-box simp-box-shadow simp-padding-medium simp-margin-top-medium simp-margin-bottom-large"
	>
		<h2 class="simp-h2 dashboard__greeting-header">
			{{ loggedInUserFirstName ? loggedInUserFirstName + ", " : "" }}{{ headerMessage }}
		</h2>
		<h5 class="simp-h5 simp-text--extra-light" *ngIf="subHeader !== ''">{{ subHeader }}</h5>
		<img
			src="{{ './../assets/brands/' + brandName + '/images/brand-logo.png' }}"
			class="simp-margin-vertical-large dashboard__brand-logo"
		/>
		<h2 class="simp-h2">{{ applicationStatus }}</h2>
		<h5 class="simp-h5">{{ message }}</h5>
		<button
			type="button"
			class="btn btn-tertiary btn-md simp-button simp-margin-top-large dashboard__update-button"
			(click)="review()"
			*ngIf="!canShowCompleteApplication"
		>
			View details<i class="fal fa-arrow-right simp-margin-left-base"></i>
		</button>
	</div>

	<div class="dashboard__reference__reference-container">
		<h5 class="simp-h5 simp-text--bold">Your reference number</h5>
		<h5 class="simp-h3 simp-text--bold dashboard__reference-number">{{ referenceNumber }}</h5>
	</div>

	<div class="simp-margin-top-small" *ngIf="completedLoading">
		<button
			type="button"
			class="btn btn-primary btn-lg simp-button dashboard__button"
			(click)="close()"
			*ngIf="!canShowCompleteApplication"
		>
			Close and logout
		</button>

		<button
			type="button"
			class="btn btn-primary btn-lg simp-button dashboard__button"
			(click)="complete()"
			*ngIf="canShowCompleteApplication"
		>
			Complete application
		</button>
	</div>
</div>
