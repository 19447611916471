<header class="app-header" [class.app-header--alt]="!showProgressTracker">
	<div class="app-header__contents simp-padding-right-small">
		<div
			class="app-header__back-button"
			[class.app-header__back-button--lighter]="showProgressTracker"
			(click)="goBack()"
		>
			<i class="far fa-arrow-left" *ngIf="showProgressTracker"></i>
			<div class="app-header__app-icon" *ngIf="!showProgressTracker">
				<i class="fas fa-clipboard-check simp-text--tertiary"></i>
			</div>
		</div>

		<div [class.simp-padding-left-small]="showProgressTracker">
			<ng-container *ngIf="showProgressTracker">
				<h6 class="simp-h6">{{ appTitle }}</h6>
				<h5 class="simp-h5 simp-text--bold">{{ currentStep.stepName }}</h5>
			</ng-container>
			<h5 class="simp-h5 simp-text--bold" *ngIf="!showProgressTracker">{{ appTitle }}</h5>
		</div>

		<div class="app-header__brand-logo-container">
			<img
				class="app-header__brand-logo"
				[class.app-header__brand-logo--centered]="!showProgressTracker"
				src="{{ './../../assets/brands/' + brandName + '/images/brand-logo-alt.png' }}"
				alt="brand-logo"
			/>
		</div>

		<button
			type="button"
			class="btn simp-button app-header__button"
			[ngClass]="showProgressTracker ? 'btn-header' : 'btn-header-alt'"
			(click)="exitApp()"
		>
			Exit
		</button>
	</div>
</header>

<ng-template #overlayContent>
	<h4 class="simp-h4 overlay__message">Exit application. Are you sure?</h4>
	<div class="simp-row overlay__buttons-container">
		<div class="col-6">
			<button class="btn btn-secondary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay()">No</button>
		</div>
		<div class="col-6">
			<button class="btn btn-primary btn-lg simp-button col-12 overlay__button" (click)="closeOverlay(true)">
				Yes
			</button>
		</div>
	</div>
</ng-template>
