import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { OtherAsset } from '../model/other-asset.model';
import { SavingsAsset } from '../model/savings-asset.model';
import { PropertyAsset } from '../model/property-asset.model';
import { PropertyAssetResponse } from '../model/property-asset-response.model';
import { ENV_CONFIG } from 'src/env-config';

@Injectable({ providedIn: 'root' })
export class AssetService extends BaseApiService<any> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/Asset');
	}

	public getSavingsByApplication(applicationId: number): Observable<SavingsAsset[]> {
		return <Observable<SavingsAsset[]>>this.getCustom(`Savings/${applicationId}`);
	}

	public getPropertiesByApplication(applicationId: number): Observable<PropertyAsset[]> {
		return <Observable<PropertyAsset[]>>this.getCustom(`Property/${applicationId}`);
	}

	public getOtherAssetsByApplication(applicationId: number): Observable<OtherAsset[]> {
		return <Observable<OtherAsset[]>>this.getCustom(`OtherAsset/${applicationId}`);
	}

	public savePropertyAsset(asset: PropertyAsset): Observable<PropertyAssetResponse> {
		return <Observable<PropertyAssetResponse>>this.postCustom('Property', asset);
	}

	public saveSavingsAsset(asset: SavingsAsset): Observable<number> {
		return <Observable<number>>this.postCustom('Savings', asset);
	}

	public saveOtherAsset(asset: OtherAsset): Observable<number> {
		return <Observable<number>>this.postCustom('OtherAsset', asset);
	}

	public deleteSavings(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`Savings/${applicationId}/${id}`);
	}

	public deleteProperty(applicationId: number, id: number, applicantId: number): Observable<void> {
		return <Observable<void>>this.delete(`Property/${applicationId}/${id}/${applicantId}`);
	}

	public deleteOtherAsset(applicationId: number, id: number): Observable<void> {
		return <Observable<void>>this.delete(`OtherAsset/${applicationId}/${id}`);
	}
}
