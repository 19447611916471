import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ENV_CONFIG } from 'src/env-config';
import { LoanDetail } from '../model/loan-detail.model';

@Injectable({ providedIn: 'root' })
export class LoanDetailService extends BaseApiService<LoanDetail> {
	public loanDetails$: Observable<LoanDetail | null>;
	private loanDetailsValue: LoanDetail | null = null;
	private loanDetails: BehaviorSubject<LoanDetail | null> = new BehaviorSubject<LoanDetail | null>(
		this.loanDetailsValue
	);

	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('InPrinciple/LoanDetails');
		this.loanDetails$ = this.loanDetails.asObservable().pipe(filter((data) => !!data));
	}

	public getByApplication(applicationId: number): Observable<LoanDetail> {
		if (this.loanDetailsValue) {
			return this.loanDetails$ as Observable<LoanDetail>;
		}

		return this.getCustom(`${applicationId}`).pipe(
			switchMap((loanDetail: LoanDetail) => {
				this.loanDetailsValue = loanDetail;
				this.loanDetails.next(this.loanDetailsValue);
				return of(loanDetail);
			})
		);
	}

	public save(detail: LoanDetail): Observable<number> {
		return <Observable<number>>this.post('', detail);
	}

	public fetchLoanDetails(applicationId: number): void {
		this.getCustom(`${applicationId}`).subscribe((loanDetail: LoanDetail) => {
			this.loanDetailsValue = loanDetail;
			this.loanDetails.next(this.loanDetailsValue);
		});
	}

	public clearLoanDetailsCache(): void {
		this.loanDetailsValue = null;
	}
}
