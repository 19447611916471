import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { takeUntil, catchError } from 'rxjs/operators';
import { forkJoin, of, Subject } from 'rxjs';
import { PropertyAsset } from 'src/app/asset-details/model/property-asset.model';
import { OverlayService } from 'src/app/overlay/overlay.service';
import { ServiceabilityService } from 'src/app/serviceability-result/service/serviceability.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import { SectionMetadata } from 'src/app/shared/model/section-metadata.model';
import { BrandService } from 'src/app/shared/api/brand.service';
import { AssetService } from '../../../asset-details/api/asset.service';
import { Asset } from '../../../asset-details/model/asset.model';
import { IncomeService } from '../../../income-details/api/income.service';
import { Income } from '../../../income-details/model/income.model';
import { OtherIncome } from '../../../income-details/model/other-income.model';
import { RentalIncome } from '../../../income-details/model/rental-income.model';
import { WorkIncome } from '../../../income-details/model/work-income.model';
import { LiabilityService } from '../../../liability-details/api/liability.service';
import { Liability } from '../../../liability-details/model/liability.model';
import { LoanDetailService } from '../../../loan-details/api/loan-detail.service';
import { LoanDetail } from '../../../loan-details/model/loan-detail.model';
import { NavigationService } from '../../../navigation/navigation.service';
import { PersonalDetailService } from '../../../personal-details/api/personal-detail.service';
import { PersonalDetail } from '../../../personal-details/model/personal-detail.model';
import { ApplicationService } from '../../../shared/api/application.service';
import { AddressHelper } from '../../../shared/helper/address-helper';
import { CalculationHelper } from '../../../shared/helper/calculation-helper';
import { Constant } from '../../../shared/helper/constant';
import { Address } from '../../../shared/model/address.model';
import {
	AuState,
	FinancialAssetType,
	FinancialInstitution,
	FrequencyShort,
	JourneyStatus,
	LiabilityTypeLimited,
	MaritalStatus,
	NameTitle,
	NonRealEstateAssetType,
	OtherIncomeType,
	PaygBasis,
	PrimaryPurposeLoanPurpose,
	RbaLendingPurpose,
	ServiceabilityResult,
	StepType
} from '../../../shared/model/enum.model';
import { ApplicantService } from '../../../shared/service/applicant.service';
import { AreaMetadata } from 'src/app/shared/model/area-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { BrandConfig } from 'src/app/shared/model/brand.config';

@Component({
	selector: 'review-details',
	templateUrl: './review-details.component.html',
	styleUrls: ['./review-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('overlayContent', { static: false })
	public overlayContent!: TemplateRef<HTMLElement>;

	public loanDetails: LoanDetail = {} as LoanDetail;
	public primaryApplicant: PersonalDetail = {} as PersonalDetail;
	public additionalApplicant: PersonalDetail = {} as PersonalDetail;
	public incomeDetails: Income = {} as Income;
	public assetDetails: Asset = {} as Asset;
	public liabilityDetails: Liability = {} as Liability;
	public numberOfApplicants: number;
	public isSubmitting = false;
	public brandName = '';
	public loanDetailsTitle = 'Loan Details';
	public stepType = StepType;

	public toggleLoanDetails = false;
	public togglePersonalDetails = false;
	public toggleIncomeDetails = false;
	public toggleAssetDetails = false;
	public toggleLiabilityDetails = false;
	public reviewAreaMetadata: AreaMetadata = {} as AreaMetadata;
	public summaryAreaMetadata: AreaMetadata = {} as AreaMetadata;
	public reviewSection: SectionMetadata = {} as SectionMetadata;
	public summarySection: SectionMetadata = {} as SectionMetadata;
	public submitButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public loanDetailsSubSection: SubSectionMetadata = {} as SubSectionMetadata;
	public applicantDetailsSubSection: SubSectionMetadata = {} as SubSectionMetadata;
	public incomeSubSection: SubSectionMetadata = {} as SubSectionMetadata;
	public expensesSubSection: SubSectionMetadata = {} as SubSectionMetadata;
	public assetsSubSection: SubSectionMetadata = {} as SubSectionMetadata;
	public liabilitiesSubSection: SubSectionMetadata = {} as SubSectionMetadata;
	public loadingMessage = '';

	private destroy$: Subject<void> = new Subject();
	private existingApplicationId = Constant.newId;
	private journeyStatus?: JourneyStatus;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private datePipe: DatePipe,
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private loanDetailService: LoanDetailService,
		private personalDetailService: PersonalDetailService,
		private incomeService: IncomeService,
		private assetService: AssetService,
		private liabilityService: LiabilityService,
		private applicantService: ApplicantService,
		private serviceabilityService: ServiceabilityService,
		private overlayService: OverlayService,
		private metadataService: MetadataService,
		private brandService: BrandService
	) {
		this.setSectionMetadata();

		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.journeyStatus = this.applicationService.getStoredJourneyStatus();
		this.numberOfApplicants = this.applicantService.getApplicants(false).length;

		this.brandService.brandConfig$.pipe(takeUntil(this.destroy$)).subscribe((brandConfig: BrandConfig) => {
			this.brandName = brandConfig.brandName;
		});
	}

	public get rbaLendingPurpose(): typeof RbaLendingPurpose {
		return RbaLendingPurpose;
	}

	public get primaryPurposeLoanPurpose(): typeof PrimaryPurposeLoanPurpose {
		return PrimaryPurposeLoanPurpose;
	}

	public get auState(): typeof AuState {
		return AuState;
	}

	public get nameTitle(): typeof NameTitle {
		return NameTitle;
	}

	public get maritalStatus(): typeof MaritalStatus {
		return MaritalStatus;
	}

	public get paygBasis(): typeof PaygBasis {
		return PaygBasis;
	}

	public get otherIncomeType(): typeof OtherIncomeType {
		return OtherIncomeType;
	}

	public get financialAssetType(): typeof FinancialAssetType {
		return FinancialAssetType;
	}

	public get nonRealEstateAssetType(): typeof NonRealEstateAssetType {
		return NonRealEstateAssetType;
	}

	public get financialInstitution(): typeof FinancialInstitution {
		return FinancialInstitution;
	}

	public get liabilityTypeLimited(): typeof LiabilityTypeLimited {
		return LiabilityTypeLimited;
	}

	public get isAppEditable(): boolean {
		return this.journeyStatus === undefined || this.journeyStatus === JourneyStatus.DataEntry;
	}

	public get showAdditionalApplicant(): boolean {
		return this.loanDetails.numberOfApplicants > 1 && !!this.additionalApplicant.title;
	}

	public get showPartnerCoApplicant(): boolean {
		return (
			this.showAdditionalApplicant &&
			[MaritalStatus.DeFacto, MaritalStatus.Married].includes(this.primaryApplicant.maritalStatus)
		);
	}

	public get totalAnnualIncome(): number {
		const workIncomeAmount = this.incomeDetails.workIncome
			?.map(
				(i) =>
					CalculationHelper.CalculateAnnualAmount(i.salaryAmount, i.salaryFrequency) +
					CalculationHelper.CalculateAnnualAmount(i.otherAmount ?? 0, i.otherFrequency ?? FrequencyShort.Yearly)
			)
			.reduce((a, b) => a + b, 0);
		const rentalIncomeAmount = this.incomeDetails.rentalIncome
			?.map((i) => CalculationHelper.CalculateAnnualAmount(i.amount, i.frequency))
			.reduce((a, b) => a + b, 0);
		const otherIncomeAmount = this.incomeDetails.otherIncome
			?.map((i) => CalculationHelper.CalculateAnnualAmount(i.amount, i.frequency))
			.reduce((a, b) => a + b, 0);
		return workIncomeAmount + rentalIncomeAmount + otherIncomeAmount;
	}

	public get totalAssets(): number {
		const propertyAssetAmount = this.assetDetails.propertyAsset
			?.map((i) => (i.value * i.percent) / 100)
			.reduce((a, b) => a + b, 0);
		const savingsAssetAmount = this.assetDetails.savingsAsset?.map((i) => i.value).reduce((a, b) => a + b, 0);
		const otherAssetAmount = this.assetDetails.otherAsset?.map((i) => i.value).reduce((a, b) => a + b, 0);
		return propertyAssetAmount + savingsAssetAmount + otherAssetAmount;
	}

	public get totalLiabilities(): number {
		const creditCardLiabilityAmount = this.liabilityDetails.creditCard
			?.map((i) => i.creditLimit)
			.reduce((a, b) => a + b, 0);
		const personalLoanLiabilityAmount = this.liabilityDetails.personalLoan
			?.map((i) => i.loanAmount)
			.reduce((a, b) => a + b, 0);
		const homeLoanLiabilityAmount = this.liabilityDetails.homeLoan?.map((i) => i.loanAmount).reduce((a, b) => a + b, 0);
		const otherLiabilityAmount = this.liabilityDetails.otherLiability
			?.map((i) => i.outstandingBalance)
			.reduce((a, b) => a + b, 0);
		return creditCardLiabilityAmount + personalLoanLiabilityAmount + homeLoanLiabilityAmount + otherLiabilityAmount;
	}

	public getYearlyWorkIncome(incomeRecord: WorkIncome): number {
		return (
			CalculationHelper.CalculateAnnualAmount(incomeRecord.salaryAmount, incomeRecord.salaryFrequency) +
			CalculationHelper.CalculateAnnualAmount(
				incomeRecord.otherAmount ?? 0,
				incomeRecord.otherFrequency ?? FrequencyShort.Yearly
			)
		);
	}

	public getYearlyRentalIncome(incomeRecord: RentalIncome): number {
		return CalculationHelper.CalculateAnnualAmount(incomeRecord.amount, incomeRecord.frequency);
	}

	public getYearlyOtherIncome(incomeRecord: OtherIncome): number {
		return CalculationHelper.CalculateAnnualAmount(incomeRecord.amount, incomeRecord.frequency);
	}

	public ngOnInit(): void {
		this.populateStepSummaries();
	}

	public ngAfterViewInit(): void {
		setTimeout(() => {
			this.navigationService.toggleProgressTracker();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public onSubmit(): void {
		//TODO Confirmation?
		if (this.existingApplicationId !== Constant.newId) {
			this.isSubmitting = true;
			this.overlayService.startOverlay(this.overlayContent);
			this.applicationService
				.submitApplication(this.existingApplicationId)
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: number) => {
					this.serviceabilityService.serviceabilityResult(result === ServiceabilityResult.Approved);
					setTimeout(() => {
						this.navigationService.goToResult();
						this.overlayService.stopOverlay();
					}, 2500);
				})
				.add(() => {
					this.isSubmitting = false;
					this.changeDetectorRef.markForCheck();
				});
		}
	}

	public editSection(section: StepType, editable: boolean): void {
		if (editable) {
			this.navigationService.goToStep(section);
		}

		switch (section) {
			case StepType.LoanDetails:
				this.toggleLoanDetails = !this.toggleLoanDetails;
				break;
			case StepType.PersonalDetails:
				this.togglePersonalDetails = !this.togglePersonalDetails;
				break;
			case StepType.Income:
				this.toggleIncomeDetails = !this.toggleIncomeDetails;
				break;
			case StepType.Assets:
				this.toggleAssetDetails = !this.toggleAssetDetails;
				break;
			case StepType.Liabilities:
				this.toggleLiabilityDetails = !this.toggleLiabilityDetails;
				break;
		}
	}

	public getApplicantName(applicantId: number): string {
		return this.applicantService.getApplicantName(applicantId)?.name ?? '';
	}

	public getCalculatedAssetValue(assetRecord: PropertyAsset): number {
		return (assetRecord.value * assetRecord.percent) / 100;
	}

	public buildAddressLine(address?: Address): string {
		if (address) {
			return AddressHelper.buildAddressLine(address);
		}

		return '';
	}

	public getLoanDetailsLabel(field: string): string {
		return this.metadataService.getTextByName(field, this.loanDetailsSubSection.texts);
	}

	public getApplicantDetailsLabel(field: string): string {
		return this.metadataService.getTextByName(field, this.applicantDetailsSubSection.texts);
	}

	public getIncomeLabel(field: string): string {
		return this.metadataService.getTextByName(field, this.incomeSubSection.texts);
	}

	public getAssetsLabel(field: string): string {
		return this.metadataService.getTextByName(field, this.assetsSubSection.texts);
	}

	public getLiabilitiesLabel(field: string): string {
		return this.metadataService.getTextByName(field, this.liabilitiesSubSection.texts);
	}

	private populateStepSummaries(): void {
		if (this.existingApplicationId !== Constant.newId) {
			this.getLoanDetails();
			this.getPersonalDetails(this.existingApplicationId);
			this.getIncomeDetails(this.existingApplicationId);
			this.getAssetDetails(this.existingApplicationId);
			this.getLiabilityDetails(this.existingApplicationId);
		}
	}

	private getLoanDetails(): void {
		this.loanDetailService.loanDetails$
			.pipe(takeUntil(this.destroy$))
			.subscribe((loanDetailResult: LoanDetail | null) => {
				this.loanDetails = loanDetailResult as LoanDetail;
				this.changeDetectorRef.markForCheck();
			});
	}

	private getPersonalDetails(existingApplicationId: number): void {
		this.personalDetailService
			.getByApplication(existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: PersonalDetail[]) => {
				this.primaryApplicant =
					result.find((personalDetails: PersonalDetail) => personalDetails.primaryApplicant) ?? ({} as PersonalDetail);

				if (this.primaryApplicant.dateOfBirth) {
					this.primaryApplicant.dateOfBirth =
						this.datePipe.transform(this.primaryApplicant.dateOfBirth, 'dd MMM yyyy') ?? '';
				}

				this.additionalApplicant =
					result.find((personalDetails: PersonalDetail) => !personalDetails.primaryApplicant) ?? ({} as PersonalDetail);
				this.changeDetectorRef.markForCheck();
			});
	}

	private getIncomeDetails(existingApplicationId: number): void {
		this.incomeService
			.getByApplication(existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: Income) => {
				this.incomeDetails = result;

				this.changeDetectorRef.markForCheck();
			});
	}

	private getAssetDetails(existingApplicationId: number): void {
		const getPropertyAssets = this.assetService
			.getPropertiesByApplication(existingApplicationId)
			.pipe(catchError((error) => of([])));
		const getSavings = this.assetService
			.getSavingsByApplication(existingApplicationId)
			.pipe(catchError((error) => of([])));
		const getOtherAssets = this.assetService
			.getOtherAssetsByApplication(existingApplicationId)
			.pipe(catchError((error) => of([])));
		forkJoin([getPropertyAssets, getSavings, getOtherAssets])
			.pipe(takeUntil(this.destroy$))
			.subscribe((results) => {
				this.assetDetails.propertyAsset = [...results[0]];
				this.assetDetails.savingsAsset = [...results[1]];
				this.assetDetails.otherAsset = [...results[2]];
				this.changeDetectorRef.markForCheck();
			});
	}

	private getLiabilityDetails(existingApplicationId: number): void {
		const getCreditcards = this.liabilityService
			.getCreditCardsByApplication(existingApplicationId)
			.pipe(catchError((error) => of([])));
		const getHomeLoans = this.liabilityService
			.getHomeLoansByApplication(existingApplicationId)
			.pipe(catchError((error) => of([])));
		const getPersonalLoans = this.liabilityService
			.getPersonalLoansByApplication(existingApplicationId)
			.pipe(catchError((error) => of([])));
		const getOtherLiabilities = this.liabilityService
			.getOtherLiabilitiesByApplication(existingApplicationId)
			.pipe(catchError((error) => of([])));
		forkJoin([getCreditcards, getHomeLoans, getPersonalLoans, getOtherLiabilities])
			.pipe(takeUntil(this.destroy$))
			.subscribe((results) => {
				this.liabilityDetails.creditCard = [...results[0]];
				this.liabilityDetails.homeLoan = [...results[1]];
				this.liabilityDetails.personalLoan = [...results[2]];
				this.liabilityDetails.otherLiability = [...results[3]];
				this.changeDetectorRef.markForCheck();
			});
	}

	private setSectionMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.reviewAreaMetadata = this.metadataService.getAreaMetadataByName('Review');
			this.reviewSection = this.metadataService.getSectionMetadataByName('Review', 'Review');

			this.submitButtonConfig = this.metadataService.getButtonByName('Submit', this.reviewAreaMetadata.buttons);
			this.loadingMessage = this.metadataService.getTextByName('LoadingMessage', this.reviewAreaMetadata.texts);

			this.loanDetailsSubSection = this.metadataService.getSubSectionMetadataByName('Review', 'Review', 'LoanDetails');
			if (this.loanDetailsSubSection.title) {
				this.loanDetailsTitle = this.loanDetailsSubSection.title;
			}
			this.applicantDetailsSubSection = this.metadataService.getSubSectionMetadataByName(
				'Review',
				'Review',
				'ApplicantDetails'
			);
			this.incomeSubSection = this.metadataService.getSubSectionMetadataByName('Review', 'Review', 'Income');
			this.expensesSubSection = this.metadataService.getSubSectionMetadataByName('Review', 'Review', 'Expenses');
			this.assetsSubSection = this.metadataService.getSubSectionMetadataByName('Review', 'Review', 'Assets');
			this.liabilitiesSubSection = this.metadataService.getSubSectionMetadataByName('Review', 'Review', 'Liabilities');
			this.summaryAreaMetadata = this.metadataService.getAreaMetadataByName('Summary');
			this.summarySection = this.metadataService.getSectionMetadataByName('Summary', 'Summary');

			this.changeDetectorRef.markForCheck();
		});
	}
}
