/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { OptionMetadata, SimpAddress } from '@simpology/client-components/utils';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LoanDetailService } from 'src/app/loan-details/api/loan-detail.service';
import { LoanDetail } from 'src/app/loan-details/model/loan-detail.model';
import { OverlayService } from 'src/app/overlay/overlay.service';
import { AddressService } from 'src/app/shared/api/address.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { AddressHelper } from 'src/app/shared/helper/address-helper';
import { Constant } from 'src/app/shared/helper/constant';
import { CurrencyHelper } from 'src/app/shared/helper/currency-helper';
import { EnumHelper } from 'src/app/shared/helper/enum-helper';
import { ValidationHelper } from 'src/app/shared/helper/validation-helper';
import { Address } from 'src/app/shared/model/address.model';
import { Applicant } from 'src/app/shared/model/applicant.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import {
	EnumObject,
	FinancialInstitution,
	RbaLendingPurpose,
	RepaymentFrequency
} from 'src/app/shared/model/enum.model';
import { FieldMetadata } from 'src/app/shared/model/field-metadata.model';
import { SubSectionMetadata } from 'src/app/shared/model/sub-section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { LiabilityService } from '../../api/liability.service';
import { LiabilityType } from '../../enums/liability-type.enum';
import { HomeLoan } from '../../model/home-loan.model';
import { LiabilityDetailService } from '../../service/liability-detail.service';

@Component({
	selector: 'home-loan-details',
	templateUrl: './home-loan-details.component.html',
	styleUrls: ['./home-loan-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeLoanDetailsComponent implements OnInit, OnDestroy {
	@Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
	@Output() public update: EventEmitter<any> = new EventEmitter<any>();
	@Output() public delete: EventEmitter<any> = new EventEmitter<any>();

	public homeLoanDetailsForm: UntypedFormGroup;
	public validationErrors: ValidationErrors;
	public options: {
		repaymentFrequencies: EnumObject[];
		financialInstitutions: EnumObject[];
		applicants: Applicant[];
		clearingFromThisLoanOptions: EnumObject[];
	} = {
		repaymentFrequencies: [],
		financialInstitutions: [],
		applicants: [],
		clearingFromThisLoanOptions: []
	};
	public isEditMode = false;
	public existingAddresses: Array<{ id: number; label: string; address: Address }> = [];
	public allowedCountries = AddressHelper.allowedCountries;
	public isRefinance = false;
	public isSubmitting = false;
	public popTrigger: string;

	public sectionTitle = '';
	public whoseLoanConfig: FieldMetadata = {} as FieldMetadata;
	public financialInstitutionConfig: FieldMetadata = {} as FieldMetadata;
	public addressConfig: FieldMetadata = {} as FieldMetadata;
	public loanAmountConfig: FieldMetadata = {} as FieldMetadata;
	public repaymentConfig: FieldMetadata = {} as FieldMetadata;
	public repaymentFrequencyConfig: FieldMetadata = {} as FieldMetadata;
	public refinancedConfig: FieldMetadata = {} as FieldMetadata;
	public cancelButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public continueButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private existingApplicationId: number;
	private destroy$: Subject<void> = new Subject();
	private selectedAddress: SimpAddress;

	private maxRepaymentValidator = (control: UntypedFormControl): ValidatorFn =>
		ValidationHelper.maximumAmount(
			control,
			CurrencyHelper.unformatAmount(this.homeLoanDetailsForm?.controls['loanAmount'].value)
		) as ValidatorFn;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private applicationService: ApplicationService,
		private applicantService: ApplicantService,
		private liabilityService: LiabilityService,
		private liabilityDetailService: LiabilityDetailService,
		private addressService: AddressService,
		private loanDetailService: LoanDetailService,
		private overlayService: OverlayService,
		private changeDetectorRef: ChangeDetectorRef,
		private metadataService: MetadataService
	) {
		this.setFieldMetadata();
		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;

		this.homeLoanDetailsForm = this.formBuilder.group({
			id: [Constant.newId],
			applicationId: [this.existingApplicationId],
			applicantId: [this.applicationService.getStoredPrimaryApplicant()?.id, Validators.required],
			financialInstitution: [null, Validators.required],
			loanAmount: [null, [Validators.required, ValidationHelper.minimumAmount, ValidationHelper.maximumAmount]],
			repayment: this.formBuilder.group({
				amount: [null, [Validators.required, ValidationHelper.minimumAmount, this.maxRepaymentValidator]],
				frequency: [this.getDefaultFrequency()?.id, Validators.required]
			}),
			description: [''],
			addressId: [Constant.newId],
			address: [null],
			clearingFromThisLoan: [false]
		});

		this.validationErrors = {
			financialInstitution: {
				required: 'Select a financial institution'
			},
			loanAmount: {
				required: 'We need the loan amount',
				min: 'Loan amount cannot be zero',
				max: 'Loan amount cannot be more than $10,000,000'
			},
			applicantId: {
				required: 'Select who is this for'
			},
			repaymentAmount: {
				required: 'We need the repayment amount',
				min: 'Repayment amount cannot be zero',
				max: 'Repayment amount cannot be more than the loan amount'
			},
			address: {
				required: 'We need the property address',
				error: `That's not a valid address`
			}
		};

		this.selectedAddress = AddressHelper.getEmptyAddress();

		this.liabilityDetailService.addEditHomeLoan$
			.pipe(takeUntil(this.destroy$))
			.subscribe((homeLoan: HomeLoan | null) => {
				this.addEditHomeLoan(homeLoan);
				this.loadAddress();
			});

		this.loanDetailService.loanDetails$
			.pipe(takeUntil(this.destroy$), distinctUntilChanged())
			.subscribe((loanDetail: LoanDetail | null) => {
				this.isRefinance = loanDetail?.plan === RbaLendingPurpose.Refinance;
				this.changeDetectorRef.markForCheck();
			});

		this.popTrigger = this.isTouchDevice() ? 'click' : 'hover';
	}

	public get applicantId() {
		return this.homeLoanDetailsForm.controls.applicantId as UntypedFormControl;
	}

	public get financialInstitution() {
		return this.homeLoanDetailsForm.controls.financialInstitution as UntypedFormControl;
	}

	public get loanAmount() {
		return this.homeLoanDetailsForm.controls.loanAmount as UntypedFormControl;
	}

	public get repayment() {
		return this.homeLoanDetailsForm.controls.repayment as UntypedFormGroup;
	}

	public get addressId() {
		return this.homeLoanDetailsForm.controls.addressId as UntypedFormControl;
	}

	public get address() {
		return this.homeLoanDetailsForm.controls.address as UntypedFormControl;
	}

	public get clearingFromThisLoan() {
		return this.homeLoanDetailsForm.controls.clearingFromThisLoan as UntypedFormControl;
	}

	public get invalidData(): boolean {
		return !this.homeLoanDetailsForm.valid || (this.addressId.value <= 0 && !this.selectedAddress?.streetName);
	}

	public ngOnInit(): void {
		this.loanAmount.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.repayment.controls.amount.updateValueAndValidity();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public handleBackClick(): void {
		this.goBack.emit();
	}

	public updateAddress(address: SimpAddress): void {
		if (!AddressHelper.isValidAddress(address as Address)) {
			this.address.setErrors({ error: true });
		}
		this.selectedAddress = { ...AddressHelper.getEmptyAddress(), ...address };
	}

	public handleExistingAddressSelect(evt: any) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		if (evt.target.checked) {
			this.homeLoanDetailsForm.patchValue({
				address: null
			});
		}
	}

	public handleNewAddressSelect(evt: any) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		if (evt.target.checked) {
			this.homeLoanDetailsForm.patchValue({
				addressId: Constant.newId,
				address: null
			});
			this.selectedAddress = AddressHelper.getEmptyAddress();
		}
	}

	public onSubmit(): void {
		if (this.homeLoanDetailsForm.invalid) {
			return;
		}

		if (this.addressId.value <= 0 && !this.address.value) {
			this.address.setErrors({ required: true });
			return;
		}

		this.isSubmitting = true;
		this.address.disable();
		const liabilityToSave: HomeLoan = {
			...this.homeLoanDetailsForm.value,
			loanAmount: CurrencyHelper.unformatAmount(this.loanAmount.value),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			financialInstitution: this.financialInstitution.value.id,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			repaymentAmount: CurrencyHelper.unformatAmount(this.repayment.value.amount as string),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			repaymentFrequency: this.repayment.value.frequency,
			address: this.getApplicantAddress(this.addressId.value),
			percent: 100,
			clearingFromThisLoan: this.clearingFromThisLoan.value === 1
		} as HomeLoan;
		this.liabilityService
			.saveHomeLoan(liabilityToSave)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.liabilityService
					.getHomeLoansByApplication(liabilityToSave.applicationId)
					.pipe(takeUntil(this.destroy$))
					.subscribe((homeLoans) => {
						const savedHomeLoan = homeLoans.find((x) => x.id === result);
						this.update.emit(savedHomeLoan);
					});
			})
			.add(() => {
				this.isSubmitting = false;
				this.address.enable();
				this.changeDetectorRef.markForCheck();
			});
	}

	public deleteRecord(): void {
		this.liabilityService
			.deleteHomeLoan(this.homeLoanDetailsForm.controls.applicationId.value, this.homeLoanDetailsForm.controls.id.value)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.delete.emit(this.homeLoanDetailsForm.value);
			});
	}

	public handlePopoverShown(): void {
		if (this.isTouchDevice()) {
			this.overlayService.startOverlay(null);
		}
	}

	public handlePopoverHidden(): void {
		if (this.isTouchDevice()) {
			this.overlayService.stopOverlay();
		}
	}

	public populateOptions(options?: OptionMetadata[]): EnumObject[] {
		options?.sort((a: OptionMetadata, b: OptionMetadata) => a.sortOrder - b.sortOrder);

		return options as EnumObject[];
	}

	private getApplicantAddress(selectedAddressId: number): Address {
		if (selectedAddressId !== -1) {
			this.selectedAddress = {
				...this.existingAddresses.find((address) => address.id === selectedAddressId)?.address
			} as SimpAddress;
		}

		return {
			...this.selectedAddress,
			id: selectedAddressId
		} as Address;
	}

	private loadAddress(): void {
		const existingApplicationId = this.applicationService.getStoredApplicationId();
		if (existingApplicationId) {
			this.addressService
				.getByApplication(existingApplicationId)
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: Address[]) => {
					this.existingAddresses = result.map((address) => ({
						id: address.id,
						label: AddressHelper.ExtractAddress(address),
						address
					}));
					if (this.existingAddresses.length === 0) {
						this.addressId.setValue(Constant.newId);
					}
					this.changeDetectorRef.detectChanges();
				});
		}
	}

	private getDefaultFrequency(): EnumObject | undefined {
		return this.options.repaymentFrequencies.find(
			(frequency: EnumObject) => frequency.id === RepaymentFrequency.Yearly
		);
	}

	private addEditHomeLoan(homeLoan: HomeLoan | null): void {
		this.homeLoanDetailsForm.reset();
		if (homeLoan) {
			this.isEditMode = true;
			this.homeLoanDetailsForm.patchValue({
				id: homeLoan.id,
				applicationId: homeLoan.applicationId,
				applicantId: homeLoan.applicantId,
				description: homeLoan.description,
				financialInstitution: EnumHelper.getEnumObject(
					FinancialInstitution as unknown as { [index: string]: number },
					homeLoan.financialInstitution
				),
				loanAmount: homeLoan.loanAmount,
				repayment: {
					amount: homeLoan.repaymentAmount,
					frequency: homeLoan.repaymentFrequency
				},
				addressId: homeLoan.address?.id,
				clearingFromThisLoan: homeLoan.clearingFromThisLoan ? 1 : 0
			});
			// Mark the button group as touched so that the css kicks in for the unselected buttons within each group.
			// Only required while loading previously saved values from api.
			this.clearingFromThisLoan.markAsTouched();
		} else {
			this.isEditMode = false;
			this.homeLoanDetailsForm.patchValue({
				id: Constant.newId,
				applicationId: this.applicationService.getStoredApplicationId(),
				applicantId: this.applicationService.getStoredPrimaryApplicant()?.id,
				description: '',
				financialInstitution: null,
				loanAmount: null,
				repayment: {
					amount: null,
					frequency: this.getDefaultFrequency()?.id
				},
				clearingFromThisLoan: false
			});
		}
	}

	private isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	private setFieldMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const sectionMetadata: SubSectionMetadata = this.metadataService.getSubSectionMetadataByName(
				'Liabilities',
				LiabilityType.HomeLoan,
				LiabilityType.HomeLoan
			);
			this.sectionTitle = sectionMetadata.title ?? `Give us some details about this loan`;

			const fields: FieldMetadata[] = sectionMetadata.fields;
			this.whoseLoanConfig = this.metadataService.getFieldByName('WhoseLoan', fields);
			this.financialInstitutionConfig = this.metadataService.getFieldByName('FinancialInstitution', fields);
			this.addressConfig = this.metadataService.getFieldByName('Address', fields);
			this.loanAmountConfig = this.metadataService.getFieldByName('LoanAmount', fields);
			this.repaymentConfig = this.metadataService.getFieldByName('Repayment', fields);
			this.repaymentFrequencyConfig = this.metadataService.getFieldByName('RepaymentFrequency', fields);
			this.refinancedConfig = this.metadataService.getFieldByName('Refinanced', fields);

			this.options = {
				financialInstitutions: this.populateOptions(this.financialInstitutionConfig.options),
				repaymentFrequencies: this.populateOptions(this.repaymentFrequencyConfig.options),
				applicants: this.applicantService.getApplicants(true),
				clearingFromThisLoanOptions: [
					{ id: 1, label: 'Yes' },
					{ id: 0, label: 'No' }
				]
			};

			const buttons = sectionMetadata.buttons;
			this.cancelButtonConfig = this.metadataService.getButtonByName('Cancel', buttons);
			this.continueButtonConfig = this.metadataService.getButtonByName('Continue', buttons);

			this.changeDetectorRef.markForCheck();
		});
	}
}
