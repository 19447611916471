import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from '../api/application.service';
import { StepStatus, StepType } from '../model/enum.model';
import { StepResponse } from '../model/step-response.model';

@Injectable({ providedIn: 'root' })
export class ApplicationStepService implements OnDestroy {
	public stepUpdated$: Observable<StepResponse | undefined>;
	private stepUpdatedSource = new BehaviorSubject<StepResponse | undefined>(undefined);
	private destroy$: Subject<void> = new Subject();

	constructor(private applicationService: ApplicationService) {
		this.stepUpdated$ = this.stepUpdatedSource.asObservable();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public updateStep(type: StepType, status: StepStatus): void {
		const applicationId = this.applicationService.getStoredApplicationId();
		if (applicationId) {
			this.applicationService
				.setStepStatus(applicationId, type, status)
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					this.stepUpdatedSource.next({ type, status });
				});
		}
	}
}
