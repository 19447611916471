import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { BrandConfig } from '../model/brand.config';

@Injectable({ providedIn: 'root' })
export class BrandService extends BaseApiService<any> {
	public currentChannelKey = '';
	public currentBrandUrl = '';
	public currentBrandName$: Observable<string>;
	public brandConfig$: Observable<BrandConfig>;

	private currentBrandNameValue = '';
	private brandConfigValue: BrandConfig = {} as BrandConfig;
	private currentBrandName: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentBrandNameValue);
	private brandConfig: BehaviorSubject<BrandConfig> = new BehaviorSubject<BrandConfig>(this.brandConfigValue);
	private gaTracking?: { tag: string; useGTM: boolean };

	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setLadmRoute('Brand');
		this.currentBrandName$ = this.currentBrandName.asObservable();
		this.brandConfig$ = this.brandConfig.asObservable();
	}

	public setBrand(): void {
		this.extractBrandFromHostName();

		this.http
			.get<BrandConfig>(`assets/brands/${this.currentBrandNameValue}/config.json`)
			.subscribe((brandConfig: BrandConfig) => {
				this.brandConfigValue = brandConfig;
				this.brandConfig.next(this.brandConfigValue);

				const documentRoot = document.documentElement;
				Object.keys(brandConfig.cssVars).forEach((cssVar) => {
					documentRoot.style.setProperty(cssVar, brandConfig.cssVars[cssVar]);
				});

				if (this.gaTracking?.tag) {
					this.injectGATrackingScripts(this.gaTracking);
				}
			});

		document
			.getElementById('favIcon')
			?.setAttribute('href', `assets/brands/${this.currentBrandNameValue}/images/favicon.png`);
	}

	private extractBrandFromHostName(): void {
		const clientHost = window.location.host;
		if (clientHost.startsWith('localhost')) {
			this.currentChannelKey = '909EF647-79CB-4144-8A4D-726346DF1926'; // D1D4C905-F8FB-47B9-8C0E-4EA19EA7B8B0
			this.currentBrandNameValue = 'simpology';
			this.currentBrandUrl = 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png';
		} else {
			const matchedBrand = BRAND_MAPPING.find((mapping) => clientHost.startsWith(mapping.host));
			this.currentChannelKey = matchedBrand?.channelKey ?? '';
			this.currentBrandNameValue = matchedBrand?.brandName ?? 'simpology';
			this.currentBrandUrl = matchedBrand?.brandUrl ?? '';
			this.gaTracking = matchedBrand?.gaTracking;
		}
		this.currentBrandName.next(this.currentBrandNameValue);
	}

	private injectGATrackingScripts({ tag, useGTM }: { tag: string; useGTM: boolean }): void {
		if (useGTM) {
			this.injectGTMScripts(tag);
		} else {
			this.injectGTagScripts(tag);
		}
	}

	private injectGTMScripts(gaTag: string): void {
		if (gaTag) {
			const script = document.createElement('script');
			script.innerHTML = `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${gaTag}');
	  `;
			document.head.prepend(script);

			const noscript = document.createElement('noscript');
			noscript.innerHTML = `
		<noscript><iframe src=https://www.googletagmanager.com/ns.html?id=${gaTag}
		height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
	  `;
			document.body.prepend(noscript);
		}
	}

	private injectGTagScripts(gaTag: string): void {
		if (gaTag) {
			const head = document.getElementsByTagName('head')[0];

			const script1 = document.createElement('script');
			script1.async = true;
			script1.src = `https://www.googletagmanager.com/gtag/js?id=${gaTag}`;
			head.appendChild(script1);

			const script2 = document.createElement('script');
			script2.innerHTML = `
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag("js", new Date());
	  `;
			head.appendChild(script2);
		}
	}
}

export const BRAND_MAPPING: Array<{
	channelKey: string;
	host: string;
	brandName: string;
	brandUrl: string;
	gaTracking?: { tag: string; useGTM: boolean };
}> = [
	// Simpology branded instances
	{
		channelKey: '909EF647-79CB-4144-8A4D-726346DF1926',
		host: 'dev-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png'
	},
	{
		channelKey: '909EF647-79CB-4144-8A4D-726346DF1926',
		host: 's3-dev-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png'
	},
	{
		channelKey: 'F3EBAE94-5A85-4AF0-89AA-3B595BB639B6',
		host: 'demo-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png'
	},
	{
		channelKey: '714BF026-7624-439C-9CA5-B333205D2674',
		host: 'test-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png',
		gaTracking: {
			tag: 'G-KFJ39LGG13',
			useGTM: false
		}
	},
	{
		channelKey: '714BF026-7624-439C-9CA5-B333205D2674',
		host: 's3-test-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png',
		gaTracking: {
			tag: 'G-KFJ39LGG13',
			useGTM: false
		}
	},
	{
		channelKey: '714BF026-7624-439C-9CA5-B333205D2674',
		host: 'uat-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png'
	},
	{
		channelKey: '714BF026-7624-439C-9CA5-B333205D2674',
		host: 's3-uat-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png'
	},
	// Homestar
	{
		channelKey: '92AECAB7-E529-4584-8966-3D463C8D29F8',
		host: 'test-homestar-aip.simpology.com.au',
		brandName: 'homestar',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1034/BrandImages/1.png'
	},
	{
		channelKey: '4E479D69-E5FB-4BB5-920D-32F6125DEEBE',
		host: 'aip.simpology.com.au',
		brandName: 'homestar',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1651/BrandImages/1.png'
	},
	{
		channelKey: '8A0707AF-F04D-42F0-98B1-1BABA4465AB5',
		host: 'homestar-aip.simpology.com.au',
		brandName: 'homestar',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1651/BrandImages/1.png'
	},
	// BOQ
	{
		channelKey: '8A0EAC91-D9D6-47AB-8AEE-7D0B48E27CD3',
		host: 'test-boq-aip.simpology.com.au',
		brandName: 'boq',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1041/BrandImages/1.png'
	},
	// Pepper
	{
		channelKey: 'CA1F332D-7BB1-4A01-AA25-1F08A2AF23D2',
		host: 'pepper-aip.simpology.com.au',
		brandName: 'pepper',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1577/BrandImages/2.png'
	},
	// 360 MMS
	{
		channelKey: '05E29532-9AB4-4829-8B37-9BD79456E5F0',
		host: '360mms-aip.simpology.com.au',
		brandName: '360mms',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1680/BrandImages/2.png'
	},
	// CBA
	{
		channelKey: '0E582E14-CC9A-421C-B178-825BB72EC77A',
		host: 'cba-aip.simpology.com.au',
		brandName: 'cba',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1572/BrandImages/3.png'
	},
	//CBA Channlid=1755
	{
		channelKey: 'E91A5C42-2841-44B5-A4F1-70A8CA434CAE',
		host: 'cba-aip.simpology.com.au',
		brandName: 'cba',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1572/BrandImages/3.png'
	},
	// Bankwest
	{
		channelKey: '42940173-C27D-4DF0-A19E-817441FDEA5D',
		host: 'bankwest-aip.simpology.com.au',
		brandName: 'bankwest',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1576/BrandImages/2.png'
	},
	// PCCU
	{
		channelKey: '79E26259-9A58-42EE-B202-71D0CE5D0FC7',
		host: 'test-apply.peopleschoice.com.au',
		brandName: 'pccu',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1075/BrandImages/2.png',
		gaTracking: {
			tag: 'GTM-T6BQTJ8',
			useGTM: true
		}
	},
	{
		channelKey: 'A977B069-9E5F-486D-927B-AAF3172DA3E8',
		host: 'test.apply.peopleschoice.com.au',
		brandName: 'pccu',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1075/BrandImages/2.png',
		gaTracking: {
			tag: 'GTM-T6BQTJ8',
			useGTM: true
		}
	},
	{
		channelKey: 'E37FB4D7-EBE9-44CE-8A39-8EEC88652CEC',
		host: 'uat.apply.peopleschoice.com.au',
		brandName: 'pccu',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1102/BrandImages/1.png',
		gaTracking: {
			tag: 'GTM-T6BQTJ8',
			useGTM: true
		}
	},
	{
		channelKey: 'D4F72CDB-ED80-4724-BA35-DFBA6490B438',
		host: 'pccu-aip.simpology.com.au',
		brandName: 'pccu',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1642/BrandImages/2.png',
		gaTracking: {
			tag: 'GTM-T6BQTJ8',
			useGTM: true
		}
	},
	{
		channelKey: '348BED2E-5030-49F7-902A-28BD0816383C',
		host: 'apply.peopleschoice.com.au',
		brandName: 'pccu',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1714/BrandImages/2.png',
		gaTracking: {
			tag: 'GTM-T6BQTJ8',
			useGTM: true
		}
	},
	// CYBER CX
	{
		channelKey: '196FCFF4-A86F-4C20-BF24-5C50720AD3F3',
		host: 'cybercx1-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png'
	},
	{
		channelKey: 'B1644388-ADC1-4616-BF30-BC966F1E688D',
		host: 'cybercx2-aip.simpology.com.au',
		brandName: 'simpology',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1011/BrandImages/1.png'
	},
	// Hejaz Financial Services
	{
		channelKey: '82EF42C9-5174-4072-AA85-85358CE751A9',
		host: 'dev-hejaz-aip.simpology.com.au',
		brandName: 'hejaz',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1066/BrandImages/1.png'
	},
	{
		channelKey: 'C32507CF-99C6-4A8E-8589-AB0B2FA4DFE1',
		host: 'test-hejaz-aip.simpology.com.au',
		brandName: 'hejaz',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1086/BrandImages/1.png'
	},
	{
		channelKey: 'C32507CF-99C6-4A8E-8589-AB0B2FA4DFE1',
		host: 'uat-hejaz-aip.simpology.com.au',
		brandName: 'hejaz',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1086/BrandImages/1.png'
	},
	{
		channelKey: '296E9942-D2F2-4272-A369-55F18ED13437',
		host: 'hejaz-aip.simpology.com.au',
		brandName: 'hejaz',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1725/BrandImages/2.png'
	},
	// Move Bank
	{
		channelKey: 'E5A2A7AE-0A7C-4097-AD97-5511739ABBE5',
		host: 'dev-movebank-aip.simpology.com.au',
		brandName: 'movebank',
		brandUrl: 'https://tama-nonprod-demosecure-assets.s3.amazonaws.com/1050/BrandImages/1.png'
	},
	{
		channelKey: '5A91DAB1-74F1-473D-A80A-26FB8E1DE446',
		host: 'test-movebank-aip.simpology.com.au',
		brandName: 'movebank',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1050/BrandImages/1.png'
	},
	{
		channelKey: '5A91DAB1-74F1-473D-A80A-26FB8E1DE446',
		host: 'uat-movebank-aip.simpology.com.au',
		brandName: 'movebank',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1050/BrandImages/1.png'
	},
	{
		channelKey: '15F3B8DD-3454-41D6-A534-9B7101E3D6E7',
		host: 'movebank-aip.simpology.com.au',
		brandName: 'movebank',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1622/BrandImages/2.png'
	},
	// REA Group
	{
		channelKey: '61BD8633-507C-4C98-B7A6-A6503B364F7D',
		host: 'test-rea-aip.simpology.com.au',
		brandName: 'rea',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1137/BrandImages/1.png'
	},
	{
		channelKey: '08AF805F-1CDB-4C6B-9F1D-FAD944AB4471',
		host: 'uat-rea-aip.simpology.com.au',
		brandName: 'rea',
		brandUrl: 'https://tama-nonprod-testsecure-assets.s3.amazonaws.com/1097/BrandImages/1.png'
	},
	{
		channelKey: '356ACFFD-8D80-45ED-A52E-3AEC6CF053DF',
		host: 'rea-aip.simpology.com.au',
		brandName: 'rea',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1754/BrandImages/2.png'
	},
	//ANZ
	{
		channelKey: '2A2765A2-8431-496F-80A2-5F955A1CB080',
		host: 'anz-aip.simpology.com.au',
		brandName: 'anz',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1757/BrandImages/2.png'
	},
	//Ubank
	{
		channelKey: '5F318915-615D-4189-9CA0-452772397CAB',
		host: 'ubank-aip.simpology.com.au',
		brandName: 'ubank',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1760/BrandImages/2.png'
	},
	//Australian Military Bank
	{
		channelKey: 'C320E4B9-DD87-45EA-A069-4689744DBB6A',
		host: 'uat-amb-aip.simpology.com.au',
		brandName: 'amb',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1109/BrandImages/1.png'
	},
	{
		channelKey: '437FD92E-376F-43CF-9F7D-586BBE6B4B64',
		host: 'amb-aip.simpology.com.au',
		brandName: 'amb',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1737/BrandImages/2.png'
	},
	//Teachers Mutual Bank Limited
	{
		channelKey: 'D478198B-59B5-4D5D-8FCF-E3B2A7A2ABDC',
		host: 'uat-tmbl-aip.simpology.com.au',
		brandName: 'tmbl',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1125/BrandImages/1.png'
	},
	{
		channelKey: '8B0734CF-A943-4629-B797-965D182C9C24',
		host: 'tmbl-aip.simpology.com.au',
		brandName: 'tmbl',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1785/BrandImages/2.png'
	},
	//Teachers Mutual Bank
	{
		channelKey: '20100B40-2116-447D-A8A6-55EEBE19DB8D',
		host: 'uat-tmb-aip.simpology.com.au',
		brandName: 'tmb',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1118/BrandImages/1.png'
	},
	{
		channelKey: '35DCA00E-07D9-48B6-8BFD-4D38F027C657',
		host: 'tmb-aip.simpology.com.au',
		brandName: 'tmb',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1612/BrandImages/2.png'
	},
	//Firefighters Mutual Bank
	{
		channelKey: '9663A20B-534E-4ECD-AA0D-994CA2514215',
		host: 'uat-fmb-aip.simpology.com.au',
		brandName: 'fmb',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1124/BrandImages/1.png'
	},
	{
		channelKey: '967327DF-2F3E-4CFA-AB61-8649138A4EAD',
		host: 'fmb-aip.simpology.com.au',
		brandName: 'fmb',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1668/BrandImages/2.png'
	},
	//unibank
	{
		channelKey: 'D5E515E0-1013-4572-9C18-CBF92FA595DC',
		host: 'uat-unibank-aip.simpology.com.au',
		brandName: 'unibank',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1123/BrandImages/2.svg'
	},
	{
		channelKey: '0716C10F-D191-4161-9870-A5CF27362979',
		host: 'unibank-aip.simpology.com.au',
		brandName: 'unibank',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1669/BrandImages/2.png'
	},
	//Health Professionals Bank
	{
		channelKey: '551A5AAC-1E4B-44AF-BC2B-84081433E1E5',
		host: 'uat-hpb-aip.simpology.com.au',
		brandName: 'hpb',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1122/BrandImages/1.png'
	},
	{
		channelKey: 'DB073D22-5842-42F3-BB12-B846A49A2376',
		host: 'hpb-aip.simpology.com.au',
		brandName: 'hpb',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1670/BrandImages/2.png'
	},
	//AMP BANK
	{
		channelKey: 'D560E483-9F06-4256-BC42-30AAC0CDCDD9',
		host: 'uat-amp-aip.simpology.com.au',
		brandName: 'amp',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1139/BrandImages/2.png'
	},
	{
		channelKey: 'D412BA42-ADD7-4D0A-8C7E-1CB35711B92B',
		host: 'amp-aip.simpology.com.au',
		brandName: 'amp',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1581/BrandImages/2.png'
	},
	//Islamic Bank Australia
	{
		channelKey: 'F201E0A3-2335-4C59-9494-A01EFED41238',
		host: 'uat-islamicbank-aip.simpology.com.au',
		brandName: 'islamic',
		brandUrl: 'https://tama-nonprod-uatsecure-assets.s3.ap-southeast-2.amazonaws.com/1150/BrandImages/2.png'
	},
	{
		channelKey: 'A4DCA358-1EB1-4944-B07A-3DE6CA780976',
		host: 'islamicbank-aip.simpology.com.au',
		brandName: 'islamic',
		brandUrl: 'https://tama-prod-prodsecure-assets.s3.amazonaws.com/1800/BrandImages/2.png'
	}
];
