import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoanDetailService } from 'src/app/loan-details/api/loan-detail.service';
import { LoanDetail } from 'src/app/loan-details/model/loan-detail.model';
import { OverlayService } from 'src/app/overlay/overlay.service';
import { ApplicationService } from 'src/app/shared/api/application.service';
import { MetadataService } from 'src/app/shared/api/metadata.service';
import { AddressHelper } from 'src/app/shared/helper/address-helper';
import { CalculationHelper } from 'src/app/shared/helper/calculation-helper';
import { Constant } from 'src/app/shared/helper/constant';
import { Address } from 'src/app/shared/model/address.model';
import { AreaMetadata } from 'src/app/shared/model/area-metadata.model';
import { ButtonMetadata } from 'src/app/shared/model/button-metadata.model';
import {
	FrequencyShort,
	OtherIncomeType,
	PaygBasis,
	RbaLendingPurpose,
	StepStatus,
	StepType
} from 'src/app/shared/model/enum.model';
import { SectionMetadata } from 'src/app/shared/model/section-metadata.model';
import { ApplicantService } from 'src/app/shared/service/applicant.service';
import { ApplicationStepService } from 'src/app/shared/service/application-step.service';
import { NavigationService } from '../../../navigation/navigation.service';
import { IncomeService } from '../../api/income.service';
import { IncomeType } from '../../enums/income-type.enum';
import { Income } from '../../model/income.model';
import { OtherIncome } from '../../model/other-income.model';
import { RentalIncome } from '../../model/rental-income.model';
import { WorkIncome } from '../../model/work-income.model';
import { IncomeDetailService } from '../../service/income-detail.service';

@Component({
	selector: 'income-details',
	templateUrl: './income-details.component.html',
	styleUrls: ['./income-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideInOut', [
			state(
				'show',
				style({
					transform: 'translate3d(0,0,0)'
				})
			),
			state(
				'hide',
				style({
					transform: 'translate3d(100%, 0, 0)'
				})
			),
			transition('show => hide', animate('400ms ease-in-out')),
			transition('hide => show', animate('400ms ease-in-out'))
		])
	]
})
export class IncomeDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('overlayContent', { static: false })
	public overlayContent!: TemplateRef<HTMLElement>;

	public additionalInfoState: 'show' | 'hide' = 'hide';
	public additionalInfoType: IncomeType = IncomeType.Employment;
	public incomeType = IncomeType;
	public income: Income;
	public numberOfApplicants: number;
	public popTrigger: string;
	public isRefinance = false;

	public areaMetadata: AreaMetadata = {} as AreaMetadata;
	public employmentIncomeSection: SectionMetadata = {} as SectionMetadata;
	public rentalIncomeSection: SectionMetadata = {} as SectionMetadata;
	public otherIncomeSection: SectionMetadata = {} as SectionMetadata;
	public finishButtonConfig: ButtonMetadata = {} as ButtonMetadata;
	public doneButtonConfig: ButtonMetadata = {} as ButtonMetadata;

	private existingApplicationId = Constant.newId;
	private applicantId = Constant.newId;
	private destroy$: Subject<void> = new Subject();

	public constructor(
		private navigationService: NavigationService,
		private applicationService: ApplicationService,
		private incomeService: IncomeService,
		private changeDetectorRef: ChangeDetectorRef,
		private incomeDetailService: IncomeDetailService,
		private applicantService: ApplicantService,
		private applicationStepService: ApplicationStepService,
		private overlayService: OverlayService,
		private metadataService: MetadataService,
		private loanDetailService: LoanDetailService
	) {
		this.setSectionMetadata();

		this.existingApplicationId = this.applicationService.getStoredApplicationId() ?? Constant.newId;
		this.applicantId = this.applicationService.getStoredPrimaryApplicant()?.id ?? Constant.newId;
		this.income = {
			applicationId: Constant.newId,
			workIncome: [],
			rentalIncome: [],
			otherIncome: []
		} as Income;
		this.numberOfApplicants = this.applicantService.getApplicants(false).length;

		this.popTrigger = this.isTouchDevice() ? 'click' : 'hover';

		this.loanDetailService.loanDetails$.pipe(takeUntil(this.destroy$)).subscribe((loanDetail: LoanDetail | null) => {
			this.isRefinance = loanDetail?.plan === RbaLendingPurpose.Refinance;
			this.changeDetectorRef.markForCheck();
		});
	}

	public get paygBasis(): typeof PaygBasis {
		return PaygBasis;
	}

	public get otherIncomeType(): typeof OtherIncomeType {
		return OtherIncomeType;
	}

	public get totalAnnualIncome(): number {
		const workIncomeAmount = this.income.workIncome
			.map(
				(i) =>
					CalculationHelper.CalculateAnnualAmount(i.salaryAmount, i.salaryFrequency) +
					CalculationHelper.CalculateAnnualAmount(i.otherAmount ?? 0, i.otherFrequency ?? FrequencyShort.Yearly)
			)
			.reduce((a, b) => a + b, 0);
		const rentalIncomeAmount = this.income.rentalIncome
			.map((i) => CalculationHelper.CalculateAnnualAmount(i.amount, i.frequency))
			.reduce((a, b) => a + b, 0);
		const otherIncomeAmount = this.income.otherIncome
			.map((i) => CalculationHelper.CalculateAnnualAmount(i.amount, i.frequency))
			.reduce((a, b) => a + b, 0);
		return workIncomeAmount + rentalIncomeAmount + otherIncomeAmount;
	}

	public get hasIncome(): boolean {
		return (
			(!!this.income?.workIncome?.length ||
				!!this.income?.rentalIncome?.length ||
				!!this.income?.otherIncome?.length) &&
			this.totalAnnualIncome !== 0
		);
	}

	public ngOnInit(): void {
		this.navigationService.syncNavigation(StepType.Income);
		this.loadIncome();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	public getApplicantName(applicantId: number): string {
		return this.applicantService.getApplicantName(applicantId)?.name ?? '';
	}

	public buildAddressLine(address: Address): string {
		return AddressHelper.buildAddressLine(address);
	}

	public onSubmit(): void {
		this.navigationService.goToNextStep(SimpProgressBarStatus.Complete);
		this.applicationStepService.updateStep(StepType.Income, StepStatus.Complete);
	}

	public toggleAdditionalInfoPage(): void {
		this.additionalInfoState = this.additionalInfoState === 'hide' ? 'show' : 'hide';
	}

	public addEditAdditionalIncome(incomeType: IncomeType, income?: WorkIncome | RentalIncome | OtherIncome): void {
		this.additionalInfoType = incomeType;
		switch (incomeType) {
			case IncomeType.Employment:
				this.incomeDetailService.addEditWorkIncome(income as WorkIncome);
				break;
			case IncomeType.Rental:
				this.incomeDetailService.addEditRentalIncome(income as RentalIncome);
				break;
			case IncomeType.Other:
				this.incomeDetailService.addEditOtherIncome(income as OtherIncome);
				break;
		}
		this.toggleAdditionalInfoPage();
	}

	public updateIncome(income: any): void {
		this.toggleAdditionalInfoPage();
		switch (this.additionalInfoType) {
			case IncomeType.Employment:
				this.updateWorkIncome(income);
				break;
			case IncomeType.Rental:
				this.updateRentalIncome();
				break;
			case IncomeType.Other:
				this.updateOtherIncome(income);
				break;
		}
	}

	public handleIncomeDelete(income: WorkIncome | RentalIncome | OtherIncome): void {
		this.toggleAdditionalInfoPage();
		let index = -1;
		switch (this.additionalInfoType) {
			case IncomeType.Employment:
				{
					const workIncomes = this.income.workIncome;
					index = workIncomes.findIndex((i) => i.id === income.id);
					if (index > -1) {
						this.income.workIncome.splice(index, 1);
					}
				}
				break;
			case IncomeType.Rental:
				{
					const rentalIncomes = this.income.rentalIncome;
					index = rentalIncomes.findIndex((i) => i.id === income.id);
					if (index > -1) {
						this.income.rentalIncome.splice(index, 1);
					}
				}
				break;
			case IncomeType.Other:
				{
					const otherIncomes = this.income.otherIncome;
					index = otherIncomes.findIndex((i) => i.id === income.id);
					if (index > -1) {
						this.income.otherIncome.splice(index, 1);
					}
				}
				break;
		}

		if (!this.hasIncome) {
			this.applicationStepService.updateStep(StepType.Income, StepStatus.Incomplete);
			this.navigationService.updateStepStatus(StepType.Income, SimpProgressBarStatus.NotStarted);
		}
	}

	public getYearlyWorkIncome(incomeRecord: WorkIncome): number {
		return (
			CalculationHelper.CalculateAnnualAmount(incomeRecord.salaryAmount, incomeRecord.salaryFrequency) +
			CalculationHelper.CalculateAnnualAmount(
				incomeRecord.otherAmount ?? 0,
				incomeRecord.otherFrequency ?? FrequencyShort.Yearly
			)
		);
	}

	public getYearlyRentalIncome(incomeRecord: RentalIncome): number {
		return CalculationHelper.CalculateAnnualAmount(incomeRecord.amount, incomeRecord.frequency);
	}

	public getYearlyOtherIncome(incomeRecord: OtherIncome): number {
		return CalculationHelper.CalculateAnnualAmount(incomeRecord.amount, incomeRecord.frequency);
	}

	public finishLater(): void {
		this.overlayService.startOverlay(this.overlayContent);
	}

	public closeOverlay(exit = false): void {
		this.overlayService.stopOverlay();
		if (exit) {
			this.navigationService.exitApp();
		}
	}

	public showOtherIncomeDescription(income: OtherIncome): boolean {
		return income.type === OtherIncomeType.OtherIncome;
	}

	public handlePopoverShown(): void {
		if (this.isTouchDevice()) {
			this.overlayService.startOverlay(null);
		}
	}

	public handlePopoverHidden(): void {
		if (this.isTouchDevice()) {
			this.overlayService.stopOverlay();
		}
	}

	private updateWorkIncome(workIncome: WorkIncome): void {
		const workIncomes = this.income.workIncome;
		const index = workIncomes.findIndex((income) => income.id === workIncome.id);
		if (index > -1) {
			this.income.workIncome.splice(index, 1, workIncome);
		} else {
			this.income.workIncome.push(workIncome);
		}
	}

	private updateRentalIncome(): void {
		this.incomeService
			.getByApplication(this.existingApplicationId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: Income) => {
				this.income.rentalIncome = [...result.rentalIncome];
				this.changeDetectorRef.markForCheck();
			});
	}

	private updateOtherIncome(otherIncome: OtherIncome): void {
		const otherIncomes = this.income.otherIncome;
		const index = otherIncomes.findIndex((income) => income.id === otherIncome.id);
		if (index > -1) {
			this.income.otherIncome.splice(index, 1, otherIncome);
		} else {
			this.income.otherIncome.push(otherIncome);
		}
	}

	private loadIncome(): void {
		if (this.existingApplicationId !== Constant.newId && this.applicantId !== Constant.newId) {
			this.incomeService
				.getByApplication(this.existingApplicationId)
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: Income) => {
					const existingIncome = {
						applicationId: this.existingApplicationId,
						workIncome: [...result.workIncome],
						rentalIncome: [...result.rentalIncome],
						otherIncome: [...result.otherIncome]
					};
					this.income = { ...existingIncome };
					this.changeDetectorRef.markForCheck();
				});
		} else {
			this.navigationService.goToHome();
		}
	}

	private isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	private setSectionMetadata(): void {
		this.metadataService.metadata$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.areaMetadata = this.metadataService.getAreaMetadataByName('Income');
			this.employmentIncomeSection = this.metadataService.getSectionMetadataByName('Income', IncomeType.Employment);
			this.rentalIncomeSection = this.metadataService.getSectionMetadataByName('Income', IncomeType.Rental);
			this.otherIncomeSection = this.metadataService.getSectionMetadataByName('Income', IncomeType.Other);

			const buttons: ButtonMetadata[] = this.areaMetadata.buttons;
			this.finishButtonConfig = this.metadataService.getButtonByName('Finish', buttons);
			this.doneButtonConfig = this.metadataService.getButtonByName('Done', buttons);
		});
	}
}
