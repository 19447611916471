import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ServiceabilityService {
	public serviceabilityResult$: Observable<boolean>;
	private serviceabilityResultValue = new BehaviorSubject<boolean>(false);

	constructor() {
		this.serviceabilityResult$ = this.serviceabilityResultValue.asObservable();
	}

	public serviceabilityResult(result: boolean): void {
		this.serviceabilityResultValue.next(result);
	}
}
