<div class="review-details">
	<div class="review-details__section-title col-12 col-md-6">
		<h3 class="simp-h3 simp-text--primary review-details__title--mobile" *ngIf="isAppEditable">
			{{ reviewAreaMetadata.title }}
		</h3>
		<h1 class="simp-h1 simp-text--primary review-details__title--desktop" *ngIf="isAppEditable">
			{{ reviewAreaMetadata.title }}
		</h1>
		<h3 class="simp-h3 simp-text--primary review-details__title--mobile" *ngIf="!isAppEditable">
			{{ summaryAreaMetadata.title }}
		</h3>
		<h1 class="simp-h1 simp-text--primary review-details__title--desktop" *ngIf="!isAppEditable">
			{{ summaryAreaMetadata.title }}
		</h1>
		<h4 class="simp-h4 simp-text--grey90" *ngIf="isAppEditable" [innerHtml]="reviewSection.title"></h4>
		<h4 class="simp-h4 simp-text--grey90" *ngIf="!isAppEditable" [innerHtml]="summarySection.title"></h4>
	</div>

	<div class="review-details__form-container col-12 col-md-6">
		<form class="review-details__form" (ngSubmit)="onSubmit()">
			<div
				class="review-details__scrollable-area col-12"
				[class.review-details__scrollable-area--full-height]="!isAppEditable"
			>
				<div
					class="review-details__category simp-margin-bottom-small"
					*ngIf="loanDetailsSubSection.visible === 'Visible'"
				>
					<div class="review-details__category-title simp-padding-vertical-base">
						{{ loanDetailsTitle }}
						<button
							type="button"
							class="btn btn-sm simp-button review-details__category-add review-details__category-add--margin-auto"
							(click)="editSection(stepType.LoanDetails, toggleLoanDetails && isAppEditable)"
							*ngIf="!toggleLoanDetails || (toggleLoanDetails && isAppEditable)"
						>
							{{ toggleLoanDetails && isAppEditable ? "Edit" : "Review" }}<i
								class="fas fa-pencil simp-margin-left-base"
								*ngIf="toggleLoanDetails && isAppEditable"
							></i>
						</button>
						<i
							class="fas review-details__chevron simp-text--grey70 simp-margin-left-base"
							[class.fa-chevron-down]="!toggleLoanDetails"
							[class.fa-chevron-up]="toggleLoanDetails"
							(click)="toggleLoanDetails = !toggleLoanDetails"
						></i>
					</div>
					<ng-container *ngTemplateOutlet="loanDetailsReview"></ng-container>
				</div>

				<div
					class="review-details__category simp-margin-bottom-small"
					*ngIf="applicantDetailsSubSection.visible === 'Visible'"
				>
					<div class="review-details__category-title simp-padding-vertical-base">
						Personal details
						<button
							type="button"
							class="btn btn-sm simp-button review-details__category-add review-details__category-add--margin-auto"
							(click)="editSection(stepType.PersonalDetails, togglePersonalDetails && isAppEditable)"
							*ngIf="!togglePersonalDetails || (togglePersonalDetails && isAppEditable)"
						>
							{{ togglePersonalDetails && isAppEditable ? "Edit" : "Review" }}<i
								class="fas fa-pencil simp-margin-left-base"
								*ngIf="togglePersonalDetails && isAppEditable"
							></i>
						</button>
						<i
							class="fas review-details__chevron simp-text--grey70 simp-margin-left-base"
							[class.fa-chevron-down]="!togglePersonalDetails"
							[class.fa-chevron-up]="togglePersonalDetails"
							(click)="togglePersonalDetails = !togglePersonalDetails"
						></i>
					</div>
					<ng-container *ngTemplateOutlet="personalDetailsReview"></ng-container>
				</div>

				<div class="review-details__category simp-margin-bottom-small" *ngIf="incomeSubSection.visible === 'Visible'">
					<div class="review-details__category-title simp-padding-top-base">
						Income
						<div class="review-details__income-summary">
							{{ totalAnnualIncome | currency: "USD":"symbol":"1.0-0" }} p.a.
						</div>
						<button
							type="button"
							class="btn btn-sm simp-button review-details__category-add"
							(click)="editSection(stepType.Income, toggleIncomeDetails && isAppEditable)"
							*ngIf="!toggleIncomeDetails || (toggleIncomeDetails && isAppEditable)"
						>
							{{ toggleIncomeDetails && isAppEditable ? "Edit" : "Review" }}<i
								class="fas fa-pencil simp-margin-left-base"
								*ngIf="toggleIncomeDetails && isAppEditable"
							></i>
						</button>
						<i
							class="fas review-details__chevron simp-text--grey70 simp-margin-left-base"
							[class.fa-chevron-down]="!toggleIncomeDetails"
							[class.fa-chevron-up]="toggleIncomeDetails"
							(click)="toggleIncomeDetails = !toggleIncomeDetails"
						></i>
					</div>
					<ng-container *ngTemplateOutlet="incomeDetailsReview"></ng-container>
				</div>

				<div class="review-details__category simp-margin-bottom-small" *ngIf="expensesSubSection.visible === 'Visible'">
					<expenses-review></expenses-review>
				</div>

				<div class="review-details__category simp-margin-bottom-small" *ngIf="assetsSubSection.visible === 'Visible'">
					<div class="review-details__category-title simp-padding-top-base">
						Assets
						<div class="review-details__income-summary">{{ totalAssets | currency: "USD":"symbol":"1.0-0" }}</div>
						<button
							type="button"
							class="btn btn-sm simp-button review-details__category-add"
							(click)="editSection(stepType.Assets, toggleAssetDetails && isAppEditable)"
							*ngIf="!toggleAssetDetails || (toggleAssetDetails && isAppEditable)"
						>
							{{ toggleAssetDetails && isAppEditable ? "Edit" : "Review" }}<i
								class="fas fa-pencil simp-margin-left-base"
								*ngIf="toggleAssetDetails && isAppEditable"
							></i>
						</button>
						<i
							class="fas review-details__chevron simp-text--grey70 simp-margin-left-base"
							[class.fa-chevron-down]="!toggleAssetDetails"
							[class.fa-chevron-up]="toggleAssetDetails"
							(click)="toggleAssetDetails = !toggleAssetDetails"
						></i>
					</div>
					<ng-container *ngTemplateOutlet="assetDetailsReview"></ng-container>
				</div>

				<div
					class="review-details__category simp-margin-bottom-small"
					*ngIf="liabilitiesSubSection.visible === 'Visible'"
				>
					<div class="review-details__category-title simp-padding-top-base">
						Liabilities
						<div class="review-details__income-summary">{{ totalLiabilities | currency: "USD":"symbol":"1.0-0" }}</div>
						<button
							type="button"
							class="btn btn-sm simp-button review-details__category-add"
							(click)="editSection(stepType.Liabilities, toggleLiabilityDetails && isAppEditable)"
							*ngIf="!toggleLiabilityDetails || (toggleLiabilityDetails && isAppEditable)"
						>
							{{ toggleLiabilityDetails && isAppEditable ? "Edit" : "Review" }}<i
								class="fas fa-pencil simp-margin-left-base"
								*ngIf="toggleLiabilityDetails && isAppEditable"
							></i>
						</button>
						<i
							class="fas review-details__chevron simp-text--grey70 simp-margin-left-base"
							[class.fa-chevron-down]="!toggleLiabilityDetails"
							[class.fa-chevron-up]="toggleLiabilityDetails"
							(click)="toggleLiabilityDetails = !toggleLiabilityDetails"
						></i>
					</div>
					<ng-container *ngTemplateOutlet="liabilityDetailsReview"></ng-container>
				</div>
			</div>

			<div class="review-details__buttons-container simp-row simp-margin-bottom-medium" *ngIf="isAppEditable">
				<button type="submit" class="btn btn-primary btn-lg simp-button" [disabled]="isSubmitting">
					{{ submitButtonConfig.title }}
				</button>
			</div>
		</form>
	</div>
</div>

<ng-template #loanDetailsReview>
	<div class="review-details__summary simp-padding-small" *ngIf="toggleLoanDetails">
		<div class="simp-row">
			<div class="col-6">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("ApplicationType") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ loanDetails.plan | enumDesc: rbaLendingPurpose }}
				</h5>
			</div>

			<div class="col-6">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("Property") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90 review-details__summary-text--no-wrap">
					{{ loanDetails.primaryPurpose | enumDesc: primaryPurposeLoanPurpose }}{{ loanDetails.state ? ", " +
					(loanDetails.state | enumDesc: auState) : "" }}
				</h5>
			</div>
		</div>

		<div class="simp-row simp-margin-top-small">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("Applicants") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ loanDetails.numberOfApplicants }} {{ loanDetails.numberOfApplicants === 1 ? "Applicant" : "Applicants " +
					(loanDetails.isMultiHousehold ? "in different households" : "in same household") }}
				</h5>
			</div>
		</div>

		<div class="simp-row simp-margin-top-small">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("Dependants") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">{{ loanDetails.noOfDependants }}</h5>
			</div>
		</div>

		<div class="simp-row simp-margin-top-small">
			<div class="col-6">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("BorrowingAmount") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ loanDetails.loanAmount | currency: "USD":"symbol":"1.0-0" }}
				</h5>
			</div>

			<div class="col-6" *ngIf="loanDetails.plan === rbaLendingPurpose.PurchaseExistingDwelling && loanDetails.deposit">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("Deposit") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ loanDetails.deposit | currency: "USD":"symbol":"1.0-0" }}
				</h5>
			</div>
			<div class="col-6" *ngIf="loanDetails.plan === rbaLendingPurpose.Refinance && loanDetails.estimatedValue">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("EstimatedValue") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ loanDetails.estimatedValue | currency: "USD":"symbol":"1.0-0" }}
				</h5>
			</div>
		</div>

		<div class="simp-row simp-margin-top-small" *ngIf="loanDetails.address">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLoanDetailsLabel("Address") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light">{{ buildAddressLine(loanDetails.address) }}</h5>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #personalDetailsReview>
	<div class="review-details__summary simp-padding-small" *ngIf="togglePersonalDetails">
		<h5 class="simp-h5 simp-text--bold" *ngIf="showAdditionalApplicant">
			{{ getApplicantDetailsLabel("PrimaryApplicantHeader") }}
		</h5>
		<div class="simp-row" [class.simp-margin-top-base]="showAdditionalApplicant">
			<div class="col-6">
				<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("Name") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ primaryApplicant.title | enumDesc: nameTitle }} {{ primaryApplicant.firstName }} {{
					primaryApplicant.lastName }}
				</h5>
			</div>

			<div class="col-6">
				<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("Mobile") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">{{ primaryApplicant.mobilePhoneNumber }}</h5>
			</div>
		</div>

		<div class="simp-row simp-margin-top-small">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("Email") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">{{ primaryApplicant.email }}</h5>
			</div>
		</div>
		<div class="simp-row simp-margin-top-small" *ngIf="primaryApplicant.dateOfBirth">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("DateOfBirth") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">{{ primaryApplicant.dateOfBirth }}</h5>
			</div>
		</div>

		<div class="simp-row simp-margin-top-small">
			<div class="col-6">
				<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("MaritalStatus") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ primaryApplicant.maritalStatus | enumDesc: maritalStatus }}
				</h5>
			</div>

			<div class="col-6" *ngIf="showPartnerCoApplicant">
				<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("PartnerCoApplicant") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
					{{ primaryApplicant.isPartnerCoApplicant ? "Yes" : "No" }}
				</h5>
			</div>
		</div>

		<div *ngIf="showAdditionalApplicant" class="simp-margin-top-medium">
			<h5 class="simp-h5 simp-text--bold">{{ getApplicantDetailsLabel("CoApplicantHeader") }}</h5>
			<div class="simp-row simp-margin-top-base">
				<div class="col-6">
					<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("Name") }}</h6>
					<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
						{{ additionalApplicant.title | enumDesc: nameTitle }} {{ additionalApplicant.firstName }} {{
						additionalApplicant.lastName }}
					</h5>
				</div>

				<div class="col-6">
					<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("MaritalStatus") }}</h6>
					<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">
						{{ additionalApplicant.maritalStatus | enumDesc: maritalStatus }}
					</h5>
				</div>
			</div>
		</div>

		<div *ngIf="showAdditionalApplicant" class="simp-row simp-margin-top-small">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getApplicantDetailsLabel("Email") }}</h6>
				<h5 class="simp-h5 simp-text--extra-light simp-text--grey90">{{ additionalApplicant.email }}</h5>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #incomeDetailsReview>
	<div class="review-details__summary simp-padding-small simp-margin-top-small" *ngIf="toggleIncomeDetails">
		<div class="simp-row" *ngIf="incomeDetails.workIncome && incomeDetails.workIncome.length > 0">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getIncomeLabel("Employment") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90"
					*ngFor="let incomeRecord of incomeDetails.workIncome"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(incomeRecord.applicantId) + ", " : "" }}{{ incomeRecord.basis |
					enumDesc: paygBasis }}, {{ getYearlyWorkIncome(incomeRecord) | currency: "USD":"symbol":"1.0-0" }} per year
				</h5>
			</div>
		</div>

		<div
			class="simp-row simp-margin-top-small"
			*ngIf="incomeDetails.rentalIncome && incomeDetails.rentalIncome.length > 0"
		>
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getIncomeLabel("Rental") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90 review-details__summary-text--truncate"
					*ngFor="let incomeRecord of incomeDetails.rentalIncome"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(incomeRecord.applicantId) + ", " : "" }} {{
					getYearlyRentalIncome(incomeRecord) | currency: "USD":"symbol":"1.0-0" }} per year, {{
					buildAddressLine(incomeRecord.address) }}
				</h5>
			</div>
		</div>

		<div
			class="simp-row simp-margin-top-small"
			*ngIf="incomeDetails.otherIncome && incomeDetails.otherIncome.length > 0"
		>
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getIncomeLabel("Other") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90"
					*ngFor="let incomeRecord of incomeDetails.otherIncome"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(incomeRecord.applicantId) + ", " : "" }}{{ incomeRecord.type |
					enumDesc: otherIncomeType }}, {{ getYearlyOtherIncome(incomeRecord) | currency: "USD":"symbol":"1.0-0" }} per
					year
				</h5>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #assetDetailsReview>
	<div class="review-details__summary simp-padding-small simp-margin-top-small" *ngIf="toggleAssetDetails">
		<div class="simp-row" *ngIf="assetDetails.propertyAsset && assetDetails.propertyAsset.length > 0">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getAssetsLabel("Property") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90 review-details__summary-text--truncate"
					*ngFor="let assetRecord of assetDetails.propertyAsset"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(assetRecord.applicantId) + ", " : "" }}{{
					getCalculatedAssetValue(assetRecord) | currency: "USD":"symbol":"1.0-0" }}, {{
					buildAddressLine(assetRecord.address) }}
				</h5>
			</div>
		</div>

		<div
			class="simp-row simp-margin-top-small"
			*ngIf="assetDetails.savingsAsset && assetDetails.savingsAsset.length > 0"
		>
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getAssetsLabel("Savings") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90 review-details__summary-text--truncate"
					*ngFor="let assetRecord of assetDetails.savingsAsset"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(assetRecord.applicantId) + ", " : "" }}{{ assetRecord.type |
					enumDesc: financialAssetType }}, {{ assetRecord.value | currency: "USD":"symbol":"1.0-0" }}
				</h5>
			</div>
		</div>

		<div class="simp-row simp-margin-top-small" *ngIf="assetDetails.otherAsset && assetDetails.otherAsset.length > 0">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getAssetsLabel("Other") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90"
					*ngFor="let assetRecord of assetDetails.otherAsset"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(assetRecord.applicantId) + ", " : "" }}{{ assetRecord.type |
					enumDesc: nonRealEstateAssetType }}, {{ assetRecord.value | currency: "USD":"symbol":"1.0-0" }}
				</h5>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #liabilityDetailsReview>
	<div class="review-details__summary simp-padding-small simp-margin-top-small" *ngIf="toggleLiabilityDetails">
		<div class="simp-row" *ngIf="totalLiabilities === 0">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLiabilitiesLabel("NoLiability") }}</h6>
			</div>
		</div>

		<div class="simp-row" *ngIf="liabilityDetails.creditCard && liabilityDetails.creditCard.length > 0">
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLiabilitiesLabel("CreditCard") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90"
					*ngFor="let creditCardRecord of liabilityDetails.creditCard"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(creditCardRecord.applicantId) + ", " : "" }} {{
					creditCardRecord.creditLimit | currency: "USD":"symbol":"1.0-0" }} limit, {{ creditCardRecord.issuer |
					enumDesc: financialInstitution }}
				</h5>
			</div>
		</div>

		<div
			class="simp-row simp-margin-top-small"
			*ngIf="liabilityDetails.personalLoan && liabilityDetails.personalLoan.length > 0"
		>
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLiabilitiesLabel("PersonalLoan") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90"
					*ngFor="let personalLoanRecord of liabilityDetails.personalLoan"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(personalLoanRecord.applicantId) + ", " : "" }} {{
					personalLoanRecord.financialInstitution | enumDesc: financialInstitution }}, {{ personalLoanRecord.loanAmount
					| currency: "USD":"symbol":"1.0-0" }}
				</h5>
			</div>
		</div>

		<div
			class="simp-row simp-margin-top-small"
			*ngIf="liabilityDetails.homeLoan && liabilityDetails.homeLoan.length > 0"
		>
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLiabilitiesLabel("HomeLoan") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90 review-details__summary-text--truncate"
					*ngFor="let homeLoanRecord of liabilityDetails.homeLoan"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(homeLoanRecord.applicantId) + ", " : "" }} {{
					homeLoanRecord.loanAmount | currency: "USD":"symbol":"1.0-0" }}, {{ buildAddressLine(homeLoanRecord.address)
					}}, {{ homeLoanRecord.financialInstitution | enumDesc: financialInstitution }}
				</h5>
			</div>
		</div>

		<div
			class="simp-row simp-margin-top-small"
			*ngIf="liabilityDetails.otherLiability && liabilityDetails.otherLiability.length > 0"
		>
			<div class="col-12">
				<h6 class="simp-h6 simp-text--grey70">{{ getLiabilitiesLabel("Other") }}</h6>
				<h5
					class="simp-h5 simp-text--extra-light simp-text--grey90"
					*ngFor="let otherLiabilityRecord of liabilityDetails.otherLiability"
				>
					{{ numberOfApplicants > 1 ? getApplicantName(otherLiabilityRecord.applicantId) + ", " : "" }} {{
					otherLiabilityRecord.type | enumDesc: liabilityTypeLimited }}, {{ otherLiabilityRecord.outstandingBalance |
					currency: "USD" }}
				</h5>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #overlayContent>
	<i class="fas fa-check-circle check-icon simp-margin-bottom-medium"></i>
	<h4 class="simp-h4">{{ loadingMessage }}</h4>
</ng-template>
